import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER } from '../fragments/fullFragmentReturnToCustomer';

export const FULL_FRAGMENT_RETURN_TO_CUSTOMER_SUB = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
  fragment FullFragmentReturnToCustomerSub on ReturnToCustomerSubNotification {
    filterSessionId
    data {
      action
      returnToCustomer {
        ...FullFragmentReturnToCustomer
      }
    }
  }
`;
