type ApolloError = {
    name: string; // "ApolloError"
    graphQLErrors: any[];
    protocolErrors: any[];
    clientErrors: any[];
    networkError: {
        headers: any;
        status: number; // like 500
        statusText: string; // like "Internal Server Erro"
        url: string; // like "http://localhost:4000/graphql"
        ok: boolean,
        name: string; // like "HttpErrorResponse"
        message: string; // like "Http failure response for http://localhost:4000/graphql: 500 Internal Server Error"
        error: {
            data: any,
            errors: Array<{
                message: string,
                locations: Array<{ line: number, column: number }>
                path: string[]
            }>
        }
    }
}

type ClientError = {
    message: string;
    locations: Array<[{
        line: number;
        column: number;
    }]>
}

export class CatchError {

    get message(): string {
        const apolloMessage = this._tryGetApolloErrorMessage();
        if (apolloMessage.found) {
            return apolloMessage.value;
        }

        const clientMessage = this._tryGetClientErrorMessage();
        if (clientMessage.found) {
            return clientMessage.value;
        }

        return JSON.stringify(this._error);
    }

    get error() {
        return this._error;
    }
    private _error: any;

    constructor(error: any) {
        this._error = error;
    }

    private _tryGetApolloErrorMessage(): Message | MessageNotFound {
        try {
            const apolloError = this._error as ApolloError;

            const message = apolloError.networkError.error.errors[0].message;
            return new Message(message);
        } catch (error) {
            return new MessageNotFound();
        }
    }

    private _tryGetClientErrorMessage(): Message | MessageNotFound {
        try {
            const clientError = this._error as ClientError;
            const message = clientError.message;
            return typeof message === 'undefined'
                ? new MessageNotFound()
                : new Message(message);

        } catch (error) {
            return new MessageNotFound();
        }
    }
}

class Message {
    found: true = true;
    get value(): string {
        return this._value;
    }

    private _value: string;

    constructor(value: string) {
        this._value = value;
    }
}

class MessageNotFound {
    found: false = false;
}