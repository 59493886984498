import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_INVENTORY_ASSET = gql`
  fragment FullFragmentInventoryAsset on InventoryAssetOutput {
    id
    tenantId
    inventoryId
    tenantAssetId
    confirmedAt
    confirmedBy
  }
`;
