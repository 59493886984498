<h1 class="flex-row align-items-center">
  <span>
    Booking
    <span class="uppercase color-primary-400 font-weight-500">
      {{ actionType?.name }}
    </span>
  </span>
  <span class="flex-grow-1"></span>
  <button
    mat-icon-button
    (click)="onClickClose()"
    [disabled]="!bookingFinished"
  >
    <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
  </button>
</h1>

<div class="content">
  <div class="flex-column gap-small">
    <div
      *ngFor="let result of bookResults | keyvalue"
      class="flex-row gap-normal align-items-center"
    >
      <span class="font-weight-500">{{ result.key }}</span>
      <lib-spinner
        *ngIf="result.value?.ongoing; else finishedElement"
      ></lib-spinner>
      <ng-template #finishedElement>
        <mat-icon
          *ngIf="result.value?.finishedOk"
          fontSet="material-symbols-outlined"
        >
          check
        </mat-icon>

        <mat-icon
          *ngIf="!result.value?.finishedOk"
          fontSet="material-symbols-outlined"
          class="color-warn-400"
          [matTooltip]="result.value.details ?? ''"
        >
          error
        </mat-icon>
      </ng-template>
    </div>
  </div>

  <div *ngIf="bookingFinished" class="flex-column mt-normal mat-body">
    <span>Booking finished.</span>
    <span *ngIf="!bookingFinishedOk">
      There were errors. Please try again later or contact support for help.
    </span>
    <button
      mat-stroked-button
      (click)="close()"
      class="mt-small align-self-start"
    >
      OK
    </button>
  </div>
</div>
