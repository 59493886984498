import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_PLAN } from '../fragments/fullFragmentPlan';

export const FULL_FRAGMENT_PLAN_SUB = gql`
  ${FULL_FRAGMENT_PLAN}
  fragment FullFragmentPlanSub on PlanSubNotification {
    filterSessionId
    data {
      action
      relevantAssetIds
      plan {
        ...FullFragmentPlan
      }
    }
  }
`;
