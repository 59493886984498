import { Injectable } from '@angular/core';
import { Operator } from 'projects/shared/src/lib/classes/operator';
import { Subject, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserOutput } from 'projects/shared/src/lib/graphql/output/userOutput';
import { SelectionService } from './selection.service';
import { UserConfigService } from 'projects/shared/src/lib/services/user-config.service';

@Injectable({
  providedIn: 'root',
})
export class InitialLoadingService {
  // #region Public Properties

  get initialLoadingProgress() {
    return this._initialLoadingProgress;
  }
  get hasInitialLoadingFinished() {
    return this._hasInitialLoadingFinished;
  }
  get hasInitialLoadingFinishedChanged() {
    return this._hasInitialLoadingFinishedChanged;
  }
  get hasInitialLoadingFinishedWithErrors() {
    return this._hasInitialLoadingFinishedWithErrors;
  }

  // get myUser2() {
  //   return this._myUser;
  // }

  // #endregion Public Properties

  // #region Private Properties

  private _operator = new Operator();
  private _initialLoadingProgress = 0;
  private _hasInitialLoadingFinished: boolean | undefined;
  private _hasInitialLoadingFinishedWithErrors: boolean | undefined;
  private _myUser: UserOutput | undefined;
  private _hasInitialLoadingFinishedChanged = new Subject<boolean>();

  // #endregion Private Properties

  // #region Constructor

  constructor(
    private _http: HttpClient,
    private _userConfigService: UserConfigService,
    private _selectionService: SelectionService
  ) {}

  // #endregion Constructor

  // #region Public Methods

  async loadInitialDataAsync() {
    this._operator.addPromiseTask(this._loadMyUserAsync.bind(this));
    this._operator.addPromiseTask(this._setSelectionsAsync.bind(this));

    this._operator.progress.subscribe({
      next: async (progress) => {
        this._initialLoadingProgress = progress;

        if (this._operator.isFinished) {
          if (!this._operator.withErrors) {
            // Make sure that the last progress update to 100% is shown before
            // removing the loading panel.
            await new Promise((x) => setTimeout(x, 1000));
            this._hasInitialLoadingFinished = true;
            this._hasInitialLoadingFinishedWithErrors = false;
            this._hasInitialLoadingFinishedChanged.next(true);
          } else {
            this._hasInitialLoadingFinished = true;
            this._hasInitialLoadingFinishedWithErrors = true;
            this._hasInitialLoadingFinishedChanged.next(false);
          }
        }
      },
    });

    await this._operator.executeTasksAsync();
  }

  async reloadMyUser() {
    await this._loadMyUserAsync();
    await this._setSelectionsAsync();
  }

  // #endregion Public Methods

  // #region Private Methods

  private async _loadMyUserAsync() {
    const url = environment.apiBaseUrl + '/api/myUser';
    this._myUser = (await firstValueFrom(this._http.get(url))) as UserOutput;
    this._selectionService.updateMyUser(this._myUser);
    if (this._myUser?.userConfig) {
      this._userConfigService.init(this._myUser.userConfig);
    }
  }

  private async _setSelectionsAsync() {
    if (
      typeof this._myUser === 'undefined' ||
      typeof this._myUser.userRoles === 'undefined' ||
      this._myUser.userRoles == null ||
      !Array.isArray(this._myUser.userRoles) ||
      this._myUser.userRoles.length === 0
    ) {
      return;
    }

    const userRoles = this._myUser.userRoles;

    try {
      // 1. Make sure that a user config is available.
      let userConfig = this._userConfigService.userConfig;

      if (!userConfig) {
        // This will NEVER happen since the backend always provides a
        // user config object ("empty" for the first login).
        throw new Error('The userConfig object from the backend is not available.');
      } else {
        // 2. Check config against previously loaded data.
        // Does the user config reference data that is "not there anymore"
        // (e.g. due to access rights changes)?
        // ATTENTION: Set the tenantId first as it is required to be included as header
        // in every graphql communication.
        if (
          !userConfig.tenantId ||
          !userRoles.map((x) => x.tenantId ?? 'na').includes(userConfig.tenantId)
        ) {
          this._selectionService.selectedTenant = userRoles[0].tenant;
          await this._userConfigService.updateAsync({
            tenantId: userRoles[0].tenantId,
          });
        } else {
          this._selectionService.selectedTenant = userRoles.find(
            (x) => x.tenantId === userConfig?.tenantId
          )?.tenant;
        }
      }
    } catch (error) {
      console.log(error);
      // Do nothing else here.
    }
  }

  // #endregion Private Methods
}
