import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  get apiFileUrl(): string {
    if (this._apiFileUrl) {
      return this._apiFileUrl;
    }

    const adjustedBaseUrl = environment.apiBaseUrl[environment.apiBaseUrl.length - 1] === '/'
      ? environment.apiBaseUrl.slice(0, environment.apiBaseUrl.length - 2)
      : environment.apiBaseUrl;

    this._apiFileUrl = adjustedBaseUrl + '/api/file';
    return this._apiFileUrl;
  }

  // #region Private Properties

  private _apiFileUrl: string | undefined;

  // #endregion Private Properties


  // #region Init

  constructor(
    private _httpClient: HttpClient
  ) { }

  // #endregion Init


  // #region Public Methods

  async download(tenantId: string | undefined, fileId: string | undefined) {
    if (!tenantId || !fileId) {
      return;
    }

    const url = this.apiFileUrl + `/download/${tenantId}/${fileId}`;

    const data: any = await firstValueFrom(this._httpClient
      .get(url, { observe: 'response', responseType: 'blob' as 'json' }));

    let filename = 'unknown.file';

    const contentDisposition: string = data.headers.get('content-disposition');
    if (contentDisposition) {
      filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      filename = filename.replace(/\"/g, '');
    }

    const blob = new Blob([data.body], { type: data.body.type });

    saveAs(blob, filename);
  }

  async upload(tenantId: string | undefined, file: File | undefined): Promise<string | undefined> {
    if (!tenantId || !file) {
      return undefined;
    }

    const formData = new FormData();
    formData.append('file', file);

    const url = this.apiFileUrl + `/upload/${tenantId}`;

    interface FileRelayResponse {
      id: string;
    }

    const fileId = await firstValueFrom(this._httpClient.post(
      url
      , formData
      , {
        responseType: 'text'
      }
    ));

    return fileId;
  }

  // #endregion Public Methods
}
