<lib-spinner
  class="mt-normal"
  *ngIf="loading.isJobXOngoing[1]; else notLoading"
></lib-spinner>

<ng-template #notLoading>
  <lib-card class="system-card form-field-density-1">
    <lib-card-title class="mat-h2">SYSTEM PROPERTIES</lib-card-title>
    <lib-card-content>
      <div class="flex-column card-content">
        <span class="mat-body mb-large">
          Some properties are defined on system level.
          <span class="font-weight-500 color-warn-400"
            >They cannot be changed or edited</span
          >. They are always available on an asset, independent on the asset
          type.
        </span>

        <div class="card-content-properties-grid">
          <div class="left">
            <mat-action-list>
              <button
                *ngFor="let property of propertiesSystemLevel"
                mat-list-item
                (click)="selectedProperty = property"
                [ngClass]="
                  selectedProperty === property ? 'active-select-bg' : ''
                "
              >
                <span>{{ property.displayName }}</span>
              </button>
            </mat-action-list>
          </div>
          <div class="right mat-body">
            <!-- Row Display Name -->
            <mat-form-field appearance="fill">
              <mat-label>Display Name</mat-label>
              <input
                matInput
                readonly
                [ngModel]="selectedProperty?.displayName"
                required
              />
            </mat-form-field>

            <!-- Row Short Info -->
            <mat-form-field appearance="fill">
              <mat-label>Short Info</mat-label>
              <textarea
                matInput
                [ngModel]="selectedProperty?.shortInfo"
                rows="2"
                readonly
              ></textarea>
            </mat-form-field>

            <!-- Row Short Info -->
            <mat-form-field appearance="fill">
              <mat-label>Long Info</mat-label>
              <textarea
                matInput
                [ngModel]="selectedProperty?.longInfo"
                rows="4"
                readonly
              ></textarea>
            </mat-form-field>

            <!-- Row Type -->
            <mat-form-field appearance="fill">
              <mat-label>Type</mat-label>
              <input
                matInput
                readonly
                [ngModel]="selectedProperty?.propertyType?.name"
                required
              />
            </mat-form-field>

            <!-- Row Optional -->
            <section>
              <mat-checkbox
                class="pointer-events-none"
                [ngModel]="selectedProperty?.isNullable"
                >Optional</mat-checkbox
              >
            </section>

            <section class="mt-normal">TODO Options</section>
          </div>
        </div>
      </div>
    </lib-card-content>
  </lib-card>
</ng-template>
