import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { AssetsQueryArgs, AssetsQueryRoot, CreateAssetMutationArgs, CreateAssetMutationRoot } from 'projects/shared/src/lib/graphql/crud/asset';
import { v4 as uuid } from 'uuid';
import { Observable, firstValueFrom, map, startWith } from 'rxjs'
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { SelectionService } from '../../services/selection.service';
import { FULL_FRAGMENT_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentAsset';

export type NewAssetDialogData = {
  assetNames: string[];
}

@Component({
  selector: 'app-new-asset-dialog',
  templateUrl: './new-asset-dialog.component.html',
  styleUrls: ['./new-asset-dialog.component.scss']
})
export class NewAssetDialogComponent implements OnInit {
  // #region Public Properties

  uuid = uuid();
  name = new FormControl<string | null>(null, Validators.required)
  activity = false;
  errorMessage: string | undefined;
  filteredAssetNames: Observable<string[]> | undefined;

  get canApply(): boolean {
    return this.name.valid
      && this.name.value !== null
      && !this.data.assetNames.map(x => x.toLowerCase()).includes(this.name.value.toLowerCase());
  }

  // #endregion Public Properties


  // #region Init

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NewAssetDialogData
    , private _dialogRef: MatDialogRef<NewAssetDialogComponent>
    , private _apollo: Apollo
    , private _selectionService: SelectionService
  ) { }

  ngOnInit(): void {
    this.filteredAssetNames = this.name.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value == null
          ? this.data.assetNames
          : this._filter(value);
      })
    );
  }

  // #endregion Init


  // #region Public Methods

  async create() {
    if (!this.canApply) {
      return;
    }

    this.activity = true;
    this.errorMessage = undefined;

    const variables: CreateAssetMutationArgs = {
      data: {
        tenantId: this._selectionService.selectedTenant?.id ?? 'will not happen',
        name: this.name.value ?? 'will not happen'
      }
    }

    try {
      const result = await firstValueFrom(
        this._apollo.mutate<CreateAssetMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_ASSET}
            mutation CreateAsset($data: AssetInputCreate!) {
              createAsset(data: $data) {
                ...FullFragmentAsset
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (store, { data }) => {

            const variables: AssetsQueryArgs = {
              tenantId: this._selectionService.selectedTenant?.id ?? 'will not happen'
            };

            const cache = store.readQuery<AssetsQueryRoot>({
              query: gql`
                ${FULL_FRAGMENT_ASSET}
                query Assets($tenantId: String!) {
                  assets(tenantId: $tenantId) {
                    ...FullFragmentAsset
                  }
                }
              `,
              variables
            });
            console.log(cache);
            if (!cache || !data?.createAsset) {
              return;
            }

            const newArray = [...cache.assets, data.createAsset].sortBy(x => x.name);

            store.writeQuery<AssetsQueryRoot>({
              query: gql`
                ${FULL_FRAGMENT_ASSET}
                query Assets($tenantId: String!) {
                  assets(tenantId: $tenantId) {
                    ...FullFragmentAsset
                  }
                }
              `,
              variables,
              data: { assets: newArray }
            });
          }
        }));

      this._dialogRef.close(result.data?.createAsset);
    } catch (error: any) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.activity = false;
    }
  }

  // #endregion Public Methods


  // #region Private Methods

  private _filter(value: string): string[] {
    return this.data.assetNames.filter(x => x.toLowerCase().includes(value.toLowerCase()));
  }

  // #endregion Private Methods
}
