import {Directive, ElementRef, Input} from '@angular/core';
import {UserOutput} from "../../../../shared/src/lib/graphql/output/userOutput";
import {SelectionService} from "../services/selection.service";
import {RoleId} from "../../../../shared/src/lib/graphql/output/roleOutput";

@Directive({
  selector: '[appNotReader]',
  standalone:true
})
export class NotReaderDirective {
  constructor(private el: ElementRef, private selectionService: SelectionService) {
    if (this.isOnlyReader(this.selectionService.myUser)) {
      this.el.nativeElement.style.display = 'none'
    }
  }

  // TODO fix this
  private isOnlyReader(user?: UserOutput): boolean {
    let roles = user?.userRoles?.map((x) => x.roleId) ?? [];
    let minRole = Math.min(...roles) ?? 9;
    return minRole === RoleId.ASSET_READER;
  }
}
