import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  PropertiesTableFormatQueryArgs,
  PropertiesTableFormatQueryRoot,
} from '../graphql/crud/property';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_TABLE_COLUMN } from '../graphql/fragments/fullFragmentTableColumn';
import { TableColumnOutput } from '../graphql/output/tableColumnOutput';

export type AssetColumn = [field: string, headerName: string, headerNameFinal: string];

const enum SPECIAL_NAMED_COLUMN_FIELD {
  id = 'id',
  actionPreviouslyLocatedAt = 'actionPreviouslyLocatedAt',
  actionCurrentlyLocatedAt = 'actionCurrentlyLocatedAt',
  currentPlanName = 'currentPlanName',
  defectUser = 'defectUser',
  defectTime = 'defectTime',
  defectType = 'defectType',
  defectState = 'defectState',
  defectComment = 'defectComment',
  inventoryNames = 'inventoryNames',
  inventoryBooked = 'inventoryBooked',
  returned = 'returned',
}

export type AssetPropertyInfo = {
  id: string;
  name: string;
}

export const enum SPECIAL_NAMED_HEADER_NAME {
  AssetId = 'AssetId',
  ModifiedAt = 'Modified At',
  ModifiedBy = 'Modified By',
  Missing = 'Missing',
  MissingComment = 'Missing Comment',
  ImportedAt = 'Imported At',
}

@Injectable({
  providedIn: 'root',
})
export class AssetColumnService {
  /**
   * string: tenantId
   */
  readonly info = new Map<string, AssetColumn[]>();
  column_ModifiedAt = new Map<string, AssetColumn>();
  column_ModifiedBy = new Map<string, AssetColumn>();
  column_Missing = new Map<string, AssetColumn>();
  column_MissingComment = new Map<string, AssetColumn>();
  column_ImportedAt = new Map<string, AssetColumn>();

  constructor(private apollo: Apollo) {}

  async assureColumns(tenantId: string): Promise<AssetColumn[] | undefined> {
    if (!this.info.has(tenantId)) {
      const variables: PropertiesTableFormatQueryArgs = {
        tenantId,
      };

      const result = await firstValueFrom(
        this.apollo.query<PropertiesTableFormatQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TABLE_COLUMN}
            query PropertiesTableFormat($tenantId: String!) {
              propertiesTableFormat(tenantId: $tenantId) {
                ...FullFragmentTableColumn
              }
            }
          `,
          variables,
          fetchPolicy: 'cache-first',
        })
      );

      this.#build(tenantId, result.data.propertiesTableFormat);
    }

    return this.info.get(tenantId);
  }

  async getColumns(tenantId: string): Promise<Map<string, AssetPropertyInfo>> {
    console.log(tenantId)
    const ret = new Map<string, AssetPropertyInfo>();
    let columns = await this.assureColumns(tenantId) ?? [];
    columns.forEach((column) => {
      ret.set(column[1], { id: column[0], name: column[2] });
    });

    return ret;
  }

  #build(tenantId: string, columnRecords: TableColumnOutput[]): AssetColumn[] {
    const assetColumns: AssetColumn[] = [];

    for (const columnRecord of columnRecords) {
      let assetColumn: AssetColumn | undefined;

      // Renaming of header columns:
      if (columnRecord.field === SPECIAL_NAMED_COLUMN_FIELD.id) {
        assetColumn = [columnRecord.field, columnRecord.headerName, 'Asset ID'];
      } else if (columnRecord.field === SPECIAL_NAMED_COLUMN_FIELD.currentPlanName) {
        assetColumn = [columnRecord.field, columnRecord.headerName, 'Currently On Plan'];
      } else if (columnRecord.headerName === SPECIAL_NAMED_HEADER_NAME.AssetId) {
        assetColumn = [columnRecord.field, columnRecord.headerName, 'Asset Type'];
      } else {
        assetColumn = [columnRecord.field, columnRecord.headerName, columnRecord.headerName];
      }

      assetColumns.push(assetColumn);

      // Setting the special columns
      switch (columnRecord.headerName) {
        case SPECIAL_NAMED_HEADER_NAME.ModifiedAt:
          this.column_ModifiedAt.set(tenantId, assetColumn);
          break;

        case SPECIAL_NAMED_HEADER_NAME.ModifiedBy:
          this.column_ModifiedBy.set(tenantId, assetColumn);
          break;

        case SPECIAL_NAMED_HEADER_NAME.Missing:
          this.column_Missing.set(tenantId, assetColumn);
          break;

        case SPECIAL_NAMED_HEADER_NAME.MissingComment:
          this.column_MissingComment.set(tenantId, assetColumn);
          break;

        case SPECIAL_NAMED_HEADER_NAME.ImportedAt:
          this.column_ImportedAt.set(tenantId, assetColumn);
          break;

        default:
          break;
      }
    }

    this.info.set(tenantId, assetColumns);

    return assetColumns;
  }
}
