<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <div class="flex-column">
    <div style="height: 4px">
      <mat-progress-bar
        *ngIf="activity"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>

    <div *ngIf="data.text" [innerHTML]="data.text" class="mt-small"></div>

    <div *ngIf="errorMessage" class="error-message mt-normal">
      {{ errorMessage }}
    </div>
  </div>
</mat-dialog-content>
