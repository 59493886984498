<div class="space-plus">
    <ng-container *ngIf="withMenu">
        <mat-icon class="space-plus-icon" [matMenuTriggerFor]="menu">{{icon}}</mat-icon>
        <mat-menu #menu="matMenu">
            <ng-content></ng-content>
        </mat-menu>
    </ng-container>

    <ng-container *ngIf="!withMenu">
        <mat-icon class="space-plus-icon" (click)="onClickIcon($event)">{{icon}}</mat-icon>
    </ng-container>

    <div class="space-plus-row">&nbsp;</div>
</div>