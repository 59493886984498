<div *ngIf="!initialLoadingService.hasInitialLoadingFinished" class="loading">
  <div class="flex-column">
    <div>Global Asset Tracker (GAT)</div>
    <mat-progress-bar
      mode="determinate"
      [value]="initialLoadingService.initialLoadingProgress"
    ></mat-progress-bar>
  </div>
</div>

<div
  *ngIf="
    initialLoadingService.hasInitialLoadingFinished &&
    initialLoadingService.hasInitialLoadingFinishedWithErrors
  "
  class="loading-error"
>
  <div class="grid-element-one flex-column align-items-center">
    <div flex-column>
      <div class="grid-element-one">OFFLINE</div>
      <mat-progress-bar mode="determinate" [value]="0"></mat-progress-bar>
    </div>

    <div style="font-size: 14px; margin-top: 24px">
      We are sorry, but the service is currently <b>unavailable</b>.
    </div>
    <div style="font-size: 36px">Please try again later.</div>
    <a
      target="_blank"
      href="mailto:stefan.havertz@accenture.com"
      class="report-problem-email"
      >Report this problem</a
    >
  </div>
  <div class="grid-element-two flex-column align-items-center">
    <i class="grid-element-two material-icons-outlined">do_disturb_alt</i>
  </div>
</div>

<mat-drawer-container
  class="sidenav-container"
  *ngIf="
    initialLoadingService.hasInitialLoadingFinished &&
    !initialLoadingService.hasInitialLoadingFinishedWithErrors
  "
>
  <mat-drawer #drawer mode="over" class="sidenav-drawer">
    <!-- DRAWER -->

    <div class="content">
      <div class="sticky">
        <mat-toolbar>
          <span class="primary-color opacity-60"> Global Asset Tracker </span>

          <button
            mat-icon-button
            class="ml-normal"
            (click)="onLeftDrawerClose(drawer)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
      </div>

      <div class="left-menu-links">
        <a
          routerLink="/home"
          (click)="activeTabRouterLink = 'home'; onLeftDrawerClose(drawer)"
          routerLinkActive="active"
          matRipple
        >
          <mat-icon class="drawer-icon">home</mat-icon>
          <span>Home</span>
        </a>

        <a
          *ngIf="selectionService.isAssetArchitect"
          routerLink="/design"
          (click)="activeTabRouterLink = 'design'; onLeftDrawerClose(drawer)"
          routerLinkActive="active"
          matRipple
        >
          <mat-icon class="drawer-icon">storage</mat-icon>
          <span>Design</span>
        </a>

        <a
          *ngIf="selectionService.isAssetManagerOrReader"
          routerLink="/assets"
          (click)="activeTabRouterLink = 'assets'; onLeftDrawerClose(drawer)"
          routerLinkActive="active"
          matRipple
        >
          <mat-icon class="drawer-icon">view_week</mat-icon>
          <span>Assets</span>
        </a>

        <a
          *ngIf="
            selectionService.isTenantOwner || selectionService.isTenantAdmin
          "
          routerLink="/security"
          (click)="activeTabRouterLink = 'security'; onLeftDrawerClose(drawer)"
          routerLinkActive="active"
          matRipple
        >
          <mat-icon class="drawer-icon">verified_user</mat-icon>
          <span>Security</span>
        </a>

        <a
          *ngIf="
            selectionService.isTenantOwner || selectionService.isTenantAdmin
          "
          routerLink="/tenant"
          (click)="activeTabRouterLink = 'tenant'; onLeftDrawerClose(drawer)"
          routerLinkActive="active"
          matRipple
        >
          <mat-icon class="drawer-icon">business</mat-icon>
          <span>Tenant</span>
        </a>
      </div>

      <div class="lower-part">
        <div class="small mb-tiny">GAT version {{ version }}</div>
        <div class="flex-row column-gap-tiny mb-tiny">
          <a routerLink="terms-of-use">Terms of Use</a>
          <mat-divider [vertical]="true"></mat-divider>
          <a routerLink="privacy-statement">Privacy Statement</a>
        </div>
        <div class="small">&copy; {{ year }} Accenture</div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <!-- MAIN TOOLBAR -->
    <mat-toolbar color="primary">
      <span>Global Asset Tracker</span>
      <button mat-icon-button class="ml-normal" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <!-- <span class="spacer"></span> -->

      <div class="flex-grow-1 flex-row align-items-center">
        <div
          class="theme-overwrite flex-grow-1 flex-row gap-large justify-content-center"
        >
          <div
            class="dropdown-link"
            [class.active]="activeTabRouterLink === 'home'"
          >
            <button
              mat-flat-button
              color="primary"
              (click)="goTo($event, '/home'); activeTabRouterLink = 'home'"
            >
              Home
            </button>
          </div>

          <div
            *ngIf="selectionService.isAssetArchitect"
            class="dropdown-link"
            [class.active]="activeTabRouterLink === 'design'"
          >
            <button
              mat-flat-button
              color="primary"
              (click)="goTo($event, '/design'); activeTabRouterLink = 'design'"
            >
              Design
            </button>
          </div>

          <div
            *ngIf="selectionService.isAssetManagerOrReader"
            class="dropdown-link"
            style="padding-right: 12px"
            [class.active]="activeTabRouterLink === 'assets'"
          >
            <lib-select-button
              (onClick)="
                goTo($event, '/assets/assets-and-plans');
                activeTabRouterLink = 'assets'
              "
              text="Assets"
              yPosition="below"
              xPosition="after"
            >
              <button
                appNotReader
                mat-menu-item
                (click)="
                  goTo($event, '/assets/return-to-customer');
                  activeTabRouterLink = 'assets'
                "
              >
                <mat-icon fontSet="material-symbols-outlined">
                  local_shipping
                </mat-icon>
                <span>Return to Customer</span>
              </button>

              <button
                appNotReader
                mat-menu-item
                (click)="
                  goTo($event, '/assets/restore');
                  activeTabRouterLink = 'assets'
                "
              >
                <mat-icon fontSet="material-symbols-outlined">
                  restore icon
                </mat-icon>
                <span>Restore assets</span>
              </button>

            </lib-select-button>

          </div>

          <div
            *ngIf="
              selectionService.isTenantOwner || selectionService.isTenantAdmin
            "
            class="dropdown-link"
            [class.active]="activeTabRouterLink === 'security'"
          >
            <button
              mat-flat-button
              color="primary"
              (click)="
                goTo($event, '/security'); activeTabRouterLink = 'security'
              "
            >
              Security
            </button>
          </div>

          <div
            *ngIf="
              selectionService.isTenantOwner || selectionService.isTenantAdmin
            "
            class="dropdown-link"
            [class.active]="activeTabRouterLink === 'tenant'"
          >
            <button
              mat-flat-button
              color="primary"
              (click)="goTo($event, '/tenant'); activeTabRouterLink = 'tenant'"
            >
              Tenant
            </button>
          </div>
        </div>

        <div class="flex-row align-items-center">
          <a class="help-btn" href="https://kju.qwikinow.de/UnitTJrCe6x/Processes/GATGlobalAssetTracker" target="_blank">
            <mat-icon >help</mat-icon>
          </a>
          <app-toolbar-toggle class="mr-normalw" #toolbarToggle>
            <!-- <app-toolbar-toggle-item
              icon="notifications"
              value="notifications"
              [count]="0"
            >
            </app-toolbar-toggle-item> -->
            <!-- <app-toolbar-toggle-item
              icon="settings"
              value="settings"
            ></app-toolbar-toggle-item> -->
          </app-toolbar-toggle>

          <button mat-button [matMenuTriggerFor]="menu" class="user-account">
            <div class="flex-row align-items-center">
              <div class="flex-column align-items-end mr-small">
                <div class="mat-body-2">{{ account?.name }}</div>
                <div class="mat-caption">
                  {{ selectionService.selectedTenant?.name }}
                </div>
              </div>
              <mat-icon>account_circle</mat-icon>
            </div>
          </button>
        </div>

        <mat-menu #menu="matMenu">
          <div
            mat-menu-item
            class="pointer-events-none menu-item-small menu-item-bold"
          >
            {{ account?.name }}
          </div>
          <mat-divider></mat-divider>
          <button mat-menu-item class="menu-item-small" (click)="signOut()">
            Sign out
          </button>

          <ng-container *ngIf="selectionService.hasMultiTenantAccess">
            <mat-divider></mat-divider>
            <div class="menu-switch-tenant-area mat-caption">
              <div
                *ngFor="let tenant of selectionService.otherAvailableTenants"
                mat-menu-item
                (click)="onTenantButtonClicked(tenant)"
              >
                <mat-icon class="mr-normal icon">business</mat-icon>
                <span class="font-size-12">{{ tenant.name }}</span>
              </div>
            </div>
          </ng-container>
        </mat-menu>
      </div>
    </mat-toolbar>

    <!-- MAIN AREA -->
    <div class="main-area">
      <mat-drawer-container class="right-drawer-container">
        <!-- MAIN AREA RIGHT SIDEBAR -->
        <mat-drawer
          #rightDrawer
          mode="side"
          [opened]="toolbarToggle.selectedValue"
          position="end"
        >
          <div class="right-drawer">
            <div class="header">
              <button mat-icon-button (click)="toolbarToggle.unselectAll()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="content">
              <app-my-settings
                *ngIf="toolbarToggle.selectedValue === 'settings'"
              ></app-my-settings>
              <app-my-notifications
                *ngIf="toolbarToggle.selectedValue === 'notifications'"
              ></app-my-notifications>
            </div>
          </div>
        </mat-drawer>

        <!-- MAIN AREA CONTENT -->
        <mat-drawer-content>
          <!-- <mat-divider></mat-divider> -->
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- <div class="toast"> -->
<app-desktop-toast></app-desktop-toast>
<!-- </div> -->
