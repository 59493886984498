import { actionTypes } from '../graphql/enums/actionType';
import { PlanStepActionOutput } from '../graphql/output/planStepActionOutput';

export class PlanStepActionHelper {
  static getActionName(
    planStepAction: PlanStepActionOutput | undefined,
    lettercase: 'uppercase' | 'lowercase' | undefined = undefined
  ): string | undefined {
    if (!planStepAction) {
      return undefined;
    }

    const actionName = actionTypes.get(planStepAction.actionTypeId);
    if (!actionName) {
      return undefined;
    }

    if (typeof lettercase === 'undefined') {
      return actionName;
    }

    if (lettercase === 'lowercase') {
      return actionName.toLowerCase();
    }

    return actionName.toUpperCase();
  }

  static getFrom(planStepAction: PlanStepActionOutput | undefined): string | undefined {
    if (!planStepAction) {
      return undefined;
    }

    return (
      planStepAction.fromLocationId ??
      planStepAction.fromUserOid ??
      planStepAction.fromMail ??
      planStepAction.fromOther ??
      'na'
    );
  }

  static getTo(planStepAction: PlanStepActionOutput | undefined): string | undefined {
    if (!planStepAction) {
      return undefined;
    }

    return (
      planStepAction.toLocationId ??
      planStepAction.toUserOid ??
      planStepAction.toMail ??
      planStepAction.toOther ??
      'na'
    );
  }
}
