import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { OptionsQueryArgs, OptionsQueryRoot } from 'projects/shared/src/lib/graphql/crud/option';
import { FULL_FRAGMENT_OPTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOption';
import { OptionOutput } from 'projects/shared/src/lib/graphql/output/optionOutput';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApolloCacheService {

  constructor(private _apollo: Apollo) { }

  // #region Public Methods

  options(tenantId: string | undefined): OptionOutput[] | undefined {
    if (!tenantId) {
      return undefined;
    }

    const variables: OptionsQueryArgs = {
      tenantId
    };

    const result = this._apollo.client.readQuery<OptionsQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_OPTION}
        query Options($tenantId: String!) {
          options(tenantId: $tenantId) {
            ...FullFragmentOption
          }
        }
      `,
      variables
    });

    return result?.options;
  }

  // #endregion Public Methods
}
