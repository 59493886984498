import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  InventoryStatisticsQueryArgs,
  InventoryStatisticsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/inventory';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_INVENTORY_STATISTICS } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventoryStatistics';
import { InventoryStatisticsOutput } from 'projects/shared/src/lib/graphql/output/inventoryStatisticsOutput';

export type InventoryStatisticsData = {
  inventoryId: string;
  inventoryName: string;
};

type PieData = Array<{
  name: string;
  value: number;
}>;

type TopConfirmor = {
  position: number;
  noOfConfirmations: number;
  percent: number;
  userOid: string;
};

@Component({
  selector: 'app-inventory-statistics-dialog',
  templateUrl: './inventory-statistics-dialog.component.html',
  styleUrls: ['./inventory-statistics-dialog.component.scss'],
})
export class InventoryStatisticsDialogComponent implements OnInit {
  loading = false;
  inventoryStatistics: InventoryStatisticsOutput | undefined;
  pieData: PieData = [];
  topConfirmors: TopConfirmor[] = [];

  customColors = [
    {
      name: 'unconfirmed',
      value: '#f44336',
    },
    {
      name: 'confirmed',
      value: '#32cd32',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InventoryStatisticsData,
    private matDialog: MatDialogRef<InventoryStatisticsDialogComponent>,
    private apollo: Apollo,
    private toastService: DesktopToastService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  formatPercent(value: number) {
    return (value / (this.inventoryStatistics?.noOfOverallAssets ?? 1)).toFixed(1);
  }

  cancel() {
    this.matDialog.close(undefined);
  }

  async #loadData() {
    this.loading = true;

    try {
      const variables: InventoryStatisticsQueryArgs = {
        inventoryId: this.data.inventoryId,
      };

      const result = await firstValueFrom(
        this.apollo.query<InventoryStatisticsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_INVENTORY_STATISTICS}
            query InventoryStatistics($inventoryId: String!) {
              inventoryStatistics(inventoryId: $inventoryId) {
                ...FullFragmentInventoryStatistics
              }
            }
          `,
          variables,
          fetchPolicy: 'no-cache',
        })
      );
      this.inventoryStatistics = result.data.inventoryStatistics;
      this.#buildData(this.inventoryStatistics);
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
    } finally {
      this.loading = false;
    }
  }

  #buildData(statistics: InventoryStatisticsOutput) {
    this.pieData = [
      {
        name: 'confirmed',
        value: statistics.noOfConfirmedAssets,
      },
      {
        name: 'unconfirmed',
        value: statistics.noOfOverallAssets - statistics.noOfConfirmedAssets,
      },
    ];

    const topConfirmors: TopConfirmor[] = [];
    let i = 1;
    for (const topConfirmor of statistics.topConfirmors.sortBy(
      (x) => x.noOfConfirmations,
      'desc'
    )) {
      topConfirmors.push({
        position: i,
        noOfConfirmations: topConfirmor.noOfConfirmations,
        percent: topConfirmor.noOfConfirmations / statistics.noOfOverallAssets,
        userOid: topConfirmor.userOid,
      });

      i++;
    }
    this.topConfirmors = topConfirmors;
  }
}
