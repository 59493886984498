<div
  class="select-button"
  #selectButton
  #trigger
  [matMenuTriggerFor]="menu"
  style="pointer-events: none"
>
  <button
    mat-flat-button
    class="select-button-main"
    [color]="color"
    (click)="$event.stopPropagation(); onClickMainButton($event)"
    [disabled]="disabled"
    style="pointer-events: all"
  >
    <div class="select-button-main-inside">
      <mat-icon *ngIf="icon" fontSet="material-symbols-outlined">
        {{ icon }}
      </mat-icon>
      <span style="line-height: initial">{{ text }}</span>
    </div>
  </button>

  <button
    mat-flat-button
    class="select-button-drop"
    [color]="color"
    [disabled]="disabled"
    (click)="$event.stopPropagation(); onArrowClick(menu)"
    style="pointer-events: all"
  >
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu" [yPosition]="yPosition" [xPosition]="xPosition">
  <div
    [ngStyle]="{ 'minWidth.px': selectButton.clientWidth }"
    class="flex-column"
  >
    <ng-content></ng-content>
  </div>
</mat-menu>
