<h2 matDialogTitle>Edit Inventory</h2>

<mat-dialog-content>
  <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

  <ng-template #notLoading>
    <div class="flex-column gap-normal form-field-density-1">
      <div style="height: 4px">
        <mat-progress-bar
          *ngIf="activity"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>

      <mat-form-field appearance="fill" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input
          matInput
          required
          type="text"
          [autocomplete]="uuid1"
          [(ngModel)]="name"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" subscriptSizing="dynamic">
        <mat-label>Notes</mat-label>
        <textarea
          rows="3"
          [cdkTextareaAutosize]="true"
          matInput
          type="text"
          [autocomplete]="uuid2"
          [(ngModel)]="notes"
        ></textarea>
      </mat-form-field>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()" [disabled]="activity">
    Cancel
  </button>

  <button
    (click)="update()"
    mat-flat-button
    color="primary"
    [disabled]="!canUpdate"
  >
    Update
  </button>
</mat-dialog-actions>
