import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { TableColumnOutput } from 'projects/shared/src/lib/graphql/output/tableColumnOutput';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import {
  SetAssetMissingDialogComponent,
  SetAssetMissingDialogData,
} from '../../component-dialogs/set-asset-missing-dialog/set-asset-missing-dialog.component';
import {
  PropertiesTableFormatQueryArgs,
  PropertiesTableFormatQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/property';
import { FULL_FRAGMENT_TABLE_COLUMN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTableColumn';
import { firstValueFrom } from 'rxjs';
import { MtxPopoverPositionEnd, MtxPopoverPositionStart } from '@ng-matero/extensions/popover';
import {
  ReportDefectDialogComponent,
  ReportDefectDialogData,
} from '../../component-dialogs/report-defect-dialog/report-defect-dialog.component';
import {
  BookRealtimeActionDialogComponent,
  BookRealtimeActionDialogData,
} from '../../component-dialogs/book-realtime-action-dialog/book-realtime-action-dialog.component';
import { TenantDefectOutput } from 'projects/shared/src/lib/graphql/output/tenantDefectOutput';
import { AssetDefectsQueryArgs, AssetDefectsQueryRoot } from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { FULL_FRAGMENT_TENANT_DEFECT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantDefect';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';

enum SpecialHeaderName {
  Missing = 'Missing',
  MissingComment = 'Missing Comment',
  Name = 'Name',
  Id = 'UniqueId',
}

@Component({
  selector: 'app-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss'],
})
export class AssetInfoComponent implements OnInit {
  @Input({ required: true })
  assetId!: string;

  @Input()
  showDefectInfo: boolean = false;

  @Input()
  showMissingInfo: boolean = false;

  @Input()
  popoverPositionStart: MtxPopoverPositionStart = 'below';

  @Input()
  popoverPositionEnd: MtxPopoverPositionEnd = 'after';

  loading = false;
  isMissing = false;
  isDefect100 = false;
  isDefect = false;
  getAssetDefectsInProgress = false;
  needsVerification = false;
  get asset(): any {
    if (typeof this.#asset === 'undefined') {
      this.#asset = {};
      this.#fetchAsset();
    }
    return this.#asset;
  }
  set asset(value) {
    this.#asset = value;
  }
  get assetDefects(): TenantDefectOutput[] {
    if (typeof this.#assetDefects === 'undefined') {
      this.#assetDefects = [];
      this.#fetchAssetDefects();
    }

    return this.#assetDefects;
  }

  tableColumns: TableColumnOutput[] = [];
  specialTableColumn_Missing: TableColumnOutput | undefined;

  #asset: object | undefined;
  #assetDefects: TenantDefectOutput[] | undefined;

  constructor(
    private _assetService: AssetService,
    private _apollo: Apollo,
    private _matDialog: MatDialog,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
  ) {}

  ngOnInit(): void {
    this.#loadDataOnce();
  }

  setMissingOrAvailable() {
    const data: SetAssetMissingDialogData = {
      setAsMissing: !(this.asset[this.specialTableColumn_Missing?.field ?? 'na'] === true),
      assets: [this.asset],
      missingPropertyName: this.specialTableColumn_Missing?.field ?? 'na',
    };

    const dialog = this._matDialog.open(SetAssetMissingDialogComponent, {
      autoFocus: false,
      data,
      minWidth: 480,
    });
  }

  reportDefect() {
    const verify = this.asset.defectType === 1;
    const data: ReportDefectDialogData = {
      assetId: this.assetId,
      tryToVerify: verify,
    };

    const dialog = this._matDialog.open(ReportDefectDialogComponent, {
      autoFocus: false,
      minWidth: 680,
      maxWidth: 800,
      data,
    });
  }

  bookAction() {
    const data: BookRealtimeActionDialogData = {
      assets: [this.asset],
    };

    const dialog = this._matDialog.open(BookRealtimeActionDialogComponent, {
      data,
      autoFocus: false,
      minWidth: 640,
      disableClose: true,
    });
  }

  async #fetchAsset() {
    this.loading = true;
    try {
      const asset = await this._assetService.fetch(this.assetId);
      this.asset = asset;
      this.isDefect100 = asset.defectState === 3;
      this.isDefect = asset.defectState === 2;
      this.needsVerification = asset.defectType === 1;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async #loadDataOnce() {
    try {
      const variables: PropertiesTableFormatQueryArgs = {
        tenantId: this._assetService.tenantId ?? 'na',
      };

      const result = await firstValueFrom(
        this._apollo.query<PropertiesTableFormatQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TABLE_COLUMN}
            query PropertiesTableFormat($tenantId: String!) {
              propertiesTableFormat(tenantId: $tenantId) {
                ...FullFragmentTableColumn
              }
            }
          `,
          variables,
          fetchPolicy: 'cache-first',
        })
      );

      this.tableColumns = result.data.propertiesTableFormat;
      this.#setSpecialTableColumns(this.tableColumns);
    } catch (error) {
      console.log(error);
    }
  }

  #setSpecialTableColumns(tableColumns: TableColumnOutput[]) {
    this.specialTableColumn_Missing = tableColumns.find(
      (x) => x.headerName === SpecialHeaderName.Missing
    );
  }

  async #fetchAssetDefects() {
    this.getAssetDefectsInProgress = true;
    try {
      const variables: AssetDefectsQueryArgs = {
        assetId: this.assetId,
      };

      const result = await firstValueFrom(
        this._apollo.query<AssetDefectsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_DEFECT}
            query AssetDefects($assetId: String!) {
              assetDefects(assetId: $assetId) {
                ...FullFragmentTenantDefect
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.#assetDefects = result.data.assetDefects.sortBy((x) => x.timestamp, 'desc');
    } catch (error) {
    } finally {
      this.getAssetDefectsInProgress = false;
    }
  }
}
