import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export type AssetFetchPolicy = 'cache-first' | 'cache-and-network' | 'network-only';

class CacheObject {
  asset: { [key: string]: any } = {};

  constructor(data: any) {
    this.update(data);
  }

  update(data: any) {
    for (const key of Object.keys(data)) {
      this.asset[key] = data[key];
    }
  }
}

type BodyById = {
  assetIds: string[];
  returnState: 'all' | 'only' | 'none' | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  get tenantId() {
    return this.#tenantId;
  }

  get cache() {
    return this.#cache;
  }

  #cache = new Map<string, CacheObject>();
  #apiUrl: string | undefined;
  #tenantId: string | undefined;

  constructor(private _http: HttpClient) {}

  setApiEndpoint(url: string) {
    this.#apiUrl = url;
  }

  setTenantId(tenantId: string) {
    this.#tenantId = tenantId;
  }

  getCachedAsset(assetId: string): any | undefined {
    const cachedObject = this.#cache.get(assetId);
    return cachedObject?.asset;
  }

  getCachedAssets(assetIds: string[]): any[] {
    const cachedObjects: CacheObject[] = [];
    for (const assetId of assetIds) {
      const cachedObject = this.#cache.get(assetId);
      if (cachedObject) {
        cachedObjects.push(cachedObject);
      }
    }

    return cachedObjects.map((x) => x.asset);
  }

  /**
   * @param assetId
   * @param fetchPolicy An optional policy for caching the data. Defaults to 'cache-first' if not set.
   * @param returnState An optional filter indicating what assets to load (defaults to 'none')
   * ('all' = load also assets including the returned to customer's /
   * 'only' = load only assets that are in a "return to customer" process /
   * 'none' = load only assets that are NOT in a "return to customer" process)
   * @returns
   */
  async fetch(
    assetId: string,
    fetchPolicy: AssetFetchPolicy | undefined = 'cache-first',
    returnState: 'all' | 'only' | 'none' | undefined = undefined
  ): Promise<any> {
    if (!this.#apiUrl) {
      throw new Error("AssetService: Error, please call 'setApiEndpoint' first.");
    }

    if (!this.#tenantId) {
      throw new Error("AssetService: Error, please call 'setTenantId' first.");
    }

    const url =
      this.#apiUrl + (this.#apiUrl[this.#apiUrl.length - 1] !== '/' ? '/' : '') + this.#tenantId;
    const body: BodyById = {
      assetIds: [assetId],
      returnState,
    };

    // CACHE-FIRST (return from cache if available, otherwise perform a network call)
    if (fetchPolicy === 'cache-first') {
      let cachedObject = this.#cache.get(assetId);
      if (cachedObject) {
        // Cache hit. Don't perform a network call.
        return cachedObject.asset;
      }

      // Cache miss. Perform network call.
      const assets = await firstValueFrom(this._http.post<any[]>(url, body));
      cachedObject = new CacheObject(assets[0]);
      this.#cache.set(assetId, cachedObject);
      return cachedObject.asset;
    }

    // NETWORK_ONLY (always perform a network call)
    if (fetchPolicy === 'network-only') {
      const assets = await firstValueFrom(this._http.post<any[]>(url, body));
      if (assets.length === 0) {
        return undefined;
      }

      let cacheObject = this.#cache.get(assetId);
      if (!cacheObject) {
        cacheObject = new CacheObject(assets[0]);
        this.#cache.set(assetId, cacheObject);
      } else {
        cacheObject.update(assets[0]);
      }
      return cacheObject.asset;
    }

    // CACHE-AND-NETWORK (return from cache (if available) but always perform network call)
    let cachedObject = this.#cache.get(assetId);
    if (!cachedObject) {
      cachedObject = new CacheObject({ id: 'na' });
      this.#cache.set(assetId, cachedObject);
    }
    firstValueFrom(this._http.post<any[]>(url, body)).then((assets) => {
      let cachedObject = this.#cache.get(assetId);
      if (!cachedObject) {
        // Should not happen as we have created it above.
        cachedObject = new CacheObject(assets[0]);
        this.#cache.set(assetId, cachedObject);
      } else {
        // This should be the case. Always.
        cachedObject.update(assets[0]);
      }
    });
    return cachedObject.asset;
  }

  /** Default fetchPolicy: cache-first */
  async fetchMultiple(
    assetIds: string[],
    fetchPolicy: AssetFetchPolicy | undefined = 'cache-first',
    returnState: 'all' | 'only' | 'none' | undefined = undefined
  ): Promise<any[]> {
    if (!this.#apiUrl) {
      throw new Error("AssetService: Error, please call 'setApiEndpoint' first.");
    }

    if (!this.#tenantId) {
      throw new Error("AssetService: Error, please call 'setTenantId' first.");
    }

    const url =
      this.#apiUrl + (this.#apiUrl[this.#apiUrl.length - 1] !== '/' ? '/' : '') + this.#tenantId;
    // const body = {
    //   assetIds: [assetId],
    // };

    // CACHE-FIRST (return from cache if available, otherwise perform a network call)
    if (fetchPolicy === 'cache-first') {
      const cachedObjects: CacheObject[] = [];
      const uncachedAssetIds: string[] = [];
      for (const assetId of assetIds) {
        let cachedObject = this.#cache.get(assetId);
        if (cachedObject) {
          cachedObjects.push(cachedObject);
        } else {
          uncachedAssetIds.push(assetId);
        }
      }

      if (uncachedAssetIds.length > 0) {
        const body: BodyById = {
          assetIds: uncachedAssetIds,
          returnState,
        };
        const assets = await firstValueFrom(this._http.post<any[]>(url, body));
        for (const asset of assets) {
          let cachedObject = this.#cache.get(asset.id);
          if (cachedObject) {
            // Should not happen.
            cachedObject.update(asset);
          } else {
            // Should happen always.
            cachedObject = new CacheObject(asset);
            this.#cache.set(asset.id, cachedObject);
          }
          cachedObjects.push(cachedObject);
        }
      }

      return cachedObjects.map((x) => x.asset);
    }

    // NETWORK-ONLY
    if (fetchPolicy === 'network-only') {
      const body: BodyById = {
        assetIds,
        returnState,
      };

      const assets = await firstValueFrom(this._http.post<any[]>(url, body));
      const cachedObjects: CacheObject[] = [];
      for (const asset of assets) {
        let cachedObject = this.#cache.get(asset.id);
        if (cachedObject) {
          cachedObject.update(asset);
        } else {
          cachedObject = new CacheObject(asset);
          this.#cache.set(asset.id, cachedObject);
        }
        cachedObjects.push(cachedObject);
      }
      return cachedObjects.map((x) => x.asset);
    }

    // CACHE-AND-NETWORK (return from cache (if available) but always perform network call)
    const cachedObjects: CacheObject[] = [];
    for (const assetId of assetIds) {
      let cachedObject = this.#cache.get(assetId);
      if (!cachedObject) {
        cachedObject = new CacheObject({ id: 'na' });
        this.#cache.set(assetId, cachedObject);
      }
      cachedObjects.push(cachedObject);
    }

    const body: BodyById = {
      assetIds,
      returnState,
    };
    firstValueFrom(this._http.post<any[]>(url, body)).then((assets) => {
      for (const asset of assets) {
        let cachedObject = this.#cache.get(asset.id);
        if (cachedObject) {
          cachedObject.update(asset);
        } else {
          // Should not happen.
          cachedObject = new CacheObject(asset);
          this.#cache.set(asset.id, cachedObject);
        }
      }
    });

    return cachedObjects.map((x) => x.asset);
  }
}
