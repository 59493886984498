import { Component, OnInit, OnDestroy } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import {
  PropertiesSystemLevelQueryArgs,
  PropertiesSystemLevelQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/property';
import { PropertyOutput } from 'projects/shared/src/lib/graphql/output/propertyOutput';
import { Subscription } from 'rxjs';
import { SelectionService } from '../../../services/selection.service';
import { Loading } from 'projects/shared/src/lib/classes/loading';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';

@Component({
  selector: 'app-global-system',
  templateUrl: './global-system.component.html',
  styleUrls: ['./global-system.component.scss'],
})
export class GlobalSystemComponent implements OnInit, OnDestroy {
  // #region Public Properties

  loading = new Loading(1);
  propertiesSystemLevel: PropertyOutput[] = [];
  selectedProperty: PropertyOutput | undefined;

  // #endregion Public Properties

  // #region Private Properties

  private _propertiesSystemLevelSubscription: Subscription | undefined;
  private _propertiesSystemLevelQuery: QueryRef<PropertiesSystemLevelQueryRoot> | undefined;

  // #endregion Private Properties

  // #region Init

  constructor(
    public assortmentService: AssortmentService,
    private _apollo: Apollo,
    private _selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    this._loadData(1);
  }

  ngOnDestroy(): void {
    this._propertiesSystemLevelSubscription?.unsubscribe();
  }

  // #endregion Init

  // #region Private Properties

  private async _loadData(jobNumber: number) {
    if (!this._selectionService.selectedTenant) {
      return;
    }

    this.loading.indicateJobStart(jobNumber);

    const variables: PropertiesSystemLevelQueryArgs = {
      tenantId: this._selectionService.selectedTenant.id,
    };

    this._propertiesSystemLevelQuery = this._apollo.watchQuery<PropertiesSystemLevelQueryRoot>({
      query: gql`
        query PropertiesSystemLevel($tenantId: String!) {
          propertiesSystemLevel(tenantId: $tenantId) {
            id
            levelId
            typeId
            displayName
            displayOrder
            shortInfo
            longInfo
            isNullable
            optionId
            createdAt

            propertyType {
              id
              name
            }

            option {
              id
              name
              optionItems {
                id
                optionId
                name
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this._propertiesSystemLevelSubscription =
      this._propertiesSystemLevelQuery.valueChanges.subscribe({
        next: ({ data, loading }) => {
          this.propertiesSystemLevel = data.propertiesSystemLevel;
          this.loading.indicateJobEnd(jobNumber);
          this.selectedProperty = this.propertiesSystemLevel[0];
        },
        error: (error) => {
          // TODO
          console.log(error);
          this.loading.indicateJobEnd(jobNumber);
        },
      });
  }

  // #endregion Private Properties
}
