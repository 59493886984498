import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string, allow: boolean = false): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter((t) => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');
    const match = text.match(regex);
    if (!match) {
      return text;
    }

    let classString = '';
    if (match.join(' ') === text && !allow) {
      // FULL MATCH
      classString = 'class="highlight-fullmatch-bg"';
    } else {
      classString = 'class="highlight-bg"';
    }

    return search ? text.replace(regex, (match) => `<span ${classString}>${match}</span>`) : text;
  }
}
