import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { v4 } from 'uuid';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  CreateReturnToCustomerMutationArgs,
  CreateReturnToCustomerMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/returnToCustomer';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER } from 'projects/shared/src/lib/graphql/fragments/fullFragmentReturnToCustomer';
import { ReturnToCustomerOutput } from 'projects/shared/src/lib/graphql/output/returnToCustomerOutput';

export type ReturnToCustomerCreateDialogData = {
  mode: 'selected' | 'filtered';
  assetIds: string[];
};

export type ReturnToCustomerCreateDialogResult = ReturnToCustomerOutput;

@Component({
  selector: 'app-return-to-customer-create-dialog',
  templateUrl: './return-to-customer-create-dialog.component.html',
  styleUrls: ['./return-to-customer-create-dialog.component.scss'],
})
export class ReturnToCustomerCreateDialogComponent {
  activity = false;
  name: string | undefined;
  description: string | undefined;
  uuid1 = v4();
  uuid2 = v4();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReturnToCustomerCreateDialogData,
    private dialog: MatDialogRef<ReturnToCustomerCreateDialogComponent>,
    private apollo: Apollo,
    private toastService: DesktopToastService
  ) {}

  close() {
    this.dialog.close(false);
  }

  async apply() {
    if (!this.name) {
      return;
    }

    this.activity = true;
    try {
      const variables: CreateReturnToCustomerMutationArgs = {
        data: {
          name: this.name,
          description: this.description ?? undefined,
          assetIds: this.data.assetIds,
        },
      };

      const result = await firstValueFrom(
        this.apollo.mutate<CreateReturnToCustomerMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
            mutation CreateReturnToCustomer($data: ReturnToCustomerInputCreate!) {
              createReturnToCustomer(data: $data) {
                ...FullFragmentReturnToCustomer
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.dialog.close(result.data?.createReturnToCustomer);
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
    } finally {
      this.activity = false;
    }
  }
}
