import { Component, OnInit, OnDestroy } from '@angular/core';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Subscription, firstValueFrom } from 'rxjs';
import { UserRoleOutput } from 'projects/shared/src/lib/graphql/output/userRoleOutput';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import {
  CreateUserRoleMutationArgs,
  CreateUserRoleMutationRoot,
  DeleteUserRoleMutationArgs,
  DeleteUserRoleMutationRoot,
  UserRolesQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/userRole';
import { FULL_FRAGMENT_USER_ROLE } from 'projects/shared/src/lib/graphql/fragments/fullFragmentUserRole';
import { RoleId } from 'projects/shared/src/lib/graphql/output/roleOutput';
import { SelectionService } from '../../services/selection.service';
import {
  TenantConfigQueryRoot,
  UpdateTenantConfigMutationArgs,
  UpdateTenantConfigMutationRoot,
  UpdateTenantMutationArgs,
  UpdateTenantMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/tenant';
import { FULL_FRAGMENT_TENANT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenant';
import { InitialLoadingService } from '../../services/initial-loading.service';
import { v4 } from 'uuid';
import { ConfirmService } from '../../services/confirm.service';
import { OUser } from 'projects/shared/src/lib/common/oUser';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { FULL_FRAGMENT_TENANT_CONFIG } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantConfig';
import { TenantConfigOutput } from 'projects/shared/src/lib/graphql/output/tenantConfigOutput';

const UserRolesQuery = gql`
  ${FULL_FRAGMENT_USER_ROLE}
  query UserRoles {
    userRoles {
      ...FullFragmentUserRole
    }
  }
`;

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit, OnDestroy {
  loading = false;
  errorMessage: string | undefined;
  userRoles: UserRoleOutput[] = [];
  tenantOwnerUserRole: UserRoleOutput | undefined;
  tenantAdminUserRoles: UserRoleOutput[] = [];
  uuid = v4();
  selectedAdminOId: string | undefined;
  loadingTenantConfig = false;
  tenantConfig: TenantConfigOutput | undefined;
  uuid2 = v4();

  #userRolesQuery: QueryRef<UserRolesQueryRoot> | undefined;
  #userRolesSubscription: Subscription | undefined;
  #timeoutNewTenantName: number | undefined;

  constructor(
    private apollo: Apollo,
    public selectionService: SelectionService,
    private toastService: DesktopToastService,
    private initialLoadingService: InitialLoadingService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  ngOnDestroy(): void {
    this.#userRolesSubscription?.unsubscribe();
  }

  async onTenantNameChange(newName: string, input: HTMLInputElement) {
    if (!newName) {
      return;
    }
    window.clearTimeout(this.#timeoutNewTenantName);

    this.#timeoutNewTenantName = window.setTimeout(async () => {
      try {
        const variables: UpdateTenantMutationArgs = {
          name: newName,
        };

        await firstValueFrom(
          this.apollo.mutate<UpdateTenantMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_TENANT}
              mutation UpdateTenant($name: String!) {
                updateTenant(name: $name) {
                  ...FullFragmentTenant
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );

        await this.initialLoadingService.reloadMyUser();
      } catch (error) {
        const message = new CatchError(error).message;
        this.toastService.error(message, 'Error');
      }
    }, 800);
  }

  async onClickDeleteAdmin(adminOUser: OUser | undefined, adminUserRole: UserRoleOutput) {
    if (!adminOUser) {
      return;
    }

    try {
      this.confirmService.open(
        'Please confirm',
        `Do you really want to remove '${adminOUser.displayName}' as tenant admin?`,
        async () => {
          await this.#deleteTenantAdmin(adminUserRole);
        },
        undefined,
        undefined
      );
    } catch (error) {}
  }

  async onClickAddAdmin() {
    if (!this.selectedAdminOId) {
      return;
    }

    try {
      const variables: CreateUserRoleMutationArgs = {
        data: {
          roleId: RoleId.TENANT_ADMIN,
          userOid: this.selectedAdminOId,
        },
      };

      const result = await firstValueFrom(
        this.apollo.mutate<CreateUserRoleMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_USER_ROLE}
            mutation CreateUserRole($data: UserRoleInputCreate!) {
              createUserRole(data: $data) {
                ...FullFragmentUserRole
                role {
                  id
                  name
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.createUserRole) {
              return;
            }
            let cachedUserRoles = cache.readQuery<UserRolesQueryRoot>({
              query: UserRolesQuery,
            })?.userRoles;

            if (!cachedUserRoles) {
              cachedUserRoles = [];
            }

            cache.writeQuery<UserRolesQueryRoot>({
              query: UserRolesQuery,
              data: {
                userRoles: [...cachedUserRoles, data.createUserRole],
              },
            });
          },
        })
      );
    } catch (error) {
      const message = new CatchError(error).message;
      this.toastService.error(message, 'Error');
    }
  }

  isAlreadyAdmin(oId: string) {
    return this.tenantAdminUserRoles.map((x) => x.userOid).includes(oId);
  }

  async onMissingGracePeriodChanged(newValue: number, inputElement: HTMLInputElement) {
    if (!this.tenantConfig) {
      return;
    }
    const variables: UpdateTenantConfigMutationArgs = {
      id: this.tenantConfig.id,
      data: {
        missingGracePeriod: newValue,
      },
    };

    const previousValue = this.tenantConfig.missingGracePeriod;

    try {
      const result = await firstValueFrom(
        this.apollo.mutate<UpdateTenantConfigMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_TENANT_CONFIG}
            mutation UpdateTenantConfig($id: String!, $data: TenantConfigInputUpdate!) {
              updateTenantConfig(id: $id, data: $data) {
                ...FullFragmentTenantConfig
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.tenantConfig = result.data?.updateTenantConfig;
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'ERROR');

      inputElement.value = previousValue.toString();
    }
  }

  async #loadData() {
    await this.#loadUserRoles();
    await this.#loadTenantConfig();
  }

  async #loadUserRoles() {
    this.loading = true;
    this.errorMessage = undefined;

    this.#userRolesQuery = this.apollo.watchQuery<UserRolesQueryRoot>({
      query: UserRolesQuery,
      fetchPolicy: 'cache-and-network',
    });

    this.#userRolesSubscription = this.#userRolesQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.userRoles = data.userRoles;
        this.tenantOwnerUserRole = data.userRoles.find((x) => x.roleId === RoleId.TENANT_OWNER);
        this.tenantAdminUserRoles = data.userRoles.filter((x) => x.roleId === RoleId.TENANT_ADMIN);

        this.loading = false;
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this.errorMessage = message;
        this.loading = false;
      },
    });
  }

  async #loadTenantConfig() {
    this.loadingTenantConfig = true;

    try {
      const result = await firstValueFrom(
        this.apollo.query<TenantConfigQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_CONFIG}
            query TenantConfig {
              tenantConfig {
                ...FullFragmentTenantConfig
              }
            }
          `,
          fetchPolicy: 'cache-first',
        })
      );

      this.tenantConfig = result.data.tenantConfig ?? undefined;
    } catch (error) {
      this.toastService.error(new CatchError(error).message);
    } finally {
      this.loadingTenantConfig = false;
    }
  }

  async #deleteTenantAdmin(adminUserRole: UserRoleOutput) {
    const variables: DeleteUserRoleMutationArgs = {
      id: adminUserRole.id,
    };

    await firstValueFrom(
      this.apollo.mutate<DeleteUserRoleMutationRoot>({
        mutation: gql`
          mutation DeleteUserRole($id: String!) {
            deleteUserRole(id: $id) {
              id
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
        update: (cache, { data }) => {
          const cachedUserRoles = cache.readQuery<UserRolesQueryRoot>({
            query: UserRolesQuery,
          })?.userRoles;

          if (typeof cachedUserRoles === 'undefined') {
            return;
          }

          const index = cachedUserRoles.findIndex((x) => x.id === adminUserRole.id);
          if (index === -1) {
            return;
          }

          const clonedArray = Array.from(cachedUserRoles);
          clonedArray.splice(index, 1);

          cache.writeQuery<UserRolesQueryRoot>({
            query: UserRolesQuery,
            data: {
              userRoles: clonedArray,
            },
          });
        },
      })
    );
  }
}
