import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { v4 } from 'uuid';
import { startWith, map, Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-auto-complete-string-input',
  templateUrl: './auto-complete-string-input.component.html',
  styleUrls: ['./auto-complete-string-input.component.scss'],
})
export class AutoCompleteStringInputComponent implements OnInit {
  @Input({ required: true }) 
  get values() {
    return this.#values
  }
  set values(newValues) {
    this.#values = newValues;
    this.filteredValues = [];
  }
  @Input() required = false;
  @Input() label: string | undefined;
  @Input() subscriptSizing: SubscriptSizing = 'dynamic';
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input()
  get disabled() {
    return this.#disabled;
  }
  set disabled(value) {
    this.#disabled = value;
    if(value) {
      this.searchValue.disable();
    } else {
      this.searchValue.enable();
    }
  }
  @Input()
  get selected() {
    return this.#selected;
  }
  set selected(value) {
    if (value === this.#selected) {
      return;
    }

    this.#selected = value;
    if (value) {
      this.searchValue.setValue(value);
    }
  }

  @Output()
  selectedChange = new EventEmitter<string | undefined>();

  searchValue: FormControl<string | null> = new FormControl(null);
  filteredValues: string[] | undefined;
  uuid = v4();

  #selected: string | undefined;
  #disabled = false;
  #values: string[] = [];

  ngOnInit(): void {
    if (this.required) {
      this.searchValue = new FormControl(
        { value: null, disabled: this.disabled },
        Validators.required
      );
    } else {
      this.searchValue = new FormControl({ value: null, disabled: this.disabled });
    }

    this.searchValue.valueChanges.subscribe((value) => {
      this.filteredValues = !value ? this.#values : this.#filter(value);
      this.selectedChange.emit(value ?? undefined);
    });
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.selected = event.option.value;
    this.selectedChange.emit(this.selected);
  }

  #filter(value: string): string[] {
    if (typeof value === 'object') {
      return this.#values;
    }
    return this.#values.filter((x) => x.toLowerCase().includes(value.toLowerCase()));
  }
}
