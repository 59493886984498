import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_PROPERTY = gql`
  fragment FullFragmentProperty on PropertyOutput {
    id
    tenantId
    typeId
    levelId
    displayName
    displayOrder
    shortInfo
    longInfo
    isNullable
    notNullDefault
    optionId
    createdAt
  }
`;
