import { InventoriesQueryRoot } from 'projects/shared/src/lib/graphql/crud/inventory';
import { AppModule } from '../../app.module';
import { LocalEventData_Inventory, LocalEventType } from '../local-event.service';
import { RemoteEventData_Inventory, RemoteEventType } from '../remote-event.service';
import { EventSource, NotificationService } from './notification.service';
import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';

export const handle_Inventory = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_Inventory | RemoteEventData_Inventory
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // In case of "RemoteOtherSession" (and an create/delete trigger) => Update the cache centrally HERE.
    if (
      eventSource === EventSource.RemoteOtherSession &&
      eventData.data.find((x) => x.action !== 'updated')
    ) {
      const createdInventories = eventData.data.filter((x) => x.action === 'created');
      const deletedInventories = eventData.data.filter((x) => x.action === 'deleted');

      const inventoriesQuery = gql`
        ${FULL_FRAGMENT_INVENTORY}
        query Inventories {
          inventories {
            ...FullFragmentInventory
          }
        }
      `;

      const cachedInventories =
        AppModule.graphqlCache.readQuery<InventoriesQueryRoot>({
          query: inventoriesQuery,
        })?.inventories ?? [];

      let newInventories: InventoryOutput[] = Array.from(cachedInventories);

      // First handle "deleted"
      for (const deletedInventory of deletedInventories) {
        const index = newInventories.findIndex((x) => x.id === deletedInventory.inventory.id);
        if (index !== -1) {
          newInventories.splice(index, 1);
        }
      }

      // Now handle "created"
      for (const createdInventory of createdInventories.sortBy(
        (x) => x.inventory.createdAt,
        'asc'
      )) {
        if (!newInventories.map((x) => x.id).includes(createdInventory.inventory.id)) {
          newInventories.unshift(createdInventory.inventory);
        }
      }

      // Write back to cache
      AppModule.graphqlCache.writeQuery<InventoriesQueryRoot>({
        query: inventoriesQuery,
        data: {
          inventories: newInventories,
        },
      });
    }

    // Do the next only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      this.hasSubscribers(EventSource.Local, LocalEventType.Inventory)
    ) {
      // We have subscribers. Notify them.
      this.localEventHandled.next([LocalEventType.Inventory, eventData]);
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.Inventory)
    ) {
      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.Inventory, eventData]);
      return;
    }
  }
};
