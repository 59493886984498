import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { environment } from 'projects/desktop/src/environments/environment';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { v4 } from 'uuid';
import { SelectionService } from '../../../../services/selection.service';
import {
  PropertiesTableFormatQueryArgs,
  PropertiesTableFormatQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/property';
import { FULL_FRAGMENT_TABLE_COLUMN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTableColumn';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { TableColumnOutput } from 'projects/shared/src/lib/graphql/output/tableColumnOutput';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { HttpClient } from '@angular/common/http';
import {
  CreatePlanAssetMutationArgs,
  CreatePlanAssetMutationRoot,
  DeletePlanAssetsMutationArgs,
  DeletePlanAssetsMutationRoot,
  DeletePlanMutationArgs,
  DeletePlanMutationRoot,
  PlanQueryArgs,
  PlanQueryRoot,
  UpdatePlanMutationArgs,
  UpdatePlanMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import { Subscription } from 'rxjs';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { FULL_FRAGMENT_PLAN_STEP } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStep';
import {
  CreatePlanStepMutationArgs,
  CreatePlanStepMutationRoot,
  DeletePlanStepMutationArgs,
  DeletePlanStepMutationRoot,
  PlanStepsQueryArgs,
  PlanStepsQueryRoot,
  UpdatePlanStepMutationArgs,
  UpdatePlanStepMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/planStep';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { MatDialog } from '@angular/material/dialog';
import { ActionType, actionTypes } from 'projects/shared/src/lib/graphql/enums/actionType';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  AddAssetsToPlanDialogComponent,
  AddAssetsToPlanDialogData,
} from '../../../../component-dialogs/add-assets-to-plan-dialog/add-assets-to-plan-dialog.component';
import { ConfirmService } from '../../../../services/confirm.service';
import {
  PlanAssetsQueryArgs,
  PlanAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planAsset';
import { PlanAssetOutput } from 'projects/shared/src/lib/graphql/output/planAssetOutput';
import { Clipboard } from '@angular/cdk/clipboard';
import { FULL_FRAGMENT_PLAN_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanAsset';
import {
  AdjustPlanDurationDialogComponent,
  AdjustPlanDurationDialogData,
} from '../../../../component-dialogs/adjust-plan-duration-dialog/adjust-plan-duration-dialog.component';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';
import { UserSelectComponent } from 'projects/shared/src/public-api';
import {
  LocalEventData_PlanAssetsAdded,
  LocalEventData_PlanAssetDeleted,
  LocalEventService,
  LocalEventType,
  LocalEventData_Plan,
} from '../../../../services/local-event.service';
import { DesktopToastService } from '../../../../services/desktop-toast.service';
import { SubscriptionService } from '../../../../serices/subscription.service';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import {
  AssetColumnService,
  SPECIAL_NAMED_HEADER_NAME,
} from 'projects/shared/src/lib/services/asset-column.service';
import { AppModule } from 'projects/desktop/src/app/app.module';

const PlanQuery = gql`
  ${FULL_FRAGMENT_PLAN}
  query Plan($id: String!) {
    plan(id: $id) {
      ...FullFragmentPlan
    }
  }
`;

const PlanAssetsQuery = gql`
  query PlanAssets($planId: String!) {
    planAssets(planId: $planId) {
      id
      planId
      tenantAssetId
    }
  }
`;

const PlanStepsQuery = gql`
  ${FULL_FRAGMENT_PLAN_STEP}
  query PlanSteps($planId: String!) {
    planSteps(planId: $planId) {
      ...FullFragmentPlanStep
    }
  }
`;

@Component({
  selector: 'app-assets-plan',
  templateUrl: './assets-plan.component.html',
  styleUrls: ['./assets-plan.component.scss'],
})
export class AssetsPlanComponent implements OnInit, OnDestroy {
  // #region In/Out

  @Input()
  get planId() {
    return this.#planId;
  }
  set planId(value: string | undefined | null) {
    if (value === null) {
      return; // Ignore
    }

    this.#planId = value;
    this.assets = [];

    if (!value) {
      this.#planAssetsSubscription?.unsubscribe();
      this.#planStepsSubscription?.unsubscribe();
      this.#planSubscription?.unsubscribe();

      this.plan = undefined;
      this.planAssets = undefined;
      this.planSteps = undefined;
    } else {
      this._loadAll();
    }
  }

  // #endregion In/Out

  // #region Public Properties

  plan: PlanOutput | undefined;
  planAssets: PlanAssetOutput[] | undefined;
  planSteps: PlanStepOutput[] | undefined;
  uuid1 = v4();
  uuid2 = v4();
  uuid3 = v4();
  assets: any[] = [];
  specialTenantAssetColumn_AssetId: TableColumnOutput | undefined;
  specialTenantAssetColumn_UniqueId: TableColumnOutput | undefined;
  specialTenantAssetColumn_Missing: TableColumnOutput | undefined;
  activity = false;
  actionType = ActionType;

  expansionPanelIsExpandedPlanStep = new Map<string, boolean>();

  // #endregion Public Properties

  // #region Private Properties

  #planId: string | undefined;
  #tenantAssetColumns: TableColumnOutput[] | undefined;
  #planQuery: QueryRef<PlanQueryRoot> | undefined;
  #planAssetsQuery: QueryRef<PlanAssetsQueryRoot> | undefined;
  #planStepsQuery: QueryRef<PlanStepsQueryRoot> | undefined;
  #planSubscription: Subscription | undefined;
  #planAssetsSubscription: Subscription | undefined;
  #planStepsSubscription: Subscription | undefined;

  // #endregion Private Properties

  // #region Init

  constructor(
    public selectionService: SelectionService,
    private apollo: Apollo,
    private httpClient: HttpClient,
    public assortmentService: AssortmentService,
    private matDialog: MatDialog,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private toastService: DesktopToastService,
    private confirmService: ConfirmService,
    public clipboard: Clipboard,
    private localEventService: LocalEventService,
    public subscriptionService: SubscriptionService,
    public assetService: AssetService,
    public assetColumnService: AssetColumnService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.#planSubscription?.unsubscribe();
    this.#planAssetsSubscription?.unsubscribe();
    this.#planStepsSubscription?.unsubscribe();
  }

  // #endregion Init

  // #region Public Methods

  filterPlanAssetsInAssetsTable() {
    const tenantAssetIds = this.planAssets?.map((x) => x.tenantAssetId);
    this.localEventService.emitNewEvent(
      LocalEventType.FilterPlanAssetsInAssetsTable,
      tenantAssetIds
    );

    // Make sure the assets table is visible.
    this.selectionService.assetsView.showAssets = true;
  }

  async addAssetToPlan(assetId: string, input: HTMLInputElement) {
    if (!this.#planId) {
      return;
    }

    this.toastService.info(`Trying to add '${assetId}' to the plan ...`, undefined);
    try {
      const variables: CreatePlanAssetMutationArgs = {
        planId: this.#planId,
        assetId: assetId,
      };

      const result = await firstValueFrom(
        this.apollo.mutate<CreatePlanAssetMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN_ASSET}
            mutation CreatePlanAsset($planId: String!, $assetId: String!) {
              createPlanAsset(planId: $planId, assetId: $assetId) {
                ...FullFragmentPlanAsset
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.createPlanAsset) {
              return;
            }

            const updateVariables: PlanAssetsQueryArgs = {
              planId: variables.planId,
            };

            const cachedPlanAssets = cache.readQuery<PlanAssetsQueryRoot>({
              query: PlanAssetsQuery,
              variables: updateVariables,
            })?.planAssets;

            if (typeof cachedPlanAssets !== 'undefined') {
              cache.writeQuery<PlanAssetsQueryRoot>({
                query: PlanAssetsQuery,
                variables: updateVariables,
                data: {
                  planAssets: [...cachedPlanAssets, data.createPlanAsset],
                },
              });
            }

            // Notify locally about the added asset.
            const eventData: LocalEventData_PlanAssetsAdded = {
              planId: variables.planId,
              planAssets: [data.createPlanAsset],
            };
            this.localEventService.emitNewEvent(LocalEventType.PlanAssetsAdded, eventData);
          },
        })
      );
      this.toastService.info(`Asset '${assetId}' was added successfully to the plan.`, 'SUCCESS');

      input.value = '';
    } catch (error) {
      const message = new CatchError(error).message;
      this.toastService.error(message, 'ERROR');
    }
  }

  async onClickDeletePlan(plan: PlanOutput) {
    this.confirmService.open(
      'Please confirm',
      `Do you really want to delete the plan '<b>${plan.name}</b>' and all related data?`,
      async () => {
        // We first need to find all tenantAssetIds of the plan.
        // These are required in the local event notification.
        const variablesPlan: PlanQueryArgs = {
          id: plan.id,
        };
        const relevantAssetIds =
          (
            await firstValueFrom(
              this.apollo.query<PlanQueryRoot>({
                query: gql`
                  query Plan($id: String!) {
                    plan(id: $id) {
                      id
                      planAssets {
                        id
                        tenantAssetId
                      }
                    }
                  }
                `,
                variables: variablesPlan,
                fetchPolicy: 'network-only',
              })
            )
          ).data.plan.planAssets?.map((x) => x.tenantAssetId) ?? [];

        const variables: DeletePlanMutationArgs = {
          id: plan.id,
        };
        await firstValueFrom(
          this.apollo.mutate<DeletePlanMutationRoot>({
            mutation: gql`
              mutation DeletePlan($id: String!) {
                deletePlan(id: $id) {
                  id
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.deletePlan) {
                return;
              }

              const eventData: LocalEventData_Plan = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'deleted',
                    plan: data.deletePlan,
                    relevantAssetIds,
                  },
                ],
              };

              this.localEventService.emitNewEvent(LocalEventType.Plan, eventData);
            },
          })
        );
      },
      undefined,
      (ok) => {
        if (!ok) {
          return;
        }

        this.selectionService.selectedPlan = undefined;
        this.plan = undefined;
        this.planId = undefined;
      }
    );
  }

  async onClickDeletePlanStep(planStep: PlanStepOutput) {
    this.confirmService.open(
      'Please confirm',
      `Do you really want to delete the step '<b>${planStep.name}</b>' of the plan '<b>${this.plan?.name}</b>'?
      You will loose all related data.`,
      async () => {
        const variables: DeletePlanStepMutationArgs = {
          planId: planStep.planId,
          planStepId: planStep.id,
        };

        await firstValueFrom(
          this.apollo.mutate<DeletePlanStepMutationRoot>({
            mutation: gql`
              mutation DeletePlanStep($planId: String!, $planStepId: String!) {
                deletePlanStep(planId: $planId, planStepId: $planStepId) {
                  id
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              const cachedPlanSteps = cache.readQuery<PlanStepsQueryRoot>({
                query: PlanStepsQuery,
                variables: { planId: planStep.planId },
              })?.planSteps;

              if (typeof cachedPlanSteps === 'undefined') {
                return;
              }

              const index = cachedPlanSteps.findIndex((x) => x.id === planStep.id);
              if (index === -1) {
                return;
              }

              const clonedArray = Array.from(cachedPlanSteps);
              clonedArray.splice(index, 1);

              cache.writeQuery<PlanStepsQueryRoot>({
                query: PlanStepsQuery,
                variables: { planId: planStep.planId },
                data: {
                  planSteps: clonedArray,
                },
              });
            },
          })
        );
      },
      undefined,
      (ok) => {
        console.log(ok);
      },
      { maxWidth: '680px' }
    );
  }

  async onClickAdjustPlanDuration(plan: PlanOutput) {
    const data: AdjustPlanDurationDialogData = {
      plan,
      planAssets: this.planAssets ?? [],
    };

    const dialog = this.matDialog.open(AdjustPlanDurationDialogComponent, {
      autoFocus: false,
      data,
      minWidth: 400,
      maxWidth: 400,
    });
  }

  async addSelectedAssetsToPlan() {
    if (!this.plan) {
      return;
    }

    // Make sure that all property information about the assets are loaded.
    await this.#loadTenantAssetPropertiesOnce();

    const data: AddAssetsToPlanDialogData = {
      plan: this.plan,
      planAssets: this.planAssets ?? [],
      assets: this.selectionService.selectedAssets,
      uniqueIdPropName: this.specialTenantAssetColumn_UniqueId?.field ?? 'na',
    };

    const dialog = this.matDialog.open(AddAssetsToPlanDialogComponent, {
      autoFocus: false,
      data,
    });

    dialog.afterClosed().subscribe((ok: boolean) => {
      if (ok) {
        this.toastService.info('Assets successfully added.', 'Success');
      }
    });
  }

  async updatePlan(event: Event, plan: PlanOutput, property: 'name' | 'description') {
    try {
      const variables: UpdatePlanMutationArgs = {
        id: plan.id,
        data: {},
      };

      variables.data[property] = (event.target as HTMLInputElement).value;

      // The next mutation will trigger the plan subscription where we
      // load all assets of the plan. This is not necessary here.

      const result = await firstValueFrom(
        this.apollo.mutate<UpdatePlanMutationRoot>({
          mutation: gql`
            mutation UpdatePlan($id: String!, $data: PlanInputUpdate!) {
              updatePlan(id: $id, data: $data) {
                id
                name
                description
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.#notifyAboutPlanUpdateLocally(plan, this.planAssets ?? []);
    } catch (error) {
      this.undoPlan(event, plan, property);
      this.toastService.error(new CatchError(error).message, 'Error');
    }
  }

  async updateResponsibleId(
    userSelect: UserSelectComponent,
    oId: string | undefined,
    plan: PlanOutput
  ) {
    try {
      const variables: UpdatePlanMutationArgs = {
        id: plan.id,
        data: {
          responsibleId: oId ?? null,
        },
      };

      // The next mutation will trigger the plan subscription where we
      // load all assets of the plan. This is not necessary here.

      await firstValueFrom(
        this.apollo.mutate<UpdatePlanMutationRoot>({
          mutation: gql`
            mutation UpdatePlan($id: String!, $data: PlanInputUpdate!) {
              updatePlan(id: $id, data: $data) {
                id
                responsibleId
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.#notifyAboutPlanUpdateLocally(plan, this.planAssets ?? []);
    } catch (error) {
      userSelect.oId = plan.responsibleId ?? undefined;
      this.toastService.error(new CatchError(error).message, 'Error');
    }
  }

  undoPlan(event: Event, plan: PlanOutput, property: 'name' | 'description') {
    const input = event.target as HTMLInputElement;
    input.value = plan[property] ?? '';
  }

  async updatePlanStep(event: Event, planStep: PlanStepOutput, property: 'name' | 'description') {
    try {
      const variables: UpdatePlanStepMutationArgs = {
        id: planStep.id,
        data: {},
      };

      variables.data[property] = (event.target as HTMLInputElement).value;
      await firstValueFrom(
        this.apollo.mutate<UpdatePlanStepMutationRoot>({
          mutation: gql`
            mutation UpdatePlanStep($id: String!, $data: PlanStepInputUpdate!) {
              updatePlanStep(id: $id, data: $data) {
                id
                name
                description
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
    } catch (error) {
      this.undoPlanStep(event, planStep, property);
      const message = new CatchError(error).message;
      this.toastService.error(message, 'Error');
    }
  }

  undoPlanStep(event: Event, planStep: PlanStepOutput, property: 'name' | 'description') {
    const input = event.target as HTMLInputElement;
    input.value = planStep[property] ?? '';
  }

  getDatetimePipeString() {
    return this.localeService.datetimePipeString(this.locale);
  }

  getActionType(actionTypeId: number) {
    return actionTypes.get(actionTypeId);
  }

  async removeAssetFromPlan(tenantAssetId: string) {
    if (!this.plan?.id) {
      return;
    }

    try {
      // Check if the asset is included in any plan step.
      // If so, it must not be deleted from the plan.

      const planQueryVariables: PlanQueryArgs = {
        id: this.plan.id,
      };
      const result = await firstValueFrom(
        this.apollo.query<PlanQueryRoot>({
          query: gql`
            query Plan($id: String!) {
              plan(id: $id) {
                id
                planSteps {
                  id
                  name
                  planStepAssets {
                    id
                    tenantAssetId
                  }
                }
              }
            }
          `,
          variables: planQueryVariables,
          fetchPolicy: 'no-cache',
        })
      );

      const planSteps = result.data.plan.planSteps?.filter((x) =>
        x.planStepAssets?.map((y) => y.tenantAssetId).includes(tenantAssetId)
      );
      // const planSteps = this.planSteps?.filter(x => x.planStepAssets?.map(y => y.tenantAssetId).includes(tenantAssetId));
      if ((planSteps?.length ?? 0) > 0) {
        this.toastService.error(
          'Asset cannot be deleted. It is included in the following steps: ' +
            planSteps?.map((x) => x.name).join(', ')
        );
        return;
      }

      const variables: DeletePlanAssetsMutationArgs = {
        planId: this.plan?.id ?? 'na',
        assetIds: [tenantAssetId],
      };

      await firstValueFrom(
        this.apollo.mutate<DeletePlanAssetsMutationRoot>({
          mutation: gql`
            mutation DeletePlanAssets($planId: String!, $assetIds: [String!]!) {
              deletePlanAssets(planId: $planId, assetIds: $assetIds) {
                id
                planAssets {
                  id
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            const cacheQueryResult = cache.readQuery<PlanAssetsQueryRoot>({
              query: PlanAssetsQuery,
              variables: { planId: this.plan?.id },
            });

            const cachedArray = cacheQueryResult?.planAssets;
            if (typeof cachedArray === 'undefined') {
              return;
            }

            const index = cachedArray.findIndex((x) => x.tenantAssetId === tenantAssetId);
            if (index === -1) {
              return;
            }

            const clonedArray = Array.from(cachedArray);
            clonedArray.splice(index, 1);

            cache.writeQuery<PlanAssetsQueryRoot>({
              query: PlanAssetsQuery,
              data: {
                planAssets: clonedArray,
              },
              variables: { planId: this.plan?.id },
            });

            const eventData: LocalEventData_PlanAssetDeleted = {
              planId: this.plan?.id ?? 'na',
              tenantAssetId: tenantAssetId,
            };
            this.localEventService.emitNewEvent(LocalEventType.PlanAssetDeleted, eventData);
          },
        })
      );
    } catch (error) {
      const message = new CatchError(error).message;
      this.toastService.error(message, 'Error');
    }
  }

  async onClickCreateStep(withAllAssets: boolean) {
    if (!this.plan) {
      return;
    }

    let assetIds: string[] = [];
    if (withAllAssets) {
      assetIds = this.planAssets?.map((x) => x.tenantAssetId) ?? [];
    }

    this.activity = true;
    try {
      const variables: CreatePlanStepMutationArgs = {
        data: {
          tenantId: this.selectionService.selectedTenant?.id ?? 'na',
          assetIds,
          name: this.#determineStepName(),
          planId: this.plan.id,
          description: undefined,
        },
      };

      await firstValueFrom(
        this.apollo.mutate<CreatePlanStepMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN_STEP}
            mutation CreatePlanStep($data: PlanStepInputCreate!) {
              createPlanStep(data: $data) {
                ...FullFragmentPlanStep
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            const cachedPlanSteps = cache.readQuery<PlanStepsQueryRoot>({
              query: PlanStepsQuery,
              variables: { planId: variables.data.planId },
            })?.planSteps;

            if (typeof cachedPlanSteps === 'undefined' || !data?.createPlanStep) {
              return;
            }

            cache.writeQuery<PlanStepsQueryRoot>({
              query: PlanStepsQuery,
              variables: { planId: variables.data.planId },
              data: {
                planSteps: [...cachedPlanSteps, data.createPlanStep],
              },
            });
          },
        })
      );
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
    } finally {
      this.activity = false;
    }
  }

  // #endregion Public Methods

  // #region Private Methods

  private async _loadAll() {
    this.#loadTenantAssetPropertiesOnce().then(() => {
      if (!this.planId) {
        return;
      }

      this._loadPlan(this.planId);
      this._loadPlanAssets(this.planId);
      this._loadPlanSteps(this.planId);
    });
  }

  private _loadPlan(planId: string) {
    const variables: PlanQueryArgs = {
      id: planId,
    };

    if (this.plan) {
      // A plan was already queried before. Just do a REFETCH (with the updated variables).
      this.#planQuery?.refetch(variables);
      return;
    }

    // This is the really first run. Set up query and subscription.
    this.#planQuery = this.apollo.watchQuery<PlanQueryRoot>({
      query: PlanQuery,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this.#planSubscription = this.#planQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (!loading) {
          this.plan = data.plan;
        }
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this.toastService.error(message, 'Error');
      },
    });
  }

  private _loadPlanAssets(planId: string) {
    const variables: PlanAssetsQueryArgs = {
      planId,
    };

    if (typeof this.planAssets !== 'undefined') {
      // The planAssets already were queried before. Just do a REFETCH (with the updated variables).
      this.#planAssetsQuery?.refetch(variables);
      return;
    }

    // This is the really first run. Set up query and subscription.
    this.#planAssetsQuery = this.apollo.watchQuery<PlanAssetsQueryRoot>({
      query: PlanAssetsQuery,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this.#planAssetsSubscription = this.#planAssetsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (!loading) {
          this.planAssets = data.planAssets;

          // Make sure that the asset data is loaded (e.g. to visualize missing assets).
          this.assetService.fetchMultiple(
            data.planAssets.map((x) => x.tenantAssetId),
            'cache-first'
          );
        }
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this.toastService.error(message, 'Error');
      },
    });
  }

  private _loadPlanSteps(planId: string) {
    const variables: PlanStepsQueryArgs = {
      planId,
    };

    if (typeof this.planSteps !== 'undefined') {
      // The planSteps already were queried before. Just do a REFETCH (with the updated variables).
      this.#planStepsQuery?.refetch(variables);
      return;
    }

    // This is the really first run. Set up query and subscription.
    this.#planStepsQuery = this.apollo.watchQuery<PlanStepsQueryRoot>({
      query: PlanStepsQuery,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this.#planStepsSubscription = this.#planStepsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (!loading) {
          this.planSteps = data.planSteps;
        }
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this.toastService.error(message, 'Error');
      },
    });
  }

  async #loadTenantAssetPropertiesOnce() {
    if (typeof this.#tenantAssetColumns !== 'undefined') {
      // Only load this data once.
      return;
    }

    const variables: PropertiesTableFormatQueryArgs = {
      tenantId: this.selectionService.selectedTenant?.id ?? 'na',
    };

    const result = await firstValueFrom(
      this.apollo.query<PropertiesTableFormatQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_TABLE_COLUMN}
          query PropertiesTableFormat($tenantId: String!) {
            propertiesTableFormat(tenantId: $tenantId) {
              ...FullFragmentTableColumn
            }
          }
        `,
        variables,
        fetchPolicy: 'no-cache',
      })
    );

    this.#tenantAssetColumns = result.data.propertiesTableFormat;
    this.specialTenantAssetColumn_AssetId = this.#tenantAssetColumns.find(
      (x) => x.headerName === 'AssetId'
    );
    this.specialTenantAssetColumn_UniqueId = this.#tenantAssetColumns.find(
      (x) => x.headerName === 'UniqueId'
    );

    this.specialTenantAssetColumn_Missing = this.#tenantAssetColumns.find(
      (x) => x.headerName === SPECIAL_NAMED_HEADER_NAME.Missing
    );
    console.log(this.specialTenantAssetColumn_Missing);
  }

  private async _loadTenantAssetData(tenantId: string, assetId: number): Promise<any> {
    const url = `${environment.apiBaseUrl}/api/assets/${tenantId}`;

    const body = {
      pageModel: {
        startRow: 0,
        endRow: 1,
      },
      filterModel: {
        id: {
          filterType: 'text',
          type: 'in',
          filter: `${assetId}`,
        },
      },
      sortModel: [],
    };

    const result = await firstValueFrom(this.httpClient.post<any[]>(url, body));
    return result[0];
  }

  #determineStepName(): string {
    return `Step ${(this.planSteps?.length ?? 0) + 1}`;
  }

  #notifyAboutPlanUpdateLocally(plan: PlanOutput, planAssets: PlanAssetOutput[]) {
    const eventData: LocalEventData_Plan = {
      filterSessionId: AppModule.sessionId,
      data: [
        {
          action: 'updated',
          plan,
          relevantAssetIds: planAssets.map((x) => x.tenantAssetId),
        },
      ],
    };

    this.localEventService.emitNewEvent(LocalEventType.Plan, eventData);
  }

  // #endregion Private Methods
}
