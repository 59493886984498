<h2 mat-dialog-title>Error Message</h2>

<mat-dialog-content>
  <div class="flex-column">
    <div
      [innerText]="data.message"
      [style.font-size]="data.fontSize ?? '16px'"
    ></div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
