import { Component } from '@angular/core';

@Component({
  selector: 'lib-select-button-items',
  templateUrl: './select-button-items.component.html',
  styleUrls: ['./select-button-items.component.scss']
})
export class SelectButtonItemsComponent {

}
