import { gql } from "apollo-angular";

export const FULL_FRAGMENT_CHANGE_ASSET_PROPERTY = gql`
fragment FullFragmentChangeAssetProperty on ChangeAssetPropertyOutput {
    id
    changeAssetId
    changeTypeId

    assetPropertyId

    originalTypeId
    originalDisplayName
    originalDisplayOrder
    originalShortInfo
    originalLongInfo
    originalIsNullable
    originalOptionId

    typeId
    displayName
    displayOrder
    shortInfo
    longInfo
    isNullable
    optionId
}`;
