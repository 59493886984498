import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-gat-card-actions',
  templateUrl: './gat-card-actions.component.html',
  styleUrls: ['./gat-card-actions.component.scss'],
})
export class GatCardActionsComponent {
  @Input() align: 'start' | 'end' = 'start';

  @HostBinding('class.align-end')
  get alignEnd() {
    return this.align === 'end';
  }

  @HostBinding('class.align-start')
  get alignStart() {
    return this.align === 'start';
  }
}
