import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { environment } from 'projects/desktop/src/environments/environment';
import { SelectionService } from '../../services/selection.service';
import { firstValueFrom } from 'rxjs';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';

export type EditMultipleAssetsDrawerData = {
  assets: any[];
};

export type EditMultipleAssetsDrawerResult = string[] | undefined;

type EditResult = {
  tenantAssetId: string;
  ongoing: boolean;
  finishedOk: boolean;
  details?: string;
};

@Component({
  selector: 'app-edit-multiple-assets-drawer',
  templateUrl: './edit-multiple-assets-drawer.component.html',
  styleUrls: ['./edit-multiple-assets-drawer.component.scss'],
})
export class EditMultipleAssetsDrawerComponent implements OnInit {
  readonly editResults: EditResult[] = [];
  finished = false;
  get finishedOk(): boolean {
    return this.finished && this.editResults.every((x) => x.finishedOk);
  }

  constructor(
    public drawerRef: MtxDrawerRef<EditMultipleAssetsDrawerComponent>,
    @Inject(MTX_DRAWER_DATA) public data: EditMultipleAssetsDrawerData,
    private _http: HttpClient,
    private _selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    this.#updateData();
  }

  close() {
    // Return those tenantAssetIds where the update was successful.
    const result: EditMultipleAssetsDrawerResult = [];
    for (const editResult of this.editResults.filter((x) => x.finishedOk)) {
      result.push(editResult.tenantAssetId);
    }
    this.drawerRef.dismiss(result);
  }

  async #updateData() {
    const url =
      environment.apiBaseUrl + '/api/assets/update/' + this._selectionService.selectedTenant?.id;

    for (const asset of this.data.assets) {
      const editResult: EditResult = {
        tenantAssetId: asset.id,
        ongoing: true,
        finishedOk: false,
      };
      this.editResults.push(editResult);

      try {
        const body = {
          assetUpdates: [
            {
              tenantAssetId: asset.id,
              assetData: asset,
            },
          ],
        };

        await firstValueFrom(this._http.post(url, body));
        editResult.finishedOk = true;
      } catch (error) {
        editResult.finishedOk = false;
        editResult.details = new CatchError(error).message;
      } finally {
        editResult.ongoing = false;
      }
    }

    this.finished = true;
  }
}
