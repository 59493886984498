import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_PLAN_ASSET } from '../fragments/fullFragmentPlanAsset';

export const FULL_FRAGMENT_PLAN_ASSET_SUB = gql`
  ${FULL_FRAGMENT_PLAN_ASSET}
  fragment FullFragmentPlanAssetSub on PlanAssetSubNotification {
    filterSessionId
    data {
      action
      plan {
        id
        planAssets {
          ...FullFragmentPlanAsset
        }
      }
      planAssets {
        ...FullFragmentPlanAsset
      }
    }
  }
`;