import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss']
})
export class ExpanderComponent {
  @Input() isExpanded: boolean = false;

}
