<h2 mat-dialog-title>
  Set Assets as
  <span class="color-primary-400 font-weight-500">
    {{ data.setAsMissing ? "MISSING" : "AVAILABLE" }}
  </span>
</h2>

<mat-dialog-content>
  <div class="flex-column form-field-density-1">
    <lib-spinner *ngIf="loading; else notLoadingElement"></lib-spinner>
    <ng-template #notLoadingElement>
      <div class="flex-column" *ngFor="let asset of assets; let i = index">
        <div
          class="flex-row align-items-center gap-small"
          [class.mt-normal]="i > 0"
        >
          <span class="font-weight-500">
            {{ asset.data.id }}
          </span>
          <span
            *ngIf="asset.data[data.missingPropertyName]"
            class="mat-body color-warn-400"
          >
            (currently missing)
          </span>
        </div>

        <div *ngIf="asset.lastBooking" class="action-info-grid mat-body">
          <span>Last Action:</span>
          <div class="flex-row align-items-center gap-small">
            <span class="uppercase">
              {{ asset.lastBooking.actionType?.name }}
            </span>
            <span>
              ({{
                asset.lastBooking.timestamp
                  | date : localeService.datetimePipeString(locale)
              }})
            </span>
          </div>

          <span>Currently Located At:</span>
          <app-action-details
            fromOrTo="to"
            [action]="asset.lastBooking"
          ></app-action-details>

          <span>Previously Located At:</span>
          <app-action-details
            fromOrTo="from"
            [action]="asset.lastBooking"
          ></app-action-details>
        </div>
      </div>

      <mat-form-field subscriptSizing="dynamic" class="mt-large">
        <mat-label>Notes</mat-label>
        <textarea
          matInput
          [(ngModel)]="notes"
          [autocomplete]="uuid1"
          [required]="data.setAsMissing"
        ></textarea>
      </mat-form-field>
    </ng-template>

    <div style="height: 4px" class="mt-small">
      <mat-progress-bar
        *ngIf="activity"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    [disabled]="loading || activity"
    (click)="cancel()"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    color="primary"
    [disabled]="loading || activity || !canApply"
    (click)="apply()"
  >
    Set {{ data.setAsMissing ? "Missing" : "Available" }} ({{ assets.length }})
  </button>
</mat-dialog-actions>
