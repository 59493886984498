<mat-form-field>
  <mat-label>
    Assets ({{ planStepAssets.length }} of {{ planAssets?.length ?? "0" }})
  </mat-label>
  <mat-chip-grid #planStepChipGrid>
    <mat-chip-row
      *ngFor="let planStepAsset of planStepAssets"
      (click)="clipboard.copy(planStepAsset.tenantAssetId)"
      [class.asset-is-missing]="
        $any(assetService.cache.get(planStepAsset.tenantAssetId))?.asset[
          missingColumnField ?? 'na'
        ] === true
      "
      matTooltip="Asset is MISSING"
      matTooltipPosition="above"
      [matTooltipDisabled]="
        $any(assetService.cache.get(planStepAsset.tenantAssetId))?.asset[
          missingColumnField ?? 'na'
        ] !== true
      "
    >
      {{ planStepAsset.tenantAssetId }}
      <button matChipRemove (click)="removeAssetFromPlanStep(planStepAsset)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>

  <input
    placeholder="Add asset ..."
    #assetInput
    [matChipInputFor]="planStepChipGrid"
    [matAutocomplete]="auto"
    (matChipInputTokenEnd)="addAssetToPlanStep($event.value)"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [hideSingleSelectionIndicator]="true"
    (optionSelected)="addAssetToPlanStep($event.option.value)"
  >
    <mat-option
      *ngFor="let tenantAssetId of getUnusedTenantAssetsIds(assetInput.value)"
      [value]="tenantAssetId"
    >
      {{ tenantAssetId }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
