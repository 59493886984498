<div mat-dialog-title>
  <span class="flex-row align-items-center">
    <span>Bookings for </span>
    <span class="ml-small uppercase color-primary-400">{{
      actionTypes.get(data.action.actionTypeId)
    }}</span>
    <app-info position="right">
      <div class="flex-column mat-body">
        <div class="flex-row align-items-center gap-small">
          <span class="font-weight-500">Date:</span>
          <span>
            {{
              data.action.date | date : localeService.datetimePipeString(locale)
            }}
          </span>
        </div>

        <div class="flex-row align-items-center gap-small">
          <span class="font-weight-500">From:</span>
          <app-action-details
            fromOrTo="from"
            [action]="data.action"
          ></app-action-details>
        </div>

        <div class="flex-row align-items-center gap-small">
          <span class="font-weight-500">To:</span>
          <app-action-details
            fromOrTo="to"
            [action]="data.action"
          ></app-action-details>
        </div>

        <ng-container *ngIf="data.action.description">
          <div class="mt-normal font-weight-500">Notes:</div>
          <span>{{ data.action.description }}</span>
        </ng-container>
      </div>
    </app-info>
  </span>
</div>

<mat-dialog-content>
  <div class="flex-column">
    <lib-spinner *ngIf="loading; else notLoadingElement"></lib-spinner>

    <ng-template #notLoadingElement>
      <span *ngIf="loadingErrorMessage; else noLoadingErrorElement">
        {{ loadingErrorMessage }}
      </span>

      <ng-template #noLoadingErrorElement>
        <div style="height: 4px">
          <mat-progress-bar
            *ngIf="activity"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>

        <span class="mat-body mb-small">
          You can adjust the asset-bookings for the planned action by either
          unchecking / removing or checking / adding the asset-booking.<br />
          When you add a booking, it will be on behalf of yourself and booked
          with the current date and time.
        </span>

        <table class="dialog-table mb-normal">
          <tr>
            <th>Asset</th>
            <th>Booked</th>
            <th>Adjustment</th>
          </tr>
          <tr *ngFor="let assetState of assetStates">
            <td>
              <div class="flex-row align-items-center gap-normal">
                {{ assetState.currentState.assetId }}
                <app-info *ngIf="assetState.currentBooking" position="right">
                  <div class="flex-column mat-body">
                    <span>Booked</span>
                    <div class="flex-row align-items-center gap-small">
                      <span class="font-weight-500">On:</span>
                      <span>{{
                        assetState.currentBooking.timestamp
                          | date : localeService.datetimePipeString(locale)
                      }}</span>
                    </div>

                    <div class="flex-row align-items-center gap-small">
                      <span class="font-weight-500">By:</span>
                      <lib-user
                        [oId]="assetState.currentBooking.userOid"
                      ></lib-user>
                    </div>
                  </div>
                </app-info>
              </div>
            </td>
            <td>
              <mat-checkbox
                [ngModel]="assetState.currentState.assetBooked"
                [disabled]="true"
              ></mat-checkbox>
            </td>
            <td>
              <mat-checkbox [(ngModel)]="assetState.newState"></mat-checkbox>
            </td>
          </tr>
        </table>
      </ng-template>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>CANCEL</button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!canApply || activity"
    (click)="apply()"
  >
    APPLY
  </button>
</mat-dialog-actions>
