import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  TenantActionsLatestQueryArgs,
  TenantActionsLatestQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { firstValueFrom } from 'rxjs';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { v4 } from 'uuid';
import {
  ResetMissingMutationArgs,
  ResetMissingMutationRoot,
  SetMissingMutationArgs,
  SetMissingMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/assetMissing';
import { FULL_FRAGMENT_ASSET_MISSING } from 'projects/shared/src/lib/graphql/fragments/fullFragmentAssetMissing';
import { AssetMissingOutput } from 'projects/shared/src/lib/graphql/output/assetMissingOutput';

export type SetAssetMissingDialogData = {
  setAsMissing: boolean;
  assets: any[];
  missingPropertyName: string;
};

export type SetAssetMissingDialogResult = AssetMissingOutput[] | undefined;

type Asset = {
  data: any;
  lastBooking: TenantActionOutput | undefined;
};

@Component({
  selector: 'app-set-asset-missing-dialog',
  templateUrl: './set-asset-missing-dialog.component.html',
  styleUrls: ['./set-asset-missing-dialog.component.scss'],
})
export class SetAssetMissingDialogComponent implements OnInit {
  loading = false;
  activity = false;
  errorMessage: string | undefined;
  assets: Asset[] = [];
  notes: string | undefined;
  uuid1 = v4();

  get canApply(): boolean {
    if (this.data.setAsMissing) {
      return typeof this.notes !== 'undefined' && !!this.notes;
    }

    return true;
  }

  constructor(
    private _apollo: Apollo,
    private _dialogRef: MatDialogRef<SetAssetMissingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SetAssetMissingDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private _toastService: DesktopToastService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.#loadData(this.data.assets.map((x) => x.id));
  }

  cancel() {
    this._dialogRef.close(undefined);
  }

  async apply() {
    if (!this.canApply) {
      return;
    }

    try {
      this.activity = true;

      if (this.data.setAsMissing) {
        // Mutation "setMissing"
        const variables: SetMissingMutationArgs = {
          assetIds: this.assets.map((x) => x.data.id),
          comment: this.notes ?? 'na',
        };

        const result = await firstValueFrom(
          this._apollo.mutate<SetMissingMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_ASSET_MISSING}
              mutation SetMissing($assetIds: [String!]!, $comment: String!) {
                setMissing(assetIds: $assetIds, comment: $comment) {
                  ...FullFragmentAssetMissing
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );
        const resultData: SetAssetMissingDialogResult = result.data?.setMissing;
        this._dialogRef.close(resultData);
      } else {
        // Mutation "resetMissing"
        const variables: ResetMissingMutationArgs = {
          assetIds: this.assets.map((x) => x.data.id),
          comment: this.notes,
        };

        const result = await firstValueFrom(
          this._apollo.mutate<ResetMissingMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_ASSET_MISSING}
              mutation ResetMissing($assetIds: [String!]!, $comment: String) {
                resetMissing(assetIds: $assetIds, comment: $comment) {
                  ...FullFragmentAssetMissing
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );
        const resultData: SetAssetMissingDialogResult = result.data?.resetMissing;
        this._dialogRef.close(resultData);
      }
    } catch (error) {
      this._toastService.error(new CatchError(error).message, 'ERROR');
    } finally {
      this.activity = false;
    }
  }

  async #loadData(assetIds: string[]) {
    this.loading = true;
    this.errorMessage = undefined;
    try {
      const variables: TenantActionsLatestQueryArgs = {
        assetIds,
      };

      const result = await firstValueFrom(
        this._apollo.query<TenantActionsLatestQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            query TenantActionsLatest($assetIds: [String!]!) {
              tenantActionsLatest(assetIds: $assetIds) {
                ...FullFragmentTenantAction
                actionType {
                  id
                  name
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      // Build assets array.
      for (const asset of this.data.assets.sortBy((x) => x.id)) {
        this.assets.push({
          data: asset,
          lastBooking: result.data.tenantActionsLatest.find((x) => x.assetId === asset.id),
        });
      }
    } catch (error) {
      this._toastService.error(new CatchError(error).message, 'ERROR');
      this._dialogRef.close(undefined);
    } finally {
      this.loading = false;
    }
  }
}
