import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from '../fragments/fullFragmentPlanStepAction';

export const FULL_FRAGMENT_ASSET_MISSING_SUB = gql`
  ${FULL_FRAGMENT_PLAN_STEP_ACTION}
  fragment FullFragmentAssetMissingSub on AssetMissingSubNotification {
    data {
      assetId
      action
      missingComment
      userOid
      planStepAction {
        ...FullFragmentPlanStepAction
      }
    }
  }
`;
