import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET = gql`
  fragment FullFragmentReturnToCustomerAsset on ReturnToCustomerAssetOutput {
    id
    tenantId
    returnToCustomerId
    tenantAssetId
  }
`;
