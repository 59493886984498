import { Overlay, OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { Component, Input, Output, EventEmitter, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';

@Component({
  selector: 'window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss']
})
export class WindowComponent implements OnDestroy, AfterViewInit {
  // https://stackblitz.com/edit/angular-open-window?file=src%2Fapp%2Fwindow.component.ts

  @ViewChild(CdkPortal) portal!: CdkPortal;

  @Input() windowRef: Window | undefined;
  @Output() windowRefChange = new EventEmitter<Window>();

  @Output() windowResized = new EventEmitter();

  private externalWindow: Window | null = null;

  constructor(private oc: OverlayContainer, private _overlay: Overlay) {

  }

  ngAfterViewInit(): void {
    this.externalWindow = window.open('', '', 'rel=opener,width=600,height=400,left=200,top=200');
    if (!this.externalWindow) {
      return;
    }

    // , undefined, this._applicationRef, this._injector
    const domPortalOutlet = new DomPortalOutlet(this.externalWindow.document.body);
    domPortalOutlet.attach(this.portal);

    document.querySelectorAll('link, style').forEach(htmlElement => {
      this.externalWindow?.document.head.appendChild(htmlElement.cloneNode(true));
    });

    // https://github.com/angular/components/blob/main/src/cdk/overlay/overlay-container.ts
    const containerElement = this.oc.getContainerElement();
    //containerElement.remove();
    //console.log(this._overlay);
    //this.oc.ngOnDestroy();
    //containerElement.remove();
    //console.log(this.oc);
    //(this.oc as CustomOverlayContainer).setDocument(this.externalWindow.document);
    // const overlayElement = document.body.getElementsByClassName(
    //   'cdk-overlay-container'
    // )[0];

    //window.document.body.remove(containerElement)
    //containerElement.remove();

    //overlayElement?.remove();

    //Special handling for tooltips/popovers. 
    //const node = this.externalWindow.document.createElement("div");
    //node.classList.add('cdk-overlay-container');
    this.externalWindow.document.body.appendChild(containerElement.cloneNode());


    // https://github.com/angular/components/blob/main/src/cdk/overlay/overlay-container.ts
    // https://github.com/angular/components/issues/21802
    // const containerClass = 'cdk-overlay-container';
    // const container = this.externalWindow.document.createElement('div');
    // container.classList.add(containerClass);
    // this.externalWindow.document.body.appendChild(container);

    // const coc = this.oc as CustomOverlayContainer;
    // coc.setDocument(this.externalWindow.document);
    // coc.createContainer();
    //coc.setContainerElement(container);



    window.setTimeout(() => {
      if (this.externalWindow) {
        this.windowRefChange.emit(this.externalWindow);
      }
    }, 50);


  }


  ngOnDestroy() {
    this.externalWindow?.close();
    console.log('destroy');
    // const coc = this.oc as CustomOverlayContainer;
    // coc.setDocument(document);
    // coc.createContainer();
  }
}
