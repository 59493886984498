<!-- ############## -->
<!-- DEFECT FILTERS -->
<!-- ############## -->
<div class="flex-row align-items-center gap-normal">
  <mat-slide-toggle
    [ngModel]="hasActiveFilter.get('defect')"
    (ngModelChange)="
      hasActiveFilter.set('defect', $event); generateFilterModel()
    "
  >
    {{ hasActiveFilter.get("defect") ? "on" : "off" }}
  </mat-slide-toggle>
  <span class="flex-grow-1"></span>
  <span class="font-weight-500 color-primary-400">DEFECT</span>
</div>

<div
  *ngIf="hasActiveFilter.get('defect')"
  class="flex-row align-items-center gap-normal font-size-12"
  style="margin-left: 32px; margin-top: 24px"
>
  <div class="flex-column">
    <span class="font-weight-500" style="margin-left: 10px">functionality</span>

    <mat-checkbox
      [(ngModel)]="defect.nonFunctional"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">D100</span>
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="defect.partiallyFunctional"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">D</span>
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="defect.functional"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">ok</span>
    </mat-checkbox>
  </div>

  <div class="flex-column">
    <span class="font-weight-500" style="margin-left: 10px">reported</span>
    <mat-checkbox
      [(ngModel)]="defect.neverReported"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">never</span>
    </mat-checkbox>
    <mat-checkbox
      [(ngModel)]="defect.unverified"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">latest unverified</span>
    </mat-checkbox>
    <mat-checkbox
      [(ngModel)]="defect.verified"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">latest verified</span>
    </mat-checkbox>
  </div>
</div>

<div class="mt-normal mb-small">
  <mat-divider></mat-divider>
</div>

<!-- ############### -->
<!-- MISSING FILTERS -->
<!-- ############### -->
<div class="flex-row align-items-center gap-normal">
  <mat-slide-toggle
    [ngModel]="hasActiveFilter.get('missing')"
    (ngModelChange)="
      hasActiveFilter.set('missing', $event); generateFilterModel()
    "
  >
    {{ hasActiveFilter.get("missing") ? "on" : "off" }}
  </mat-slide-toggle>
  <span class="flex-grow-1"></span>
  <span class="font-weight-500 color-primary-400">MISSING</span>
</div>

<div
  *ngIf="hasActiveFilter.get('missing')"
  class="flex-row align-items-center gap-normal font-size-12"
  style="margin-left: 32px; margin-top: 16px"
>
  <div class="flex-column">
    <mat-checkbox
      [(ngModel)]="missing.unavailable"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">missing</span>
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="missing.available"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">available</span>
    </mat-checkbox>
  </div>
</div>

<div class="mt-normal mb-small">
  <mat-divider></mat-divider>
</div>

<!-- ################# -->
<!-- INVENTORY FILTERS -->
<!-- ################# -->
<div class="flex-row align-items-center gap-normal">
  <mat-slide-toggle
    [ngModel]="hasActiveFilter.get('inventory')"
    (ngModelChange)="
      hasActiveFilter.set('inventory', $event); generateFilterModel()
    "
  >
    {{ hasActiveFilter.get("inventory") ? "on" : "off" }}
  </mat-slide-toggle>
  <span class="flex-grow-1"></span>
  <span class="font-weight-500 color-primary-400">INVENTORY</span>
</div>

<div
  *ngIf="hasActiveFilter.get('inventory')"
  class="flex-row align-items-center gap-normal font-size-12"
  style="margin-left: 32px; margin-top: 24px"
>
  <div class="flex-column">
    <span class="font-weight-500" style="margin-left: 10px">&nbsp;</span>
    <mat-checkbox
      [(ngModel)]="inventory.onActiveInventory"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">on active</span>
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="inventory.notOnActiveInventory"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">not on active</span>
    </mat-checkbox>
  </div>

  <div class="flex-column">
    <span class="font-weight-500" style="margin-left: 10px">confirmation</span>
    <mat-checkbox
      [(ngModel)]="inventory.confirmationRequired"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">required</span>
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="inventory.confirmationNotRequired"
      (ngModelChange)="generateFilterModel()"
    >
      <span class="font-size-12">not required</span>
    </mat-checkbox>
  </div>
</div>

<!-- ####### -->
<!-- ACTIONS -->
<!-- ####### -->
<div class="flex-row mt-normal">
  <span class="flex-grow-1"></span>
  <button
    mat-flat-button
    color="primary"
    (click)="apply()"
    [disabled]="!unappliedFilterModel"
  >
    Apply
  </button>
</div>
