import { gql } from 'apollo-angular';
import { LocalEventData_ReturnToCustomer, LocalEventType } from '../local-event.service';
import { RemoteEventData_ReturnToCustomer, RemoteEventType } from '../remote-event.service';
import { EventSource, NotificationService } from './notification.service';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER } from 'projects/shared/src/lib/graphql/fragments/fullFragmentReturnToCustomer';
import { AppModule } from '../../app.module';
import { ReturnToCustomersQueryRoot } from 'projects/shared/src/lib/graphql/crud/returnToCustomer';
import { ReturnToCustomerOutput } from 'projects/shared/src/lib/graphql/output/returnToCustomerOutput';

export const handle_ReturnToCustomer = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_ReturnToCustomer | RemoteEventData_ReturnToCustomer
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // Do the following ALWAYS and centrally here.
    const returnToCustomersQuery = gql`
      ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
      query ReturnToCustomers {
        returnToCustomers {
          ...FullFragmentReturnToCustomer
        }
      }
    `;

    const created =
      eventData.data?.filter((x) => x.action === 'created').map((x) => x.returnToCustomer) ?? [];
    const deleted =
      eventData.data?.filter((x) => x.action === 'deleted').map((x) => x.returnToCustomer) ?? [];

    if (deleted.length > 0) {
      const cachedRTCs =
        AppModule.graphqlCache.readQuery<ReturnToCustomersQueryRoot>({
          query: returnToCustomersQuery,
        })?.returnToCustomers ?? [];

      let afterDelete: ReturnToCustomerOutput[] = [];
      for (const cachedRtc of cachedRTCs) {
        if (deleted.map((x) => x.id).includes(cachedRtc.id)) {
          continue;
        }

        afterDelete.push(cachedRtc);
      }

      AppModule.graphqlCache.writeQuery<ReturnToCustomersQueryRoot>({
        query: returnToCustomersQuery,
        data: {
          returnToCustomers: afterDelete.sortBy((x) => x.createdAt, 'desc'),
        },
      });
    }

    if (created.length > 0) {
      const cachedRTCs =
        AppModule.graphqlCache.readQuery<ReturnToCustomersQueryRoot>({
          query: returnToCustomersQuery,
        })?.returnToCustomers ?? [];

      AppModule.graphqlCache.writeQuery<ReturnToCustomersQueryRoot>({
        query: returnToCustomersQuery,
        data: {
          returnToCustomers: [...cachedRTCs, ...created].sortBy((x) => x.createdAt, 'desc'),
        },
      });
    }

    // Do the following only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      this.hasSubscribers(EventSource.Local, LocalEventType.ReturnToCustomer)
    ) {
      // We have subscribers. Notify them.
      this.localEventHandled.next([LocalEventType.ReturnToCustomer, eventData]);
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.ReturnToCustomer)
    ) {
      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.ReturnToCustomer, eventData]);
      return;
    }
  }
};
