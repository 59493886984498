<lib-spinner
  class="mt-normal"
  *ngIf="loading.isJobXOngoing[1]; else notLoading"
></lib-spinner>

<ng-template #notLoading>
  <lib-card class="locations-card form-field-density-1">
    <lib-card-title class="mat-h2">LOCATIONS</lib-card-title>
    <lib-card-content>
      <div class="flex-column card-content">
        <div style="height: 100%; overflow-y: auto; min-width: 400px">
          <mat-tree
            [dataSource]="treeDataSource"
            [treeControl]="treeControl"
            class="example-tree"
          >
            <!-- This is the tree node template for leaf nodes -->
            <!-- There is inline padding applied to this node using styles.
              This padding value depends on the mat-icon-button width. -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <span class="leaf" (click)="onClickLeaf(node)">
                {{ node.name }}
              </span>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle>
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                    }}
                  </mat-icon>
                </button>
                <span>{{ node.name }}</span>
                <span>&nbsp;({{ node.locations.length }})</span>
              </div>
              <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
              <div
                [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                role="group"
              >
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
        <div class="right">
          <app-edit-location
            *ngIf="showEditLocation"
            [locationNode]="editLocationNode"
          ></app-edit-location>
        </div>
      </div>
    </lib-card-content>
  </lib-card>
</ng-template>
