import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { DateTime } from 'luxon';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  ActivePlansQueryArgs,
  ActivePlansQueryRoot,
  PlanQueryArgs,
  PlanQueryRoot,
  UpdatePlanMutationArgs,
  UpdatePlanMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { FULL_FRAGMENT_PLAN_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanAsset';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { PlanAssetOutput } from 'projects/shared/src/lib/graphql/output/planAssetOutput';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom } from 'rxjs';
import { DesktopToastService } from '../../services/desktop-toast.service';

export type AdjustPlanDurationDialogData = {
  plan: PlanOutput;
  planAssets: PlanAssetOutput[];
};

@Component({
  selector: 'app-adjust-plan-duration-dialog',
  templateUrl: './adjust-plan-duration-dialog.component.html',
  styleUrls: ['./adjust-plan-duration-dialog.component.scss'],
})
export class AdjustPlanDurationDialogComponent implements OnInit {
  get currentDurationString(): string {
    const start =
      this._datePipe.transform(
        this.data.plan.planStart,
        this.localeService.datetimePipeString(this.locale)
      ) ?? '';

    const end =
      this._datePipe.transform(
        this.data.plan.planEnd,
        this.localeService.datetimePipeString(this.locale)
      ) ?? '';

    return `${start} - ${end}`;
  }

  hasNewDuration = false;

  // Will be either Date (initially) or DateTime (after change)
  get startDate(): any {
    return this.#startDate;
  }
  set startDate(value) {
    this.#startDate = value;
    this.hasCheckedConflicts = false;
  }

  // Wll be either Date (initially) or DateTime (after change)
  get endDate(): any {
    return this.#endDate;
  }
  set endDate(value) {
    this.#endDate = value;
    this.hasCheckedConflicts = false;
  }

  get conflicts(): boolean {
    return this.conflictingPlans.size > 0 || this.conflictingAlreadyPlannedActions.length > 0;
  }

  loading = false;
  loadingErrorMessage: string | undefined;
  alreadyPlannedActions: PlanStepActionOutput[] = [];
  conflictingAlreadyPlannedActions: PlanStepActionOutput[] = [];
  activity = false;
  hasCheckedConflicts = false;
  errorMessage: string | undefined;
  concurrentPlans: PlanOutput[] = [];
  conflictingPlans = new Map<PlanOutput, string[]>();

  #startDate: any;
  #endDate: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdjustPlanDurationDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private _dialogRef: MatDialogRef<AdjustPlanDurationDialogComponent>,
    private _apollo: Apollo,
    private _datePipe: DatePipe,
    private _toastService: DesktopToastService
  ) {
    this.startDate = new Date(data.plan.planStart);
    this.endDate = new Date(data.plan.planEnd);
  }

  ngOnInit(): void {
    this.#loadData();
  }

  evalHasNewDuration(): boolean {
    if (!this.startDate || !this.endDate) {
      this.hasNewDuration = false;
      return false;
    }

    const sinceTest = DateTime.fromJSDate(this.startDate);
    const untilTest = DateTime.fromJSDate(this.endDate);
    const sinceDateTime = sinceTest.isValid ? sinceTest : (this.startDate as DateTime);
    const untilDateTime = untilTest.isValid ? untilTest : (this.endDate as DateTime);

    if (new Date(this.data.plan.planStart).getTime() != sinceDateTime.toJSDate().getTime()) {
      this.hasNewDuration = true;
      return true;
    }

    if (
      DateTime.fromJSDate(new Date(this.data.plan.planEnd)).startOf('day').toJSDate().getTime() !=
      untilDateTime.startOf('day').toJSDate().getTime()
    ) {
      this.hasNewDuration = true;
      return true;
    }

    this.hasNewDuration = false;
    return false;
  }

  async checkConflicts() {
    if (this.hasCheckedConflicts) {
      return;
    }

    const sinceTest = DateTime.fromJSDate(this.startDate);
    const untilTest = DateTime.fromJSDate(this.endDate);
    const sinceDateTime = sinceTest.isValid ? sinceTest : (this.startDate as DateTime);
    let untilDateTime = untilTest.isValid ? untilTest : (this.endDate as DateTime);
    untilDateTime = untilDateTime.endOf('day');

    try {
      this.activity = true;
      this.errorMessage = undefined;

      const variables: ActivePlansQueryArgs = {
        startDate: sinceDateTime.toJSDate(),
        endDate: untilDateTime.toJSDate(),
      };

      const result = await firstValueFrom(
        this._apollo.query<ActivePlansQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PLAN}
            ${FULL_FRAGMENT_PLAN_ASSET}
            query ActivePlans($startDate: DateTime!, $endDate: DateTime!) {
              activePlans(startDate: $startDate, endDate: $endDate) {
                ...FullFragmentPlan
                planAssets {
                  ...FullFragmentPlanAsset
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.concurrentPlans = result.data.activePlans.filter((x) => x.id !== this.data.plan.id);
      this.#evaluateConflictsWithOtherPlans();

      this.#evaluateConflictsWithAlreadyPlannedActions(sinceDateTime, untilDateTime);

      this.hasCheckedConflicts = true;
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.activity = false;
    }
  }

  async apply() {
    if (!this.hasNewDuration || !this.hasCheckedConflicts || this.conflicts) {
      return;
    }

    const sinceTest = DateTime.fromJSDate(this.startDate);
    const untilTest = DateTime.fromJSDate(this.endDate);
    const sinceDateTime = sinceTest.isValid ? sinceTest : (this.startDate as DateTime);
    const untilDateTime = untilTest.isValid ? untilTest : (this.endDate as DateTime);

    try {
      this.activity = true;
      this.errorMessage = undefined;

      const variables: UpdatePlanMutationArgs = {
        id: this.data.plan.id,
        data: {
          planStart: sinceDateTime.toJSDate(),
          planEnd: untilDateTime.endOf('day').toJSDate(),
        },
      };

      await firstValueFrom(
        this._apollo.mutate<UpdatePlanMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN}
            mutation UpdatePlan($id: String!, $data: PlanInputUpdate!) {
              updatePlan(id: $id, data: $data) {
                ...FullFragmentPlan
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this._toastService.info('Plan duration changed.', 'SUCCESS');
      this._dialogRef.close(null);
    } catch (error) {
      this._toastService.error(new CatchError(error).message, 'ERROR');
    } finally {
      this.activity = false;
    }
  }

  async #loadData() {
    try {
      this.errorMessage = undefined;
      this.loading = true;

      const variables: PlanQueryArgs = {
        id: this.data.plan.id,
      };

      const result = await firstValueFrom(
        this._apollo.query<PlanQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PLAN_STEP_ACTION}
            query Plan($id: String!) {
              plan(id: $id) {
                id
                planSteps {
                  id
                  planStepActions {
                    ...FullFragmentPlanStepAction
                    actionType {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      const alreadyPlannedActions: PlanStepActionOutput[] = [];
      for (const planStep of result.data.plan.planSteps ?? []) {
        planStep.planStepActions?.forEach((x) => alreadyPlannedActions.push(x));
      }
      this.alreadyPlannedActions = alreadyPlannedActions;
    } catch (error) {
      this.loadingErrorMessage = new CatchError(error).message;
    } finally {
      this.loading = false;
    }
  }

  #evaluateConflictsWithOtherPlans() {
    const conflicts = new Map<PlanOutput, string[]>();
    for (const concurrentPlan of this.concurrentPlans.sortBy((x) => x.planStart)) {
      const intersectedTenantAssetIds = this.data.planAssets
        ?.map((x) => x.tenantAssetId)
        .filter((x) => concurrentPlan.planAssets?.map((y) => y.tenantAssetId).includes(x));

      if (
        typeof intersectedTenantAssetIds === 'undefined' ||
        intersectedTenantAssetIds.length === 0
      ) {
        continue;
      }

      conflicts.set(
        concurrentPlan,
        intersectedTenantAssetIds.sortBy((x) => x)
      );
    }

    this.conflictingPlans = conflicts;
  }

  #evaluateConflictsWithAlreadyPlannedActions(
    intendedStartDate: DateTime,
    intendedEndDate: DateTime
  ) {
    const conflictingAlreadyPlannedActions: PlanStepActionOutput[] = [];

    for (const alreadyPlannedAction of this.alreadyPlannedActions) {
      if (
        new Date(alreadyPlannedAction.date) < intendedStartDate.toJSDate() ||
        new Date(alreadyPlannedAction.date) > intendedEndDate.toJSDate()
      ) {
        // Conflict.
        conflictingAlreadyPlannedActions.push(alreadyPlannedAction);
      }
    }
    this.conflictingAlreadyPlannedActions = conflictingAlreadyPlannedActions.sortBy((x) => x.date);
  }
}
