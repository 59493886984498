import { Apollo, gql } from 'apollo-angular';
import {
  GetScannedAssetsQueryArgs,
  GetScannedAssetsQueryRoot,
} from '../graphql/crud/tenantAssetPlanning';
import { firstValueFrom } from 'rxjs';
import { ScannedAssetOutput } from '../graphql/output/scannedAssetOutput';
import { CatchError } from '../classes/catch-error';

export class ActionAssetsWrapper {
  get relevantAssets(): ScannedAssetOutput[] {
    if (this._loadingState === 0) {
      setTimeout(() => {
        this.#loadData(this._planStepActionId);
      }, 100);
      return [];
    }

    return this._relevantAssets;
  }
  set relevantAssets(value) {
    this._relevantAssets = value;
  }

  get bookedAssets(): ScannedAssetOutput[] {
    return this.relevantAssets.filter((x) => x.assetBooked === true);
  }

  get notBookedAssets(): ScannedAssetOutput[] {
    return this.relevantAssets.filter((x) => !x.assetBooked);
  }

  get loadingState(): 0 | 1 | 2 | 3 {
    if (this._loadingState === 0) {
      setTimeout(() => {
        this.#loadData(this._planStepActionId);
      }, 100);
    }
    return this._loadingState;
  }
  set loadingState(value) {
    this._loadingState = value;
  }

  get isLoading() {
    return this.loadingState === 1;
  }

  get someBooked(): boolean {
    return (
      this.relevantAssets.length > 0 &&
      this.bookedAssets.length > 0 &&
      this.relevantAssets.length > this.bookedAssets.length
    );
  }

  get noneBooked(): boolean {
    return this.relevantAssets.length > 0 && this.bookedAssets.length === 0;
  }

  get allBooked(): boolean {
    return (
      this.relevantAssets.length > 0 && this.bookedAssets.length === this.relevantAssets.length
    );
  }

  errorMessage: string | undefined;

  private _relevantAssets: ScannedAssetOutput[] = [];
  private _loadingState: 0 | 1 | 2 | 3 = 0; // 1 = loading  2 = finished (ok)  3 = finished (failure)

  constructor(private _apollo: Apollo, private _planStepActionId: string) {}

  async #loadData(planStepActionId: string) {
    if (this._loadingState !== 0) {
      return;
    }
    this.loadingState = 1;

    const variables: GetScannedAssetsQueryArgs = {
      planStepActionId,
    };

    try {
      const result = await firstValueFrom(
        this._apollo.query<GetScannedAssetsQueryRoot>({
          query: gql`
            query GetScannedAssets($planStepActionId: String!) {
              getScannedAssets(planStepActionId: $planStepActionId) {
                planStepActionId
                assetId
                assetBooked
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.relevantAssets = result.data.getScannedAssets;
      this.loadingState = 2;
    } catch (error) {
      this.loadingState = 3;
      const message = new CatchError(error).message;
      this.errorMessage = message;
      console.log(error);
    }
  }
}
