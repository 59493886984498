import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Apollo, QueryRef, gql} from 'apollo-angular';
import {
  CompleteReturnToCustomerMutationArgs,
  CompleteReturnToCustomerMutationRoot,
  DeleteReturnToCustomerMutationArgs,
  DeleteReturnToCustomerMutationRoot,
  ReturnToCustomersQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/returnToCustomer';
import {
  FULL_FRAGMENT_RETURN_TO_CUSTOMER
} from 'projects/shared/src/lib/graphql/fragments/fullFragmentReturnToCustomer';
import {
  ReturnToCustomerOutput
} from 'projects/shared/src/lib/graphql/output/returnToCustomerOutput';
import {LocaleService} from 'projects/shared/src/lib/services/locale.service';
import {Subscription, firstValueFrom} from 'rxjs';
import {SelectionService} from '../../../services/selection.service';
import {ConfirmService} from '../../../services/confirm.service';
import {DesktopToastService} from '../../../services/desktop-toast.service';
import {CatchError} from 'projects/shared/src/lib/classes/catch-error';
import {SubscriptionService} from '../../../serices/subscription.service';
import {NotificationService} from '../../../services/notificationService/notification.service';
import {
  LocalEventData_ReturnToCustomer,
  LocalEventData_ReturnToCustomerAsset,
  LocalEventService,
  LocalEventType,
} from '../../../services/local-event.service';
import {
  RemoteEventData_ReturnToCustomer,
  RemoteEventData_ReturnToCustomerAsset,
  RemoteEventType,
} from '../../../services/remote-event.service';
import {AppModule} from '../../../app.module';

const returnToCustomersQuery = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
  query ReturnToCustomers {
    returnToCustomers {
      ...FullFragmentReturnToCustomer
      returnToCustomerAssets {
        id
      }
    }
  }
`;

const returnToCustomerAssetsQuery = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
  query SearchReturnToCustomerByAsset($searchString: String!) {
    searchReturnToCustomerByAsset(searchString: $searchString){
      ...FullFragmentReturnToCustomer
      returnToCustomerAssets {
        id
      }
    }
  }
`;

@
  Component({
    selector: 'app-return-to-customer',
    templateUrl: './return-to-customer.component.html',
    styleUrls: ['./return-to-customer.component.scss'],
  })

export class ReturnToCustomerComponent implements OnInit, OnDestroy {
  loading = false;
  rtcs: ReturnToCustomerOutput[] = [];

  #rtcsQuery: QueryRef<ReturnToCustomersQueryRoot> | undefined;
  #rtcsSubscription: Subscription | undefined;
  #notificationLocalSubscription: Subscription | undefined;
  #notificationRemoteSubscription: Subscription | undefined;
  generalSearchValue: string = "";

  constructor(
    private apollo: Apollo,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public selectionService: SelectionService,
    private router: Router,
    private confirmService: ConfirmService,
    private toastService: DesktopToastService,
    public subscriptionService: SubscriptionService,
    private notificationService: NotificationService,
    private localEventService: LocalEventService
  ) {
  }

  ngOnInit(): void {
    this.#loadData();

    this.notificationService.handleLocalEvents([
      LocalEventType.ReturnToCustomer,
      LocalEventType.ReturnToCustomerAsset,
    ]);
    this.notificationService.handleRemoteEvents([
      RemoteEventType.ReturnToCustomer,
      RemoteEventType.ReturnToCustomerAsset,
    ]);

    this.#notificationLocalSubscription = this.notificationService.localEventHandled.subscribe(
      this.#localEventHandling.bind(this)
    );
    this.#notificationRemoteSubscription = this.notificationService.remoteEventHandled.subscribe(
      this.#remoteEventHandling.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.#rtcsSubscription?.unsubscribe();

    this.notificationService.unhandleLocalEvents([
      LocalEventType.ReturnToCustomer,
      LocalEventType.ReturnToCustomerAsset,
    ]);
    this.notificationService.unhandleRemoteEvents([
      RemoteEventType.ReturnToCustomer,
      RemoteEventType.ReturnToCustomerAsset,
    ]);

    this.#notificationLocalSubscription?.unsubscribe();
    this.#notificationRemoteSubscription?.unsubscribe();
  }

  navigateTo(id: string) {
    this.router.navigateByUrl('assets/return-to-customer/' + id);
  }

  async deleteReturnToCustomer() {
    if (!this.selectionService.selectedReturnToCustomer) {
      return;
    }

    const name = this.selectionService.selectedReturnToCustomer.name;

    this.confirmService.open(
      'Are you sure?',
      `Do you really want to delete the 'Return to Customer' record <b>${name}</b> and all its notes?<br>` +
      'If you do so, the included assets will be made available again for planning and booking.',
      async () => {
        const variables: DeleteReturnToCustomerMutationArgs = {
          id: this.selectionService.selectedReturnToCustomer?.id ?? 'na',
        };

        await firstValueFrom(
          this.apollo.mutate<DeleteReturnToCustomerMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
              mutation DeleteReturnToCustomer($id: String!) {
                deleteReturnToCustomer(id: $id) {
                  ...FullFragmentReturnToCustomer
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, {data}) => {
              if (!data?.deleteReturnToCustomer) {
                return;
              }

              const eventData: LocalEventData_ReturnToCustomer = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'deleted',
                    returnToCustomer: data.deleteReturnToCustomer,
                  },
                ],
              };

              this.localEventService.emitNewEvent(LocalEventType.ReturnToCustomer, eventData);
            },
          })
        );
      },
      undefined,
      () => {
        (document.activeElement as HTMLElement)?.blur();
      }
    );
  }

  async completeReturnToCustomer() {
    if (!this.selectionService.selectedReturnToCustomer) {
      return;
    }

    const name = this.selectionService.selectedReturnToCustomer.name;

    this.confirmService.open(
      'Are you sure?',
      `Do you really want to set the 'Return to Customer' record <b>${name}</b> to 'completed'?<br>` +
      `You cannot make any changes after that and this action also cannot be undone.<br>` +
      `<br>Proceed with care.`,
      async () => {
        const variables: CompleteReturnToCustomerMutationArgs = {
          id: this.selectionService.selectedReturnToCustomer?.id ?? 'na',
        };

        await firstValueFrom(
          this.apollo.mutate<CompleteReturnToCustomerMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
              mutation CompleteReturnToCustomer($id: String!) {
                completeReturnToCustomer(id: $id) {
                  ...FullFragmentReturnToCustomer
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );
      },
      undefined,
      () => {
        (document.activeElement as HTMLElement)?.blur();
      }
    );
  }

  async #loadData(query = false) {
    this.#rtcsSubscription?.unsubscribe();
    this.loading = true;

    if (query) {
      this.#rtcsQuery = this.apollo.watchQuery<ReturnToCustomersQueryRoot>({
        query: returnToCustomerAssetsQuery,
        variables: {
          searchString: this.generalSearchValue,
        },
        fetchPolicy: 'cache-and-network',
      });
    } else {
      this.#rtcsQuery = this.apollo.watchQuery<ReturnToCustomersQueryRoot>({
        query: returnToCustomersQuery,
        fetchPolicy: 'cache-and-network',
      });
    }


    this.#rtcsSubscription = this.#rtcsQuery.valueChanges.subscribe({
      next: ({data, loading}) => {
        if (query) {
          // TODO fix this properly
          //@ts-ignore
          this.rtcs = data.searchReturnToCustomerByAsset.sortBy((x) => x.createdAt, 'desc');
        } else {
          this.rtcs = data.returnToCustomers.sortBy((x) => x.createdAt, 'desc');
        }
        this.loading = false;
      },
      error: (error) => {
        this.toastService.error(new CatchError(error).message, 'Error');
      },
    });
  }

  #localEventHandling(value: [LocalEventType, any]) {
    if (value[0] === LocalEventType.ReturnToCustomer) {
      this.#handleReturnToCustomerChanges(value[1]);
    } else if (value[0] === LocalEventType.ReturnToCustomerAsset) {
      this.#handleReturnToCustomerAssetChanges(value[1]);
    }
  }

  #remoteEventHandling(value: [RemoteEventType, any]) {
    if (value[0] === RemoteEventType.ReturnToCustomer) {
      this.#handleReturnToCustomerChanges(value[1]);
    } else if (value[0] === RemoteEventType.ReturnToCustomerAsset) {
      this.#handleReturnToCustomerAssetChanges(value[1]);
    }
  }

  #handleReturnToCustomerChanges(
    eventData: LocalEventData_ReturnToCustomer | RemoteEventData_ReturnToCustomer
  ) {
    // Manipulating the cache (for the query "returnToCustomers") is already done centrally.
    // We need to handle the deletion of an RTC that the user currently has selected.
    const deletedReturnToCustomerIds =
      eventData.data?.filter((x) => x.action === 'deleted').map((x) => x.returnToCustomer.id) ?? [];
    if (
      deletedReturnToCustomerIds.includes(
        this.selectionService.selectedReturnToCustomer?.id ?? 'na'
      )
    ) {
      // we need to change the route.
      this.router.navigateByUrl('/assets/return-to-customer');
    }
  }

  #handleReturnToCustomerAssetChanges(
    eventData: LocalEventData_ReturnToCustomerAsset | RemoteEventData_ReturnToCustomerAsset
  ) {
    // Not good, but currently the easies way.
    this.#rtcsQuery?.refetch();
  }

  async onGeneralSearchChange($event: any) {
    if (this.generalSearchValue === "") {
      await this.#loadData();
    } else {
      await this.#loadData(true);
    }
  }
}
