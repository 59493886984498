import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CreateOptionMutationArgs, CreateOptionMutationRoot, OptionsQueryRoot } from 'projects/shared/src/lib/graphql/crud/option';
import { v4 } from 'uuid';
import { SelectionService } from '../../services/selection.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Observable, firstValueFrom, map, startWith } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { FULL_FRAGMENT_OPTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOption';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type NewOptionDialogData = {
  optionNames: string[];
}

@Component({
  selector: 'app-new-option-dialog',
  templateUrl: './new-option-dialog.component.html',
  styleUrls: ['./new-option-dialog.component.scss']
})
export class NewOptionDialogComponent implements OnInit {
  // #region Public Properties

  uuid = v4();
  name = new FormControl<string | null>(null, Validators.required)
  activity = false;
  errorMessage: string | undefined;
  filteredOptionNames: Observable<string[]> | undefined;

  get canApply(): boolean {
    return this.name.valid
      && this.name.value != null
      && !this.data.optionNames.map(x => x.toLowerCase()).includes(this.name.value.toLowerCase())
  }

  // #endregion Public Properties


  // #region Init

  constructor(
    private _selectionService: SelectionService
    , private _apollo: Apollo
    , private _dialogRef: MatDialogRef<NewOptionDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: NewOptionDialogData
  ) { }

  ngOnInit(): void {
    this.filteredOptionNames = this.name.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value === null
          ? this.data.optionNames
          : this._filter(value);
      })
    )
  }

  // #endregion Init


  // #region Public Methods

  async create() {
    if (!this.canApply) {
      return;
    }

    this.activity = true;
    this.errorMessage = undefined;
    const tenantId = this._selectionService.selectedTenant?.id ?? 'will not happen';

    const variables: CreateOptionMutationArgs = {
      data: {
        tenantId,
        name: this.name.value ?? 'will not happen'
      }
    };

    try {
      const result = await firstValueFrom(this._apollo.mutate<CreateOptionMutationRoot>({
        mutation: gql`
          ${FULL_FRAGMENT_OPTION}
          mutation CreateOption($data: OptionInputCreate!) {
            createOption(data: $data) {
              ...FullFragmentOption
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
        update: (store, { data }) => {
          const cache = store.readQuery<OptionsQueryRoot>({
            query: gql`
              ${FULL_FRAGMENT_OPTION}
              query Options($tenantId: String!) {
                options(tenantId: $tenantId) {
                  ...FullFragmentOption
                }
              }
            `,
            variables: { tenantId }
          });

          if (!cache || !data?.createOption) {
            return;
          }

          const newArray = [...cache.options, data.createOption].sortBy(x => x.name);

          store.writeQuery<OptionsQueryRoot>({
            query: gql`
              ${FULL_FRAGMENT_OPTION}
              query Options($tenantId: String!) {
                options(tenantId: $tenantId) {
                  ...FullFragmentOption
                }
              }
            `,
            variables: { tenantId },
            data: { options: newArray }
          })
        }
      }));

      this._dialogRef.close(result.data?.createOption);
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.activity = false;
    }
  }

  // #endregion Public Methods


  // #region Private Methods

  private _filter(value: string): string[] {
    return this.data.optionNames.filter(x => x.toLowerCase().includes(value.toLowerCase()));
  }

  // #endregion Private Methods
}
