import { ColDef, ColGroupDef, ITextFilterParams, ValueFormatterParams } from 'ag-grid-community';
import { AssetsTableComponent } from './assets-table.component';
import { AssetInfoRendererComponent } from 'projects/desktop/src/app/component-helpers/ag/asset-info-renderer/asset-info-renderer.component';
import { PropertyType } from 'projects/shared/src/lib/graphql/enums/propertyType';
import { PropertyLevel } from 'projects/shared/src/lib/graphql/enums/propertyLevel';
import {
  DESKTOP_LOCAL_STORAGE,
  DesktopLocalStorageAssetsTableColumnOrder,
} from 'projects/desktop/src/app/common/localStorage';
import { TableColumnOutput } from 'projects/shared/src/lib/graphql/output/tableColumnOutput';
import { AgCustomHeaderComponent } from 'projects/desktop/src/app/component-helpers/ag/ag-custom-header/ag-custom-header.component';

export const HIDDEN_COLUMN_PROPERTIES = ['defectState', 'defectType', 'inventoryBooked'];
export const HIDDEN_COLUMN_NAMES = ['Missing', 'Defect'];

export const buildColumnDefs = function (this: AssetsTableComponent) {
  if (!this.isRebuildingColumnDefsRequired) {
    return;
  }

  // Building can only be executed if all relevant data was loaded.
  // => tableColumns are available/loaded
  // => assets are available/loaded
  if (this.assets.length === 0 || this.tableColumns.length === 0) {
    return; // not all relevant data is available
  }

  // All data is available. Build columnDefs for the table.

  // FIRST HANDLING COLUMN
  const stateDefs: ColDef[] = [
    {
      colId: '0',
      headerName: '',
      checkboxSelection: true,
      sortable: false,
      filter: false,
      resizable: false,
      width: 40,
      suppressAutoSize: true,
      suppressMovable: true,
    },
    {
      colId: '1',
      headerName: 'Info',
      sortable: false,
      filter: false,
      resizable: true,
      width: 116,
      suppressAutoSize: false,
      suppressMovable: true,
      cellRenderer: AssetInfoRendererComponent,
    },
  ];

  const globalColumnGroup: ColGroupDef = {
    headerName: 'Global Properties',
    children: [],
  };
  const assetColumnGroups: ColGroupDef[] = [];
  for (let tableColumn of this.tableColumns) {
    if (this.hiddenTableColumns.map((x) => x.field).includes(tableColumn.field)) {
      // Ignore all columns that are flagged as "to be hidden".
      // The data for these columns is loaded, but the table columns are not constructed.
      //continue;
    }

    const columnDefs: ColDef = {
      columnGroupShow: 'open',
      field: tableColumn.field,
      tooltipField: tableColumn.field, // default, will later be changed for specific cases
      headerName: tableColumn.headerName,
    };

    setHeaderComponentOnColumnDef.call(this, columnDefs, tableColumn);

    switch (tableColumn.propertyTypeId) {
      case PropertyType.Text:
        columnDefs.filter = 'agTextColumnFilter';
        columnDefs.filterParams = {
          filterOptions: [
            {
              displayKey: 'in',
              displayName: 'In List',
              predicate: () => {
                return true;
              },
            },
            'contains',
            'notContains',
            'equals',
            'notEqual',
            'startsWith',
            'endsWith',
            'empty',
          ],
          defaultOption: 'in',
        };
        break;

      case PropertyType.Boolean:
        columnDefs.filter = 'agTextColumnFilter';
        break;

      case PropertyType.Date:
      case PropertyType.DateTime:
        columnDefs.filter = 'agDateColumnFilter';
        break;

      case PropertyType.Number:
        columnDefs.filter = 'agNumberColumnFilter';
        break;

      default:
        columnDefs.filter = false;
        break;
    }

    // Pure renaming of certain headerNames
    if (columnDefs.field === 'currentPlanName') {
      columnDefs.headerName = 'Currently On Plan';
    }

    if (
      HIDDEN_COLUMN_PROPERTIES.includes(columnDefs.field ?? '') ||
      HIDDEN_COLUMN_NAMES.includes(columnDefs.headerName ?? '')
    ) {
      columnDefs.hide = true;
    }
    if (columnDefs.field === 'defectState') {
      // const f: ITextFilterParams = {
      //   maxNumConditions = 3;
      // }
      columnDefs.filterParams = {
        maxNumConditions: 3,
      };
    }

    // Add value formatter to the AssetId column (to display a human friendly name).
    // Also add a special custom filter.
    if (columnDefs.headerName === 'AssetId') {
      columnDefs.headerName = 'Asset Type';
      columnDefs.valueGetter = this.assetIdValueGetter.bind(this);
      columnDefs.tooltipField = undefined;
      columnDefs.tooltipValueGetter = this.assetIdTooltipValueGetter.bind(this);
      const filterParams: ITextFilterParams = {
        filterOptions: [
          'empty',
          {
            displayKey: 'in',
            displayName: 'Contains',
            predicate: ([filterValue], cellValue) => {
              return true;
            },
          },
        ],
        defaultOption: 'in',
      };

      columnDefs.filterParams = filterParams;
    }

    // Add a special custom filter to the UniqueId column
    if (columnDefs.field === 'id') {
      columnDefs.headerName = 'Asset ID'; // overwrite header name
      const filterParams: ITextFilterParams = {
        filterOptions: [
          {
            displayKey: 'in',
            displayName: 'In List',
            predicate: ([filterValue], cellValue) => {
              return true;
            },
          },
          'contains',
          'notContains',
          'equals',
          'notEqual',
          'startsWith',
          'endsWith',
          'empty',
        ],
        defaultOption: 'in',
      };

      columnDefs.filterParams = filterParams;
    }

    if (
      tableColumn.propertyLevelId === PropertyLevel.System ||
      tableColumn.propertyLevelId === PropertyLevel.Tenant
    ) {
      globalColumnGroup.children.push(columnDefs);
    } else {
      // Asset specific property.
      const asset = this.assets.find((x) => x.id === tableColumn.groupAssetId);
      //console.log(asset);
      if (!asset) {
        // This should not happen. But anyway, make sure that nothing breaks here.
        continue;
      }

      let assetColumnGroup = assetColumnGroups.find((x) => x.headerName === asset.name);
      if (!assetColumnGroup) {
        // This is the first property of the asset. Create an group for it.
        assetColumnGroup = {
          headerName: asset.name,
          children: [],
        };
        assetColumnGroups.push(assetColumnGroup);
      }

      assetColumnGroup.children.push(columnDefs);
    }

    // Add ValueFormatter for DateTime columns to display accordingly.
    if (tableColumn.propertyTypeId === PropertyType.DateTime) {
      columnDefs.valueFormatter = (params: ValueFormatterParams) => {
        return (
          this.datePipe.transform(
            params.value,
            this.localeService.datetimePipeString(this.locale)
          ) ?? ''
        );
      };

      // Overwrite default tooltip behavior.
      columnDefs.tooltipField = undefined;
      columnDefs.tooltipValueGetter = (params) => {
        return (
          this.datePipe.transform(
            params.value,
            this.localeService.datetimePipeString(this.locale)
          ) ?? ''
        );
      };
    }

    // Add ValueFormatter for Date columns to display accordingly.
    if (tableColumn.propertyTypeId === PropertyType.Date) {
      columnDefs.valueFormatter = (params: ValueFormatterParams) => {
        return (
          this.datePipe.transform(params.value, this.localeService.datePipeString(this.locale)) ??
          ''
        );
      };

      // Overwrite default tooltip behavior.
      columnDefs.tooltipField = undefined;
      columnDefs.tooltipValueGetter = (params) => {
        return (
          this.datePipe.transform(params.value, this.localeService.datePipeString(this.locale)) ??
          ''
        );
      };
    }

    // Set default sorting of column "Modified At" to DESC
    if (tableColumn.headerName === 'Modified At') {
      columnDefs.sort = 'desc';
    }
  }

  // Try to sort the columns (inside their group) according to the cached
  // info from local storage. But at the same time create and persist an array
  // about the default (backend) order.
  let defaultOrderIndex = 0;
  const defaultOrder: Array<{ colId: string; index: number }> = [];

  for (const colDef of globalColumnGroup.children) {
    defaultOrder.push({
      colId: (colDef as ColDef).field ?? 'na',
      index: defaultOrderIndex++,
    });
  }

  globalColumnGroup.children = createOrderedColumns.call(this, globalColumnGroup.children);
  for (let assetColumnGroup of assetColumnGroups) {
    for (const colDef of assetColumnGroup.children) {
      defaultOrder.push({
        colId: (colDef as ColDef).field ?? 'na',
        index: defaultOrderIndex++,
      });
    }
    assetColumnGroup.children = createOrderedColumns.call(this, assetColumnGroup.children);
  }

  this.columnDefs = [...stateDefs, globalColumnGroup, ...assetColumnGroups];
  this.isRebuildingColumnDefsRequired = false;

  // Save default column order to localStorage
  localStorage.setItem(
    DESKTOP_LOCAL_STORAGE.ASSETS_TABLE_DEFAULT_COLUMN_ORDER,
    JSON.stringify(defaultOrder)
  );

  // Signal to the AGSidebar component, that every column data has been loaded and processed.
  // ATTENTION: At this time the gridApi has NOT yet fully set up it's internal column data.
  this.agSidebar?.rebuildColumnData();
};

export const setHeaderComponentOnColumnDef = function (
  this: AssetsTableComponent,
  colDef: ColDef,
  tableColumn: TableColumnOutput
) {
  const replaceTag = '__REPLACE__';
  const baseTemplate =
    '<div class="ag-cell-label-container" role="presentation">' +
    //'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    replaceTag +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="margin-right: 4px"></span>' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>';

  const isSystemProperty = tableColumn.propertyLevelId === PropertyLevel.System;
  const isTenantProperty = tableColumn.propertyLevelId === PropertyLevel.Tenant;
  if (isTenantProperty) {
    this.headerFieldsTenantLevel.push(tableColumn.field);
  }

  const isAssetTypeProperty = tableColumn.propertyLevelId === PropertyLevel.Asset;

  let isEditableProperty = true; // defaults to "true"

  if (
    tableColumn.field === 'id' ||
    tableColumn.field === 'actionPreviouslyLocatedAt' ||
    tableColumn.field === 'actionCurrentlyLocatedAt' ||
    tableColumn.field === 'actionLatestBookingNotes' ||
    tableColumn.field === 'actionLatestBookingBy' ||
    tableColumn.field === 'actionLatestBookingLocation' ||
    tableColumn.field === 'currentPlanName' ||
    tableColumn.field === 'defectUser' ||
    tableColumn.field === 'defectTime' ||
    tableColumn.field === 'defectType' ||
    tableColumn.field === 'defectState' ||
    tableColumn.field === 'defectComment' ||
    tableColumn.field === 'inventoryNames' ||
    tableColumn.field === 'inventoryBooked' ||
    tableColumn.field === 'actionNextBookingBy' ||
    tableColumn.headerName === 'AssetId' ||
    tableColumn.headerName === 'Modified At' ||
    tableColumn.headerName === 'Modified By' ||
    tableColumn.headerName === 'Missing' ||
    tableColumn.headerName === 'Missing Comment' ||
    tableColumn.headerName === 'Imported At'
  ) {
    this.headerFieldsNotEditable.push(tableColumn.field);
    isEditableProperty = false;
  }

  // We have a custom header that allows to fetch distinct values.
  // Determine, if we can use it here.
  if (isEditableProperty) {
    // This might be a candidate.
    switch (tableColumn.propertyTypeId) {
      case PropertyType.Text:
      case PropertyType.Number:
      case PropertyType.Boolean:
        colDef.headerComponent = AgCustomHeaderComponent;
        return;

      default:
        break;
    }
  }

  // We have not set our custom header. Let's determine what template we will provide for the header.

  let addition = '';

  if (isTenantProperty) {
    addition +=
      '    <span class="material-symbols-outlined" style="font-size: 18px; font-weight: 400; margin-right: 2px; opacity: 0.3;"> circle </span>';
  }

  if (isEditableProperty) {
    addition +=
      '    <span class="material-symbols-outlined" style="font-size: 18px; font-weight: 400; margin-right: 2px; opacity: 0.3;"> stylus </span>';
  }

  colDef.headerComponentParams = {
    template: baseTemplate.replace(replaceTag, addition),
  };
};

export const createOrderedColumns = function (this: AssetsTableComponent, colDefs: ColDef[]) {
  const lsString = localStorage.getItem(DESKTOP_LOCAL_STORAGE.ASSETS_TABLE_COLUMN_ORDER);
  if (!lsString) {
    return colDefs;
  }

  const order = JSON.parse(lsString) as DesktopLocalStorageAssetsTableColumnOrder;

  const sortedColDefs = new Map<number, ColDef>();
  let unknownIndex = 10000;

  for (const child of colDefs) {
    const colDef = child as ColDef;
    const index = order.findIndex((x) => x.colId === colDef.field);
    if (index !== -1) {
      sortedColDefs.set(index, child);
    } else {
      sortedColDefs.set(unknownIndex, child);
      unknownIndex++;
    }
  }

  const sortedColDefsArray: ColDef[] = [];
  for (const sortedIndex of Array.from(sortedColDefs.keys()).sortBy((x) => x)) {
    const colDef = sortedColDefs.get(sortedIndex);
    if (!colDef) {
      continue;
    }
    sortedColDefsArray.push(colDef);
  }

  return sortedColDefsArray;
};
