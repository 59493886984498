import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-space-plus',
  templateUrl: './space-plus.component.html',
  styleUrls: ['./space-plus.component.scss']
})
export class SpacePlusComponent {
  @Input() icon = 'add_circle';
  @Input() withMenu = false;
  @Output() onClick = new EventEmitter();

  onClickIcon(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
