import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  TenantActionsLatestQueryArgs,
  TenantActionsLatestQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom } from 'rxjs';
import { ActionTypeOutput } from 'projects/shared/src/lib/graphql/output/actionTypeOutput';
import { ActionFrom, actionFroms } from 'projects/shared/src/lib/graphql/enums/actionFrom';
import { ActionTo, actionTos } from 'projects/shared/src/lib/graphql/enums/actionTo';
import { v4 } from 'uuid';
import { ActionType } from 'projects/shared/src/lib/graphql/enums/actionType';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import {
  BookRealtimeActionComponent,
  BookRealtimeActionData,
  BookRealtimeActionResult,
} from '../../component-helpers/book-realtime-action/book-realtime-action.component';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';
import { DesktopToastService } from '../../services/desktop-toast.service';

export type BookRealtimeActionDialogData = {
  assets: any[];
};

@Component({
  selector: 'app-book-realtime-action-dialog',
  templateUrl: './book-realtime-action-dialog.component.html',
  styleUrls: ['./book-realtime-action-dialog.component.scss'],
})
export class BookRealtimeActionDialogComponent implements OnInit {
  loading = false;
  activity = false;
  errorMessage: string | undefined;
  tenantActions: TenantActionOutput[] = [];
  selectedFromType: ActionFrom | undefined;
  selectedToType: ActionTo | undefined;
  selectedTransitLocationId: string | undefined;
  fromTypes = actionFroms;
  toTypes = actionTos;
  fromDetails: string | undefined;
  toDetails: string | undefined;
  description: string | undefined;
  uuid1 = v4();
  uuid2 = v4();
  uuid3 = v4();
  meaningfulNextAction = true;

  get selectedAction() {
    return this.#selectedAction;
  }
  set selectedAction(value) {
    this.#selectedAction = value;
    this.#setFromAndToOptions();
  }

  get cannotBook(): boolean {
    return (
      this.tenantActions.length === 0 ||
      !this.#selectedAction ||
      !this.fromDetails ||
      !this.toDetails
    );
  }

  #selectedAction: ActionTypeOutput | undefined;
  #closeWithReload = false;

  constructor(
    private _apollo: Apollo,
    private _dialogRef: MatDialogRef<BookRealtimeActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BookRealtimeActionDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    public assortmentService: AssortmentService,
    private _drawer: MtxDrawer,
    private _toastService: DesktopToastService
  ) {}

  ngOnInit(): void {
    this.#loadData(this.data.assets.filter((x) => x.currentPlanName == null).map((x) => x.id)).then(
      (x) => {
        if (this.tenantActions.length === 0) {
          return;
        }

        this.assortmentService.getActionTypesAsync().then(() => {
          const meaningfulActionType = this.#getMeaningfulActionType();
          const fta = this.tenantActions[0];
          if (meaningfulActionType) {
            this.selectedAction = meaningfulActionType;
            this.fromDetails =
              fta.toUserOid ?? fta.toLocationId ?? fta.toMail ?? fta.toOther ?? undefined;
          } else {
            this.meaningfulNextAction = false;
            this.selectedAction = this.assortmentService.actionTypes.find(
              (x) => x.id === ActionType.PickUp
            );
          }
        });
      }
    );
  }

  book() {
    if (
      !this.selectedAction ||
      !this.selectedFromType ||
      !this.selectedToType ||
      !this.fromDetails ||
      !this.toDetails
    ) {
      return;
    }

    const data: BookRealtimeActionData = {
      actionTypeId: this.selectedAction.id,
      tenantAssetIds: this.tenantActions.map((x) => x.assetId),
      fromType: this.selectedFromType,
      toType: this.selectedToType,
      fromDetails: this.fromDetails,
      toDetails: this.toDetails,
      description: this.description,
      transitLocationId: this.selectedTransitLocationId,
    };

    const drawer = this._drawer.open(BookRealtimeActionComponent, {
      position: 'right',
      width: '400px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: false,
      data,
    });

    drawer.afterDismissed().subscribe((result: BookRealtimeActionResult) => {
      // The result array holds all tenantAssetIds where the booking was successful.
      if (result.length === this.tenantActions.length) {
        this._toastService.info(
          `Successfully booked ${this.#selectedAction?.name.toUpperCase()} for the ${
            this.tenantActions.length
          } ${this.tenantActions.length > 1 ? 'assets' : 'asset'}.`,
          'Success'
        );
        this._dialogRef.close(true);
      }

      // Not all bookings were successful.
      this.#closeWithReload = true;
      this.tenantActions = this.tenantActions.filter((x) => !result.includes(x.assetId));
    });
  }

  onClickClose() {
    this._dialogRef.close(this.#closeWithReload);
  }

  async #loadData(assetIds: string[]) {
    this.loading = true;
    this.errorMessage = undefined;
    try {
      const variables: TenantActionsLatestQueryArgs = {
        assetIds,
      };

      const result = await firstValueFrom(
        this._apollo.query<TenantActionsLatestQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            query TenantActionsLatest($assetIds: [String!]!) {
              tenantActionsLatest(assetIds: $assetIds) {
                ...FullFragmentTenantAction
                actionType {
                  id
                  name
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.tenantActions = result.data.tenantActionsLatest;
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.loading = false;
    }
  }

  #setFromAndToOptions() {
    switch (this.#selectedAction?.id) {
      case ActionType.PickUp:
        this.fromTypes = new Map<number, string>([[ActionFrom.Location, 'location']]);
        this.toTypes = new Map<number, string>([
          [ActionTo.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        this.selectedFromType = ActionFrom.Location;
        this.selectedToType = ActionTo.User;
        break;

      case ActionType.Handover:
        this.fromTypes = new Map<number, string>([
          [ActionFrom.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        this.toTypes = new Map<number, string>([
          [ActionTo.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        this.selectedFromType = ActionFrom.User;
        this.selectedToType = ActionTo.User;
        break;

      case ActionType.Deposit:
        this.fromTypes = new Map<number, string>([
          [ActionFrom.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        this.toTypes = new Map<number, string>([[ActionTo.Location, 'location']]);
        this.selectedFromType = ActionFrom.User;
        this.selectedToType = ActionTo.Location;
        break;

      default:
        break;
    }
  }

  #getMeaningfulActionType(): ActionTypeOutput | undefined {
    const that = this;
    const nextValidActionType = function (actionTypeId: number) {
      switch (actionTypeId) {
        case ActionType.Deposit:
          return that.assortmentService.actionTypes.find((x) => x.id === ActionType.PickUp);

        case ActionType.Handover:
          return that.assortmentService.actionTypes.find((x) => x.id === ActionType.Deposit);

        case ActionType.PickUp:
          return that.assortmentService.actionTypes.find((x) => x.id === ActionType.Deposit);

        default:
          return undefined;
      }
    };

    if (this.tenantActions.length === 0) {
      return undefined;
    }

    // If there is only one tenantAction, then return the action type.
    const fta = this.tenantActions[0];
    if (this.tenantActions.length === 1) {
      return nextValidActionType(fta.actionTypeId);
    }

    //onst fta = this.tenantActions[0];
    if (
      this.tenantActions.every(
        (x) =>
          x.actionTypeId == fta.actionTypeId &&
          x.toUserOid === fta.toUserOid &&
          x.toLocationId === fta.toLocationId &&
          x.toMail === fta.toMail &&
          x.toOther === fta.toOther
      )
    ) {
      return nextValidActionType(fta.actionTypeId);
    }

    return undefined;
  }
}
