import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-abc',
  templateUrl: './abc.component.html',
  styleUrls: ['./abc.component.scss'],
})
export class AbcComponent {
  constructor(private location: Location) {}
  
  back() {
    this.location.back();
  }
}
