<mat-icon *ngIf="activity; else noActivity" class="spinner">
  donut_large
</mat-icon>

<ng-template #noActivity>
  <span *ngIf="!oUser; else oUserAvailable">na</span>

  <ng-template #oUserAvailable>
    <!-- VARIANT: DESKTOP (DEFAULT) -->
    <ng-container *ngIf="variant === 'desktop'">
      <div class="flex-row align-items-center gap-small">
        <span>
          {{ oUser?.displayName }}
        </span>

        <span
          class="material-icons info-icon prevent-select"
          [mtxPopoverTriggerFor]="userPopover"
          mtxPopoverTriggerOn="hover"
          [mtxPopoverTriggerData]="{ oUser: oUser }"
        >
          open_in_new
        </span>
      </div>
    </ng-container>

    <!-- VARIANT: PHONE -->
    <ng-container *ngIf="variant === 'phone'">
      <span class="variant-phone" [class.own-style]="!inheritAppearance" (click)="onClickNameVariantPhone(oUser)">{{ oUser?.displayName }}</span>
    </ng-container>
  </ng-template>

  <mtx-popover #userPopover="mtxPopover" [position]="['above', 'center']">
    <ng-template mtxPopoverContent let-oUser="oUser">
      <div class="flex-column mat-body">
        <span class="row font-weight-500">{{ oUser.displayName }}</span>
        <span class="row">{{ oUser.jobTitle }}</span>
        <div
          *ngIf="oUser.mail"
          class="row flex-row align-items-center gap-small"
        >
          <span class="email">{{ oUser.mail }}</span>
          <span
            class="material-icons clipboard-icon"
            matRipple
            (click)="copyToClipboard(oUser.mail)"
          >
            content_copy
          </span>
        </div>
      </div>
    </ng-template>
  </mtx-popover>
</ng-template>
