
export enum Role {
    TenantOwner = 1,
    TenantAdmin = 2,
    AssetArchitect = 3,
    AssetManager = 4,
    AssetReader = 5,
    AssetUser = 6,
    SysAdmin = 7, // Test and Debug Role
}

export const roles = new Map<number, string>([
    [Role.TenantOwner, "Tenant Owner"],
    [Role.TenantAdmin, "Tenant Admin"],
    [Role.AssetArchitect, "Asset Architect"],
    [Role.AssetManager, "Asset Manager"],
    [Role.AssetReader, "Asset Reader"],
    [Role.AssetUser, "Asset User"],
    [Role.SysAdmin, "SYS Admin"],
]);
