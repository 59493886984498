import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { PlanStepActionHelper } from 'projects/shared/src/lib/classes/planStepActionHelper';
import {
  PlanStepActionsQueryArgs,
  PlanStepActionsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planStepAction';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { Subscription } from 'rxjs';

const PlanStepActionsQuery = gql`
  ${FULL_FRAGMENT_PLAN_STEP_ACTION}
  query PlanStepActions($planId: String!, $planStepId: String!) {
    planStepActions(planId: $planId, planStepId: $planStepId) {
      ...FullFragmentPlanStepAction
    }
  }
`;

@Component({
  selector: 'app-plan-step-actions-summary',
  templateUrl: './plan-step-actions-summary.component.html',
  styleUrls: ['./plan-step-actions-summary.component.scss'],
})
export class PlanStepActionsSummaryComponent implements OnInit, OnDestroy {
  @Input() plan!: PlanOutput;
  @Input() planStep!: PlanStepOutput;

  loading = true;
  errorMessage: string | undefined;

  planStepActions: PlanStepActionOutput[] = [];
  planStepActionHelper = PlanStepActionHelper;

  private _planStepActionsQuery: QueryRef<PlanStepActionsQueryRoot> | undefined;
  private _planStepActionsSubscription: Subscription | undefined;

  constructor(private _apollo: Apollo) {}

  ngOnInit(): void {
    this._loadData();
  }

  ngOnDestroy(): void {}

  private _loadData() {
    this.loading = true;
    this.errorMessage = undefined;

    const variables: PlanStepActionsQueryArgs = {
      planId: this.plan.id,
      planStepId: this.planStep.id,
    };

    this._planStepActionsQuery =
      this._apollo.watchQuery<PlanStepActionsQueryRoot>({
        query: PlanStepActionsQuery,
        variables,
        fetchPolicy: 'cache-first',
      });

    this._planStepActionsSubscription =
      this._planStepActionsQuery.valueChanges.subscribe({
        next: ({ data, loading }) => {
          if (!loading) {
            this.planStepActions = data.planStepActions;
            this.loading = false;
          }
        },
        error: (error) => {
          this.loading = false;
          const message = new CatchError(error).message;
          this.errorMessage = message;
        },
      });
  }
}
