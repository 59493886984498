<h2 mat-dialog-title>
  Book Realtime Action
  <span
    *ngIf="selectedAction"
    class="color-primary-400 font-weight-500 uppercase"
  >
    {{ selectedAction.name }}
  </span>
</h2>

<mat-dialog-content>
  <div class="flex-column form-field-density-1">
    <lib-spinner *ngIf="loading; else notLoadingElement"></lib-spinner>

    <ng-template #notLoadingElement>
      <mat-expansion-panel [expanded]="tenantActions.length < 3">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Selected Asset{{ tenantActions.length > 1 ? "s" : "" }} ({{
              tenantActions.length
            }})
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="flex-column"
          *ngFor="let tenantAction of tenantActions; let i = index"
        >
          <span class="font-weight-500" [class.mt-normal]="i > 0">{{
            tenantAction.assetId
          }}</span>

          <div class="action-info-grid">
            <span>Last Action:</span>
            <div class="flex-row align-items-center gap-small">
              <span class="uppercase">{{ tenantAction.actionType?.name }}</span>
              <span>
                ({{
                  tenantAction.timestamp
                    | date : localeService.datetimePipeString(locale)
                }})
              </span>
            </div>

            <span>Currently Located At:</span>
            <app-action-details
              fromOrTo="to"
              [action]="tenantAction"
            ></app-action-details>

            <span>Previously Located At:</span>
            <app-action-details
              fromOrTo="from"
              [action]="tenantAction"
            ></app-action-details>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-form-field subscriptSizing="dynamic" class="mt-normal">
        <mat-label>Action</mat-label>
        <mat-select
          [(ngModel)]="selectedAction"
          (ngModelChange)="selectedTransitLocationId = undefined"
          required
        >
          <mat-select-trigger>
            <span class="uppercase">{{ selectedAction?.name }}</span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let actionTypeOutput of assortmentService.actionTypes"
            [value]="actionTypeOutput"
          >
            <span class="uppercase">{{ actionTypeOutput.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <lib-location-select
        *ngIf="selectedAction?.id === 2"
        class="mt-normal"
        label="Handover Location"
        [(locationId)]="selectedTransitLocationId"
      ></lib-location-select>

      <div class="h-grid-1-2 gap-normal mt-normal">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>From</mat-label>
          <mat-select [(ngModel)]="selectedFromType" required>
            <mat-option
              *ngFor="let from of fromTypes | keyvalue"
              [value]="from.key"
            >
              {{ from.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="(selectedFromType ?? 0) > 2">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label class="capitalize">
              {{ fromTypes.get(selectedFromType ?? 0) }} Details
            </mat-label>
            <input
              matInput
              [(ngModel)]="fromDetails"
              [autocomplete]="uuid1"
              required
              [disabled]="!selectedFromType"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedFromType === 1">
          <lib-location-select
            label="Location"
            [required]="true"
            [(locationId)]="fromDetails"
          >
          </lib-location-select>
        </ng-container>

        <ng-container *ngIf="selectedFromType === 2">
          <lib-user-select
            appearance="fill"
            label="User"
            [required]="true"
            [(oId)]="fromDetails"
            [tenantUsersOnly]="true"
          ></lib-user-select>
        </ng-container>
      </div>

      <div class="h-grid-1-2 gap-normal mt-normal">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>To</mat-label>
          <mat-select [(ngModel)]="selectedToType" required>
            <mat-option *ngFor="let to of toTypes | keyvalue" [value]="to.key">
              {{ to.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="(selectedToType ?? 0) > 2">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label class="capitalize">
              {{ toTypes.get(selectedToType ?? 0) }} Details
            </mat-label>
            <input
              matInput
              [(ngModel)]="toDetails"
              [autocomplete]="uuid2"
              required
              [disabled]="!selectedToType"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedToType === 1">
          <lib-location-select
            label="Location"
            [required]="true"
            [(locationId)]="toDetails"
          >
          </lib-location-select>
        </ng-container>

        <ng-container *ngIf="selectedToType === 2">
          <lib-user-select
            appearance="fill"
            label="User"
            [required]="true"
            [(oId)]="toDetails"
            [tenantUsersOnly]="true"
          ></lib-user-select>
        </ng-container>
      </div>

      <mat-form-field
        appearance="fill"
        subscriptSizing="dynamic"
        class="mt-normal"
      >
        <mat-label>Notes</mat-label>
        <textarea
          type="text"
          matInput
          [autocomplete]="uuid3"
          [(ngModel)]="description"
          rows="2"
          [cdkTextareaAutosize]="true"
        ></textarea>
      </mat-form-field>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    [disabled]="loading || activity"
    (click)="onClickClose()"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    [disabled]="loading || activity || cannotBook"
    [color]="meaningfulNextAction ? 'primary' : 'warn'"
    matTooltip="Not all assets are in the same state. Booking the same action for all of them might break valid action chains for some."
    matTooltipPosition="above"
    [matTooltipDisabled]="meaningfulNextAction"
    (click)="book()"
  >
    Book
  </button>
</mat-dialog-actions>
