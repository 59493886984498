import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_TENANT_ACTION } from '../fragments/fullFragmentTenantAction';

export const FULL_FRAGMENT_BOOKING_SUB = gql`
  ${FULL_FRAGMENT_TENANT_ACTION}
  fragment FullFragmentBookingSub on BookingSubNotification {
    data {
      action
      bookingType
      bookingUserOid
      bookingData {
        ...FullFragmentTenantAction
      }
    }
  }
`;
