<h2 mat-dialog-title>New Option</h2>

<mat-dialog-content>
    <div class="flex-column form-field-density-1">
        <span class="mt-normal mat-body-2">
            Please provide a name for the new option. It will only be visible to Asset Architects.
        </span>

        <mat-form-field class="mt-normal" appearance="fill">
            <mat-label>Name</mat-label>
            <input #optionNameInput matInput [formControl]="name" [autocomplete]="uuid" (keyup.enter)="create()"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let optionName of filteredOptionNames | async">
                    <span [innerHTML]="optionName | highlight: optionNameInput.value"></span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="error-message">
            {{errorMessage}}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close [disabled]="activity">Cancel</button>
    <button (click)="create()" mat-flat-button color="primary" [disabled]="!canApply || activity">
        <span *ngIf="!activity"> Create </span>
        <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
    </button>

</mat-dialog-actions>