import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_RETURN_TO_CUSTOMER = gql`
  fragment FullFragmentReturnToCustomer on ReturnToCustomerOutput {
    id
    tenantId
    name
    createdAt
    createdBy
    description
    completedAt
    completedBy
  }
`;
