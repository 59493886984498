import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_PLAN_STEP_ACTION = gql`
  fragment FullFragmentPlanStepAction on PlanStepActionOutput {
    id
    tenantId
    planStepId
    actionTypeId
    actionOrder
    date
    completed
    missingGracePeriod
    fromLocationId
    fromUserOid
    fromMail
    fromOther
    toLocationId
    toUserOid
    toMail
    toOther
    description
    transitLocationId
  }
`;
