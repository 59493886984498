<div class="assets-plan-root">
  <!-- ACTIONS -->
  <div class="actions">
    <div class="section">
      <div>PLAN BUILDER</div>
      <span
        *ngIf="subscriptionService.online"
        class="material-symbols-outlined color-green prevent-select"
        matTooltipPosition="above"
        matTooltip="Online Sync ON"
      >
        wifi
      </span>
      <span
        *ngIf="!subscriptionService.online"
        class="material-symbols-outlined color-warn-400 prevent-select"
        matTooltipPosition="above"
        matTooltip="Online Sync OFF"
      >
        wifi_off
      </span>
    </div>

    <span class="font-weight-500">{{ plan?.name }}</span>
    <span class="font-size-14">
      {{ plan?.planStart | date : localeService.datePipeString(locale) }}
      -
      {{ plan?.planEnd | date : localeService.datePipeString(locale) }}
    </span>

    <div class="actions-divider"></div>

    <button
      mat-button
      matTooltip="Filter the assets table for all assets available in this plan."
      matTooltipPosition="above"
      (click)="filterPlanAssetsInAssetsTable()"
    >
      <div class="flex-row gap-tiny align-items-center">
        <mat-icon>filter_list</mat-icon>
        <span style="line-height: initial">filter in assets table</span>
      </div>
    </button>

    <button
      *ngIf="selectionService.selectedAssets.length > 0"
      mat-button
      (click)="addSelectedAssetsToPlan()"
    >
      <div class="flex-row gap-tiny align-items-center">
        <mat-icon>add</mat-icon>
        <span style="line-height: initial">
          add selected Assets ({{ selectionService.selectedAssets.length }})
        </span>
      </div>
    </button>

    <!-- RIGHT ACTIONS -->
    <div class="actions-button-right">
      <button
        *ngIf="plan"
        mat-flat-button
        color="warn"
        (click)="onClickDeletePlan(plan)"
      >
        <div class="flex-row align-items-center gap-tiny">
          <mat-icon fontSet="material-symbols-outlined"> delete </mat-icon>
          <span style="line-height: initial">Delete Plan</span>
        </div>
      </button>

      <button
        *ngIf="plan"
        mat-flat-button
        color="accent"
        (click)="onClickAdjustPlanDuration(plan)"
      >
        <div class="flex-row align-items-center gap-tiny">
          <mat-icon fontSet="material-symbols-outlined">date_range</mat-icon>
          <span style="line-height: initial">Adjust Plan Duration</span>
        </div>
      </button>
    </div>
  </div>

  <!-- CONTENT -->
  <div *ngIf="!plan" class="content-no-plan">
    Please select a plan to start editing.
  </div>

  <mtx-split *ngIf="plan" class="content">
    <mtx-split-pane [size]="22">
      <div class="left form-field-density-1">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <mat-label>Name</mat-label>
          <input
            matInput
            [autocomplete]="uuid1"
            [ngModel]="plan.name"
            (change)="updatePlan($event, plan, 'name')"
            (keyup.escape)="undoPlan($event, plan, 'name')"
          />
        </mat-form-field>

        <lib-user-select
          #responsibleUserSelect
          class="mt-normal"
          appearance="fill"
          label="Responsible"
          [tenantUsersOnly]="true"
          [oId]="plan.responsibleId ?? undefined"
          (oIdChange)="updateResponsibleId(responsibleUserSelect, $event, plan)"
        ></lib-user-select>

        <mat-form-field class="mt-normal" subscriptSizing="dynamic">
          <mat-label>Notes</mat-label>
          <textarea
            matInput
            [ngModel]="plan.description"
            (change)="updatePlan($event, plan, 'description')"
            (keyup.escape)="undoPlan($event, plan, 'description')"
          ></textarea>
        </mat-form-field>

        <mat-form-field class="mt-normal" subscriptSizing="dynamic">
          <mat-label>Assets ({{ planAssets?.length ?? "0" }})</mat-label>
          <mat-chip-grid #chipGrid>
            <mat-chip-row
              *ngFor="let planAsset of planAssets"
              (click)="clipboard.copy(planAsset.tenantAssetId)"
              [class.asset-is-missing]="
                $any(assetService.cache.get(planAsset.tenantAssetId))?.asset[
                  specialTenantAssetColumn_Missing?.field ?? 'na'
                ] === true
              "
              matTooltip="Asset is MISSING"
              matTooltipPosition="above"
              [matTooltipDisabled]="
                $any(assetService.cache.get(planAsset.tenantAssetId))?.asset[
                  specialTenantAssetColumn_Missing?.field ?? 'na'
                ] !== true
              "
            >
              {{ planAsset.tenantAssetId }}
              <button
                matChipRemove
                (click)="removeAssetFromPlan(planAsset.tenantAssetId)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            #assetPlanInput
            [autocomplete]="uuid3"
            [matChipInputFor]="chipGrid"
            placeholder="Add asset ..."
            (matChipInputTokenEnd)="
              addAssetToPlan($event.value, assetPlanInput)
            "
          />
        </mat-form-field>
      </div>
    </mtx-split-pane>

    <mtx-split-pane [size]="78">
      <div class="right form-field-density-1">
        <mat-accordion *ngIf="planSteps" multi class="mb-normal">
          <!-- PLAN-STEP EXPANSION -->
          <ng-container *ngFor="let planStep of planSteps">
            <mat-expansion-panel
              [expanded]="expansionPanelIsExpandedPlanStep.get(planStep.id)"
              (opened)="expansionPanelIsExpandedPlanStep.set(planStep.id, true)"
              (closed)="
                expansionPanelIsExpandedPlanStep.set(planStep.id, false)
              "
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="uppercase">{{ planStep.name }}</span>
                </mat-panel-title>
                <mat-panel-description>
                  <app-plan-step-actions-summary
                    [plan]="plan"
                    [planStep]="planStep"
                  ></app-plan-step-actions-summary>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <!-- PLAN-STEP EXPANSION CONTENT -->
              <ng-template matExpansionPanelContent>
                <div class="plan-step-expansion-panel-grid">
                  <!-- LEFT -->
                  <div class="flex-column">
                    <mat-form-field appearance="fill">
                      <mat-label>Name</mat-label>
                      <input
                        matInput
                        [autocomplete]="uuid2"
                        [ngModel]="planStep.name"
                        (change)="updatePlanStep($event, planStep, 'name')"
                        (keyup.escape)="undoPlanStep($event, planStep, 'name')"
                      />
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Notes</mat-label>
                      <textarea
                        matInput
                        [ngModel]="planStep.description"
                        (change)="
                          updatePlanStep($event, planStep, 'description')
                        "
                        (keyup.escape)="
                          undoPlanStep($event, planStep, 'description')
                        "
                      >
                      </textarea>
                    </mat-form-field>

                    <app-plan-step-assets
                      [plan]="plan"
                      [planStep]="planStep"
                      [planAssets]="planAssets"
                    ></app-plan-step-assets>

                    <button
                      mat-flat-button
                      color="warn"
                      (click)="onClickDeletePlanStep(planStep)"
                    >
                      <div class="flex-row align-items-center gap-tiny">
                        <mat-icon>playlist_remove</mat-icon>
                        <span style="line-height: initial">Remove Step</span>
                      </div>
                    </button>
                  </div>

                  <!-- RIGHT -->
                  <div class="plan-step-expansion-panel-grid-right">
                    <app-plan-step-actions
                      [plan]="plan"
                      [planStep]="planStep"
                    ></app-plan-step-actions>
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>

        <lib-select-button
          icon="playlist_add"
          text="Add Step: with all assets"
          (onClick)="onClickCreateStep(true)"
        >
          <button mat-menu-item (click)="onClickCreateStep(true)">
            Add Step: with <b>all</b> assets
          </button>
          <button mat-menu-item (click)="onClickCreateStep(false)">
            Add Step: with <b>no</b> assets
          </button>
        </lib-select-button>
      </div>
    </mtx-split-pane>
  </mtx-split>
</div>

<!-- 
<lib-select-button style="margin-left: 200px;">
    Hallo
</lib-select-button> -->
