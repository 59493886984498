import { gql } from 'apollo-angular';
import { LocalEventData_ReturnToCustomerNote, LocalEventType } from '../local-event.service';
import { RemoteEventData_ReturnToCustomerNote, RemoteEventType } from '../remote-event.service';
import { EventSource, NotificationService } from './notification.service';

export const handle_ReturnToCustomerNote = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_ReturnToCustomerNote | RemoteEventData_ReturnToCustomerNote
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // Do the following only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      this.hasSubscribers(EventSource.Local, LocalEventType.ReturnToCustomerNote)
    ) {
      // We have subscribers. Notify them.
      this.localEventHandled.next([LocalEventType.ReturnToCustomerNote, eventData]);
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.ReturnToCustomerNote)
    ) {
      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.ReturnToCustomerNote, eventData]);
      return;
    }
  }
};
