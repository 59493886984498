import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';

@Component({
  selector: 'app-plan-info-renderer',
  templateUrl: './plan-info-renderer.component.html',
  styleUrls: ['./plan-info-renderer.component.scss']
})
export class PlanInfoRendererComponent implements ICellRendererAngularComp {
  isCurrentPlan = false;
  isFuturePlan = false;

  agInit(params: ICellRendererParams): void {
    if (typeof params.data === 'undefined') {
      return;
    }

    this._evaluateData(params);
  }
  refresh(params: ICellRendererParams): boolean {
    if (typeof params.data === 'undefined') {
      return true;
    }

    this._evaluateData(params);
    return true;
  }

  private _evaluateData(params: ICellRendererParams | undefined) {
    if (!params) {
      return;
    }

    const plan = params.data as PlanOutput;

    const now = new Date();

    this.isCurrentPlan = now >= new Date(plan.planStart) && now <= new Date(plan.planEnd);
    this.isFuturePlan = now < new Date(plan.planStart);
  }
}
