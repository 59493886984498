<div class="asset-main">
    <lib-spinner class="mt-normal" *ngIf="loading.isJobXOngoing[1]; else notLoading"></lib-spinner>

    <ng-template #notLoading>
        <lib-card class="card-top form-field-density-1">
            <lib-card-title class="mat-h2">
                <div class="flex-row align-items-center ">
                    <div class="mr-normal">ASSET TYPE</div>
                    <button mat-button color="accent" *ngIf="selectedChangeAssetChangeable !== liveChangeAssetChangeable &&  
                        selectedChangeAssetChangeable?.hasChangedToDatabaseSource"
                        (click)="saveChangeAssetAsync(selectedChangeAssetChangeable)">save changes</button>
                </div>
            </lib-card-title>
            <lib-card-actions>
                <div class="flex-row align-items-center">
                    <span *ngIf="!selectedChangeAssetChangeable?.isActive" class="mr-small inactive-text mat-body">asset
                        is not visible
                        and
                        cannot be used</span>
                    <mat-slide-toggle color="primary" [ngModel]="selectedChangeAssetChangeable?.isActive"
                        labelPosition="before" class="actions-corrections">
                        <span *ngIf="selectedChangeAssetChangeable?.isActive">active</span>
                        <div *ngIf="!selectedChangeAssetChangeable?.isActive">
                            <span>inactive</span>
                        </div>
                    </mat-slide-toggle>
                </div>
            </lib-card-actions>

            <lib-card-content class="mat-body">
                <div class="flex-column">
                    <mat-form-field appearance="fill" class="correction-mb-17">
                        <mat-label>Name</mat-label>
                        <input matInput [autocomplete]="uuid1" [(ngModel)]="selectedChangeAssetChangeable!.name"
                            (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                            required [readonly]="isChangeAssetLocked">
                    </mat-form-field>
                </div>
            </lib-card-content>
        </lib-card>


        <lib-card class="card-bottom form-field-density-1">

            <lib-card-title class="mat-h2">PROPERTIES</lib-card-title>

            <lib-card-actions>
                <div class="flex-row gap-small">

                    <section>
                        <mat-checkbox
                            *ngIf="selectedChangeAssetPropertyChangeable && !isChangeAssetLocked && !(!selectedChangeAssetPropertyChangeable.originalTypeId && !selectedChangeAssetPropertyChangeable.databaseSource)"
                            color="warn" [(ngModel)]="selectedChangeAssetPropertyChangeable.markAsToBeDeleted"
                            (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                            labelPosition="before">
                            delete
                        </mat-checkbox>
                    </section>
                    <button
                        *ngIf="selectedChangeAssetPropertyChangeable && !selectedChangeAssetPropertyChangeable.originalTypeId && !selectedChangeAssetPropertyChangeable.databaseSource"
                        mat-flat-button color="warn" (click)="deleteNotPersistedObject()">
                        Delete
                    </button>
                    <button mat-flat-button color="primary" (click)="addAssetProperty()"
                        [disabled]="isChangeAssetLocked">
                        Add
                    </button>
                </div>
            </lib-card-actions>

            <lib-card-content>
                <div class="properties-grid">
                    <div class="left">
                        <mat-action-list class="correction2 list">

                            <ng-container
                                *ngFor="let changeAssetPropertyChangeable of selectedChangeAssetChangeable?.changeAssetProperties">
                                <button mat-list-item
                                    [ngClass]="selectedChangeAssetPropertyChangeable === changeAssetPropertyChangeable ? 'active-select-bg' : ''"
                                    (click)="selectedChangeAssetPropertyChangeable = changeAssetPropertyChangeable">
                                    <span>{{changeAssetPropertyChangeable.displayName}}</span>
                                </button>
                            </ng-container>
                        </mat-action-list>
                    </div>
                    <div class="right mat-body">
                        <span
                            *ngIf="!selectedChangeAssetPropertyChangeable && liveChangeAssetChangeable?.changeAssetProperties?.length === 0; else hasChangeAssetProperties">There
                            are currently no Asset specific
                            properties defined. Start adding one by clicking on the button "Add Asset Property".
                            If you already have ongoing and unapproved changes you need to continue to work on them.
                        </span>

                        <ng-template #hasChangeAssetProperties>

                            <!-- Row Display Name -->
                            <mat-form-field appearance="fill">
                                <mat-label>Display Name</mat-label>
                                <input matInput [autocomplete]="uuid2"
                                    [(ngModel)]="selectedChangeAssetPropertyChangeable!.displayName"
                                    (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable?.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                                    required [readonly]="isChangeAssetLocked">
                            </mat-form-field>

                            <!-- Row Type -->
                            <div class="flex-row gap-small">
                                <mat-form-field appearance="fill" class="width-100">
                                    <mat-label>Type</mat-label>
                                    <!-- The typeId cannot be set for properties that were already live -->
                                    <input
                                        *ngIf="selectedChangeAssetPropertyChangeable!.originalTypeId || isChangeAssetLocked; else noLiveTypeIdElement"
                                        matInput [ngModel]="selectedChangeAssetPropertyChangeable?.typeName" required
                                        readonly>
                                    <ng-template #noLiveTypeIdElement>
                                        <mat-select [(ngModel)]="selectedChangeAssetPropertyChangeable!.typeId"
                                            (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable?.specialActionForTypeIdChanges(); selectedChangeAssetPropertyChangeable?.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                                            name="type" required>
                                            <mat-option *ngFor="let type of assortmentService.propertyTypes"
                                                [value]="type.id">
                                                {{type.name}}
                                            </mat-option>
                                        </mat-select>
                                    </ng-template>
                                </mat-form-field>

                                <ng-container *ngIf="selectedChangeAssetPropertyChangeable!.typeId === 1">
                                    <!-- readonly input -->
                                    <mat-form-field class="width-100" appearance="fill">
                                        <mat-label>Text Options</mat-label>
                                        <input *ngIf="isChangeAssetLocked; else showOptionSelectElement" matInput
                                            [ngModel]="getOptionNameById(selectedChangeAssetPropertyChangeable?.optionId)"
                                            readonly>
                                        <ng-template #showOptionSelectElement>
                                            <mat-select [(ngModel)]="selectedChangeAssetPropertyChangeable!.optionId"
                                                (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable?.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                                                name="option">
                                                <mat-option *ngFor="let option of options" [value]="option.id">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </ng-template>
                                    </mat-form-field>
                                </ng-container>

                            </div>

                            <!-- Row Optional -->
                            <section class="mb-large">
                                <mat-radio-group [(ngModel)]="selectedChangeAssetPropertyChangeable!.isNullable"
                                    (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable?.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();">
                                    <mat-radio-button [value]="false"
                                        [ngClass]="isChangeAssetLocked ? 'pointer-events-none' : ''">mandatory</mat-radio-button>
                                    <mat-radio-button [value]="true"
                                        [ngClass]="isChangeAssetLocked ? 'pointer-events-none' : ''">optional</mat-radio-button>
                                </mat-radio-group>
                            </section>

                            <!-- Row Short Info -->
                            <mat-form-field appearance="fill">
                                <mat-label>Short Info</mat-label>
                                <textarea matInput [autocomplete]="uuid3"
                                    [(ngModel)]="selectedChangeAssetPropertyChangeable!.shortInfo"
                                    (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable?.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                                    rows="2" maxlength="100" [readonly]="isChangeAssetLocked"></textarea>
                            </mat-form-field>

                            <!-- Row Long Info -->
                            <mat-form-field appearance="fill">
                                <mat-label>Long Info</mat-label>
                                <textarea rows="4" matInput [autocomplete]="uuid3"
                                    [(ngModel)]="selectedChangeAssetPropertyChangeable!.longInfo"
                                    (ngModelChange)="tryCreateOpenChangeAsset(); selectedChangeAssetPropertyChangeable?.evaluateHasChangedToDatabaseSource(); selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();"
                                    [readonly]="isChangeAssetLocked">
                                </textarea>
                            </mat-form-field>

                            <!-- <section class="mt-normal">
                                TODO Options
                            </section> -->

                        </ng-template>
                    </div>
                </div>
            </lib-card-content>
        </lib-card>


    </ng-template>
</div>


<div class="asset-history">
    <button mat-button (click)="historyIsExpanded = !historyIsExpanded">
        <mat-icon *ngIf="!historyIsExpanded">chevron_left</mat-icon>
        <mat-icon *ngIf="historyIsExpanded">chevron_right</mat-icon>
    </button>

    <lib-spinner *ngIf="loadingChanges.isOngoing" class="align-self-center"></lib-spinner>
    <mat-action-list *ngIf="!loadingChanges.isOngoing" class="history-list">
        <!-- LIVE -->
        <button mat-list-item
            [ngClass]="selectedChangeAssetChangeable === liveChangeAssetChangeable ? 'active-live' : ''"
            (click)="selectedChangeAssetChangeable = liveChangeAssetChangeable; setSelectedChangeAssetPropertyChangeable(liveChangeAssetChangeable)">
            <div class="flex-row align-items-center">
                <i class="material-icons" style="color: green">play_circle_filled</i>
                <span *ngIf="historyIsExpanded" class="ml-normal">live</span>
            </div>
        </button>

        <button *ngFor="let changeAssetChangeable of changeAssetChangeables" mat-list-item
            [ngClass]="selectedChangeAssetChangeable === changeAssetChangeable ? 'active-select-bg' : ''"
            (click)="selectedChangeAssetChangeable = changeAssetChangeable; setSelectedChangeAssetPropertyChangeable(changeAssetChangeable)">
            <!-- Already approved change -->
            <ng-container *ngIf="changeAssetChangeable.databaseSource?.approvedAt">
                <div class="flex-row align-items-center">
                    <i class="material-icons" style="color: dimgray">task_alt</i>
                    <span *ngIf="historyIsExpanded" class="ml-normal">
                        <div class="flex-column">
                            <span>applied on</span>
                            <span class="sub-state">{{changeAssetChangeable.databaseSource?.approvedAt |
                                date:'yyyy-MM-dd'}}</span>
                        </div>
                    </span>
                </div>
            </ng-container>

            <!-- Unapproved change -->
            <!-- Might be "ready for approval" or still "under construction" -->
            <ng-container *ngIf="!changeAssetChangeable.databaseSource?.approvedAt">
                <div class="flex-row align-items-center">
                    <i class="material-icons"
                        [ngClass]="changeAssetChangeable.databaseSource?.readyForApproval ? 'icon-ready-for-approval' : 'icon-ongoing'">radio_button_unchecked</i>
                    <span *ngIf="historyIsExpanded" class="ml-normal">
                        <div class="flex-column">
                            <span>open</span>
                            <span class="sub-state">unsaved</span>
                        </div>
                    </span>
                </div>
            </ng-container>
        </button>

    </mat-action-list>
</div>