<div class="button-container">
  <button
    (click)="onClick($event)"
    mat-raised-button
    color="primary"
    class="restore-button"
  >
    {{label}}
  </button>
</div>
