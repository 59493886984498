import { Component, OnDestroy } from '@angular/core';
import { SelectionService } from '../../services/selection.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnDestroy {
  constructor( public selectionService: SelectionService) {}

  ngOnDestroy(): void {
    this.selectionService.selectedAssets = [];
    this.selectionService.selectedNodesInAssetsTable = [];
  }
}
