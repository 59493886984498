<div class="d-toast-container">
  <div
    class="message"
    *ngFor="let event of toastService.toastEvents"
    (mouseenter)="event.staysOpen = true"
    [ngClass]="
      event.type === 2 ? 'error' : event.type === 1 ? 'warning' : 'info'
    "
  >
    <div class="left">
      <div *ngIf="event.title" class="title">{{ event.title }}</div>
      <div class="mat-body" [innerHTML]="event.message"></div>
    </div>
    <div *ngIf="event.staysOpen" style="margin-top: -10px; margin-right: -10px">
      <button mat-icon-button (click)="toastService.close(event)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
