import { Subject } from "rxjs";

export class Operator {
    // #region Public Properties

    progress = new Subject<number>();
    get isFinished() {
        return this._hasFinished;
    }
    withErrors = false;

    // #endregion Public Properties


    // #region Private & Static Properties

    private _tasks = 0;
    private _finishedTasks = 0;
    private _promiseTasks = new Map<number, () => Promise<void>>();
    private _nonPromiseTasks = new Map<number, (next: () => void, error: () => void) => any>();
    private _hasStarted = false;
    private _hasFinished = false;

    static errorMessageNoMoreTasks = 'You cannot add more tasks since the execution already has started.';

    // #endregion Private & Static Properties


    // #region Public Methods

    /**
     * If the task has any dependencies don't forget to bind the respective context.
     */
    addPromiseTask(task: () => Promise<void>): void {
        if (this._hasStarted) {
            throw new Error(Operator.errorMessageNoMoreTasks);
        }

        this._tasks++;
        this._promiseTasks.set(this._tasks, task);
    }

    /**
     * Make sure to catch any errors inside the task itself. Use NEXT 
     * for a successful execution or ERROR for an unsuccessful execution.
     * 
     * If the task has any dependencies don't forget to bind the respective context.
     */
    addNonPromiseTask(task: (next: () => void, error: () => void) => any): void {
        if (this._hasStarted) {
            throw new Error(Operator.errorMessageNoMoreTasks);
        }

        this._tasks++;
        this._nonPromiseTasks.set(this._tasks, task);
    }

    async executeTasksAsync() {
        this._hasStarted = true;

        for (let task = 1; task <= this._tasks; task++) {
            // Find task either as promise or regular method
            if (this._promiseTasks.has(task)) {
                try {
                    const promiseTask = this._promiseTasks.get(task);
                    if (promiseTask) {
                        await promiseTask();
                    }
                } catch (error) {
                    this.withErrors = true;
                } finally {
                    this._finishedTasks++;
                    this._evaluateProgress();
                }
            } else {
                const nonPromiseTask = this._nonPromiseTasks.get(task);
                if (nonPromiseTask) {
                    nonPromiseTask(this._next.bind(this), this._error.bind(this))
                }
            }
        }
    }

    // #endregion Public Methods


    // #region Private Methods

    private _evaluateProgress() {
        const value = (this._finishedTasks / this._tasks) * 100;

        if (value === 100) {
            this._hasFinished = true;
        }

        this.progress.next(value);
    }

    private _next(): void {
        this._finishedTasks++;
        this._evaluateProgress();
    }

    private _error(): void {
        this._finishedTasks++;
        this.withErrors = true;
    }

    // #endregion Private Methods
}