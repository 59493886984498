import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { Subscription, firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';
import { OUser } from '../../common/oUser';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserSelectService } from '../../services/user-select.service';
import { Apollo, gql } from 'apollo-angular';
import { TenantUserOIdsQueryRoot } from '../../graphql/crud/user';

type GraphResponse = {
  '@odata.count': number;
  '@odata.nextLink'?: string;
  value: OUser[];
};

@Component({
  selector: 'lib-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit, OnDestroy {
  // #region In / Out

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() subscriptSizing: SubscriptSizing = 'dynamic';
  @Input() label: string | undefined;
  @Input() readonly = false;
  @Input() required = false;
  @Input() hint: string | undefined;
  @Input() hideSearchIcon = false;
  @Input() tenantUsersOnly = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = value;
    if (value === true) {
      this.searchText.enable();
    } else {
      this.searchText.disable();
    }
  }

  @Input()
  get oId(): string | undefined {
    return this._oId;
  }
  set oId(value: string | undefined) {
    this._oId = value;
    if (value) {
      this._loadOUser(value);
    } else {
      this.searchText.setValue(null);
      this.oUsers = [];
      this.oUser = undefined;
      this.oUserChange.emit(undefined);
    }
  }
  @Output() oIdChange = new EventEmitter<string>();

  @Output() oUserChange = new EventEmitter<OUser>();

  // #endregion In / Out

  // #region Public Properties

  oUser: OUser | undefined;
  uuid = v4();
  activity = 0;
  searchText = new FormControl<string | null>(null);
  oUsers: OUser[] = [];

  // #endregion Public Properties

  private _oId: string | undefined;
  private _disabled = false;
  private _searchStringSubscription: Subscription | undefined;
  private _delayTimeout: number | undefined;

  constructor(
    private _http: HttpClient,
    private _userSelectService: UserSelectService,
    private _apollo: Apollo
  ) {}

  ngOnInit(): void {
    if (this.disabled) {
      this.searchText.disable();
    }

    this._searchStringSubscription = this.searchText.valueChanges.subscribe((value) => {
      if (!value) {
        return;
      }

      if ((value?.length ?? 0) <= 2) {
        return;
      }

      window.clearTimeout(this._delayTimeout);

      this._delayTimeout = window.setTimeout(() => {
        this._fetch(value);
      }, 500);
    });
  }

  ngOnDestroy(): void {
    this._searchStringSubscription?.unsubscribe();
  }

  displayFn(oUser: OUser | null): string {
    return oUser?.displayName ?? '';
  }

  onClickClear() {
    this.searchText.setValue(null);
    this.oUsers = [];
    this.oUser = undefined;
    this._oId = undefined;
    this.oIdChange.emit(undefined);
    this.oUserChange.emit(undefined);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.oUser = event.option.value;
    this._oId = this.oUser?.id;
    this.oIdChange.emit(this.oId);
    this.oUserChange.emit(this.oUser);
  }

  extractEID(email: string | null): string {
    if (!email) {
      return '';
    }
    return email.split('@')[0];
  }

  private async _fetch(searchString: string) {
    this.activity++;
    try {
      if (this.tenantUsersOnly) {
        // The search should only be performed on users that have configured
        // any role in the tenant.
        const filteredOUsers = await this.#loadAndFilterTenantOUsers(searchString);
        this.oUsers = filteredOUsers ?? [];
      } else {
        // Search through all Accenture.
        const baseUrl = 'https://graph.microsoft.com/v1.0/users';
        const searchParam = this._buildSearchParameter(searchString);
        const orderParam = `$orderby=displayName`;
        const countParam = `$count=true`;
        const topParam = `$top=20`;

        const url = `${baseUrl}?${searchParam}&${orderParam}&${countParam}&${topParam}`;

        const result = await firstValueFrom(
          this._http.get(url, {
            headers: {
              ConsistencyLevel: 'eventual',
            },
          })
        );

        this.oUsers = (result as GraphResponse).value;
      }

      // Cache users.
      this.oUsers.forEach((x) => this._userSelectService.setOUserByOId(x, x.id));
    } catch (error) {
      console.log(error);
    } finally {
      this.activity--;
    }
  }

  private _buildSearchParameter(searchString: string): string {
    const processed = searchString.toLowerCase().trim();

    const splitResult = processed.split(' ');

    if (splitResult.length === 1) {
      return `$search="displayName:${processed}" OR "mail:${processed}"`;
    }

    let returnParam = '$search=';

    let i = -1;
    for (let searchPart of splitResult) {
      i++;
      if (!searchPart) {
        continue;
      }

      if (i > 0) {
        returnParam += ' AND ';
      }

      returnParam += `"displayName:${searchPart}"`;
    }

    returnParam += ` OR "mail:${processed}"`;

    return returnParam;
  }

  private async _loadOUser(oid: string) {
    this.activity++;
    try {
      const oUser = await this._userSelectService.getOUserByOId(oid);
      this.oUser = oUser;
      this.searchText.setValue(oUser as any);
      this.oUserChange.emit(oUser);
    } catch (error) {
      console.log(error);
    } finally {
      this.activity--;
    }
  }

  async #loadAndFilterTenantOUsers(searchString: string): Promise<OUser[] | undefined> {
    try {
      const tenantUserOIds = await this._userSelectService.getTenantUserOIds();

      if (tenantUserOIds.length === 0) {
        this.oUsers = [];
      }

      // Load "only" the user information from Microsoft Graph for the provided tenant users.
      const url = 'https://graph.microsoft.com/v1.0/directoryObjects/getByIds';
      const body = {
        ids: tenantUserOIds,
        types: ['user'],
      };

      const httpResult = await firstValueFrom(this._http.post(url, body));
      const oUsers = (httpResult.valueOf() as GraphResponse).value as OUser[];
      const filteredOUsers = this.#manuallyFilterOUsers(oUsers, searchString);
      return filteredOUsers;
    } catch (error) {
      return undefined;
    }
  }

  #manuallyFilterOUsers(oUsers: OUser[], searchString: string): OUser[] {
    const searchParts = searchString.trim().toLowerCase().split(' ');

    const filteredOUsers: OUser[] = [];
    for (const oUser of oUsers) {
      let hits = 0;

      for (const searchPart of searchParts) {
        if (oUser.displayName?.toLowerCase().includes(searchPart)) {
          hits++;
          continue;
        }

        if (oUser.jobTitle?.toLowerCase().includes(searchPart)) {
          hits++;
          continue;
        }

        if (oUser.mail?.toLowerCase().includes(searchPart)) {
          hits++;
          continue;
        }
      }

      if (hits === searchParts.length) {
        filteredOUsers.push(oUser);
      }
    }

    return filteredOUsers;
  }
}
