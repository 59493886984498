import { Component, Inject, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { DateTime, Info } from 'luxon';
import {
  BookPlanStepActionDialogComponent,
  BookPlanStepActionDialogData,
  BookPlanStepActionDialogResult,
} from 'projects/desktop/src/app/component-dialogs/book-plan-step-action-dialog/book-plan-step-action-dialog.component';
import {
  CreateOrEditPlanStepActionDialogComponent,
  CreateOrEditPlanStepActionDialogData,
  CreateOrEditPlanStepActionDialogResult,
} from 'projects/desktop/src/app/component-dialogs/create-or-edit-plan-step-action-dialog/create-or-edit-plan-step-action-dialog.component';
import { DesktopToastService } from 'projects/desktop/src/app/services/desktop-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { PlanQueryArgs, PlanQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';
import {
  PlanStepActionQueryArgs,
  PlanStepActionQueryRoot,
  PlanStepActionsQueryArgs,
  PlanStepActionsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planStepAction';
import {
  BookingPlannedSubscriptionArgs,
  BookingPlannedSubscriptionRoot,
  TenantActionsQueryArgs,
  TenantActionsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { actionTypes } from 'projects/shared/src/lib/graphql/enums/actionType';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { FULL_FRAGMENT_PLAN_STEP } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStep';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { FULL_FRAGMENT_PLAN_STEP_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAsset';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { PlanStepAssetOutput } from 'projects/shared/src/lib/graphql/output/planStepAssetOutput';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { PlannedActionBookingData, SubscriptionEvent, SubscriptionEventType } from './type-defs';
import {
  AssetMissingSubscriptionRoot,
  GetMissingQueryArgs,
  GetMissingQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/assetMissing';
import { FULL_FRAGMENT_ASSET_MISSING_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentAssetMissingSub';
import { FULL_FRAGMENT_ASSET_MISSING } from 'projects/shared/src/lib/graphql/fragments/fullFragmentAssetMissing';
import { AssetMissingSubNotificationData } from 'projects/shared/src/lib/graphql/subNotifications/assetMissingSubNotification';
import {
  PlanStepAssetsQueryArgs,
  PlanStepAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planStepAsset';
import { SubscriptionService } from 'projects/desktop/src/app/serices/subscription.service';

type PlannedActionData = {
  plan: PlanOutput;
  planStep: PlanStepOutput;
  planStepAction: PlanStepActionOutput;
  planStepAssets: PlanStepAssetOutput[];
};

class PlannedAction {
  bookings: TenantActionOutput[] = [];
  name: string;
  get data() {
    return this.#data;
  }

  get hasMissingAssets(): boolean {
    return this.data.planStepAssets.some((x) => x.assetMissing?.missing === true);
  }

  #data: PlannedActionData;
  readonly subscriptionEvents: SubscriptionEvent<any>[] = [];
  gracePeriodDate: Date;

  constructor(data: PlannedActionData) {
    this.#data = data;
    this.name = actionTypes.get(data.planStepAction.actionTypeId) ?? 'na';
    this.gracePeriodDate = DateTime.fromISO(this.data.planStepAction.date)
      .plus({
        hours: this.data.planStepAction.missingGracePeriod,
      })
      .toJSDate();
  }

  resetData(data: PlannedActionData) {
    this.#data = data;
    this.recalcGracePeriodDate();
  }

  recalcGracePeriodDate() {
    this.gracePeriodDate = DateTime.fromISO(this.data.planStepAction.date)
      .plus({
        hours: this.data.planStepAction.missingGracePeriod,
      })
      .toJSDate();
  }

  getBooking(tenantAssetId: string): TenantActionOutput | undefined {
    return this.bookings.find((x) => x.assetId === tenantAssetId);
  }

  async refetchPlanStepAssets(apollo: Apollo) {
    const variables: PlanStepAssetsQueryArgs = {
      planId: this.data.plan.id,
      planStepId: this.data.planStep.id,
    };

    const result = await firstValueFrom(
      apollo.query<PlanStepAssetsQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_PLAN_STEP_ASSET}
          ${FULL_FRAGMENT_ASSET_MISSING}
          query PlanStepAssets($planId: String!, $planStepId: String!) {
            planStepAssets(planId: $planId, planStepId: $planStepId) {
              ...FullFragmentPlanStepAsset
              assetMissing {
                ...FullFragmentAssetMissing
              }
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    this.data.planStepAssets = result.data.planStepAssets.sortBy((x) => x.tenantAssetId);
  }
}

@Injectable()
export class CWRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<DateTime>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this.#createCWRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this.#createCWRange(activeDate);
  }

  #createCWRange(date: D | null): DateRange<D> {
    if (date == null) {
      return new DateRange<D>(null, null);
    }

    const dateTime = date as unknown as DateTime;
    const start = dateTime.startOf('week');
    const end = dateTime.endOf('week');
    return new DateRange<D>(start as D, end as D);
  }
}

@Component({
  selector: 'app-assets-plans-live',
  templateUrl: './assets-plans-live.component.html',
  styleUrls: ['./assets-plans-live.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: CWRangeSelectionStrategy,
    },
  ],
})
export class AssetsPlansLiveComponent implements OnInit, OnDestroy {
  @Input()
  get plans() {
    return this.#plans;
  }
  set plans(value) {
    this.#plans = value.sortBy((x) => x.name);
    this.#inputPlansHaveChanged();
  }

  weekYear!: number;
  weekNumber!: number;
  cwStartDate: any;
  cwEndDate: any;
  readonly subscriptionEvents: SubscriptionEvent<any>[] = [];

  /**
   * string: planStepActionId
   */
  isPlannedActionConsideredRed = new Map<string, boolean>();

  /**
   * string: \<planId_weekYear_weekNumber_weekDay\>
   *
   * PlannedAction[]: The planned actions with relevant information.
   */
  plannedActions = new Map<string, PlannedAction[]>();

  /**
   * string: \<planStepActionId\>
   *
   * string: \<planId_weekYear_weekNumber_weekDay\>
   */
  planStepActionIdMatch = new Map<string, string>();

  planActivity = new Map<string, boolean>();
  subscriptionEventType = SubscriptionEventType;

  #plans: PlanOutput[] = [];
  #loadedPlanIds = new Set<string>();
  #bookingSubscription: Subscription | undefined;
  #assetMissingSubscription: Subscription | undefined;

  constructor(
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private _apollo: Apollo,
    private _matDialog: MatDialog,
    private _toastService: DesktopToastService,
    public subscriptionService: SubscriptionService
  ) {
    this.#setToday();
  }

  ngOnInit(): void {
    this.#startBookingSubscription();
    this.#startAssetMissingSubscription();
  }

  ngOnDestroy(): void {
    this.#bookingSubscription?.unsubscribe();
    this.#assetMissingSubscription?.unsubscribe();
  }

  adjustBookings(lookupId: string, plan: PlanOutput, action: PlanStepActionOutput) {
    const data: BookPlanStepActionDialogData = {
      plan,
      action,
    };

    const dialog = this._matDialog.open(BookPlanStepActionDialogComponent, {
      autoFocus: false,
      data,
    });

    dialog
      .afterClosed()
      .subscribe(async (didChanges: BookPlanStepActionDialogResult | undefined) => {
        if (!didChanges) {
          return; // No adjustments to the bookings were made.
        }

        try {
          this.planActivity.set(plan.id, true);
          const tenantActions = await this.#loadTenantActions(plan.id);
          this.#exchangePlannedActionBookings(lookupId, action.id, tenantActions);

          const plannedAction = this.plannedActions
            .get(lookupId)
            ?.find((x) => x.data.planStepAction.id === action.id);
          this.#updateIsPlannedActionConsideredRed(plannedAction ? [plannedAction] : undefined);
        } catch (error) {
        } finally {
          this.planActivity.set(plan.id, false);
        }
      });
  }

  async editAction(lookupId: string, plannedAction: PlannedAction) {
    let allPlanStepActions: PlanStepActionOutput[] = [];

    try {
      // First get all planStepActions for the planStep.
      const variables: PlanStepActionsQueryArgs = {
        planId: plannedAction.data.plan.id,
        planStepId: plannedAction.data.planStepAction.planStepId,
      };

      const result = await firstValueFrom(
        this._apollo.query<PlanStepActionsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PLAN_STEP_ACTION}
            query PlanStepActions($planId: String!, $planStepId: String!) {
              planStepActions(planId: $planId, planStepId: $planStepId) {
                ...FullFragmentPlanStepAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      allPlanStepActions = result.data.planStepActions.sortBy((x) => x.date);
    } catch (error) {
      this._toastService.error(new CatchError(error).message, 'ERROR');
      return;
    }

    // Find (possible) beforeAction and afterAction.
    const editActionIndex = allPlanStepActions.findIndex(
      (x) => x.id === plannedAction.data.planStepAction.id
    );
    if (editActionIndex === -1) {
      this._toastService.error('Could not determine previous or next action.', 'ERROR');
      return;
    }

    const beforeAction: PlanStepActionOutput | undefined =
      editActionIndex === 0 ? undefined : allPlanStepActions[editActionIndex - 1];

    const afterAction: PlanStepActionOutput | undefined =
      editActionIndex === allPlanStepActions.length - 1
        ? undefined
        : allPlanStepActions[editActionIndex + 1];

    const data: CreateOrEditPlanStepActionDialogData = {
      planStep: plannedAction.data.planStep,
      currentAction: plannedAction.data.planStepAction,
      actionTypeId: undefined,
      beforeAction,
      afterAction,
    };

    const dialog = this._matDialog.open(CreateOrEditPlanStepActionDialogComponent, {
      data,
      autoFocus: false,
    });

    dialog
      .afterClosed()
      .subscribe(async (updatedPlanStepAction: CreateOrEditPlanStepActionDialogResult) => {
        if (typeof updatedPlanStepAction === 'undefined') {
          return; // Nothing to do.
        }

        await this.#updatePlannedActionsViaPlanStepAction(
          updatedPlanStepAction,
          plannedAction.data.plan.id
        );
      });
  }

  onDatePickerClosed(event: any) {
    const start = this.cwStartDate as DateTime;

    const weekYear = start.weekYear;
    const weekNumber = start.weekNumber;

    if (this.weekYear !== weekYear) {
      this.weekYear = weekYear;
    }

    if (this.weekNumber !== weekNumber) {
      this.weekNumber = weekNumber;
    }
  }

  today() {
    this.#setToday();
  }

  plusOneWeek() {
    const currentWeek = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    });
    const nextWeek = currentWeek.plus({ weeks: 1 });

    this.weekNumber = nextWeek.weekNumber;
    this.weekYear = nextWeek.weekYear;
    this.cwStartDate = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    })
      .startOf('week')
      .toJSDate();
    this.cwEndDate = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    })
      .endOf('week')
      .toJSDate();
  }

  minusOneWeek() {
    const currentWeek = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    });
    const nextWeek = currentWeek.plus({ weeks: -1 });

    this.weekNumber = nextWeek.weekNumber;
    this.weekYear = nextWeek.weekYear;
    this.cwStartDate = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    })
      .startOf('week')
      .toJSDate();
    this.cwEndDate = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    })
      .endOf('week')
      .toJSDate();
  }

  dayOfMonth(weekday: 1 | 2 | 3 | 4 | 5 | 6 | 7): number {
    const currentWeek = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    });

    const currentDay = currentWeek.plus({ days: weekday - 1 });
    return currentDay.day;
  }

  isToday(weekday: 1 | 2 | 3 | 4 | 5 | 6 | 7): boolean {
    const currentWeek = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    });

    const currentDay = currentWeek.plus({ days: weekday - 1 });

    if (
      currentDay.startOf('day').toJSDate().getTime() ===
      DateTime.now().startOf('day').toJSDate().getTime()
    ) {
      return true;
    }
    return false;
  }

  clearSubscriptionsEvents() {
    this.subscriptionEvents.length = 0;
    for (const plannedActions of this.plannedActions.values()) {
      plannedActions.forEach((x) => (x.subscriptionEvents.length = 0));
    }
  }

  #setToday() {
    const now = DateTime.now();
    this.weekYear = now.weekYear;
    this.weekNumber = now.weekNumber;
    this.cwStartDate = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    })
      .startOf('week')
      .toJSDate();
    this.cwEndDate = DateTime.fromObject({
      weekYear: this.weekYear,
      weekNumber: this.weekNumber,
    })
      .endOf('week')
      .toJSDate();
  }

  async #inputPlansHaveChanged() {
    for (const plan of this.#plans.sortBy((x) => x.name)) {
      if (this.#loadedPlanIds.has(plan.id)) {
        continue; // Data has already been loaded before.
      }

      try {
        this.planActivity.set(plan.id, true);

        const loadedPlan = await this.#loadPlan(plan.id);

        this.#updatePlannedActions(loadedPlan);
        this.#loadedPlanIds.add(plan.id);

        const tenantActions = await this.#loadTenantActions(plan.id);

        this.#addPlannedActionBookings(tenantActions);

        this.#updateIsPlannedActionConsideredRed();
      } catch (error) {
      } finally {
        this.planActivity.set(plan.id, false);
      }
    }
  }

  async #loadPlan(id: string): Promise<PlanOutput> {
    const variables: PlanQueryArgs = {
      id,
    };

    const result = await firstValueFrom(
      this._apollo.query<PlanQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_PLAN}
          ${FULL_FRAGMENT_PLAN_STEP}
          ${FULL_FRAGMENT_PLAN_STEP_ACTION}
          ${FULL_FRAGMENT_PLAN_STEP_ASSET}
          ${FULL_FRAGMENT_ASSET_MISSING}
          query Plan($id: String!) {
            plan(id: $id) {
              ...FullFragmentPlan
              planSteps {
                ...FullFragmentPlanStep
                planStepActions {
                  ...FullFragmentPlanStepAction
                }
                planStepAssets {
                  ...FullFragmentPlanStepAsset
                  assetMissing {
                    ...FullFragmentAssetMissing
                  }
                }
              }
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    return result.data.plan;
  }

  async #loadTenantActions(planId: string, planStepId?: string): Promise<TenantActionOutput[]> {
    const variables: TenantActionsQueryArgs = {
      planId,
    };

    if (planStepId) {
      variables.planStepId = planStepId;
    }

    this.planActivity.set(planId, true);
    const result = await firstValueFrom(
      this._apollo.query<TenantActionsQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_TENANT_ACTION}
          query TenantActions($planId: String!, $planStepId: String) {
            tenantActions(planId: $planId, planStepId: $planStepId) {
              ...FullFragmentTenantAction
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    return result.data.tenantActions;
  }

  #updatePlannedActions(plan: PlanOutput) {
    for (const planStep of plan.planSteps ?? []) {
      for (const planStepAction of planStep.planStepActions ?? []) {
        const dateTime = DateTime.fromISO(planStepAction.date);
        const idString = `${plan.id}_${dateTime.weekYear}_${dateTime.weekNumber}_${dateTime.weekday}`;
        this.planStepActionIdMatch.set(planStepAction.id, idString);

        const plannedActions = this.plannedActions.get(idString);
        if (typeof plannedActions === 'undefined') {
          this.plannedActions.set(idString, [
            new PlannedAction({
              plan,
              planStep,
              planStepAction,
              planStepAssets: (planStep.planStepAssets ?? []).sortBy((x) => x.tenantAssetId),
            }),
          ]);
        } else {
          // Could either be a new or an existing planned action for that day.
          const plannedAction = plannedActions.find(
            (x) => x.data.planStepAction.id === planStepAction.id
          );
          const newData: PlannedActionData = {
            plan,
            planStep,
            planStepAction,
            planStepAssets: (planStep.planStepAssets ?? []).sortBy((x) => x.tenantAssetId),
          };
          if (plannedAction) {
            plannedAction.resetData(newData);
          } else {
            plannedActions.push(new PlannedAction(newData));
          }
        }
      }
    }
  }

  async #updatePlannedActionsViaPlanStepAction(
    upsertedPlanStepAction: PlanStepActionOutput,
    planId: string
  ) {
    try {
      this.planActivity.set(planId, true);
      const dateTime = DateTime.fromISO(upsertedPlanStepAction.date);
      const upsertedLookupId = `${planId}_${dateTime.weekYear}_${dateTime.weekNumber}_${dateTime.weekday}`;

      let plannedActions = this.plannedActions.get(upsertedLookupId);
      if (typeof plannedActions === 'undefined') {
        // There is no action on that day. Create an empty record.
        plannedActions = [];
        this.plannedActions.set(upsertedLookupId, plannedActions);
      }
      let plannedAction = plannedActions.find(
        (x) => x.data.planStepAction.id === upsertedPlanStepAction.id
      );

      if (plannedAction) {
        // There already way an entry for that day. Just exchange the action.
        plannedAction.data.planStepAction = upsertedPlanStepAction;
        plannedAction.recalcGracePeriodDate();
      } else {
        // We have to create a completely new entry for that day.
        // Load all relevant additional data.

        const variables: PlanStepActionQueryArgs = {
          id: upsertedPlanStepAction.id,
        };

        const result = await firstValueFrom(
          this._apollo.query<PlanStepActionQueryRoot>({
            query: gql`
              ${FULL_FRAGMENT_PLAN_STEP_ACTION}
              ${FULL_FRAGMENT_PLAN_STEP}
              ${FULL_FRAGMENT_PLAN}
              ${FULL_FRAGMENT_PLAN_STEP_ASSET}
              ${FULL_FRAGMENT_ASSET_MISSING}
              query PlanStepAction($id: String!) {
                planStepAction(id: $id) {
                  ...FullFragmentPlanStepAction
                  planStep {
                    ...FullFragmentPlanStep
                    plan {
                      ...FullFragmentPlan
                    }
                    planStepAssets {
                      ...FullFragmentPlanStepAsset
                      assetMissing {
                        ...FullFragmentAssetMissing
                      }
                    }
                  }
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );

        if (
          !result.data.planStepAction ||
          !result.data.planStepAction.planStep ||
          !result.data.planStepAction.planStep.plan ||
          !result.data.planStepAction.planStep.planStepAssets
        ) {
          throw new Error('Invalid server response when querying data.');
        }

        plannedAction = new PlannedAction({
          plan: result.data.planStepAction?.planStep?.plan,
          planStep: result.data.planStepAction?.planStep,
          planStepAction: result.data.planStepAction,
          planStepAssets: result.data.planStepAction.planStep.planStepAssets.sortBy(
            (x) => x.tenantAssetId
          ),
        });

        plannedActions.push(plannedAction);
        this.plannedActions.set(
          upsertedLookupId,
          plannedActions.sortBy((x) => x.data.planStepAction.date)
        );

        const variablesBookings: TenantActionsQueryArgs = {
          planId,
          planStepId: upsertedPlanStepAction.planStepId,
        };

        const resultBookings = await firstValueFrom(
          this._apollo.query<TenantActionsQueryRoot>({
            query: gql`
              ${FULL_FRAGMENT_TENANT_ACTION}
              query TenantActions($planId: String!, $planStepId: String) {
                tenantActions(planId: $planId, planStepId: $planStepId) {
                  ...FullFragmentTenantAction
                }
              }
            `,
            variables: variablesBookings,
            fetchPolicy: 'network-only',
          })
        );

        plannedAction.bookings = resultBookings.data.tenantActions.filter(
          (x) => x.planStepActionId === upsertedPlanStepAction.id
        );
      }

      this.planStepActionIdMatch.set(upsertedPlanStepAction.id, upsertedLookupId);
      this.#updateIsPlannedActionConsideredRed([plannedAction]);

      // Go through ALL other days and check if we found an outdated entry.
      // If so, delete the record.
      for (const keyValue of this.plannedActions) {
        if (keyValue[0] === upsertedLookupId) {
          continue;
        }

        const index = keyValue[1].findIndex(
          (x) => x.data.planStepAction.id === upsertedPlanStepAction.id
        );
        if (index === -1) {
          continue;
        }

        // We have found an old entry. Delete it.
        keyValue[1].splice(index, 1);
        break;
      }
    } catch (error) {
      this._toastService.error(new CatchError(error).message, 'ERROR');
    } finally {
      this.planActivity.set(planId, false);
    }
  }

  #updatePlannedActionsBooking(
    booking: TenantActionOutput,
    action: 'created' | 'deleted'
  ): PlannedAction | undefined {
    const lookupId = this.planStepActionIdMatch.get(booking.planStepActionId ?? 'na');
    if (!lookupId) {
      // No planned action available. Do nothing.
      return;
    }

    const plannedAction = this.plannedActions
      .get(lookupId)
      ?.find((x) => x.data.planStepAction.id === booking.planStepActionId);

    if (!plannedAction) {
      // Should not happen.
      return;
    }

    if (action === 'created') {
      plannedAction.bookings = [...plannedAction.bookings, booking].sortBy((x) => x.timestamp);
    } else {
      const index = plannedAction.bookings.findIndex((x) => x.id === booking.id);
      if (index !== -1) {
        plannedAction.bookings.splice(index, 1);
      }
    }
    return plannedAction;
  }

  #addPlannedActionBookings(tenantActions: TenantActionOutput[]) {
    for (const tenantAction of tenantActions) {
      const idString = this.planStepActionIdMatch.get(tenantAction.planStepActionId ?? 'na');
      if (!idString) {
        continue; // This bookings does not have a "planned mirror".
      }

      const plannedActions = this.plannedActions.get(idString);
      if (typeof plannedActions === 'undefined') {
        continue; // This bookings does not have a "planned mirror".
      }

      const plannedAction = plannedActions.find(
        (x) => x.data.planStepAction.id === tenantAction.planStepActionId
      );
      if (!plannedAction) {
        continue; // This bookings does not have a "planned mirror".
      }

      if (plannedAction.bookings.find((x) => x.id === tenantAction.id)) {
        continue; // This booking already exists.
      }

      plannedAction.bookings.push(tenantAction);
    }
  }

  #exchangePlannedActionBookings(
    lookupId: string,
    planStepActionId: string,
    tenantActions: TenantActionOutput[]
  ) {
    const plannedAction = this.plannedActions
      .get(lookupId)
      ?.find((x) => x.data.planStepAction.id === planStepActionId);

    if (!plannedAction) {
      console.log('no planned action found');
      return; // Nothing found to update.
    }

    const plannedActionBookings = tenantActions.filter(
      (x) => x.planStepActionId === planStepActionId
    );

    plannedAction.bookings = plannedActionBookings;
  }

  /**
   * Update isPlannedActionConsideredRed either for all plannedActions
   * or just the provided ones.
   */
  #updateIsPlannedActionConsideredRed(relevantPlannedActions?: PlannedAction[]) {
    const plannedActions: PlannedAction[] = [];

    if (typeof relevantPlannedActions !== 'undefined') {
      plannedActions.push(...relevantPlannedActions);
    } else {
      for (const dayPlannedActions of this.plannedActions.values()) {
        plannedActions.push(...dayPlannedActions);
      }
    }

    const nowDate = new Date();

    for (const plannedAction of plannedActions) {
      // Green Condition 1:
      // The planned action is "in the future".
      if (new Date(plannedAction.data.planStepAction.date).getTime() > nowDate.getTime()) {
        this.isPlannedActionConsideredRed.set(plannedAction.data.planStepAction.id, false);
        continue;
      }

      // Green Condition 2:
      // All assets are already booked.
      if (plannedAction.bookings.length === plannedAction.data.planStepAssets.length) {
        this.isPlannedActionConsideredRed.set(plannedAction.data.planStepAction.id, false);
        continue;
      }

      // Green Condition 3:
      // We are currently within the grace period and no asset
      // is currently missing (e.g. because the asset manager has
      // manually set one asset to missing before the grace period ends).
      const gracePeriodEnd = DateTime.fromISO(plannedAction.data.planStepAction.date).plus({
        hours: plannedAction.data.planStepAction.missingGracePeriod,
      });
      if (
        nowDate.getTime() < gracePeriodEnd.toJSDate().getTime() &&
        !plannedAction.hasMissingAssets
      ) {
        this.isPlannedActionConsideredRed.set(plannedAction.data.planStepAction.id, false);
        continue;
      }

      // All "green" conditions were NOT met. Set to "red".
      this.isPlannedActionConsideredRed.set(plannedAction.data.planStepAction.id, true);
    }
  }

  #startBookingSubscription() {
    this.#bookingSubscription?.unsubscribe();

    const variables: BookingPlannedSubscriptionArgs = {
      planIds: this.plans.map((x) => x.id),
    };

    this.#bookingSubscription = this._apollo
      .subscribe<BookingPlannedSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_TENANT_ACTION}
          subscription BookingPlannedSubscription($planIds: [String!]!) {
            bookingPlannedSubscription(planIds: $planIds) {
              data {
                action
                bookingType
                bookingUserOid
                bookingData {
                  ...FullFragmentTenantAction
                }
              }
            }
          }
        `,
        variables,
      })
      .subscribe((result) => {
        const bookingData = result.data?.bookingPlannedSubscription;

        if (!bookingData?.data) {
          return;
        }

        for (const data of bookingData.data) {
          const plannedAction = this.#updatePlannedActionsBooking(data.bookingData, data.action);

          if (!plannedAction) {
            continue;
          }

          const type =
            data.action === 'created'
              ? SubscriptionEventType.PlannedActionBookingAdded
              : SubscriptionEventType.PlannedActionBookingRemoved;

          const eventData: PlannedActionBookingData = {
            bookingUserOid: data.bookingUserOid,
            tenantAssetId: data.bookingData.assetId,
            planStepAction: plannedAction.data.planStepAction,
          };

          const newSubscriptionEvent = new SubscriptionEvent(type, eventData);
          this.subscriptionEvents.unshift(newSubscriptionEvent);
          plannedAction.subscriptionEvents.unshift(newSubscriptionEvent);
        }
      });
  }

  #startAssetMissingSubscription() {
    this.#assetMissingSubscription?.unsubscribe();

    this.#assetMissingSubscription = this._apollo
      .subscribe<AssetMissingSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_ASSET_MISSING_SUB}
          subscription AssetMissingSubscription {
            assetMissingSubscription {
              ...FullFragmentAssetMissingSub
            }
          }
        `,
      })
      .subscribe({
        next: async (result) => {
          // We might receive a missing notification (set or reset)
          // WITH or WITHOUT information about a planStepAction.
          // WITH: The notification was triggered automatically due to a grace period trigger.
          // WITHOUT: An asset manager has manually changed the missing state of an asset.
          if (
            !result.data ||
            result.data.assetMissingSubscription.data == null ||
            !Array.isArray(result.data.assetMissingSubscription.data)
          ) {
            return;
          }

          console.log(result.data.assetMissingSubscription);

          const notifications = result.data.assetMissingSubscription.data;
          const newSubscriptionEvents: SubscriptionEvent<AssetMissingSubNotificationData>[] = [];
          for (const notification of notifications) {
            const type =
              notification.action === 'set'
                ? SubscriptionEventType.AssetMissingSet
                : SubscriptionEventType.AssetMissingReset;
            newSubscriptionEvents.push(
              new SubscriptionEvent<AssetMissingSubNotificationData>(type, notification)
            );
          }
          // Add to "global" subscription event log.
          this.subscriptionEvents.unshift(...newSubscriptionEvents);

          const assetIds = notifications.map((x) => x.assetId);

          for (const plannedActions of this.plannedActions.values()) {
            for (const plannedAction of plannedActions) {
              if (
                plannedAction.data.planStepAssets
                  .map((x) => x.tenantAssetId)
                  .some((x) => assetIds.includes(x))
              ) {
                this.planActivity.set(plannedAction.data.plan.id, true);
                await plannedAction.refetchPlanStepAssets(this._apollo);
                this.planActivity.set(plannedAction.data.plan.id, false);
                const relevantSubscriptionEvents = newSubscriptionEvents.filter((x) =>
                  plannedAction.data.planStepAssets
                    .map((y) => y.tenantAssetId)
                    .includes(x.data.assetId)
                );

                plannedAction.subscriptionEvents.unshift(...relevantSubscriptionEvents);

                // Make sure that the green/red state is re-evaluated.
                this.#updateIsPlannedActionConsideredRed([plannedAction]);
              }
            }
          }
        },
      });
  }
}
