<lib-card>
  <lib-card-title><b>Tenant Information</b></lib-card-title>
  <lib-card-content>
    <div class="flex-column gap-normal">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input
          #tenantNameInput
          matInput
          [autocomplete]="uuid"
          [ngModel]="selectionService.selectedTenant?.name"
          (ngModelChange)="onTenantNameChange($event, tenantNameInput)"
          (keyup.esc)="
            tenantNameInput.value =
              selectionService.selectedTenant?.name ?? 'na'
          "
        />
      </mat-form-field>

      <lib-user-select
        appearance="fill"
        label="Owner"
        [readonly]="true"
        [hideSearchIcon]="true"
        [oId]="tenantOwnerUserRole?.userOid"
        hint="Please contact support if you want to change the tenant owner."
      ></lib-user-select>
    </div>
  </lib-card-content>
</lib-card>

<lib-card class="mt-normal">
  <lib-card-title><b>Tenant Admins</b> </lib-card-title>
  <lib-card-actions>
    <div class="flex-row align-items-center form-field-density-1 gap-large-x2">
      <div class="flex-row">
        <lib-user-select
          label="Add Tenant Admin"
          appearance="fill"
          [(oId)]="selectedAdminOId"
        >
        </lib-user-select>

        <div class="ml-tiny">
          <button
            mat-flat-button
            color="primary"
            style="height: 100% !important"
            [disabled]="!selectedAdminOId || isAlreadyAdmin(selectedAdminOId)"
            (click)="onClickAddAdmin()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </lib-card-actions>

  <lib-card-content>
    <div class="flex-column">
      <mat-list #adminsElement>
        <mat-list-item *ngFor="let x of tenantAdminUserRoles">
          <div
            class="flex-row align-items-center justify-content-space-between"
          >
            <lib-user #adminUser [oId]="x.userOid"></lib-user>

            <button
              mat-icon-button
              color="warn"
              (click)="onClickDeleteAdmin(adminUser.oUser, x)"
              [disabled]="x.userOid === selectionService.myUser?.oid"
              class="mt-tiny mb-tiny delete-admin-button"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </lib-card-content>
</lib-card>

<lib-card class="mt-normal">
  <lib-card-title><b>Tenant Configuration</b></lib-card-title>
  <lib-card-content>
    <lib-spinner
      *ngIf="loadingTenantConfig; else tenantConfigLoaded"
    ></lib-spinner>

    <ng-template #tenantConfigLoaded>
      <div class="flex-column form-field-density-1">
        <mat-form-field subscriptSizing="dynamic" class="align-self-start">
          <mat-label>Planned Action Grace Period [h]</mat-label>
          <input
            matInput
            #gracePeriodInput
            type="number"
            min="0"
            [autocomplete]="uuid2"
            required
            [ngModel]="tenantConfig?.missingGracePeriod"
            (ngModelChange)="onMissingGracePeriodChanged($event, gracePeriodInput)"
          />
        </mat-form-field>
      </div>
    </ng-template>
  </lib-card-content>
</lib-card>
