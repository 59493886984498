import {
  AssetDefectsQueryArgs,
  AssetDefectsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { AssetsTableComponent } from './assets-table.component';
import { firstValueFrom } from 'rxjs';
import { gql } from 'apollo-angular';
import { environment } from 'projects/desktop/src/environments/environment';
import {
  LocalEventData_AssetDefect,
  LocalEventData_AssetMissing,
  LocalEventData_BookingPlanned,
  LocalEventData_BookingRealtime,
  LocalEventData_Inventory,
  LocalEventData_InventoryAssetsCreated,
  LocalEventData_InventoryAssetsDeleted,
  LocalEventData_Plan,
  LocalEventData_PlanAssetDeleted,
  LocalEventData_PlanAssetsAdded,
  LocalEventType,
} from 'projects/desktop/src/app/services/local-event.service';
import {
  InventoryQueryArgs,
  InventoryQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/inventory';
import {
  RemoteEventData_AssetDefect,
  RemoteEventData_AssetMissing,
  RemoteEventData_BookingPlanned,
  RemoteEventData_BookingRealtime,
  RemoteEventData_Inventory,
  RemoteEventData_InventoryAssetsBooked,
  RemoteEventData_InventoryAssetsCreated,
  RemoteEventData_InventoryAssetsDeleted,
  RemoteEventData_Plan,
  RemoteEventData_PlanAssetsAdded,
  RemoteEventData_PlanAssetsDeleted,
  RemoteEventType,
} from 'projects/desktop/src/app/services/remote-event.service';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { PlansQueryArgs, PlansQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';

export const onOpenedContextMenuAssetDefects = async function (
  this: AssetsTableComponent,
  assetId: string
) {
  try {
    const variables: AssetDefectsQueryArgs = {
      assetId,
    };

    const result = await firstValueFrom(
      this.apollo.query<AssetDefectsQueryRoot>({
        query: gql`
          query AssetDefects($assetId: String!) {
            assetDefects(assetId: $assetId) {
              id
            }
          }
        `,
        variables,
        fetchPolicy: 'no-cache',
      })
    );

    const assetHasDefectRecords = result.data.assetDefects.length > 0;
    this.assetHasDefectRecords.set(assetId, assetHasDefectRecords);
  } catch (error) {
    console.log('Could not fetch asset defect information for asset: ' + assetId);
  }
};

export const createSnapshotDistinctIds = async function (this: AssetsTableComponent) {
  // Get the filter model and check if there are actually any filters set.
  const filterModel = this.gridApi.getFilterModel();
  if (Object.keys(filterModel).length === 0) {
    this.snapshotDistinctColumnValues.set('id', []);
    return;
  }

  const body = {
    pageModel: { startRow: 0, endRow: null },
    filterModel,
  };

  const url = `${environment.apiBaseUrl}/api/assets/distinctValues/${this.selectionService.selectedTenant?.id}/id`;
  const data = await firstValueFrom(this.http.post<(string | null)[]>(url, body));
  const realData = data.filter((x) => x != null && x !== '') as string[];
  this.snapshotDistinctColumnValues.set('id', realData);
};

export const localEventHandling = async function (
  this: AssetsTableComponent,
  value: [LocalEventType, any]
) {
  if (value[0] === LocalEventType.Plan) {
    // The global subscription already has made sure that
    // query "Plans" has been executed and the cached results are accurate.

    const eventData = value[1] as LocalEventData_Plan;

    const relevantAssetIds = eventData.data?.map((x) => x.relevantAssetIds).flat() ?? [];

    if (relevantAssetIds.empty()) {
      return;
    }

    await this.refetchAssetDataAndRefreshTable(Array.from(new Set<string>(relevantAssetIds)));
  } else if (value[0] === LocalEventType.PlanAssetDeleted) {
    const data: LocalEventData_PlanAssetDeleted = value[1];
    const assetData = await this.restService.fetchAsset(
      this.selectionService.selectedTenant?.id,
      data.tenantAssetId
    );

    if (assetData) {
      const rowNode = this.gridApi.getRowNode(data.tenantAssetId);
      // Reset the row data with the same data just to trigger re-rendering.
      rowNode?.setData(assetData);
    }
  } else if (value[0] === LocalEventType.PlanAssetsAdded) {
    const data: LocalEventData_PlanAssetsAdded = value[1];
    const assetsData = await this.restService.fetchAssets(
      this.selectionService.selectedTenant?.id,
      data.planAssets.map((x) => x.tenantAssetId)
    );
    if (typeof assetsData !== 'undefined') {
      for (const assetData of assetsData) {
        const rowNode = this.gridApi.getRowNode(assetData.id);
        // Reset the row data with the same data just to trigger re-rendering.
        rowNode?.setData(assetData);
      }
    }
  } else if (value[0] === LocalEventType.AssetDefect) {
    const eventData: LocalEventData_AssetDefect = value[1];
    const assetIds = eventData.data?.map((x) => x.assetDefect.assetId) ?? [];
    await this.refetchAssetDataAndRefreshTable(assetIds);
  } else if (value[0] === LocalEventType.AssetMissing) {
    const eventData: LocalEventData_AssetMissing = value[1];

    for (const info of eventData.data ?? []) {
      const rowNode = this.gridApi.getRowNode(info.assetId);
      if (!rowNode) {
        continue;
      }

      const isMissing = info.action === 'set';

      rowNode.setDataValue(this.specialTableColumn_Missing?.field ?? 'na', isMissing);
      rowNode.setDataValue(
        this.specialTableColumn_MissingComment?.field ?? 'na',
        info.missingComment
      );
    }
  } else if (value[0] === LocalEventType.BookingRealtime) {
    // The notificationService already has made sure that the corresponding assets data
    // were downloaded AND CACHED (in the AssetService).
    // We can work with these cached versions here.

    const eventData: LocalEventData_BookingRealtime = value[1];
    const assetIds = eventData.data?.map((x) => x.bookingData.assetId) ?? [];
    await this.refetchAssetDataAndRefreshTable(assetIds, true);
  } else if (value[0] === LocalEventType.BookingPlanned) {
    // The notificationService already has made sure that the corresponding assets data
    // were downloaded AND CACHED (in the AssetService).
    // We can work with these cached versions here.

    const eventData: LocalEventData_BookingPlanned = value[1];
    const assetIds = eventData.data?.map((x) => x.bookingData.assetId) ?? [];
    await this.refetchAssetDataAndRefreshTable(assetIds, true);
  } else if (
    [LocalEventType.InventoryAssetsCreated, LocalEventType.InventoryAssetsDeleted].includes(
      value[0]
    )
  ) {
    // The notificationService already has made sure that the corresponding assets data
    // were downloaded AND CACHED (in the AssetService).
    // We can work with these cached versions here.

    const eventData: LocalEventData_InventoryAssetsCreated | LocalEventData_InventoryAssetsDeleted =
      value[1];
    const assetIds = eventData.data.map((x) => x.assetIds).flat();
    await this.refetchAssetDataAndRefreshTable(assetIds, true);
  } else if (value[0] === LocalEventType.Inventory) {
    const eventData: LocalEventData_Inventory = value[1];

    // Handle these 2 situations differently:
    // SITUATION 1: An inventory was "created"
    // SITUATION 2: An inventory was "deleted" or "updated" (name or description)

    let relevantAssetIds = new Set<string>();

    const createdDatas = eventData.data.filter((x) => x.action === 'created');
    if (createdDatas.length > 0) {
      // SITUATION 1
      // We cannot determine which assets are affected. We first need to load
      // the relevant assetIds from the backend.

      const inventoryIds = Array.from(new Set<string>(createdDatas.map((x) => x.inventory.id)));
      for (const inventoryId of inventoryIds) {
        const variables: InventoryQueryArgs = {
          inventoryId,
        };

        try {
          const result = await firstValueFrom(
            this.apollo.query<InventoryQueryRoot>({
              query: gql`
                query Inventory($inventoryId: String!) {
                  inventory(inventoryId: $inventoryId) {
                    id
                    inventoryAssets {
                      id
                      tenantAssetId
                    }
                  }
                }
              `,
              variables,
              fetchPolicy: 'network-only',
            })
          );
          const assetIds = result.data.inventory.inventoryAssets?.map((x) => x.tenantAssetId) ?? [];
          assetIds.forEach((x) => relevantAssetIds.add(x));
        } catch (error) {
          // Do nothing and continue.
        }
      }
    }

    const remainingDatas = eventData.data.filter((x) => x.action !== 'created');
    if (remainingDatas.length > 0) {
      // SITUATION 2
      // We can determine which assets are affected by analyzing the column "inventoryBooked".
      // This column holds all inventoryIds relevant for that asset.

      const inventoryIds = eventData.data.map((x) => x.inventory.id);

      // Iterate through all cached nodes.
      this.gridApi.forEachNode((rowNode, index) => {
        const inventoryBooked = rowNode.data.inventoryBooked as string | undefined;
        if (!inventoryBooked) {
          return;
        }

        for (const inventoryId of inventoryIds) {
          if (inventoryBooked.includes(inventoryId)) {
            relevantAssetIds.add(rowNode.data.id);
            break;
          }
        }
      });
    }

    await this.refetchAssetDataAndRefreshTable(Array.from(relevantAssetIds), false);
  }
};

export const remoteEventHandling = async function (
  this: AssetsTableComponent,
  value: [RemoteEventType, any]
) {
  if (value[0] === RemoteEventType.Plan) {
    // The global subscription already has made sure that
    // query "Plans" has been executed and the cached results are accurate.

    const eventData = value[1] as RemoteEventData_Plan;
    // Only relevant events here: CREATE and DELETE
    const relevantAssetIds = eventData.data?.map((x) => x.relevantAssetIds).flat() ?? [];

    if (relevantAssetIds.empty()) {
      return;
    }

    await this.refetchAssetDataAndRefreshTable(Array.from(new Set<string>(relevantAssetIds)));
  } else if (
    value[0] === RemoteEventType.PlanAssetsDeleted ||
    value[0] === RemoteEventType.PlanAssetsAdded
  ) {
    // This only fires if the remote event comes NOT from this session.
    const eventData: RemoteEventData_PlanAssetsAdded | RemoteEventData_PlanAssetsDeleted = value[1];
    if (eventData.data == null) {
      return;
    }
    const relevantAssetIds: string[] = [];
    for (const info of eventData.data) {
      relevantAssetIds.push(...info.planAssets.map((x) => x.tenantAssetId));
    }

    const assetsData = await this.restService.fetchAssets(
      this.selectionService.selectedTenant?.id,
      Array.from(new Set<string>(relevantAssetIds))
    );

    if (typeof assetsData !== 'undefined') {
      for (const assetData of assetsData) {
        const rowNode = this.gridApi.getRowNode(assetData.id);
        // Reset the row data with the same data just to trigger re-rendering.
        rowNode?.setData(assetData);
      }
    }
  } else if (value[0] === RemoteEventType.AssetMissing) {
    const eventData: RemoteEventData_AssetMissing = value[1];

    for (const info of eventData.data ?? []) {
      const rowNode = this.gridApi.getRowNode(info.assetId);
      if (!rowNode) {
        continue;
      }

      const isMissing = info.action === 'set';

      rowNode.setDataValue(this.specialTableColumn_Missing?.field ?? 'na', isMissing);
      rowNode.setDataValue(
        this.specialTableColumn_MissingComment?.field ?? 'na',
        info.missingComment
      );
    }
  } else if (value[0] === RemoteEventType.AssetDefect) {
    const eventData: RemoteEventData_AssetDefect = value[1];
    const assetIds = eventData.data?.map((x) => x.assetDefect.assetId) ?? [];
    await this.refetchAssetDataAndRefreshTable(assetIds);
  } else if (value[0] === RemoteEventType.BookingRealtime) {
    // The notificationService already has made sure that the corresponding assets data
    // were downloaded AND CACHED (in the AssetService).
    // We can work with these cached versions here.

    const eventData: RemoteEventData_BookingRealtime = value[1];
    const assetIds = eventData.data?.map((x) => x.bookingData.assetId) ?? [];
    await this.refetchAssetDataAndRefreshTable(assetIds, true);
  } else if (value[0] === RemoteEventType.BookingPlanned) {
    // The notificationService already has made sure that the corresponding assets data
    // were downloaded AND CACHED (in the AssetService).
    // We can work with these cached versions here.

    const eventData: RemoteEventData_BookingPlanned = value[1];
    const assetIds = eventData.data?.map((x) => x.bookingData.assetId) ?? [];
    await this.refetchAssetDataAndRefreshTable(assetIds, true);
  } else if (
    [
      RemoteEventType.InventoryAssetsBooked,
      RemoteEventType.InventoryAssetsCreated,
      RemoteEventType.InventoryAssetsDeleted,
    ].includes(value[0])
  ) {
    // The notificationService already has made sure that the corresponding assets data
    // were downloaded AND CACHED (in the AssetService).
    // We can work with these cached versions here.

    const eventData:
      | RemoteEventData_InventoryAssetsBooked
      | RemoteEventData_InventoryAssetsCreated
      | RemoteEventData_InventoryAssetsDeleted = value[1];
    const assetIds = eventData.data.map((x) => x.assetIds).flat();
    await this.refetchAssetDataAndRefreshTable(assetIds, true);
  } else if (value[0] === RemoteEventType.Inventory) {
    const eventData: RemoteEventData_Inventory = value[1];

    // Handle these 2 situations differently:
    // SITUATION 1: An inventory was "created"
    // SITUATION 2: An inventory was "deleted" or "updated" (name or description)

    let relevantAssetIds = new Set<string>();

    const createdDatas = eventData.data.filter((x) => x.action === 'created');
    if (createdDatas.length > 0) {
      // SITUATION 1
      // We cannot determine which assets are affected. We first need to load
      // the relevant assetIds from the backend.

      const inventoryIds = Array.from(new Set<string>(createdDatas.map((x) => x.inventory.id)));
      for (const inventoryId of inventoryIds) {
        const variables: InventoryQueryArgs = {
          inventoryId,
        };

        try {
          const result = await firstValueFrom(
            this.apollo.query<InventoryQueryRoot>({
              query: gql`
                query Inventory($inventoryId: String!) {
                  inventory(inventoryId: $inventoryId) {
                    id
                    inventoryAssets {
                      id
                      tenantAssetId
                    }
                  }
                }
              `,
              variables,
              fetchPolicy: 'network-only',
            })
          );
          const assetIds = result.data.inventory.inventoryAssets?.map((x) => x.tenantAssetId) ?? [];
          assetIds.forEach((x) => relevantAssetIds.add(x));
        } catch (error) {
          // Do nothing and continue.
        }
      }
    }

    const remainingDatas = eventData.data.filter((x) => x.action !== 'created');
    if (remainingDatas.length > 0) {
      // SITUATION 2
      // We can determine which assets are affected by analyzing the column "inventoryBooked".
      // This column holds all inventoryIds relevant for that asset.

      const inventoryIds = eventData.data.map((x) => x.inventory.id);

      // Iterate through all cached nodes.
      this.gridApi.forEachNode((rowNode, index) => {
        const inventoryBooked = rowNode.data.inventoryBooked as string | undefined;
        if (!inventoryBooked) {
          return;
        }

        for (const inventoryId of inventoryIds) {
          if (inventoryBooked.includes(inventoryId)) {
            relevantAssetIds.add(rowNode.data.id);
            break;
          }
        }
      });
    }

    await this.refetchAssetDataAndRefreshTable(Array.from(relevantAssetIds), false);
  }
};
