import { Injectable } from '@angular/core';
import { PropertyTypeOutput } from '../graphql/output/propertyTypeOutput';
import { ActionTypeOutput } from '../graphql/output/actionTypeOutput';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { PropertyTypesQueryRoot } from '../graphql/crud/propertyType';
import { ActionTypesQueryRoot } from '../graphql/crud/actionType';

const ASSORTMENT = {
  PROPERTY_TYPES: 'property types',
  ACTION_TYPES: 'action types',
};

@Injectable({
  providedIn: 'root',
})
export class AssortmentService {
  // #region Public Properties

  get propertyTypes(): PropertyTypeOutput[] {
    if (!this._loadedAssortments.has(ASSORTMENT.PROPERTY_TYPES)) {
      this._loadAndSetPropertyTypesAsync();
    }
    return this._propertyTypes;
  }

  get actionTypes(): ActionTypeOutput[] {
    if (!this._loadedAssortments.has(ASSORTMENT.ACTION_TYPES)) {
      this._loadAndSetActionTypesAsync();
    }
    return this._actionTypes;
  }

  // #endregion Public Properties

  // #region Private Properties

  private _propertyTypes: PropertyTypeOutput[] = [];
  private _actionTypes: ActionTypeOutput[] = [];
  private _loadedAssortments = new Set<string>();

  // #endregion Private Properties

  constructor(private _apollo: Apollo) {}

  // #region Public Methods

  async getActionTypesAsync(): Promise<ActionTypeOutput[]> {
    if (this._loadedAssortments.has(ASSORTMENT.ACTION_TYPES)) {
      return this._actionTypes;
    }

    await this._loadAndSetActionTypesAsync();
    return this._actionTypes;
  }

  // #endregion Public Methods

  private async _loadAndSetPropertyTypesAsync() {
    if (this._loadedAssortments.has(ASSORTMENT.PROPERTY_TYPES)) {
      return;
    }

    try {
      const result = await firstValueFrom(
        this._apollo.query<PropertyTypesQueryRoot>({
          query: gql`
            query PropertyTypes {
              propertyTypes {
                id
                name
              }
            }
          `,
          fetchPolicy: 'network-only',
        })
      );

      this._propertyTypes = result.data.propertyTypes;
      this._loadedAssortments.add(ASSORTMENT.PROPERTY_TYPES);
    } catch (error) {
      // do nothing
    }
  }

  private async _loadAndSetActionTypesAsync() {
    if (this._loadedAssortments.has(ASSORTMENT.ACTION_TYPES)) {
      return;
    }

    try {
      const result = await firstValueFrom(
        this._apollo.query<ActionTypesQueryRoot>({
          query: gql`
            query ActionTypes {
              actionTypes {
                id
                name
              }
            }
          `,
          fetchPolicy: 'network-only',
        })
      );

      this._actionTypes = result.data.actionTypes;
      this._loadedAssortments.add(ASSORTMENT.ACTION_TYPES);
    } catch (error) {
      // do nothing
    }
  }
}
