import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { firstValueFrom } from 'rxjs'
import { AccountInfo, } from '@azure/msal-browser'

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {

  constructor(
    private _msalService: MsalService
  ) { }

  ngOnInit(): void {
    this._getTokenAsync();

  }

  private async _getTokenAsync() {
    const account = this._msalService.instance.getAllAccounts()[0];
    if (!account) {
      return;
    }

    const result = await firstValueFrom(this._msalService.acquireTokenSilent({
      scopes: ['openid', 'profile'],
      account
    }));

    console.log(result);
  }
}
