<h2 mat-dialog-title>Adjust Plan Duration</h2>

<mat-dialog-content>
  <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

  <ng-template #notLoading>
    <span *ngIf="loadingErrorMessage; else notLoadingNoError">{{
      loadingErrorMessage
    }}</span>

    <ng-template #notLoadingNoError>
      <div class="flex-column gap-normal form-field-densitiy-1">
        <div class="flex-column">
          <span class="mat-body">
            Each asset can only be included in one plan at a time. Changing the
            duration of this plan is only possible after conflicts with other
            plans have been checked and ruled out.
          </span>
        </div>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Plan</mat-label>
          <input type="text" matInput readonly [ngModel]="data.plan.name" />
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Current Duration</mat-label>
          <input
            type="text"
            matInput
            readonly
            [value]="currentDurationString"
          />
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>New Duration</mat-label>
          <mat-date-range-input [rangePicker]="durationPicker">
            <input
              matStartDate
              placeholder="Start date"
              [(ngModel)]="startDate"
              (dateChange)="evalHasNewDuration()"
            />
            <input
              matEndDate
              placeholder="End date"
              [(ngModel)]="endDate"
              (dateChange)="evalHasNewDuration()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="durationPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker
            #durationPicker
            (closed)="evalHasNewDuration()"
          ></mat-date-range-picker>
        </mat-form-field>

        <div class="flex-column">
          <span class="title">Conflict Check</span>
          <ng-container *ngIf="!hasNewDuration; else hasNewDurationElement">
            <span class="mat-body">Nothing to do.</span>
          </ng-container>
          <ng-template #hasNewDurationElement>
            <ng-container
              *ngIf="!hasCheckedConflicts; else hasCheckedConflictsElement"
            >
              <span class="mat-body">Please perform check.</span>
            </ng-container>
            <ng-template #hasCheckedConflictsElement>
              <ng-container *ngIf="!conflicts; else conflictsElement">
                <span class="mat-body">No conflicts detected.</span>
              </ng-container>
              <ng-template #conflictsElement>
                <!-- PLAN CONFLICTS -->
                <ng-container *ngIf="conflictingPlans.size > 0">
                  <span class="mat-body mb-tiny">
                    There are asset conflicts with the following plans:
                  </span>

                  <ul class="mat-body ul-adapt">
                    <mtx-popover
                      #popover="mtxPopover"
                      [yOffset]="8"
                      [position]="['after', 'above']"
                    >
                      <ng-template mtxPopoverContent let-conflict="conflict">
                        <div class="flex-column font-size-14">
                          <span class="mb-small">
                            <b>{{ conflict.key.name }}</b> ({{
                              conflict.key.planStart
                                | date
                                  : localeService.datetimePipeString(locale)
                            }}
                            -
                            {{
                              conflict.key.planEnd
                                | date
                                  : localeService.datetimePipeString(locale)
                            }})
                          </span>
                          <ul class="ul-adapt">
                            <li *ngFor="let tenantAsetId of conflict.value">
                              {{ tenantAsetId }}
                            </li>
                          </ul>
                        </div>
                      </ng-template>
                    </mtx-popover>
                    <li *ngFor="let conflict of conflictingPlans | keyvalue">
                      <div class="flex-row align-items-center gap-normal">
                        <span>{{ conflict.key.name }}</span>
                        <span>conflicts:</span>
                        <span>{{ conflict.value.length }}</span>
                        <span
                          class="material-symbols-outlined"
                          #popoverTrigger="mtxPopoverTrigger"
                          [mtxPopoverTriggerFor]="popover"
                          [mtxPopoverTriggerData]="{ conflict: conflict }"
                          style="font-size: 16px"
                        >
                          open_in_new
                        </span>
                      </div>
                    </li>
                  </ul>
                </ng-container>

                <!-- ACTION CONFLICTS -->
                <ng-container
                  *ngIf="conflictingAlreadyPlannedActions.length > 0"
                >
                  <span class="mat-body mb-tiny">
                    There are planned actions outside the new plan duration:
                  </span>
                  <ul class="mat-body ul-adapt">
                    <li
                      *ngFor="
                        let planAction of conflictingAlreadyPlannedActions
                      "
                    >
                      <div class="flex-row align-items-center gap-small">
                        <span class="uppercase">
                          {{ planAction.actionType?.name }}
                        </span>
                        <span>
                          on
                          {{
                            planAction.date
                              | date : localeService.datetimePipeString(locale)
                          }}
                        </span>
                      </div>
                    </li>
                  </ul>
                </ng-container>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close [disabled]="activity">
    Cancel
  </button>

  <button
    (click)="checkConflicts()"
    mat-flat-button
    color="accent"
    [disabled]="!hasNewDuration || activity"
  >
    <span> Check Conflicts </span>
  </button>

  <button
    (click)="apply()"
    mat-flat-button
    color="primary"
    [disabled]="!hasCheckedConflicts || conflicts || activity"
  >
    <span> Apply </span>
  </button>
</mat-dialog-actions>
