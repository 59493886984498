import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-custom',
  templateUrl: './global-custom.component.html',
  styleUrls: ['./global-custom.component.scss']
})
export class GlobalCustomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
