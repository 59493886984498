import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_INVENTORY } from '../fragments/fullFragmentInventory';

export const FULL_FRAGMENT_INVENTORY_SUB = gql`
  ${FULL_FRAGMENT_INVENTORY}
  fragment FullFragmentInventorySub on InventorySubNotification {
    filterSessionId
    data {
      action
      inventory {
        ...FullFragmentInventory
      }
      userOid
    }
  }
`;
