import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_LOCATION = gql`
  fragment FullFragmentLocation on LocationOutput {
    id
    tenantId
    level1
    level2
    level3
    level4
    level5
    createdAt
  }
`;
