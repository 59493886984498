import { Injectable, EventEmitter } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AssetMissingSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/assetMissing';
import { InventorySubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/inventory';
import {
  InventoryAssetsBookedSubscriptionRoot,
  InventoryAssetsCreatedSubscriptionRoot,
  InventoryAssetsDeletedSubscriptionRoot,
} from 'projects/shared/src/lib/graphql/crud/inventoryAsset';
import {
  PlanAssetSubscriptionRoot,
  PlanSubscriptionRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import { ReturnToCustomerSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/returnToCustomer';
import { ReturnToCustomerAssetSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/returnToCustomerAsset';
import { ReturnToCustomerNoteSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/returnToCustomerNote';
import {
  BookingPlannedSubscriptionArgs,
  BookingPlannedSubscriptionRoot,
  BookingRealtimeSubscriptionRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { AssetDefectSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { AssetDefectSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/assetDefectSubNotification';
import { AssetMissingSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/assetMissingSubNotification';
import { BookingSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/bookingSubNotification';
import { FULL_FRAGMENT_ASSET_DEFECT_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentAssetDefectSub';
import { FULL_FRAGMENT_ASSET_MISSING_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentAssetMissingSub';
import { FULL_FRAGMENT_BOOKING_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentBookingSub';
import { FULL_FRAGMENT_INVENTORY_ASSETS_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentInventoryAssetsSub';
import { FULL_FRAGMENT_INVENTORY_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentInventorySub';
import { FULL_FRAGMENT_PLAN_ASSET_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentPlanAssetSub';
import { FULL_FRAGMENT_PLAN_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentPlanSub';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentReturnToCustomerAssetSub';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentReturnToCustomerNoteSub';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_SUB } from 'projects/shared/src/lib/graphql/subNotifications/fullFragmentReturnToCustomerSub';
import { InventoryAssetsSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/inventoryAssetsSubNotification';
import { InventorySubNotification } from 'projects/shared/src/lib/graphql/subNotifications/inventorySubNotification';
import { PlanAssetSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/planAssetSubNotification';
import { PlanSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/planSubNotification';
import { ReturnToCustomerAssetSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerAssetSubNotification';
import { ReturnToCustomerNoteSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerNoteSubNotification';
import { ReturnToCustomerSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerSubNotification';
import { Subscription } from 'rxjs';

export enum RemoteEventType {
  AssetDefect,
  AssetMissing,
  BookingPlanned,
  BookingRealtime,
  Inventory,
  InventoryAssetsBooked,
  InventoryAssetsCreated,
  InventoryAssetsDeleted,
  PlanAssetsAdded,
  PlanAssetsDeleted,
  PlanDeleted,
  ReturnToCustomer,
  ReturnToCustomerAsset,
  ReturnToCustomerNote,
  Plan,
}

export type RemoteEvent = {
  type: RemoteEventType;
  data: any;
};

export type RemoteEventData_AssetDefect = AssetDefectSubNotification;
export type RemoteEventData_BookingPlanned = BookingSubNotification;
export type RemoteEventData_BookingRealtime = BookingSubNotification;
export type RemoteEventData_PlanAssetsAdded = PlanAssetSubNotification;
export type RemoteEventData_PlanAssetsDeleted = PlanAssetSubNotification;
export type RemoteEventData_AssetMissing = AssetMissingSubNotification;
export type RemoteEventData_InventoryAssetsCreated = InventoryAssetsSubNotification;
export type RemoteEventData_InventoryAssetsDeleted = InventoryAssetsSubNotification;
export type RemoteEventData_InventoryAssetsBooked = InventoryAssetsSubNotification;
export type RemoteEventData_Inventory = InventorySubNotification;
export type RemoteEventData_ReturnToCustomerNote = ReturnToCustomerNoteSubNotification;
export type RemoteEventData_ReturnToCustomer = ReturnToCustomerSubNotification;
export type RemoteEventData_ReturnToCustomerAsset = ReturnToCustomerAssetSubNotification;
export type RemoteEventData_Plan = PlanSubNotification;

@Injectable({
  providedIn: 'root',
})
export class RemoteEventService {
  newEvent = new EventEmitter<RemoteEvent>();

  #assetDefectSubscription: Subscription | undefined;
  #assetMissingSubscription: Subscription | undefined;
  #bookingPlannedSubscription: Subscription | undefined;
  #bookingRealtimeSubscription: Subscription | undefined;
  #inventorySubscription: Subscription | undefined;
  #inventoryAssetsBookedSubscription: Subscription | undefined;
  #inventoryAssetsCreatedSubscription: Subscription | undefined;
  #inventoryAssetsDeletedSubscription: Subscription | undefined;
  #planSubscription: Subscription | undefined;
  #planAssetSubscription: Subscription | undefined;
  #returnToCustomerSubscription: Subscription | undefined;
  #returnToCustomerAssetSubscription: Subscription | undefined;
  #returnToCustomerNoteSubscription: Subscription | undefined;

  constructor(private _apollo: Apollo) {}

  initialize() {
    this.#startRemoteSubscriptions();
  }

  #emit(type: RemoteEventType, data: any) {
    this.newEvent.next({
      type,
      data,
    });
  }

  #startRemoteSubscriptions() {
    this.#startAssetDefectSubscription();
    this.#startAssetMissingSubscription();
    this.#startBookingPlannedSubscription(); // monitor all plans
    this.#startBookingRealtimeSubscription();
    this.#startInventorySubscription();
    this.#startInventoryAssetsBookedSubscription();
    this.#startInventoryAssetsCreatedSubscription();
    this.#startInventoryAssetsDeletedSubscription();
    this.#startPlanSubscription();
    this.#startPlanAssetSubscription();
    this.#startReturnToCustomerSubscription();
    this.#startReturnToCustomerAssetSubscription();
    this.#startReturnToCustomerNoteSubscription();
  }

  #startAssetDefectSubscription() {
    this.#assetDefectSubscription?.unsubscribe();
    this.#assetDefectSubscription = this._apollo
      .subscribe<AssetDefectSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_ASSET_DEFECT_SUB}
          subscription AssetDefectSubscription {
            assetDefectSubscription {
              ...FullFragmentAssetDefectSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (!result.data?.assetDefectSubscription.data) {
          return;
        }

        this.#emit(RemoteEventType.AssetDefect, result.data.assetDefectSubscription);
      });
  }
  #startAssetMissingSubscription() {
    this.#assetMissingSubscription?.unsubscribe();
    this.#assetMissingSubscription = this._apollo
      .subscribe<AssetMissingSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_ASSET_MISSING_SUB}
          subscription AssetMissingSubscription {
            assetMissingSubscription {
              ...FullFragmentAssetMissingSub
            }
          }
        `,
      })
      .subscribe({
        next: (result) => {
          if (!result.data?.assetMissingSubscription.data) {
            return;
          }

          this.#emit(RemoteEventType.AssetMissing, result.data.assetMissingSubscription);
        },
      });
  }

  #startBookingPlannedSubscription() {
    this.#bookingPlannedSubscription?.unsubscribe();

    const variables: BookingPlannedSubscriptionArgs = {
      planIds: [],
    };

    this.#bookingPlannedSubscription = this._apollo
      .subscribe<BookingPlannedSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_BOOKING_SUB}
          subscription BookingPlannedSubscription($planIds: [String!]!) {
            bookingPlannedSubscription(planIds: $planIds) {
              ...FullFragmentBookingSub
            }
          }
        `,
        variables,
      })
      .subscribe((result) => {
        if (!result.data?.bookingPlannedSubscription.data) {
          return;
        }
        this.#emit(RemoteEventType.BookingPlanned, result.data.bookingPlannedSubscription);
      });
  }

  #startBookingRealtimeSubscription() {
    this.#bookingRealtimeSubscription?.unsubscribe();
    this.#bookingRealtimeSubscription = this._apollo
      .subscribe<BookingRealtimeSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_BOOKING_SUB}
          subscription BookingRealtimeSubscription {
            bookingRealtimeSubscription {
              ...FullFragmentBookingSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (!result.data?.bookingRealtimeSubscription.data) {
          return;
        }
        this.#emit(RemoteEventType.BookingRealtime, result.data.bookingRealtimeSubscription);
      });
  }

  #startInventorySubscription() {
    this.#inventorySubscription?.unsubscribe();
    this.#inventorySubscription = this._apollo
      .subscribe<InventorySubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_INVENTORY_SUB}
          subscription InventorySubscription {
            inventorySubscription {
              ...FullFragmentInventorySub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (!result.data?.inventorySubscription.data) {
          return;
        }

        this.#emit(RemoteEventType.Inventory, result.data.inventorySubscription);
      });
  }

  #startInventoryAssetsBookedSubscription() {
    this.#inventoryAssetsBookedSubscription?.unsubscribe();
    this.#inventoryAssetsBookedSubscription = this._apollo
      .subscribe<InventoryAssetsBookedSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_INVENTORY_ASSETS_SUB}
          subscription InventoryAssetsBookedSubscription {
            inventoryAssetsBookedSubscription {
              ...FullFragmentInventoryAssetsSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (typeof result.data?.inventoryAssetsBookedSubscription.data === 'undefined') {
          return;
        }

        if (result.data.inventoryAssetsBookedSubscription.data.length > 0) {
          this.#emit(
            RemoteEventType.InventoryAssetsCreated,
            result.data.inventoryAssetsBookedSubscription
          );
        }
      });
  }

  #startInventoryAssetsCreatedSubscription() {
    this.#inventoryAssetsCreatedSubscription?.unsubscribe();
    this.#inventoryAssetsCreatedSubscription = this._apollo
      .subscribe<InventoryAssetsCreatedSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_INVENTORY_ASSETS_SUB}
          subscription InventoryAssetsCreatedSubscription {
            inventoryAssetsCreatedSubscription {
              ...FullFragmentInventoryAssetsSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (typeof result.data?.inventoryAssetsCreatedSubscription.data === 'undefined') {
          return;
        }

        if (result.data.inventoryAssetsCreatedSubscription.data.length > 0) {
          this.#emit(
            RemoteEventType.InventoryAssetsCreated,
            result.data.inventoryAssetsCreatedSubscription
          );
        }
      });
  }

  #startInventoryAssetsDeletedSubscription() {
    this.#inventoryAssetsDeletedSubscription?.unsubscribe();
    this.#inventoryAssetsDeletedSubscription = this._apollo
      .subscribe<InventoryAssetsDeletedSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_INVENTORY_ASSETS_SUB}
          subscription InventoryAssetsDeletedSubscription {
            inventoryAssetsDeletedSubscription {
              ...FullFragmentInventoryAssetsSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (typeof result.data?.inventoryAssetsDeletedSubscription.data === 'undefined') {
          return;
        }

        if (result.data.inventoryAssetsDeletedSubscription.data.length > 0) {
          this.#emit(
            RemoteEventType.InventoryAssetsDeleted,
            result.data.inventoryAssetsDeletedSubscription
          );
        }
      });
  }

  #startPlanSubscription() {
    this.#planSubscription?.unsubscribe();
    this.#planSubscription = this._apollo
      .subscribe<PlanSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_PLAN_SUB}
          subscription PlanSubscription {
            planSubscription {
              ...FullFragmentPlanSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (
          typeof result.data?.planSubscription === 'undefined' ||
          result.data.planSubscription.data == null
        ) {
          return;
        }

        this.#emit(RemoteEventType.Plan, result.data.planSubscription);
      });
  }

  #startPlanAssetSubscription() {
    this.#planAssetSubscription?.unsubscribe();
    this.#planAssetSubscription = this._apollo
      .subscribe<PlanAssetSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_PLAN_ASSET_SUB}
          subscription PlanAssetSubscription {
            planAssetSubscription {
              ...FullFragmentPlanAssetSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (
          typeof result.data?.planAssetSubscription.data === 'undefined' ||
          result.data.planAssetSubscription.data == null
        ) {
          return;
        }

        const adds = result.data.planAssetSubscription.data.filter((x) => x.action === 'created');
        const deletes = result.data.planAssetSubscription.data.filter(
          (x) => x.action === 'deleted'
        );

        if (adds.length > 0) {
          this.#emit(RemoteEventType.PlanAssetsAdded, result.data.planAssetSubscription);
        }

        if (deletes.length > 0) {
          this.#emit(RemoteEventType.PlanAssetsDeleted, result.data.planAssetSubscription);
        }
      });
  }

  #startReturnToCustomerSubscription() {
    this.#returnToCustomerSubscription?.unsubscribe();
    this.#returnToCustomerSubscription = this._apollo
      .subscribe<ReturnToCustomerSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_SUB}
          subscription ReturnToCustomerSubscription {
            returnToCustomerSubscription {
              ...FullFragmentReturnToCustomerSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (
          typeof result.data?.returnToCustomerSubscription === 'undefined' ||
          result.data.returnToCustomerSubscription.data == null
        ) {
          return;
        }

        this.#emit(RemoteEventType.ReturnToCustomer, result.data.returnToCustomerSubscription);
      });
  }

  #startReturnToCustomerAssetSubscription() {
    this.#returnToCustomerAssetSubscription?.unsubscribe();
    this.#returnToCustomerAssetSubscription = this._apollo
      .subscribe<ReturnToCustomerAssetSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET_SUB}
          subscription ReturnToCustomerAssetSubscription {
            returnToCustomerAssetSubscription {
              ...FullFragmentReturnToCustomerAssetSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (
          typeof result.data?.returnToCustomerAssetSubscription === 'undefined' ||
          result.data.returnToCustomerAssetSubscription.data == null
        ) {
          return;
        }
        this.#emit(
          RemoteEventType.ReturnToCustomerAsset,
          result.data.returnToCustomerAssetSubscription
        );
      });
  }

  #startReturnToCustomerNoteSubscription() {
    this.#returnToCustomerNoteSubscription?.unsubscribe();
    this.#returnToCustomerNoteSubscription = this._apollo
      .subscribe<ReturnToCustomerNoteSubscriptionRoot>({
        query: gql`
          ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE_SUB}
          subscription ReturnToCustomerNoteSubscription {
            returnToCustomerNoteSubscription {
              ...FullFragmentReturnToCustomerNoteSub
            }
          }
        `,
      })
      .subscribe((result) => {
        if (
          typeof result.data?.returnToCustomerNoteSubscription === 'undefined' ||
          result.data.returnToCustomerNoteSubscription.data == null
        ) {
          return;
        }

        this.#emit(
          RemoteEventType.ReturnToCustomerNote,
          result.data.returnToCustomerNoteSubscription
        );
      });
  }
}
