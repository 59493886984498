import { gql } from 'apollo-angular';
import { LocalEventData_Plan, LocalEventType } from '../local-event.service';
import { RemoteEventData_Plan, RemoteEventType } from '../remote-event.service';
import { NotificationService, EventSource } from './notification.service';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { PlansQueryArgs, PlansQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';
import { firstValueFrom } from 'rxjs';

const CURRENT_AND_FUTURE_PLANS_QUERY = gql`
  ${FULL_FRAGMENT_PLAN}
  query Plans($pastIncluded: Boolean!, $currentIncluded: Boolean!, $futureIncluded: Boolean!) {
    plans(
      pastIncluded: $pastIncluded
      currentIncluded: $currentIncluded
      futureIncluded: $futureIncluded
    ) {
      ...FullFragmentPlan
      planAssets {
        id
        tenantAssetId
      }
    }
  }
`;

export const handle_Plan = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_Plan | RemoteEventData_Plan
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // Reload some data ALWAYS as other parts will work on the cached records.
    const variables: PlansQueryArgs = {
      currentIncluded: true,
      futureIncluded: true,
      pastIncluded: false,
    };

    await firstValueFrom(
      this.apollo.query<PlansQueryRoot>({
        query: CURRENT_AND_FUTURE_PLANS_QUERY,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    // Do the next only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      this.hasSubscribers(EventSource.Local, LocalEventType.Plan)
    ) {
      // We have subscribers. Notify them.
      this.localEventHandled.next([LocalEventType.Plan, eventData]);
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.Plan)
    ) {
      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.Plan, eventData]);
      return;
    }
  }
};
