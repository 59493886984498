import { Component } from '@angular/core';

@Component({
  selector: 'lib-expander-content',
  templateUrl: './expander-content.component.html',
  styleUrls: ['./expander-content.component.scss']
})
export class ExpanderContentComponent {

}
