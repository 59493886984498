<h2 mat-dialog-title>
  Create DEFECT record for
  <span class="color-primary-400 font-weight-500">
    {{ data.assetId }}
  </span>
</h2>

<!-- ############# -->
<!-- ## CONTENT ## -->
<!-- ############# -->
<mat-dialog-content>
  <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

  <ng-template #notLoading>
    <div class="flex-column">
      <div
        class="flex-column"
        style="position: sticky; top: 0; z-index: 1; background-color: white"
      >
        <!-- Progress Bar -->
        <div style="height: 4px; margin-bottom: 8px">
          <mat-progress-bar
            *ngIf="activity"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>

        <div class="flex-row gap-normal form-field-density-1">
          <mat-form-field subscriptSizing="dynamic" style="width: 180px">
            <mat-label>As</mat-label>
            <mat-select [(ngModel)]="selectedKind" required>
              <mat-select-trigger>
                <span class="font-weight-500">{{ selectedKind?.name }}</span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let kind of kinds | keyvalue"
                [value]="kind.value"
              >
                <span class="font-weight-500">{{ kind.value.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field subscriptSizing="dynamic" style="flex-grow: 1">
            <mat-label>Asset is</mat-label>
            <mat-select [(ngModel)]="selectedDefectOption" required>
              <mat-select-trigger>
                <div class="flex-row align-items-center gap-normal">
                  <span class="font-weight-500">{{
                    selectedDefectOption?.name
                  }}</span>
                  <span class="subname">{{
                    selectedDefectOption?.subname
                  }}</span>
                </div>
              </mat-select-trigger>

              <mat-option
                *ngFor="let defectOption of defectOptions | keyvalue"
                [value]="defectOption.value"
              >
                <div class="flex-row gap-tiny align-items-center">
                  <span class="font-weight-500">{{
                    defectOption.value.name
                  }}</span>
                  <span class="flex-grow-1"></span>
                  <span class="subname">{{ defectOption.value.subname }}</span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field subscriptSizing="dynamic" class="mt-normal mb-normal">
          <mat-label>Notes</mat-label>
          <textarea
            matInput
            [(ngModel)]="comment"
            [autocomplete]="uuid"
            required
          ></textarea>
        </mat-form-field>
      </div>

      <span *ngIf="assetDefects.length === 0; else hasDefects"
        >No previous defect records available.</span
      >

      <ng-template #hasDefects>
        <table class="gat-table">
          <tr>
            <th>Type</th>
            <th>State</th>
            <th>By</th>
            <th>Notes</th>
          </tr>
          <tr *ngFor="let assetDefect of assetDefects">
            <td>
              <span [class.color-green]="assetDefect.defectTypeId === 2">
                {{ kinds.get(assetDefect.defectTypeId)?.name }}
              </span>
            </td>
            <td>
              <div
                class="flex-column"
                *ngIf="
                  defectOptions.get(assetDefect.defectStateId) as defectOption
                "
              >
                <span class="font-weight-500">{{ defectOption.name }}</span>
                <span class="subname" style="white-space: nowrap">
                  {{ defectOption.subname }}
                </span>
              </div>
            </td>
            <td>
              <div class="flex-column">
                <lib-user
                  [oId]="assetDefect.userOid"
                  style="white-space: nowrap"
                ></lib-user>
                <span>
                  {{
                    assetDefect.timestamp
                      | date : localeService.datetimePipeString(locale)
                  }}
                </span>
              </div>
            </td>
            <td>{{ assetDefect.comment }}</td>
          </tr>
        </table>
      </ng-template>
    </div>
  </ng-template>
</mat-dialog-content>

<!-- ############# -->
<!-- ## ACTIONS ## -->
<!-- ############# -->
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    [disabled]="loading || activity"
    (click)="closeDialog()"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    color="primary"
    [disabled]="loading || activity || !canApply"
    (click)="apply()"
  >
    Create
  </button>
</mat-dialog-actions>
