<lib-spinner
  class="mt-normal"
  *ngIf="loading.isJobXOngoing[1]; else notLoading"
></lib-spinner>

<ng-template #notLoading>
  <lib-card class="main-card form-field-density-1">
    <lib-card-title class="flex-row align-items-center">
      <div class="mat-h2 fix-card-title">OPTION</div>
      <lib-spinner class="ml-normal" *ngIf="activity"></lib-spinner>
    </lib-card-title>

    <lib-card-actions>
      <div class="action-icons">
        <button
          mat-flat-button
          color="warn"
          *ngIf="showDeleteOptionItemsButton"
          (click)="onClickDeleteItems()"
        >
          Delete
        </button>
        <button mat-flat-button color="primary" (click)="addOptionItems()">
          Add
        </button>
      </div>
    </lib-card-actions>

    <lib-card-content>
      <div class="flex-column card-content mat-body">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input
            #optionInput
            matInput
            [disabled]="activity"
            [ngModel]="option?.name"
            required
            (blur)="onBlurOptionName($event)"
            (keyup.escape)="optionInput.value = option?.name ?? ''"
            (keyup.enter)="onKeyupEnterOptionName($event)"
            [matAutocomplete]="auto"
            (ngModelChange)="onChangeOptionName($event)"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let optionName of filteredOptionNames">
              <span
                [innerHTML]="optionName | highlight : optionInput.value"
              ></span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div *ngIf="!option?.optionItems; else optionItemsElement">
          There are currently no option items defined.
        </div>
        <ng-template #optionItemsElement>
          <button
            mat-button
            class="align-self-end"
            (click)="onClickSelectAllOptionItems()"
          >
            toggle
          </button>

          <div class="overflow-y-auto height-100 flex-column">
            <mat-selection-list
              #optionItemsSelectionList
              (selectionChange)="onSelectionChangeOptionItemsList($event)"
            >
              <mat-list-option
                *ngFor="let optionItem of option?.optionItems"
                [value]="optionItem"
              >
                <app-text-button
                  (click)="onClickOptionItem($event, optionItem)"
                  color="primary"
                  >{{ optionItem.name }}</app-text-button
                >
              </mat-list-option>
            </mat-selection-list>
          </div>
        </ng-template>
      </div>
    </lib-card-content>
  </lib-card>
</ng-template>
