import { Component } from '@angular/core';

@Component({
  selector: 'app-gat-card',
  templateUrl: './gat-card.component.html',
  styleUrls: ['./gat-card.component.scss']
})
export class GatCardComponent {

}
