import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE } from '../fragments/fullFragmentReturnToCustomerNote';

export const FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE_SUB = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE}
  fragment FullFragmentReturnToCustomerNoteSub on ReturnToCustomerNoteSubNotification {
    filterSessionId
    data {
      action
      returnToCustomerNote {
        ...FullFragmentReturnToCustomerNote
      }
    }
  }
`;
