import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  AssetHistoryFullQueryArgs,
  AssetHistoryFullQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAuditLog';
import { FULL_FRAGMENT_TENANT_AUDIT_LOG } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAuditLog';
import { TenantAuditLogOutput } from 'projects/shared/src/lib/graphql/output/tenantAuditLogOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  ExportTenantAssetHistoryQueryArgs,
  ExportTenantAssetHistoryQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/dataExport';
import { FileService } from '../../services/file.service';
import { SelectionService } from '../../services/selection.service';
import { DesktopToastService } from '../../services/desktop-toast.service';

export type ShowAssetHistoryDialogData = {
  tenantAssetId: string;
};

@Component({
  selector: 'app-show-asset-history-dialog',
  templateUrl: './show-asset-history-dialog.component.html',
  styleUrls: ['./show-asset-history-dialog.component.scss'],
})
export class ShowAssetHistoryDialogComponent implements OnInit {
  loading = false;
  activity = false;
  errorMessage: string | undefined;
  auditLogs: TenantAuditLogOutput[] = [];

  #auditLogJsonErrors = new Set<number>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowAssetHistoryDialogData,
    private _dialogRef: MatDialogRef<ShowAssetHistoryDialogComponent>,
    private _apollo: Apollo,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private _clipboard: Clipboard,
    private apollo: Apollo,
    private fileService: FileService,
    private selectionService: SelectionService,
    private toastService: DesktopToastService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  async export() {
    this.activity = true;
    const variables: ExportTenantAssetHistoryQueryArgs = {
      tenantAssetId: this.data.tenantAssetId,
    };

    try {
      const result = await firstValueFrom(
        this.apollo.query<ExportTenantAssetHistoryQueryRoot>({
          query: gql`
            query ExportTenantAssetHistory($tenantAssetId: String!) {
              exportTenantAssetHistory(tenantAssetId: $tenantAssetId)
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      await this.fileService.download(
        this.selectionService.selectedTenant?.id,
        result.data.exportTenantAssetHistory
      );
    } catch (error) {
      const message = new CatchError(error).message;
      this.toastService.error(message, 'ERROR');
    } finally {
      this.activity = false;
    }
  }

  getJson(data: any) {
    if (typeof data === 'undefined' || data == null) {
      return {
        data: null,
      };
    }

    try {
      return JSON.parse(data);
    } catch (error) {
      return {
        error: true,
      };
    }
  }

  getJsonString(data: string | null | undefined, auditLogId: number): string {
    if (typeof data === 'undefined' || data == null) {
      return 'null';
    }

    try {
      if (this.#auditLogJsonErrors.has(auditLogId)) {
        return 'ERROR: Could not parse content!';
      }

      return JSON.stringify(JSON.parse(data), null, 4);
    } catch (error) {
      this.#auditLogJsonErrors.add(auditLogId);
      return 'ERROR: Could not parse content!';
    }
  }

  copyToClipboard(text: string | undefined | null) {
    if (!text) {
      return;
    }

    this._clipboard.copy(JSON.stringify(JSON.parse(text), null, 2));
  }

  async #loadData() {
    this.loading = true;
    this.errorMessage = undefined;

    try {
      const variables: AssetHistoryFullQueryArgs = {
        assetId: this.data.tenantAssetId,
      };

      const result = await firstValueFrom(
        this._apollo.query<AssetHistoryFullQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_AUDIT_LOG}
            query AssetHistoryFull($assetId: String!) {
              assetHistoryFull(assetId: $assetId) {
                ...FullFragmentTenantAuditLog
                auditLogEvent {
                  id
                  name
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.auditLogs = result.data.assetHistoryFull.sortBy((x) => x.timestamp, 'desc');
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.loading = false;
    }
  }
}
