<h2 matDialogTitle>
  Statistics for Inventory
  <span class="color-primary-400">{{ data.inventoryName }}</span>
</h2>

<mat-dialog-content>
  <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

  <ng-template #notLoading>
    <div class="flex-column">
      <div style="line-height: initial; height: 300px; margin-top: -24px">
        <ngx-charts-advanced-pie-chart
          [results]="pieData"
          [animations]="false"
          [percentageFormatting]="formatPercent"
          [customColors]="customColors"
        >
        </ngx-charts-advanced-pie-chart>
      </div>

      <div style="margin-top: -24px"></div>

      <div
        *ngIf="
          inventoryStatistics && inventoryStatistics.firstConfirmation != null
        "
        class="flex-row align-items-center gap-small"
      >
        <span><span class="font-weight-500">First</span> confirmation</span>
        <span>at</span>
        <span class="font-weight-500">
          {{
            inventoryStatistics.firstConfirmation.confirmedAt
              | date : "MMM d, y - HH:mm"
          }}
        </span>
        <span>by</span>
        <lib-user
          [oId]="inventoryStatistics.firstConfirmation.confirmedBy ?? 'na'"
        ></lib-user>
      </div>

      <div
        *ngIf="
          inventoryStatistics && inventoryStatistics.latestConfirmation != null
        "
        class="flex-row align-items-center gap-small"
      >
        <span><span class="font-weight-500">Latest</span> confirmation</span>
        <span>at</span>
        <span class="font-weight-500">
          {{
            inventoryStatistics.latestConfirmation.confirmedAt
              | date : "MMM d, y - HH:mm"
          }}
        </span>
        <span>by</span>
        <lib-user
          [oId]="inventoryStatistics.latestConfirmation.confirmedBy ?? 'na'"
        ></lib-user>
      </div>

      <table class="generic-table mt-normal">
        <thead>
          <tr>
            <th>Top</th>
            <th>Confirmations [#]</th>
            <th>Confirmations [%]</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let confirmor of topConfirmors">
            <td>{{ confirmor.position }}</td>
            <td>{{ confirmor.noOfConfirmations }}</td>
            <td>{{ confirmor.percent | percent : "1.1-1" }}</td>
            <td>
              <lib-user [oId]="confirmor.userOid"></lib-user>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="cancel()">
        Cancel
      </button>
</mat-dialog-actions>
