
export enum ActionType {
    PickUp = 1,
    Handover = 2,
    Deposit = 3,
}

export const actionTypes = new Map<number, string>([
    [ActionType.PickUp, "pickup"],
    [ActionType.Handover, "handover"],
    [ActionType.Deposit, "deposit"],
]);
