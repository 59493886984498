<h2 mat-dialog-title>
  View DEFECT records for
  <span class="color-primary-400 font-weight-500">
    {{ data.assetId }}
  </span>
</h2>

<mat-dialog-content>
  <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>

  <ng-template #notLoading>
    <div class="flex-column">
      <span *ngIf="assetDefects.length === 0; else hasDefects"
        >No defect records available.</span
      >
      <ng-template #hasDefects>
        <table class="gat-table">
          <tr>
            <th>Type</th>
            <th>State</th>
            <th>By</th>
            <th>Notes</th>
          </tr>
          <tr *ngFor="let assetDefect of assetDefects">
            <td>
              <span [class.color-green]="assetDefect.defectTypeId === 2">
                {{ kinds.get(assetDefect.defectTypeId)?.name }}
              </span>
            </td>
            <td>
              <div
                class="flex-column"
                *ngIf="
                  defectOptions.get(assetDefect.defectStateId) as defectOption
                "
              >
                <span class="font-weight-500">{{ defectOption.name }}</span>
                <span class="subname" style="white-space: nowrap">
                  {{ defectOption.subname }}
                </span>
              </div>
            </td>
            <td>
              <div class="flex-column">
                <lib-user
                  [oId]="assetDefect.userOid"
                  style="white-space: nowrap"
                ></lib-user>
                <span>
                  {{
                    assetDefect.timestamp
                      | date : localeService.datetimePipeString(locale)
                  }}
                </span>
              </div>
            </td>
            <td>{{ assetDefect.comment }}</td>
          </tr>
        </table>
      </ng-template>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
