import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { ActionTypeOutput } from 'projects/shared/src/lib/graphql/output/actionTypeOutput';
import { UntypedFormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { ActionFrom, actionFroms } from 'projects/shared/src/lib/graphql/enums/actionFrom';
import { ActionTo, actionTos } from 'projects/shared/src/lib/graphql/enums/actionTo';
import { v4 } from 'uuid';
import { ActionType, actionTypes } from 'projects/shared/src/lib/graphql/enums/actionType';
import {
  CreatePlanStepActionMutationArgs,
  PlanStepActionInputCreate,
  PlanStepActionInputUpdate,
  UpdatePlanStepActionMutationArgs,
  UpdatePlanStepActionMutationRoot,
  createPlanStepActionMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/planStepAction';
import { SelectionService } from '../../services/selection.service';
import { firstValueFrom, Observable, of, from } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { UserSelectService } from 'projects/shared/src/lib/services/user-select.service';
import { PlanQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';
import { LocationSelectComponent, UserSelectComponent } from 'projects/shared/src/public-api';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';
import { TenantConfigQueryRoot } from 'projects/shared/src/lib/graphql/crud/tenant';
import { FULL_FRAGMENT_TENANT_CONFIG } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantConfig';
import { DesktopToastService } from '../../services/desktop-toast.service';

export type CreateOrEditPlanStepActionDialogData = {
  planStep: PlanStepOutput;
  actionTypeId: number | undefined;
  beforeAction: PlanStepActionOutput | undefined;
  afterAction: PlanStepActionOutput | undefined;
  currentAction: PlanStepActionOutput | undefined;
};

export type CreateOrEditPlanStepActionDialogResult = PlanStepActionOutput | undefined;

@Component({
  selector: 'app-create-or-edit-plan-step-action-dialog',
  templateUrl: './create-or-edit-plan-step-action-dialog.component.html',
  styleUrls: ['./create-or-edit-plan-step-action-dialog.component.scss'],
})
export class CreateOrEditPlanStepActionDialogComponent implements OnInit {
  // #region Public Properties

  actionTypes = actionTypes;
  get actionType(): ActionTypeOutput | undefined {
    return this.assortmentService.actionTypes.find((x) => x.id === this.data.actionTypeId);
  }

  get selectedActionTypeOutput(): ActionTypeOutput | undefined {
    return this.#selectedActionTypeOutput;
  }
  set selectedActionTypeOutput(value: ActionTypeOutput | undefined) {
    this.#selectedActionTypeOutput = value;
    this.#setFromAndToOptions();
  }

  get minDate(): Date | undefined {
    if (this._minDate) {
      return this._minDate;
    }

    const plan = this.apollo.client.cache.readQuery<PlanQueryRoot>({
      query: gql`
        query Plan($id: String!) {
          plan(id: $id) {
            id
            planStart
            planEnd
          }
        }
      `,
      variables: { id: this.data.planStep.planId },
    })?.plan;

    if (!plan) {
      return undefined;
    }
    this._minDate = new Date(plan.planStart);
    this._maxDate = new Date(plan.planEnd);

    return this._minDate;
  }
  private _minDate: Date | undefined;

  get maxDate(): Date | undefined {
    if (this._maxDate) {
      return this._maxDate;
    }

    const plan = this.apollo.client.cache.readQuery<PlanQueryRoot>({
      query: gql`
        query Plan($id: String!) {
          plan(id: $id) {
            id
            planStart
            planEnd
          }
        }
      `,
      variables: { id: this.data.planStep.planId },
    })?.plan;

    if (!plan) {
      return undefined;
    }
    this._maxDate = new Date(plan.planEnd);

    return this._maxDate;
  }
  private _maxDate: Date | undefined;

  datetime = new UntypedFormControl();
  transitLocationId: string | undefined;
  fromTypes = actionFroms;
  selectedFromType: ActionFrom | undefined;
  fromDetails: string | undefined;
  toTypes = actionTos;
  selectedToType: ActionTo | undefined;
  toDetails: string | undefined;
  description: string | undefined;
  missingGracePeriod: number = 4;
  uuid1 = v4();
  uuid2 = v4();
  uuid3 = v4();
  activity = false;
  errorMessage: string | undefined;
  @ViewChild('toUserSelect') toUserSelect: UserSelectComponent | undefined;
  @ViewChild('fromUserSelect') fromUserSelect: UserSelectComponent | undefined;
  @ViewChild('toLocationSelect') toLocationSelect: LocationSelectComponent | undefined;
  @ViewChild('fromLocationSelect') fromLocationSelect: LocationSelectComponent | undefined;

  get isCreate(): boolean {
    return typeof this.data.currentAction === 'undefined';
  }

  get canApply(): boolean {
    return this.#canApply;
  }

  // #endregion Public Properties

  #selectedActionTypeOutput: ActionTypeOutput | undefined;
  #canApply = false;

  // #region Init

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateOrEditPlanStepActionDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private dialogRef: MatDialogRef<CreateOrEditPlanStepActionDialogComponent>,
    public assortmentService: AssortmentService,
    private selectionService: SelectionService,
    private apollo: Apollo,
    private toastService: DesktopToastService
  ) {}

  ngOnInit(): void {
    this.assortmentService.getActionTypesAsync().then((actionTypeOutputs) => {
      if (this.data.currentAction) {
        // EDIT EXISTING Plan Step Action
        const currentAction = this.data.currentAction;
        this.selectedActionTypeOutput = actionTypeOutputs.find(
          (x) => x.id === currentAction?.actionTypeId
        );
        this.datetime.setValue(new Date(currentAction.date) as any);
        this.description = currentAction.description ?? undefined;
        this.fromDetails =
          currentAction.fromUserOid ??
          currentAction.fromLocationId ??
          currentAction.fromMail ??
          currentAction.fromOther ??
          undefined;
        this.toDetails =
          currentAction.toUserOid ??
          currentAction.toLocationId ??
          currentAction.toMail ??
          currentAction.toOther ??
          undefined;
        this.selectedFromType;
        this.missingGracePeriod = currentAction.missingGracePeriod;
        this.transitLocationId = currentAction.transitLocationId ?? undefined;
      } else {
        // CREATE NEW Plan Step Action
        this.#loadTenantConfig();
        this.#setDefaults();
      }
      this.#setFromAndToOptions();

      this.evaluateCanApply();
    });
  }

  // #endregion Init

  // #region Public Methods

  evaluateCanApply(): boolean {
    // Make sure that a location or user was selected when the
    // type requires it.
    if (this.selectedToType === ActionTo.User) {
      if (!this.toUserSelect?.oUser) {
        this.#canApply = false;
        return false;
      }
    } else if (this.selectedToType === ActionTo.Location) {
      if (!this.toLocationSelect?.location) {
        this.#canApply = false;
        return false;
      }
    }

    if (this.selectedFromType === ActionFrom.User) {
      if (!this.fromUserSelect?.oUser) {
        this.#canApply = false;
        return false;
      }
    } else if (this.selectedFromType === ActionFrom.Location) {
      if (!this.fromLocationSelect?.location) {
        this.#canApply = false;
        return false;
      }
    }

    if (this.data.currentAction) {
      // This is an EDIT. Check if the data was changed. Only
      // if the data has changed should the apply be possible.
      // no changes => no apply
      // changes => apply possible

      const changes: boolean[] = [];
      changes.push(
        this.#getDate().getTime() !== new Date(this.data.currentAction.date).getTime()
          ? true
          : false
      );

      changes.push(
        this.data.currentAction.actionTypeId !== this.selectedActionTypeOutput?.id ? true : false
      );

      // Handle transitLocationId
      if (
        this.data.currentAction.transitLocationId === null &&
        this.transitLocationId === undefined
      ) {
        changes.push(false);
      } else {
        changes.push(this.data.currentAction.transitLocationId !== this.transitLocationId);
      }

      if (
        this.data.currentAction.toUserOid &&
        (this.selectedToType !== ActionTo.User ||
          this.data.currentAction.toUserOid !== this.toDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.fromUserOid &&
        (this.selectedFromType !== ActionFrom.User ||
          this.data.currentAction.fromUserOid !== this.fromDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.toLocationId &&
        (this.selectedToType !== ActionTo.Location ||
          this.data.currentAction.toLocationId !== this.toDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.fromLocationId &&
        (this.selectedFromType !== ActionFrom.Location ||
          this.data.currentAction.fromLocationId !== this.fromDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.toMail &&
        (this.selectedToType !== ActionTo.Mail || this.data.currentAction.toMail !== this.toDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.fromMail &&
        (this.selectedFromType !== ActionFrom.Mail ||
          this.data.currentAction.fromMail !== this.fromDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.toOther &&
        (this.selectedToType !== ActionTo.Other ||
          this.data.currentAction.toOther !== this.toDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (
        this.data.currentAction.fromOther &&
        (this.selectedFromType !== ActionFrom.Other ||
          this.data.currentAction.fromOther !== this.fromDetails)
      ) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (this.data.currentAction.description !== this.description) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (this.data.currentAction.missingGracePeriod !== this.missingGracePeriod) {
        changes.push(true);
      } else {
        changes.push(false);
      }

      if (changes.every((x) => x === false)) {
        this.#canApply = false;
        return false;
      }
    }

    this.#canApply = !!this.fromDetails && !!this.toDetails && this.datetime.value;
    return this.#canApply;
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  async apply() {
    if (!this.canApply) {
      return;
    }

    if (this.isCreate) {
      await this.#create();
    } else {
      await this.#update();
    }
  }

  onActionTypeChange() {
    // Clear any previously selected transitLocationId when the
    // action type changes from HANDOVER to something else.
    if (this.selectedActionTypeOutput?.id !== ActionType.Handover) {
      this.transitLocationId = undefined;
    }

    this.evaluateCanApply();
  }

  // #endregion Public Methods

  // #region Private Methods

  async #create() {
    this.activity = true;
    this.errorMessage = undefined;

    try {
      const date: Date | undefined = (this.datetime.value as DateTime).toJSDate();

      const data: PlanStepActionInputCreate = {
        tenantId: this.selectionService.selectedTenant?.id ?? 'na',
        planStepId: this.data.planStep.id,
        actionTypeId: this.selectedActionTypeOutput?.id ?? -1,
        actionOrder: 1, // TODO
        date,
        description: this.description,
        missingGracePeriod: this.missingGracePeriod,
      };

      if (this.selectedActionTypeOutput?.id === ActionType.Handover) {
        data.transitLocationId = this.transitLocationId;
      }

      if (this.selectedFromType === ActionFrom.Location) {
        data.fromLocationId = this.fromDetails;
      } else if (this.selectedFromType === ActionFrom.User) {
        data.fromUserOid = this.fromDetails;
      } else if (this.selectedFromType === ActionFrom.Mail) {
        data.fromMail = this.fromDetails;
      } else {
        data.fromOther = this.fromDetails;
      }

      if (this.selectedToType === ActionTo.Location) {
        data.toLocationId = this.toDetails;
      } else if (this.selectedToType === ActionTo.User) {
        data.toUserOid = this.toDetails;
      } else if (this.selectedToType === ActionTo.Mail) {
        data.toMail = this.toDetails;
      } else {
        data.toOther = this.toDetails;
      }

      const variables: CreatePlanStepActionMutationArgs = {
        data,
      };

      const result = await firstValueFrom(
        this.apollo.mutate<createPlanStepActionMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN_STEP_ACTION}
            mutation CreatePlanStepAction($data: PlanStepActionInputCreate!) {
              createPlanStepAction(data: $data) {
                ...FullFragmentPlanStepAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.dialogRef.close(result.data?.createPlanStepAction);
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
      console.log(error);
    } finally {
      this.activity = false;
    }
  }

  async #update() {
    this.activity = true;
    this.errorMessage = undefined;

    try {
      let date: Date | undefined;

      // Handle this.datetime.
      // If it was only set once at the beginning and not changed,
      // it will NOT hold a luxon DateTime object.
      if (typeof this.datetime.value.isValid === 'undefined') {
        const datetime = DateTime.fromJSDate(new Date(this.datetime.value));
        date = datetime.toJSDate();
      } else {
        date = (this.datetime.value as DateTime).toJSDate();
      }

      const data: PlanStepActionInputUpdate = {
        actionTypeId: this.selectedActionTypeOutput?.id ?? -1,
        actionOrder: this.data.currentAction?.actionOrder ?? 1, // TODO
        date,
        description: this.description,
        missingGracePeriod: this.missingGracePeriod,
      };

      if (this.selectedActionTypeOutput?.id === ActionType.Handover) {
        data.transitLocationId = this.transitLocationId ?? null;
      }

      if (this.selectedFromType === ActionFrom.Location) {
        data.fromUserOid = null;
        data.fromLocationId = this.fromDetails;
        data.fromMail = null;
        data.fromOther = null;
      } else if (this.selectedFromType === ActionFrom.User) {
        data.fromUserOid = this.fromDetails;
        data.fromLocationId = null;
        (data.fromMail = null), (data.fromOther = null);
      } else if (this.selectedFromType === ActionFrom.Mail) {
        data.fromUserOid = null;
        data.fromLocationId = null;
        data.fromMail = this.fromDetails;
        data.fromOther = null;
      } else {
        data.fromUserOid = null;
        data.fromLocationId = null;
        data.fromMail = null;
        data.fromOther = this.fromDetails;
      }

      if (this.selectedToType === ActionTo.Location) {
        data.toUserOid = null;
        data.toLocationId = this.toDetails;
        data.toMail = null;
        data.toOther = null;
      } else if (this.selectedToType === ActionTo.User) {
        data.toUserOid = this.toDetails;
        data.toLocationId = null;
        data.toMail = null;
        data.toOther = null;
      } else if (this.selectedToType === ActionTo.Mail) {
        data.toUserOid = null;
        data.toLocationId = null;
        data.toMail = this.toDetails;
        data.toOther = null;
      } else {
        data.toUserOid = null;
        data.toLocationId = null;
        data.toMail = null;
        data.toOther = this.toDetails;
      }

      const variables: UpdatePlanStepActionMutationArgs = {
        planStepActionId: this.data.currentAction?.id ?? 'na',
        data,
      };

      const result = await firstValueFrom(
        this.apollo.mutate<UpdatePlanStepActionMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN_STEP_ACTION}
            mutation UpdatePlanStepAction(
              $planStepActionId: String!
              $data: PlanStepActionInputUpdate!
            ) {
              updatePlanStepAction(planStepActionId: $planStepActionId, data: $data) {
                ...FullFragmentPlanStepAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.dialogRef.close(result.data?.updatePlanStepAction);
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
      console.log(error);
    } finally {
      this.activity = false;
    }
  }

  #setDefaults() {
    if (this.data.actionTypeId) {
      console.log('already set');
      this.selectedActionTypeOutput = this.assortmentService.actionTypes.find(
        (x) => x.id === this.data.actionTypeId
      );
    } else {
      // TODO: Chose best option
      //this.selectedActionTypeOutput =

      if (this.data.beforeAction) {
        // There is a before action.
        if (this.data.beforeAction.toUserOid) {
          this.selectedActionTypeOutput = this.assortmentService.actionTypes.find(
            (x) => x.id === ActionType.Deposit
          );
          this.fromDetails = this.data.beforeAction.toUserOid;
        } else if (this.data.beforeAction.toLocationId) {
          this.selectedActionTypeOutput = this.assortmentService.actionTypes.find(
            (x) => x.id === ActionType.PickUp
          );
          this.fromDetails = this.data.beforeAction.toLocationId;
        }
      } else if (this.data.afterAction) {
        // No before action but after action.
        if (this.data.afterAction.fromUserOid) {
          this.selectedActionTypeOutput = this.assortmentService.actionTypes.find(
            (x) => x.id === ActionType.Handover
          );
          this.toDetails = this.data.afterAction.fromUserOid;
        } else if (this.data.afterAction.fromLocationId) {
          this.selectedActionTypeOutput = this.assortmentService.actionTypes.find(
            (x) => x.id === ActionType.Deposit
          );
          this.toDetails = this.data.afterAction.fromLocationId;
        }
      }
    }
  }

  #setFromAndToOptions() {
    switch (this.#selectedActionTypeOutput?.id) {
      case ActionType.PickUp:
        this.fromTypes = new Map<number, string>([[ActionFrom.Location, 'location']]);
        this.toTypes = new Map<number, string>([
          [ActionTo.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        // In case of EDIT, also consider the available data.
        if (!this.data.currentAction) {
          this.selectedFromType = ActionFrom.Location;
          this.selectedToType = ActionTo.User;
        } else {
          // EDIT
          this.selectedFromType = ActionFrom.Location;
          const proposedToType = this.#getSelectedToBasedOnAction(this.data.currentAction);
          this.selectedToType = Array.from(this.toTypes.keys()).includes(proposedToType)
            ? proposedToType
            : ActionTo.User;
        }

        break;

      case ActionType.Handover:
        this.fromTypes = new Map<number, string>([
          [ActionFrom.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        this.toTypes = new Map<number, string>([
          [ActionTo.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        // In case of EDIT, also consider the available data.
        if (!this.data.currentAction) {
          this.selectedFromType = ActionFrom.User;
          this.selectedToType = ActionTo.User;
        } else {
          // EDIT
          const proposedFromType = this.#getSelectedFromBasedOnAction(this.data.currentAction);
          this.selectedFromType = Array.from(this.fromTypes.keys()).includes(proposedFromType)
            ? proposedFromType
            : ActionFrom.User;
          const proposedToType = this.#getSelectedToBasedOnAction(this.data.currentAction);
          this.selectedToType = Array.from(this.toTypes.keys()).includes(proposedToType)
            ? proposedToType
            : ActionTo.User;
        }
        break;

      case ActionType.Deposit:
        this.fromTypes = new Map<number, string>([
          [ActionFrom.User, 'user'],
          [ActionTo.Mail, 'mail'],
          [ActionTo.Other, 'other'],
        ]);
        this.toTypes = new Map<number, string>([[ActionTo.Location, 'location']]);
        // In case of EDIT, also consider the available data.
        if (!this.data.currentAction) {
          this.selectedFromType = ActionFrom.User;
          this.selectedToType = ActionTo.Location;
        } else {
          // EDIT
          const proposedFromType = this.#getSelectedFromBasedOnAction(this.data.currentAction);
          this.selectedFromType = Array.from(this.fromTypes.keys()).includes(proposedFromType)
            ? proposedFromType
            : ActionFrom.User;
          this.selectedToType = ActionTo.Location;
        }

        break;

      default:
        break;
    }
  }

  #getSelectedFromBasedOnAction(action: PlanStepActionOutput): ActionFrom {
    if (action.fromUserOid) {
      return ActionFrom.User;
    }

    if (action.fromLocationId) {
      return ActionFrom.Location;
    }

    if (action.fromMail) {
      return ActionFrom.Mail;
    }

    return ActionFrom.Other;
  }

  #getSelectedToBasedOnAction(action: PlanStepActionOutput): ActionTo {
    if (action.toUserOid) {
      return ActionTo.User;
    }

    if (action.toLocationId) {
      return ActionTo.Location;
    }

    if (action.toMail) {
      return ActionTo.Mail;
    }

    return ActionTo.Other;
  }

  #getDate(): Date {
    const datetimeTest = DateTime.fromJSDate(this.datetime.value as any);
    const datetime = datetimeTest.isValid ? datetimeTest : (this.datetime.value as DateTime);
    return datetime.toJSDate();
  }

  async #loadTenantConfig() {
    try {
      const result = await firstValueFrom(
        this.apollo.query<TenantConfigQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_CONFIG}
            query TenantConfig {
              tenantConfig {
                ...FullFragmentTenantConfig
              }
            }
          `,
          fetchPolicy: 'network-only',
        })
      );

      this.missingGracePeriod = result.data.tenantConfig?.missingGracePeriod ?? 4;
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'ERROR');
    }
  }

  // #endregion Private Methods
}
