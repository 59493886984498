import { ChangeType } from '../enums/changeType';
import { propertyTypes } from '../enums/propertyType';
import { PropertyType } from '../enums/propertyType';
import { AssetPropertyOutput } from '../output/assetPropertyOutput';
import { ChangeAssetPropertyOutput } from '../output/changeAssetPropertyOutput';
import { Changeable } from './changeable';
import { v4 } from 'uuid';

export class ChangeAssetPropertyChangeable extends Changeable<ChangeAssetPropertyOutput> {
  // #region Changeable Properties

  markAsToBeDeleted: boolean = false;

  changeTypeId!: number;
  assetPropertyId: string | null = null;

  typeId!: number;
  displayName!: string;
  displayOrder!: number;
  shortInfo: string | null = null;
  longInfo: string | null = null;
  isNullable!: boolean;
  notNullDefault: string | null = null;
  optionId: string | null = null;

  hasChangedToDatabaseSource = false;

  // #endregion Changeable Properties

  // #region Immutable Properties

  private _originalTypeId?: number | null;
  get originalTypeId() {
    return this._originalTypeId;
  }

  private _originalDisplayName?: string | null;
  get originalDisplayName() {
    return this._originalDisplayName;
  }

  private _originalDisplayOrder?: number | null;
  get originalDisplayOrder() {
    return this._originalDisplayOrder;
  }

  private _originalShortInfo?: string | null;
  get originalShortInfo() {
    return this._originalShortInfo;
  }

  private _originalLongInfo?: string | null;
  get originalLongInfo() {
    return this._originalLongInfo;
  }

  private _originalIsNullable?: boolean | null;
  get originalIsNullable() {
    return this._originalIsNullable;
  }

  private _originalNotNullDefault?: string | null;
  get originalNotNullDefault() {
    return this._originalNotNullDefault;
  }

  private _originalOptionId?: string | null;
  get originalOptionId() {
    return this._originalOptionId;
  }

  // #endregion Immutable Properties

  // #region Computed Properties

  get typeName(): string | undefined {
    return propertyTypes.get(this.typeId);
  }

  // #endregion Computed Properties

  get assetProperty(): AssetPropertyOutput | undefined {
    return this._assetProperty;
  }
  private _assetProperty: AssetPropertyOutput | undefined;

  constructor(
    databaseSource: ChangeAssetPropertyOutput | undefined,
    assetProperty: AssetPropertyOutput | undefined = undefined
  ) {
    super(databaseSource);

    if (assetProperty) {
      this._assetProperty = assetProperty;
    }

    this.applyDatabaseSource();
  }

  // #region Public Methods

  specialActionForTypeIdChanges() {
    // Options are only allowed/defined for typeId === 1 (Text)
    // If the user changes the typeId (and previously has set an optionId),
    // we need to reset the optionId to null.
    // ATTENTION: This should only be able when the typeId was never live before.

    if (this.typeId !== 1 && this.optionId !== null) {
      this.optionId = null;

      this.evaluateHasChangedToDatabaseSource();
    }
  }

  applyDatabaseSource(databaseSource: ChangeAssetPropertyOutput | undefined = undefined) {
    if (databaseSource) {
      this.setDatabaseSource(databaseSource);
    }

    if (!this.databaseSource) {
      // We create this object "blank". There is no saved record available.
      // Use the assetProperty as source IF IT IS AVAILABLE
      // (for "Add New Property" properties it will not be available).
      if (!this.assetProperty) {
        this.changeTypeId = ChangeType.Add;
      } else {
        this.changeTypeId = ChangeType.Keep;
        this.assetPropertyId = this.assetProperty.id;
      }

      this.typeId = this.assetProperty?.property.typeId ?? PropertyType.Text;
      this._originalTypeId = this.assetProperty?.property.typeId;
      this.displayName = this.assetProperty?.property.displayName ?? v4().slice(0, 8);
      this._originalDisplayName = this.assetProperty?.property.displayName;
      this.displayOrder = this.assetProperty?.property.displayOrder ?? 1; // TODO
      this._originalDisplayOrder = this.assetProperty?.property.displayOrder; // TODO
      this.shortInfo = this.assetProperty?.property.shortInfo ?? null;
      this._originalShortInfo = this.assetProperty?.property.shortInfo;
      this.longInfo = this.assetProperty?.property.longInfo ?? null;
      this._originalLongInfo = this.assetProperty?.property.longInfo;
      this.isNullable = this.assetProperty?.property.isNullable ?? true;
      this._originalIsNullable = this.assetProperty?.property.isNullable;
      this.notNullDefault = this.assetProperty?.property.notNullDefault ?? 'change me';
      this._originalNotNullDefault = this.assetProperty?.property.notNullDefault;
      this.optionId = this.assetProperty?.property.optionId ?? null;
      this._originalOptionId = this.assetProperty?.property.optionId;
    } else {
      // We create this object from an already saved record.
      // Use the saved record as source.
      this.changeTypeId = this.databaseSource.changeTypeId;
      this.assetPropertyId = this.databaseSource.assetPropertyId;

      this.typeId = this.databaseSource.typeId;
      this.displayName = this.databaseSource.displayName;
      this.displayOrder = this.databaseSource.displayOrder;
      this.shortInfo = this.databaseSource.shortInfo;
      this.longInfo = this.databaseSource.longInfo;
      this.isNullable = this.databaseSource.isNullable;
      this.notNullDefault = this.databaseSource.notNullDefault;
      this.optionId = this.databaseSource.optionId;

      this._originalTypeId = this.databaseSource.originalTypeId;
      this._originalDisplayName = this.databaseSource.originalDisplayName;
      this._originalDisplayOrder = this.databaseSource.originalDisplayOrder;
      this._originalShortInfo = this.databaseSource.originalShortInfo;
      this._originalLongInfo = this.databaseSource.originalLongInfo;
      this._originalIsNullable = this.databaseSource.originalIsNullable;
      this._originalNotNullDefault = this.databaseSource.originalNotNullDefault;
      this._originalOptionId = this.databaseSource.originalOptionId;
    }

    this.evaluateHasChangedToDatabaseSource();
  }

  hasOriginal(): boolean {
    if (this.originalDisplayName) {
      return true;
    }
    return false;
  }

  hasChangedToOriginal(): boolean {
    if (!this.hasOriginal()) {
      return false;
    }

    if (this.typeId !== this.originalTypeId) {
      return true;
    }
    if (this.displayName !== this.originalDisplayName) {
      return true;
    }
    if (this.displayOrder !== this.originalDisplayOrder) {
      return true;
    }
    if (this.shortInfo !== this.originalShortInfo) {
      return true;
    }
    if (this.longInfo !== this.originalLongInfo) {
      return true;
    }
    if (this.isNullable !== this.originalIsNullable) {
      return true;
    }
    if (this.notNullDefault !== this.originalNotNullDefault) {
      return true;
    }
    if (this.optionId !== this.originalOptionId) {
      return true;
    }

    return false;
  }

  evaluateHasChangedToDatabaseSource() {
    let hasChanged = false;

    if (this.markAsToBeDeleted) {
      hasChanged = true;
    } else if (this.databaseSource?.typeId !== this.typeId) {
      hasChanged = true;
    } else if (this.databaseSource.displayName !== this.displayName) {
      hasChanged = true;
    } else if (this.databaseSource.displayOrder !== this.displayOrder) {
      hasChanged = true;
    } else if (this.databaseSource.shortInfo !== this.shortInfo) {
      hasChanged = true;
    } else if (this.databaseSource.longInfo !== this.longInfo) {
      hasChanged = true;
    } else if (this.databaseSource.isNullable !== this.isNullable) {
      hasChanged = true;
    } else if (this.databaseSource.notNullDefault !== this.notNullDefault) {
      hasChanged = true;
    } else if (this.databaseSource.optionId !== this.optionId) {
      hasChanged = true;
    }

    this.hasChangedToDatabaseSource = hasChanged;
  }

  // #endregion Pubic Methods
}
