<h2 mat-dialog-title>Attention</h2>

<mat-dialog-content>
  <div class="flex-column">
    <span class="mat-body">
      Your
      <span class="font-weight-500 color-primary-400">user roles</span> have
      been changed. Please
      <span class="font-weight-500 color-primary-400">reload</span> the app in
      order to continue.
    </span>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>RELOAD</button>
</mat-dialog-actions>
