import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OUser } from '../common/oUser';
import {firstValueFrom, lastValueFrom, Subject} from 'rxjs';
import { OUserWrapper } from '../classes/oUserWrapper';
import {TenantUserOIdsQueryRoot, UsersQueryArgs, UsersQueryRoot} from '../graphql/crud/user';
import { Apollo, gql } from 'apollo-angular';
import {UserRoleOutput} from "../graphql/output/userRoleOutput";

@Injectable({
  providedIn: 'root',
})
export class UserSelectService {
  readonly oUsers = new Map<string, OUserWrapper>();
  readonly errorOIds = new Set<string>();
  public refresh$ = new Subject<string>();

  constructor(private _http: HttpClient, private _apollo: Apollo) {}

  getOUserWrapperByOId(oId: string, userRole: UserRoleOutput): OUserWrapper {
    const cachedOUser = this.oUsers.get(oId);
    if (cachedOUser) {
      return cachedOUser;
    }

    // Check if the oId provided an error the last time it was fetched.
    if (this.errorOIds.has(oId)) {
      throw new Error(`The oId '${oId}' could not be fetched before and is now skipped.`);
    }

    // Create Wrapper.
    const wrapper = new OUserWrapper();
    wrapper.isLoading = true;
    this.oUsers.set(oId, wrapper);

    // This oId was not loaded before. Query the data from
    // Microsoft Graph.

    const baseUrl = 'https://graph.microsoft.com/v1.0/users';

    firstValueFrom(this._http.get(`${baseUrl}/${oId}`))
      .then((result) => {
        wrapper.oUser = result as OUser;
        wrapper.isLoading = false;
      })
      .catch(async (error) => {
        this.errorOIds.add(oId);
        wrapper.isLoading = false;
        wrapper.isError = true;
        this.refresh$.next(oId);
        wrapper.oUser = await this.getUserFromDb(oId);
      });

    return wrapper;
  }

  async getUserFromDb(oId: string): Promise<any> {
    const variables: UsersQueryArgs = {
      ids: [oId || '']
    };

    const result = await lastValueFrom(this._apollo.query<UsersQueryRoot>({
      query: gql`
        query Users($ids: [String!]!) {
          users(ids: $ids) {
            oid
            email
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only'
    }))

    const email = result.data.users[0].email || 'Unknown'
    return {
      id: oId,
      displayName: email,
      mail: email,
      jobTitle: null,
      userPrincipalName: email,
      mobilePhone:  null,
      officeLocation: null,
    }
  }

  async getOUserByOId(oId: string): Promise<OUser | undefined> {
    const cachedOUser = this.oUsers.get(oId);
    console.log("Getting user from cache", cachedOUser?.oUser)
    if (cachedOUser?.oUser) {
      return cachedOUser.oUser;
    }

    // Check if the oId provided an error the last time it was fetched.
    console.log("Checking error oIds", this.errorOIds, oId)
    if (this.errorOIds.has(oId)) {
      throw new Error(`The oId '${oId}' could not be fetched before and is now skipped.`);
    }

    // This oId was not loaded before. Query the data from
    // Microsoft Graph.

    const baseUrl = 'https://graph.microsoft.com/v1.0/users';

    try {
      const result = await firstValueFrom(this._http.get(`${baseUrl}/${oId}`));
      const fetchedOUser = result as OUser;
      console.log("Fetched user", fetchedOUser)

      if (!this.oUsers.has(oId)) {
        console.log("Setting user in cache", fetchedOUser)
        const wrapper = new OUserWrapper();
        wrapper.oUser = fetchedOUser;
        this.oUsers.set(oId, wrapper);
      }
      return fetchedOUser;
    } catch (error) {
      console.log("Error fetching user", error)
      if (!this.oUsers.has(oId)) {
        console.log("Setting error in cache")
        const wrapper = new OUserWrapper();
        wrapper.isError = true;
        wrapper.oUser = await this.getUserFromDb(oId);
        this.oUsers.set(oId, wrapper);
        this.errorOIds.add(oId);
        return wrapper.oUser as OUser
      }
      return undefined;
    }
  }

  setOUserByOId(oUser: OUser, oId: string) {
    if (this.oUsers.has(oId)) {
      return;
    }

    const wrapper = new OUserWrapper();
    wrapper.oUser = oUser;

    this.oUsers.set(oId, wrapper);
  }

  async getTenantUserOIds(): Promise<string[]> {
    const result = await firstValueFrom(
      this._apollo.query<TenantUserOIdsQueryRoot>({
        query: gql`
          query TenantUserOIds {
            tenantUserOIds
          }
        `,
        fetchPolicy: 'cache-first',
      })
    );

    return result.data.tenantUserOIds;
  }
}
