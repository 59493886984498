import { Component, Inject } from '@angular/core';
import { OUser } from '../../common/oUser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type UserInfoDialogData = {
  oUser: OUser;
};

@Component({
  selector: 'lib-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss'],
})
export class UserInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: UserInfoDialogData) {}
}
