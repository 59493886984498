import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { roles } from 'projects/shared/src/lib/graphql/enums/role';

@Component({
  selector: 'app-role-updates-dialog',
  templateUrl: './role-updates-dialog.component.html',
  styleUrls: ['./role-updates-dialog.component.scss'],
})
export class RoleUpdatesDialogComponent {
  userRoles = roles;

  constructor() {}
}
