import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { Subscription, firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';
import { LocationOutput } from '../../graphql/output/locationOutput';
import { Apollo, gql } from 'apollo-angular';
import { LocationsQueryRoot } from '../../graphql/crud/location';
import { FULL_FRAGMENT_LOCATION } from '../../graphql/fragments/fullFragmentLocation';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import '../../extensions/array';

@Component({
  selector: 'lib-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.scss'],
})
export class LocationSelectComponent implements OnInit, OnDestroy {
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() subscriptSizing: SubscriptSizing = 'dynamic';
  @Input() label: string | undefined;
  @Input() readonly = false;
  @Input() required = false;
  @Input() hint: string | undefined;
  @Input() hideSearchIcon = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = value;
    if (value === true) {
      this.searchText.enable();
    } else {
      this.searchText.disable();
    }
  }

  @Input()
  get locationId(): string | undefined {
    return this._locationId;
  }
  set locationId(value) {
    this._locationId;
    if (value) {
      this._loadLocation(value);
    } else {
      this.searchText.setValue(null);
      this.filteredLocations = [];
      this.location = undefined;
    }
  }
  @Output() locationIdChange = new EventEmitter<string>();

  location: LocationOutput | undefined;
  uuid = v4();
  activity = false;
  searchText = new FormControl<string | null>(null);
  locations: LocationOutput[] = [];
  filteredLocations: LocationOutput[] = [];
  getLocationDisplay = LocationSelectComponent.getLocationDisplayString;

  private _hasLoadedLocations = false;
  private _disabled = false;
  private _searchStringSubscription: Subscription | undefined;
  private _locationId: string | undefined;

  constructor(private _apollo: Apollo) {}

  ngOnInit(): void {
    if (this.disabled) {
      this.searchText.disable();
    }

    this._searchStringSubscription = this.searchText.valueChanges.subscribe((value) => {
      this._loadLocations(value);
      this._setFilteredLocations(value);
    });
  }

  ngOnDestroy(): void {
    this._searchStringSubscription?.unsubscribe();
  }

  displayFn(location: LocationOutput | null): string {
    if (!location) {
      return '';
    }
    return LocationSelectComponent.getLocationDisplayString(location);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.location = event.option.value;
    this._locationId = this.location?.id;
    this.locationIdChange.emit(this.locationId);
  }

  onClickClear() {
    this.searchText.setValue(null);
    this.filteredLocations = [];
    this.location = undefined;
    this._locationId = undefined;
    this.locationIdChange.emit(undefined);
  }

  static getLocationDisplayString(location: LocationOutput | undefined): string {
    if (!location) {
      return '';
    }

    const elements: string[] = [];

    for (let i = 1; i < 6; i++) {
      const part = (location as any)['level' + i] as string | undefined;
      if (!part) {
        break;
      }
      elements.push(part);
    }

    return elements.join(', ');
  }

  private async _loadLocation(locationId: string) {
    if (!this._hasLoadedLocations) {
      await this._loadLocations(locationId);
    }
    const location = this.locations.find((x) => x.id === locationId);
    this.location = location;
    this.searchText.setValue(location as any);
  }

  private async _loadLocations(searchString: string | null) {
    if (this._hasLoadedLocations) {
      return;
    }

    this.activity = true;

    try {
      const result = await firstValueFrom(
        this._apollo.query<LocationsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_LOCATION}
            query Locations {
              locations {
                ...FullFragmentLocation
              }
            }
          `,
          fetchPolicy: 'cache-first',
        })
      );

      this.locations = result.data.locations;
      this._setFilteredLocations(searchString);
      this._hasLoadedLocations = true;
    } catch (error) {
      // ???
    } finally {
      this.activity = false;
    }
  }

  private _setFilteredLocations(search: LocationOutput | string | null) {
    if (!search) {
      this.filteredLocations = this.locations;
      return;
    }

    if (typeof (search as any).__typename !== 'undefined') {
      return;
    }

    const searchString = search as string;

    const filteredLocations: LocationOutput[] = [];
    const searchArray = searchString.trim().toLowerCase().split(' ');

    for (const location of this.locations) {
      let matches = 0;
      const locationDisplayString = LocationSelectComponent.getLocationDisplayString(location);
      searchArray.forEach((x) => {
        if (locationDisplayString.toLowerCase().includes(x)) {
          matches++;
        }
      });

      if (matches === searchArray.length) {
        filteredLocations.push(location);
      }
    }

    this.filteredLocations = filteredLocations.sortBy((x) =>
      LocationSelectComponent.getLocationDisplayString(x)
    );
  }
}
