<div
  [matTooltip]="tooltip ?? 'na'"
  matTooltipPosition="above"
  [matTooltipDisabled]="!tooltip"
  [matTooltipShowDelay]="700"
  class="hover-icon"
  [class.hover-icon-disabled]="disabled"
>
  <span class="material-symbols-outlined unfilled">
    {{ icon }}
  </span>

  <span
    class="material-symbols-outlined filled fill"
    [class.color-primary-400]="color === 'primary'"
    [class.color-warn]="color === 'warn'"
    [class.color-accent-400]="color === 'accent'"
  >
    {{ icon }}
  </span>
</div>
