<div *ngIf="showContent" class="content">
  <!-- ################## -->
  <!-- SHOW COLUMNS START -->
  <!-- ################## -->
  <ng-container *ngIf="showContent === 'columns'">
    <div class="mt-small ml-small mb-small font-weight-500">Columns</div>

    <div>
      <button
        mat-flat-button
        color="accent"
        (click)="restoreDefaultColumnOrder.next(null)"
        class="ml-small"
      >
        Restore Default Order
      </button>
    </div>

    <div *ngFor="let def of context?.columnDefs ?? []">
      <ng-container *ngIf="$any(def).children; else isColDef">
        <mat-checkbox
          [ngModel]="
            (childSelectionInGroup.get(def.headerName ?? 'na') ??
              [true, true])[1]
          "
          [indeterminate]="
            (childSelectionInGroup.get(def.headerName ?? 'na') ??
              [true, true])[0]
          "
          (ngModelChange)="
            switchGroupHideStates($event, $any(def));
            evaluateGroupCheckState(def.headerName)
          "
        >
          {{ def.headerName }}
        </mat-checkbox>
        <div
          *ngIf="sortedColDefsInGroup.get(def.headerName ?? 'na') as children"
          cdkDropList
          (cdkDropListDropped)="onDropColumnHeader($event, children)"
          inner-list
        >
          <div
            cdkDrag
            *ngFor="let child of children"
            class="ml-normal inner-list-item"
          >
            <ng-container *ngIf="!columnIsMasked.get(child.field ?? 'na')">
              <mat-checkbox
                [ngModel]="!columnIsHidden.get(child.field ?? 'na')"
                (click)="
                  switchHideState(child.field ?? 'na');
                  evaluateGroupCheckState(def.headerName)
                "
              >
                {{ child.headerName }}
              </mat-checkbox>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #isColDef>
        <mat-checkbox
          [ngModel]="!$any(def).hide"
          (ngModelChange)="switchHideState($any(def).colId)"
        >
          {{ def.headerName }}
        </mat-checkbox>
      </ng-template>
    </div>
  </ng-container>
  <!-- ################ -->
  <!-- SHOW COLUMNS END -->
  <!-- ################ -->

  <!-- ###################### -->
  <!-- SHOW INVENTORIES START -->
  <!-- ###################### -->
  <ng-container appNotReader *ngIf="showContent === 'inventories'">
    <div
      *ngIf="inventories.length === 0; else hasInventories"
      style="width: 330px; font-style: italic"
      class="mt-normal ml-normal"
    >
      No inventories available.
    </div>

    <ng-template #hasInventories>
      <div class="flex-column">
        <span class="drag-info">
          To add assets to or remove assets from an inventory, use the drag
          handle in the 'Info' column. When you drag the asset(s) onto an
          inventory, the respective drop zones for 'ADD' and 'REMOVE' will
          become visible.
        </span>

        <div
          *ngFor="let inventory of inventories"
          class="inventory-item prevent-select"
          [class.active]="inventoriesSelected?.id === inventory.id"
          style="min-width: 330px; max-width: 360px"
          (dragenter)="onInventoryItemDragEnter($event, inventory.id)"
          (dragleave)="onInventoryItemDragLeave($event, inventory.id)"
          (click)="onInventoryClick(inventory)"
        >
          <!-- ACTIVITY OVERLAY -->
          <div
            class="activity-overlay"
            *ngIf="inventoriesActivity.get(inventory.id)"
          >
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <!-- DRAG AND DROP OVERLAY -->
          <div
            class="drag-overlay"
            *ngIf="(inventoriesDragSituation.get(inventory.id) ?? 0) > 0"
          >
            <div class="grid">
              <!-- ADD -->
              <div
                class="add"
                (dragenter)="onDropZoneDragEnter($event)"
                (dragover)="onInventoryItemDropZoneDragOver($event)"
                (dragleave)="onDropZoneDragLeave($event)"
                (drop)="onInventoryDropAddAssets($event, inventory)"
              >
                <span
                  class="material-symbols-outlined"
                  style="margin-top: 4px; pointer-events: none"
                >
                  add
                </span>
              </div>

              <!-- REMOVE -->
              <div
                class="remove"
                (dragenter)="onDropZoneDragEnter($event)"
                (dragover)="onInventoryItemDropZoneDragOver($event)"
                (dragleave)="onDropZoneDragLeave($event)"
                (drop)="onInventoryDropRemoveAssets($event, inventory)"
              >
                <span
                  class="material-symbols-outlined"
                  style="margin-top: 4px; pointer-events: none"
                >
                  delete
                </span>
              </div>
            </div>
          </div>

          <!-- REGULAR ITEM ELEMENT -->
          <div class="left" style="pointer-events: none">
            <div class="flex-row align-items-center gap-normal">
              <span>{{ inventory.name }}</span>
              <span class="font-size-12">
                ({{ inventory.noOfConfirmedAssets }} /
                {{ inventory.noOfOverallAssets }})
              </span>
              <span
                *ngIf="inventory.completed"
                class="material-symbols-outlined color-green"
              >
                task_alt
              </span>
            </div>
            <div
              *ngIf="inventory.description"
              class="font-size-12"
              style="opacity: 0.87; overflow-x: hidden"
            >
              {{ inventory.description }}
            </div>
            <div
              class="flex-row align-items-center gap-normal font-size-12 mat-body nowrap"
            >
              <span>
                {{
                  inventory.createdAt
                    | date : localeService.datetimePipeString(locale)
                }}
              </span>
              <lib-user
                [oId]="inventory.createdBy"
                variant="phone"
                [inheritAppearance]="true"
              ></lib-user>
            </div>
          </div>

          <div class="top-actions">
            <span
              *ngIf="!inventory.completed"
              matTooltip="Set to 'completed'"
              matTooltipPosition="above"
              matRipple
              class="material-symbols-outlined item"
              (click)="onInventoryCompleteClick($event, inventory)"
            >
              task_alt
            </span>
            <span
              matTooltip="Show Statistics"
              matTooltipPosition="above"
              matRipple
              class="material-symbols-outlined item"
              (click)="onInventoryStatisticsClick($event, inventory)"
            >
              bar_chart
            </span>
            <span
              matTooltip="Edit"
              matTooltipPosition="above"
              matRipple
              class="material-symbols-outlined fill item"
              (click)="onInventoryEditClick($event, inventory)"
            >
              stylus
            </span>
          </div>

          <div class="right-actions">
            <span
              class="material-symbols-outlined item"
              matTooltip="Delete"
              matTooltipPosition="above"
              (click)="onInventoryDeleteClick($event, inventory)"
            >
              delete
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <!-- #################### -->
  <!-- SHOW INVENTORIES END -->
  <!-- #################### -->
</div>

<div class="navigation">
  <div
    *ngIf="context?.columnDefs"
    class="item"
    (click)="onNavigation('columns')"
    [class.active]="showContent === 'columns'"
  >
    <mat-icon>view_column</mat-icon>
    <span class="text">Columns</span>
  </div>

  <div
    appNotReader
    class="item"
    (click)="onNavigation('inventories')"
    [class.active]="showContent === 'inventories'"
  >
    <mat-icon fontSet="material-symbols-outlined">inventory</mat-icon>
    <span class="text" style="margin-top: 24px">Inventories</span>
    <mat-icon
      *ngIf="inventoriesSelected"
      fontSet="material-symbols-outlined"
      style="margin-top: 20px"
      color="warn"
    >
      priority_high
    </mat-icon>
  </div>
</div>
