import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetsTableComponent } from '../assets-table.component';

@Component({
  selector: 'app-assets-table-filter',
  templateUrl: './assets-table-filter.component.html',
  styleUrls: ['./assets-table-filter.component.scss'],
})
export class AssetsTableFilterComponent {
  @Input({ required: true }) context: AssetsTableComponent | undefined;
  @Output() onFilterModel = new EventEmitter<{ [key: string]: any }>();

  readonly hasActiveFilter = new Map<'defect' | 'missing' | 'inventory', boolean>([
    ['defect', false],
    ['missing', false],
    ['inventory', false],
  ]);

  defect = {
    // These apply to the column "defectState"
    functional: false,
    partiallyFunctional: false,
    nonFunctional: false,

    // These apply to the column "defectType"
    verified: false,
    unverified: false,
    neverReported: false,
  };

  missing = {
    available: false,
    unavailable: false,
  };

  inventory = {
    // These apply to the column "inventoryNames"
    onActiveInventory: false,
    notOnActiveInventory: false,

    // These apply to the column "inventoryBooked"
    confirmationRequired: false,
    confirmationNotRequired: false,
  };

  unappliedFilterModel: { [key: string]: any } | undefined;

  constructor() {}

  disableAllFilters() {
    this.hasActiveFilter.set('defect', false);
    this.hasActiveFilter.set('missing', false);
    this.hasActiveFilter.set('inventory', false);
    this.unappliedFilterModel = undefined;
  }

  apply() {
    if (!this.unappliedFilterModel) {
      return;
    }

    this.onFilterModel.emit(this.unappliedFilterModel);
    this.unappliedFilterModel = undefined;
  }

  generateFilterModel() {
    if (!this.context) {
      return;
    }

    const filterModel = this.context.gridApi.getFilterModel();

    const missingPropertyName = this.context.specialTableColumn_Missing?.field;
    if (!missingPropertyName) {
      throw new Error('The property name for the missing column was not set.');
    }
    const defectTypePropertyName = 'defectType';
    const defectStatePropertyName = 'defectState';
    const inventoryNamesPropertyName = 'inventoryNames';
    const inventoryBookedPropertyName = 'inventoryBooked';

    // Reset any previously available missing filters.
    filterModel[missingPropertyName] = undefined;
    filterModel[defectTypePropertyName] = undefined;
    filterModel[defectStatePropertyName] = undefined;
    filterModel[inventoryNamesPropertyName] = undefined;
    filterModel[inventoryBookedPropertyName] = undefined;

    if (this.hasActiveFilter.get('defect')) {
      // Add defect related filters to filterModel.

      // First for the column "defectType" (1 = reported, 2 = verified, nothing = never reported)
      const reportConditions: { [key: string]: any }[] = [];
      if (this.defect.verified) {
        reportConditions.push({
          filterType: 'number',
          type: 'equals',
          filter: 2,
        });
      }

      if (this.defect.unverified) {
        reportConditions.push({
          filterType: 'number',
          type: 'equals',
          filter: 1,
        });
      }

      if (this.defect.neverReported) {
        reportConditions.push({
          filterType: 'number',
          type: 'blank',
        });
      }

      if (reportConditions.length === 3) {
        // Nothing to do. No filter.
      } else if (reportConditions.length === 1) {
        filterModel[defectTypePropertyName] = reportConditions[0];
      } else if (reportConditions.length > 1) {
        const thisFilterModel: { [key: string]: any } = {
          filterType: 'number',
          operator: 'OR',
          conditions: reportConditions,
        };

        for (let i = 0; i < reportConditions.length; i++) {
          thisFilterModel['condition' + (i + 1)] = reportConditions[i];
        }

        filterModel[defectTypePropertyName] = thisFilterModel;
      }

      // Second for the column "defectState" (1 = ok, 2 = D, 3 = D100, nothing = ok)
      const functionalityConditions: { [key: string]: any }[] = [];
      if (this.defect.functional) {
        functionalityConditions.push({
          filterType: 'number',
          type: 'equals',
          filter: 1,
        });
        functionalityConditions.push({
          filterType: 'number',
          type: 'blank',
        });
      }

      if (this.defect.partiallyFunctional) {
        functionalityConditions.push({
          filterType: 'number',
          type: 'equals',
          filter: 2,
        });
      }

      if (this.defect.nonFunctional) {
        functionalityConditions.push({
          filterType: 'number',
          type: 'equals',
          filter: 3,
        });
      }

      if (functionalityConditions.length === 4) {
        // Nothing to do. No filter.
      } else if (functionalityConditions.length === 1) {
        filterModel[defectStatePropertyName] = functionalityConditions[0];
      } else {
        const thisFilterModel: { [key: string]: any } = {
          filterType: 'number',
          operator: 'OR',
          conditions: functionalityConditions,
        };

        for (let i = 0; i < functionalityConditions.length; i++) {
          thisFilterModel['condition' + (i + 1)] = functionalityConditions[i];
        }

        filterModel[defectStatePropertyName] = thisFilterModel;
      }
    }

    if (this.hasActiveFilter.get('missing')) {
      // Add missing related filters to filterModel.

      if (this.missing.available && !this.missing.unavailable) {
        // Show only available
        filterModel[missingPropertyName] = {
          filterType: 'text',
          type: 'equals',
          filter: 'false',
        };
      } else if (!this.missing.available && this.missing.unavailable) {
        // Show only missing
        filterModel[missingPropertyName] = {
          filterType: 'text',
          type: 'equals',
          filter: 'true',
        };
      }
    }

    if (this.hasActiveFilter.get('inventory')) {
      // Add inventory related filters to filterModel.

      // First for the column "inventoryNames"
      if (this.inventory.onActiveInventory && !this.inventory.notOnActiveInventory) {
        filterModel[inventoryNamesPropertyName] = {
          filterType: 'text',
          type: 'notBlank',
        };
      } else if (!this.inventory.onActiveInventory && this.inventory.notOnActiveInventory) {
        filterModel[inventoryNamesPropertyName] = {
          filterType: 'text',
          type: 'blank',
        };
      }

      // Next for the column "inventoryBooked"
      if (this.inventory.confirmationRequired && !this.inventory.confirmationNotRequired) {
        filterModel[inventoryBookedPropertyName] = {
          filterType: 'text',
          type: 'contains',
          filter: 'false',
        };
      } else if (!this.inventory.confirmationRequired && this.inventory.confirmationNotRequired) {
        const condition1 = {
          filterType: 'text',
          type: 'blank',
        };
        const condition2 = {
          filterType: 'text',
          type: 'notContains',
          filter: 'false',
        };

        filterModel[inventoryBookedPropertyName] = {
          condition1,
          condition2,
          conditions: [condition1, condition2],
          filterType: 'text',
          operator: 'OR',
        };
      }
    }

    this.unappliedFilterModel = filterModel;
  }
}
