import { Component, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, SortDirection } from 'ag-grid-community';
import { environment } from 'projects/desktop/src/environments/environment';
import { SelectionService } from '../../../services/selection.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AssetsTableComponent } from '../../../components/assets/assets-and-plans/assets-table/assets-table.component';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';

@Component({
  selector: 'app-ag-custom-header',
  templateUrl: './ag-custom-header.component.html',
  styleUrls: ['./ag-custom-header.component.scss'],
})
export class AgCustomHeaderComponent implements IHeaderAngularComp, OnDestroy {
  params: IHeaderParams<any, any> | undefined;
  sortDirection: SortDirection = null;
  isFilterActive = false;
  isTenantProperty = false;
  isEditable = true;
  errorMessage: string | undefined;

  distinctLoading = false;
  distinctValues: [boolean, string][] = [];
  hasColumnFilter = false;

  constructor(private http: HttpClient, private selectionService: SelectionService) {}

  ngOnDestroy(): void {
    this.params?.column.removeEventListener('sortChanged', this.#sortChanged.bind(this));
    this.params?.column.removeEventListener('filterChanged', this.#filterChanged.bind(this));
  }

  agInit(params: IHeaderParams<any, any>): void {
    this.params = params;

    this.#setSortDirection();
    this.#setOtherProperties();
    this.isFilterActive = params.column.isFilterActive();
    this.#checkColumnFilter();

    this.params.column.addEventListener('sortChanged', this.#sortChanged.bind(this));
    this.params.column.addEventListener('filterChanged', this.#filterChanged.bind(this));
  }

  refresh(params: IHeaderParams<any, any>): boolean {
    return false;
  }

  onHeaderClicked(event: MouseEvent) {
    if (!this.params?.enableSorting) {
      return;
    }

    if (event.shiftKey) {
      this.params?.progressSort(event.shiftKey);
      return;
    }

    if (!this.params.column.isSortAscending() && !this.params.column.isSortDescending()) {
      // No sorting set. Default now is "ASC".
      this.params.setSort('asc', false);
    } else if (this.params.column.isSortAscending()) {
      this.params.setSort('desc', false);
    } else {
      this.params.setSort(null);
    }
  }

  onClickDistinctMenu() {
    this.#checkColumnFilter();
    this.#loadDistinctValues();
  }

  onClickClearColumnFilter() {
    if (!this.params || !this.hasColumnFilter) {
      return;
    }

    const filterModel = this.params.api.getFilterModel();
    const columnId = this.params.column.getId();

    filterModel[columnId] = undefined;
    this.params.api.setFilterModel(filterModel);
    this.#checkColumnFilter();

    this.#loadDistinctValues();
  }

  onClickDistinctValue(value: string) {
    if (!this.params) {
      return;
    }

    const filterModel = this.params.api.getFilterModel();
    const columnId = this.params.column.getId();

    console.log(JSON.stringify(filterModel));
    filterModel[columnId] = {
      filterType: 'text',
      type: 'equals',
      filter: value,
    };
    this.params.api.setFilterModel(filterModel);
    this.#checkColumnFilter();
    this.#loadDistinctValues();
  }

  #setSortDirection() {
    if (!this.params) {
      return;
    }
    this.sortDirection = this.params.column.getSort() ?? null;
  }

  #setOtherProperties() {
    const context = this.params?.context as AssetsTableComponent | undefined;

    if (!context || !this.params) {
      return;
    }

    if (context.headerFieldsNotEditable.includes(this.params.column.getColId())) {
      this.isEditable = false;
    }

    if (context.headerFieldsTenantLevel.includes(this.params.column.getColId())) {
      this.isTenantProperty = true;
    }
  }

  #sortChanged() {
    if (!this.params) {
      return;
    }

    // get sort state from column
    this.#setSortDirection();
  }

  #filterChanged() {
    if (!this.params) {
      return;
    }

    this.isFilterActive = this.params.column.isFilterActive();
  }

  async #loadDistinctValues() {
    if (!this.params) {
      return;
    }

    const context = this.params.context as AssetsTableComponent;

    this.distinctLoading = true;
    this.errorMessage = undefined;
    const filterModel = this.params.api.getFilterModel();
    const hasFilters = Object.keys(filterModel).length > 0;

    try {
      const body = {
        filterModel: hasFilters ? filterModel : undefined,
        pageModel: {
          startRow: 0,
          endRow: null,
        },
        inventoryId: context.filterInventoryId,
      };

      const url = `${environment.apiBaseUrl}/api/assets/distinctValues/${
        this.selectionService.selectedTenant?.id
      }/${this.params?.column.getId()}`;
      const data = await firstValueFrom(this.http.post<(string | null)[]>(url, body));
      const distinctValues = data.filter((x) => x != null && x !== '') as string[];

      const values: [boolean, string][] = [];
      distinctValues.forEach((x) => {
        values.push([true, x]);
      });

      this.distinctValues = values;
    } catch (error) {
      this.distinctValues = [];
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.distinctLoading = false;
    }
  }

  #checkColumnFilter() {
    if (!this.params) {
      return;
    }

    const filterModel = this.params.api.getFilterModel();
    const columnId = this.params.column.getId();

    this.hasColumnFilter = typeof filterModel[columnId] !== 'undefined';
  }
}
