import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivityDialogComponent,
  ActivityDialogData,
} from '../component-dialogs/activity-dialog/activity-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private _matDialog: MatDialog) {}

  async open(
    title: string,
    text: string | undefined,
    run: () => Promise<void>,
    afterClosed?: (ok: boolean) => Promise<void>
  ) {
    const data: ActivityDialogData = {
      title,
      text,
      run,
    };
    const dialog = this._matDialog.open(ActivityDialogComponent, {
      data,
      autoFocus: false,
      minWidth: 400,
    });

    const closedWithTrue: boolean = await firstValueFrom(dialog.afterClosed());
    if (afterClosed) {
      await afterClosed(closedWithTrue);
    }
  }
}
