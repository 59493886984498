<h2 mat-dialog-title>
  Edit Asset Property
  <span class="color-primary-400">{{ data.columnPropertyHeaderName }}</span>
</h2>

<mat-dialog-content>
  <div class="flex-column">
    <lib-spinner *ngIf="loading; else notLoadingElement"></lib-spinner>

    <ng-template #notLoadingElement>
      <span *ngIf="loadingErrorMessage; else noLoadingErrorElement">
        {{ loadingErrorMessage }}
      </span>

      <ng-template #noLoadingErrorElement>
        <table class="gat-table mb-normal">
          <tr>
            <th>Asset ID</th>
            <th>{{ data.columnPropertyHeaderName }}</th>
          </tr>
          <tr *ngFor="let asset of data.assets">
            <td>{{ asset.id }}</td>

            <!-- Date -->
            <td *ngIf="property?.typeId === propertyType.Date; else notDate">
              {{
                asset[data.columnPropertyId]
                  | date : localeService.datePipeString(locale)
              }}
            </td>

            <ng-template #notDate>
              <td
                *ngIf="
                  property?.typeId === propertyType.DateTime;
                  else notDateTime
                "
              >
                {{
                  asset[data.columnPropertyId]
                    | date : localeService.datetimePipeString(locale)
                }}
              </td>

              <ng-template #notDateTime>
                <td>{{ asset[data.columnPropertyId] }}</td>
              </ng-template>
            </ng-template>
          </tr>
        </table>

        <!-- Text or Number and NO OPTION -->
        <ng-container
          *ngIf="
            (property?.typeId === propertyType.Text ||
              property?.typeId === propertyType.Number) &&
            !property?.optionId
          "
        >
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>New Value</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="selectedText"
              [autocomplete]="uuid1"
              [required]="!property?.isNullable"
            />
          </mat-form-field>
        </ng-container>

        <!-- Text and OPTION -->
        <ng-container
          *ngIf="property?.typeId === propertyType.Text && property?.optionId"
        >
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>New Value</mat-label>
            <mat-select
              [(ngModel)]="selectedTextOptionItem"
              [required]="!property?.isNullable"
            >
              <mat-select-trigger>
                <span>{{ selectedTextOptionItem?.name }}</span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let optionItem of option?.optionItems"
                [value]="optionItem"
              >
                <span>{{ optionItem.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="property?.typeId === propertyType.Boolean">
          <!-- <mat-checkbox [(ngModel)]="selectedBoolean"></mat-checkbox> -->
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>New Value</mat-label>
            <mat-select
              [(ngModel)]="selectedBooleanOption"
              [required]="!property?.isNullable"
            >
              <mat-select-trigger>
                <span>{{ selectedBooleanOption?.name }}</span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let booleanOption of booleanOptions"
                [value]="booleanOption"
              >
                <span>{{ booleanOption.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="property?.typeId === propertyType.DateTime">
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>New Date + Time</mat-label>
            <mtx-datetimepicker
              #datetimePicker
              type="datetime"
              mode="portrait"
              [multiYearSelector]="false"
              startView="month"
              [twelvehour]="false"
              [timeInterval]="1"
              [touchUi]="false"
              [timeInput]="true"
            ></mtx-datetimepicker>
            <input
              [mtxDatetimepicker]="datetimePicker"
              [(ngModel)]="selectedDateTime"
              matInput
              [required]="!property?.isNullable"
            />
            <mtx-datetimepicker-toggle
              [for]="datetimePicker"
              matSuffix
            ></mtx-datetimepicker-toggle>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="property?.typeId === propertyType.Date">
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <mat-label>New Date</mat-label>
            <input
              matInput
              [matDatepicker]="datepicker"
              [(ngModel)]="selectedDate"
              [required]="!property?.isNullable"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="datepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
        </ng-container>
      </ng-template>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>CANCEL</button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!canApply"
    (click)="apply()"
  >
    APPLY
  </button>
</mat-dialog-actions>
