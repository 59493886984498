import { ColDef } from 'ag-grid-community';

export interface ColDefExt extends ColDef {
    propertyLevelId?: number;
    propertyTypeId?: number;
    propertyOptionId?: string;
}


// AG-Grid: Each filter has at least a filter type
export interface AgFilterModelDetail {
    filterType: "text" | "number";
}

// AG-Grid: In case only one filter is active on a column thats the format provided
export interface AgFilterModelDetailSingle extends AgFilterModelDetail {
    filter: string; // value
    type: "contains" | "notContains" | "equals" | "notEqual" | "startsWith" | "endsWith" | "blank" | "notBlank" | "lessThan" | "greaterThan" | 'in';
}

// AG-Grid: In case multiple filters are active on a column, thats the format provided
export interface AgFilterModelDetailMulti extends AgFilterModelDetail {
    operator: "AND" | "OR";
    conditions: AgFilterModelDetailSingle[];
}

export interface AgFilterModel {
    [id: string]: AgFilterModelDetailSingle | AgFilterModelDetailMulti;
}

export interface GatFilterOption {
    filterPropName: string;
    items: any[]; // the list of options (e.g. could be AssetOutput[], ...)
    itemSearchPropName: string; // the name of the property on an item where to search
    itemResultPropName: string; // the name of the property on an item what is to return when a search match is detected
}

export class AgGridFilterModel {
    get transformed(): AgFilterModel {
        return this._transformed;
    }


    private _original: AgFilterModel;
    private _transformed: AgFilterModel = {};
    private _filterOptions: GatFilterOption[];


    constructor(filterModel: AgFilterModel, filterOptions: GatFilterOption[]) {
        this._original = filterModel;
        Object.assign(this._transformed, filterModel);
        this._filterOptions = filterOptions;

        this._transformFilterModel();
    }


    private _transformFilterModel() {
        for (let filterOption of this._filterOptions) {
            // Check if the filterOption is RELEVANT (meaning: there is actually a filter for that column).
            if (!this._original[filterOption.filterPropName]) {
                // Not relevant
                continue;
            }

            // First, empty the filterModel details in the transformed object. 
            // We will later reset them in our transformed way.
            const transformedDetail: AgFilterModelDetailSingle = {
                filterType: 'text',
                type: 'in',
                filter: 'tbd' // will be changed below
            }

            const detailsFilter = this._original[filterOption.filterPropName];
            if (typeof (detailsFilter as AgFilterModelDetailMulti).operator !== 'undefined') {
                transformedDetail.filter = this._filterMulti(detailsFilter as AgFilterModelDetailMulti, filterOption)
            } else {
                transformedDetail.filter = this._filterSingle(detailsFilter as AgFilterModelDetailSingle, filterOption)
            }

            this._transformed[filterOption.filterPropName] = transformedDetail;
        }
    }

    private _filterSingle(single: AgFilterModelDetailSingle, filterOption: GatFilterOption): string {
        const searchString = single.filter.trim().toLowerCase();

        const matches = filterOption.items.filter(x => x[filterOption.itemSearchPropName].toLowerCase().includes(searchString))
        return matches.map(x => x[filterOption.itemResultPropName]).join(',');
    }

    private _filterMulti(multiple: AgFilterModelDetailMulti, filterOption: GatFilterOption): string {
        const matches: any[] = [];
        for (let item of filterOption.items) {
            const itemSearchPropValue = item[filterOption.itemSearchPropName];

            // We assume that there are only 2 conditions. That is specified.
            const searchString1 = multiple.conditions[0].filter.trim().toLowerCase();
            const searchString2 = multiple.conditions[1].filter.trim().toLowerCase();

            if (multiple.operator === 'AND') {
                // AND
                if (itemSearchPropValue.includes(searchString1) && itemSearchPropValue.includes(searchString2)) {
                    matches.push(item);
                }
            } else {
                // OR
                if (itemSearchPropValue.includes(searchString1) || itemSearchPropValue.includes(searchString2)) {
                    matches.push(item);
                }
            }
        }

        return matches.map(x => x[filterOption.itemResultPropName]).join(',');
    }
}