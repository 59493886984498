<h2 mat-dialog-title>
  <ng-container *ngIf="data.items.length === 1">
    Asset <span class="color-primary-400">{{ data.items[0] }}</span>
  </ng-container>

  <ng-container *ngIf="data.items.length > 1"> Assets </ng-container>
</h2>

<mat-dialog-content>
  <div class="flex-column gap-normal">
    <div *ngFor="let item of data.items" class="flex-column align-items-center">
      <span *ngIf="data.items.length > 1" style="margin-bottom: -16px; z-index: 1">{{item}}</span>
      <qrcode
        
        [qrdata]="item"
        [width]="256"
        [errorCorrectionLevel]="'M'"
      ></qrcode>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="primary">Ok</button>
</mat-dialog-actions>
