<div class="expander-wrapper">
    <!-- HEADER -->
    <div class="expander-header">
        <button mat-icon-button (click)="isExpanded = !isExpanded">
            <mat-icon *ngIf="!isExpanded">chevron_right</mat-icon>
            <mat-icon *ngIf="isExpanded">expand_more</mat-icon>
        </button>
        <ng-content select="lib-expander-header"></ng-content>
    </div>

    <!-- CONTENT -->
    <ng-content *ngIf="isExpanded" select="lib-expander-content"></ng-content>
</div>