<h2 mat-dialog-title>
  Asset
  <span class="color-primary-400">{{ data.tenantAssetId }}</span> ({{
    auditLogs.length
  }}
  entries)
</h2>

<mat-dialog-content>
  <div class="flex-column">
    <lib-spinner *ngIf="loading; else notLoadingElement"></lib-spinner>

    <ng-template #notLoadingElement>
      <table class="generic-table custom-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>By</th>
            <th>Event Type</th>
            <th>Event Info</th>
            <th>Latest Booking By</th>
            <th>Latest Booking Location</th>
            <th>Latest Booking Notes</th>
            <th>Change Data</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let auditLog of auditLogs">
            <td>
              {{
                auditLog.timestamp
                  | date : localeService.datetimePipeString(locale)
              }}
            </td>
            <td>
              <lib-user [oId]="auditLog.userOid"></lib-user>
            </td>
            <td>
              {{ auditLog.auditLogEvent?.name }}
            </td>
            <td>
              <span class="color-primary-400">{{ auditLog.changeInfo }}</span>
            </td>
            <td>
              <lib-user
                *ngIf="auditLog.latestBookingBy_calcStr"
                [oId]="auditLog.latestBookingBy_calcStr"
              ></lib-user>
            </td>
            <td>
              <span>{{ auditLog.latestBookingLocation_calcStr }}</span>
            </td>
            <td>
              <span>{{ auditLog.latestBookingNotes_calcStr }}</span>
            </td>
            <td>
              <span>{{ auditLog.displayValue }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="history-grid">
        <ng-container *ngFor="let auditLog of auditLogs">
          <div class="flex-column">
            <div>
              {{
                auditLog.timestamp
                  | date : localeService.datetimePipeString(locale)
              }}
            </div>
            <div>{{ auditLog.auditLogEvent?.name }}</div>
            <span class="color-primary-400">{{ auditLog.changeInfo }}</span>
            <lib-user [oId]="auditLog.userOid"></lib-user>
          </div>

          <div class="code">
            <code class="content">
              {{ getJsonString(auditLog.oldValue, auditLog.id) }}
            </code>
            <div class="copy-overlay">
              <button
                mat-icon-button
                (click)="copyToClipboard(auditLog.oldValue)"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>

          <div class="code">
            <code class="content">
              {{ getJsonString(auditLog.newValue, auditLog.id) }}
            </code>
            <div class="copy-overlay">
              <button
                mat-icon-button
                (click)="copyToClipboard(auditLog.newValue)"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div> -->

      <div class="error-message">
        {{ errorMessage }}
      </div>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="export()" [disabled]="loading || activity">
    Export Excel
  </button>

  <button mat-stroked-button mat-dialog-close [disabled]="loading">
    Close
  </button>
</mat-dialog-actions>
