<div class="wrapper">
  <ag-grid-angular
    style="width: 95%; height: 100%"
    class="table ag-theme-alpine"
    [columnDefs]="colDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [rowSelection]="'single'"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"
    [rowHeight]="36"
    (selectionChanged)="onSelectionChanged($event)"
    [enableCellTextSelection]="true"
    [ensureDomOrder]="true"
  >
  </ag-grid-angular>
</div>
