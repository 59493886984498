export const DESKTOP_LOCAL_STORAGE = {
  ASSETS_TABLE_COLUMN_ORDER: 'assetsTableColumnOrder',
  ASSETS_TABLE_DEFAULT_COLUMN_ORDER: 'assetsTableDefaultColumnOrder',
  ASSET_VIEWS: 'assetViews'
};

export type DesktopLocalStorageAssetsTableColumnOrder = Array<{
  colId: string;
  index: number;
}>;
