import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatMenuPanel, MatMenuTrigger, MenuPositionX, MenuPositionY } from '@angular/material/menu';

@Component({
  selector: 'lib-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
})
export class SelectButtonComponent {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() icon: string | undefined;
  @Input() text: string = 'To Do';
  @Input() disabled: boolean = false;
  @Input() xPosition: MenuPositionX = 'before';
  @Input() yPosition: MenuPositionY = 'below';

  @Output() onClick = new EventEmitter<MouseEvent>();
  @ViewChild('trigger', {read: MatMenuTrigger, static: false}) trigger: MatMenuTrigger | undefined;

  //matMenuPanel: MatMenuPanel<any> | null = null;

  onClickMainButton(event: MouseEvent) {
    this.onClick.emit(event);
  }

  onArrowClick(menu: MatMenuPanel<any>) {
    this.trigger?.openMenu();
  }
}
