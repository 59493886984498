import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-restore-dialog',
  templateUrl: './restore-dialog.component.html',
  styleUrls: ['./restore-dialog.component.scss']
})
export class RestoreDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { id: string }, public dialogRef: MatDialogRef<RestoreDialogComponent>,) {
  }

  onNoClick() {
    this.dialogRef.close({restoreAsset: false})
  }

  onYesClick() {
    this.dialogRef.close({restoreAsset: true})
  }
}
