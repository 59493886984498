import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ENTER } from '@angular/cdk/keycodes';
import { v4 } from 'uuid';
import { firstValueFrom, startWith, map, Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CreateOptionItemsMutationArgs, CreateOptionItemsMutationRoot } from 'projects/shared/src/lib/graphql/crud/optionItem';
import { FULL_FRAGMENT_OPTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOption';
import { FULL_FRAGMENT_OPTION_ITEM } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOptionItem';
import { OptionOutput } from 'projects/shared/src/lib/graphql/output/optionOutput';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { FormControl } from '@angular/forms';


export type NewOptionItemsDialogData = {
  option: OptionOutput;
}

type OptionItem = {
  name: string;
}

@Component({
  selector: 'app-new-option-items-dialog',
  templateUrl: './new-option-items-dialog.component.html',
  styleUrls: ['./new-option-items-dialog.component.scss']
})
export class NewOptionItemsDialogComponent implements OnInit, OnDestroy {
  // #region Public Properties

  activity = false;
  errorMessage: string | undefined;
  items: OptionItem[] = [];
  readonly separatorKeysCodes = [ENTER] as const;
  uuid = v4();

  newNameFormControl = new FormControl<string | OptionItem>('');
  filteredOptionItems: Observable<OptionItem[]> | undefined;

  // #endregion Public Properties


  // #region Private Properties

  private _alreadyAvailableOptionItems: OptionItem[];

  // #endregion Private Properties


  // #region Init

  constructor(
    private _dialogRef: MatDialogRef<NewOptionItemsDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: NewOptionItemsDialogData
    , private _apollo: Apollo
  ) {
    const availableOptionItems: OptionItem[] = [];
    data.option.optionItems?.forEach(x => {
      availableOptionItems.push({
        name: x.name ?? 'na'
      })
    });

    this._alreadyAvailableOptionItems = availableOptionItems.sortBy(x => x.name);
  }

  ngOnInit(): void {
    this.filteredOptionItems = this.newNameFormControl.valueChanges.pipe(
      startWith('')
      , map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name)
          : this._alreadyAvailableOptionItems
      })
    )
  }

  ngOnDestroy(): void {

  }

  // #endregion Init


  // #region Public Methods

  removeItem(item: OptionItem) {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  editItem(item: OptionItem, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.removeItem(item);
      return;
    }

    // Edit existing fruit
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items[index].name = value;
    }

    this.items = this.items.sortBy(x => x.name);
  }

  addItem(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    // Check if the value already exists (in the new list and in the already available list).
    if (this.items.find(x => x.name.toLowerCase() === value.toLowerCase())
      || this.data.option.optionItems?.map(x => x.name?.toLowerCase()).includes(value.toLowerCase())
    ) {
      return;
    }

    if (value) {
      this.items.push({ name: value });

      this.items = this.items.sortBy(x => x.name);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  async create() {
    try {
      this.activity = true;

      const variables: CreateOptionItemsMutationArgs = {
        optionId: this.data.option.id,
        names: this.items.map(x => x.name)
      };

      await firstValueFrom(this._apollo.mutate<CreateOptionItemsMutationRoot>({
        mutation: gql`
          ${FULL_FRAGMENT_OPTION}
          ${FULL_FRAGMENT_OPTION_ITEM}
          mutation CreateOptionItems($optionId: String!, $names: [String!]!) {
            createOptionItems(optionId: $optionId, names: $names) {
              ...FullFragmentOption

              optionItems {
                ...FullFragmentOptionItem
              }
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only'
      }));

      this._dialogRef.close();
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
    } finally {
      this.activity = false;
    }
  }

  // #endregion Public Methods


  // #region Private Methods

  private _filter(name: string): OptionItem[] {
    const filterValue = name.toLowerCase();

    const allItems = [...this._alreadyAvailableOptionItems, ...this.items].sortBy(x => x.name);
    return allItems.filter(x => x.name.toLowerCase().includes(filterValue));
  }


  // #endregion Private Methods

}
