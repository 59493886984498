import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowClassRules,
  SelectionChangedEvent
} from 'ag-grid-community';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { OUserWrapper } from 'projects/shared/src/lib/classes/oUserWrapper';
import {
  CreateUserRolesMutationArgs,
  CreateUserRolesMutationRoot,
  DeleteUserRoleMutationArgs,
  DeleteUserRoleMutationRoot,
  DeleteUserRolesMutationArgs,
  DeleteUserRolesMutationRoot,
  UserRolesQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/userRole';
import { FULL_FRAGMENT_USER_ROLE } from 'projects/shared/src/lib/graphql/fragments/fullFragmentUserRole';
import { UserRoleOutput } from 'projects/shared/src/lib/graphql/output/userRoleOutput';
import { UserSelectService } from 'projects/shared/src/lib/services/user-select.service';
import {firstValueFrom, Subject, takeUntil} from 'rxjs';
import { UserRoleRendererComponent } from '../../component-helpers/ag/user-role-renderer/user-role-renderer.component';
import { RoleId } from 'projects/shared/src/lib/graphql/output/roleOutput';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { UserConfiguration, UserConfigurationFactory } from './userConfiguration';
import { TenantQueryArgs, TenantQueryRoot } from 'projects/shared/src/lib/graphql/crud/tenant';
import { SelectionService } from '../../services/selection.service';
import { FULL_FRAGMENT_PROPERTY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentProperty';
import { PropertyLevel } from 'projects/shared/src/lib/graphql/enums/propertyLevel';
import { PropertyOutput } from 'projects/shared/src/lib/graphql/output/propertyOutput';
import { PropertyType } from 'projects/shared/src/lib/graphql/enums/propertyType';
import { UserConstraintWrapper } from './userConstraintWrapper';
import {
  CreateUserConstraintsMutation,
  CreateUserConstraintsMutationArgs,
  DeleteUserConstraintsMutationArgs,
  DeleteUserConstraintsMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/userConstraint';
import { FULL_FRAGMENT_USER_CONSTRAINT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentUserConstraint';
import { HttpClient } from '@angular/common/http';

export class ExtUserRole {
  userRole: UserRoleOutput;

  get oUserWrapper(): OUserWrapper {
    if (typeof this._oUserWrapper === 'undefined') {
      this._oUserWrapper = this._userSelectService.getOUserWrapperByOId(this.userRole.userOid, this.userRole);
    }
    return this._oUserWrapper;
  }

  private _oUserWrapper: OUserWrapper | undefined;

  constructor(userRole: UserRoleOutput, private _userSelectService: UserSelectService) {
    this.userRole = userRole;
  }
}

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
  providers: [UserConfigurationFactory],
})
export class SecurityComponent implements OnInit, OnDestroy {
  loading = false;
  errorMessage: string | undefined;
  extUserRoles: ExtUserRole[] = [];
  activity = false;

  selectedUserConfiguration: UserConfiguration | undefined;
  selectedUserRole: UserRoleOutput | undefined;
  properties: PropertyOutput[] = [];

  get selectedOId(): string | undefined {
    return this._selectedOId;
  }
  set selectedOId(value) {
    this._selectedOId = value;

    if (!value) {
      this.selectedUserConfiguration = undefined;
      return;
    }
    this.selectedUserConfiguration = this.userConfigurationFactory.create(value);
  }

  get overallNoOfUsers(): number {
    return new Set(this.extUserRoles.map((x) => x.userRole.userOid)).size;
  }

  get noOfUsersInRoleAssetUser(): number {
    return new Set(
      this.extUserRoles
        .filter((x) => x.userRole.roleId === RoleId.ASSET_USER)
        .map((x) => x.userRole.userOid)
    ).size;
  }

  get noOfUsersInRoleAssetReader(): number {
    return new Set(
      this.extUserRoles
        .filter((x) => x.userRole.roleId === RoleId.ASSET_READER)
        .map((x) => x.userRole.userOid)
    ).size;
  }

  get noOfUsersInRoleAssetManager(): number {
    return new Set(
      this.extUserRoles
        .filter((x) => x.userRole.roleId === RoleId.ASSET_MANAGER)
        .map((x) => x.userRole.userOid)
    ).size;
  }

  get noOfUsersInRoleAssetArchitect(): number {
    return new Set(
      this.extUserRoles
        .filter((x) => x.userRole.roleId === RoleId.ASSET_ARCHITECT)
        .map((x) => x.userRole.userOid)
    ).size;
  }

  get noOfUsersInRoleTenantAdmin(): number {
    return new Set(
      this.extUserRoles
        .filter((x) => x.userRole.roleId === RoleId.TENANT_ADMIN)
        .map((x) => x.userRole.userOid)
    ).size;
  }

  get noOfUsersInRoleTenantOwner(): number {
    return new Set(
      this.extUserRoles
        .filter((x) => x.userRole.roleId === RoleId.TENANT_OWNER)
        .map((x) => x.userRole.userOid)
    ).size;
  }

  colDefs: ColDef[] = [
    {
      headerName: 'Display Name',
      field: 'dn',
      cellRenderer: UserRoleRendererComponent,
      cellRendererParams: {
        propertyName: 'displayName',
      },
      filterValueGetter: (params) => {
        const extUserRole = params.data as ExtUserRole;
        return extUserRole.oUserWrapper.oUser?.displayName;
      },
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        const oUserA = nodeA.data.oUserWrapper.oUser;
        const oUserB = nodeB.data.oUserWrapper.oUser;
        const aDisplayName = oUserA?.displayName as string | undefined | null;
        const bDisplayName = oUserB?.displayName as string | undefined | null;

        if (!aDisplayName && !bDisplayName) {
          return 0;
        }

        if (!aDisplayName) {
          return 1;
        }

        if (!bDisplayName) {
          return -1;
        }

        return aDisplayName.localeCompare(bDisplayName);
      },
      sortable: true,
    },
    {
      headerName: 'Email',
      cellRenderer: UserRoleRendererComponent,
      cellRendererParams: {
        propertyName: 'mail',
      },
      filterValueGetter: (params) => {
        const extUserRole = params.data as ExtUserRole;
        return extUserRole.oUserWrapper.oUser?.mail;
      },
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        const oUserA = nodeA.data.oUserWrapper.oUser;
        const oUserB = nodeB.data.oUserWrapper.oUser;
        const aMail = oUserA?.mail as string | undefined | null;
        const bMail = oUserB?.mail as string | undefined | null;
        if (!aMail && !bMail) {
          return 0;
        }

        if (!aMail) {
          return 1;
        }

        if (!bMail) {
          return -1;
        }

        return aMail.localeCompare(bMail);
      },
    },
    {
      headerName: 'Job Title',
      cellRenderer: UserRoleRendererComponent,
      cellRendererParams: {
        propertyName: 'jobTitle',
      },
      filterValueGetter: (params) => {
        const extUserRole = params.data as ExtUserRole;
        return extUserRole.oUserWrapper.oUser?.jobTitle;
      },
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        const oUserA = nodeA.data.oUserWrapper.oUser;
        const oUserB = nodeB.data.oUserWrapper.oUser;
        const aJobTitle = oUserA?.jobTitle as string | undefined | null;
        const bJobTitle = oUserB?.jobTitle as string | undefined | null;

        if (!aJobTitle && !bJobTitle) {
          return 0;
        }

        if (!aJobTitle) {
          return 1;
        }

        if (!bJobTitle) {
          return -1;
        }

        return aJobTitle.localeCompare(bJobTitle);
      },
    },
    {
      headerName: 'Role',
      field: 'userRole.role.name',
    },
  ];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: 'agTextColumnFilter',
    resizable: true,
    floatingFilter: true,
    // filterParams: {
    //   buttons: ['clear', 'apply'],
    // },
  };

  private _gridApi: GridApi | undefined;
  private _selectedOId: string | undefined;
  public rowClassRules: RowClassRules<any> | undefined = {
    'ag-row-red': (params) => {
      return params.data.oUserWrapper.isError
    },
  }

  private destroy$ = new Subject<void>();


  constructor(
    private _apollo: Apollo,
    private _userSelectService: UserSelectService,
    private _toastService: DesktopToastService,
    private userConfigurationFactory: UserConfigurationFactory,
    private _selectionService: SelectionService,
    private _http: HttpClient
  ) {
    this._userSelectService.refresh$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userId: string) => {
      if (this._gridApi) {
        const id = this.extUserRoles.findIndex((x) => x.userRole.userOid === userId);
        const rowNode = this._gridApi.getRowNode(id.toString());
        if (rowNode) {
          this._gridApi.redrawRows({ rowNodes: [rowNode] })
        }
      }
    })
  }

  ngOnInit(): void {
    this._loadData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onClickApply() {
    if (!this.selectedUserConfiguration) {
      return;
    }

    const config = this.selectedUserConfiguration;

    const deleteRoleIds = config.deleteRoleIds;
    const createRoleIds = config.createRoleIds;

    if (
      deleteRoleIds.length === 0 &&
      createRoleIds.length === 0 &&
      config.constraints.every((x) => !x.needsSaving)
    ) {
      return;
    }

    try {
      this.activity = true;

      if (deleteRoleIds.length > 0) {
        // Determine the userRoleIds to be deleted.
        const userRoleIds = this.extUserRoles
          .filter(
            (x) => x.userRole.userOid === config.oId && deleteRoleIds.includes(x.userRole.roleId)
          )
          .map((x) => x.userRole.id);

        const variables: DeleteUserRolesMutationArgs = {
          ids: userRoleIds,
        };

        await firstValueFrom(
          this._apollo.mutate<DeleteUserRolesMutationRoot>({
            mutation: gql`
              mutation DeleteUserRoles($ids: [String!]!) {
                deleteUserRoles(ids: $ids)
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );

        // Update extUserRoles
        userRoleIds.forEach((userRoleId) => {
          const index = this.extUserRoles.findIndex((x) => x.userRole.id === userRoleId);
          if (index !== -1) {
            this.extUserRoles.splice(index, 1);
          }
        });
      }

      if (createRoleIds.length > 0) {
        const variables: CreateUserRolesMutationArgs = {
          data: [],
        };

        createRoleIds.forEach((x) =>
          variables.data.push({
            roleId: x,
            userOid: config.oId,
          })
        );

        const result = await firstValueFrom(
          this._apollo.mutate<CreateUserRolesMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_USER_ROLE}
              mutation CreateUserRoles($data: [UserRoleInputCreate!]!) {
                createUserRoles(data: $data) {
                  ...FullFragmentUserRole
                  role {
                    id
                    name
                  }
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
          })
        );

        for (let userRoleOutput of result.data?.createUserRoles ?? []) {
          this.extUserRoles.push(new ExtUserRole(userRoleOutput, this._userSelectService));
        }
      }

      const deletedUserConstraintIds = config.constraints
        .filter((x) => x.isDeleted && x.original)
        .map((x) => x.original?.id) as string[];
      if (deletedUserConstraintIds.length > 0) {
        const variablesDelete: DeleteUserConstraintsMutationArgs = {
          ids: deletedUserConstraintIds,
        };

        const resultDelete = await firstValueFrom(
          this._apollo.mutate<DeleteUserConstraintsMutationRoot>({
            mutation: gql`
              mutation DeleteUserConstraints($ids: [String!]!) {
                deleteUserConstraints(ids: $ids)
              }
            `,
            variables: variablesDelete,
            fetchPolicy: 'network-only',
          })
        );
      }

      const addedUserConstraints = config.constraints.filter((x) => x.isNew);
      if (addedUserConstraints.length > 0) {
        const variablesAdded: CreateUserConstraintsMutationArgs = {
          data: [],
        };
        for (const constraint of addedUserConstraints) {
          if (!constraint.property || !constraint.value) {
            continue;
          }

          variablesAdded.data.push({
            propertyId: constraint.property.id,
            value: constraint.value,
            userOid: config.oId,
          });
        }

        const resultCreate = await firstValueFrom(
          this._apollo.mutate<CreateUserConstraintsMutation>({
            mutation: gql`
              ${FULL_FRAGMENT_USER_CONSTRAINT}
              mutation CreateUserConstraints($data: [UserConstraintInputCreate!]!) {
                createUserConstraints(data: $data) {
                  ...FullFragmentUserConstraint
                }
              }
            `,
            variables: variablesAdded,
            fetchPolicy: 'network-only',
          })
        );
      }

      // Force a redraw of the table.
      this.extUserRoles = Array.from(this.extUserRoles);
    } catch (error) {
      const message = new CatchError(error).message;
      this._toastService.error(message, 'Error');
    } finally {
      this.activity = false;

      // Make sure that the role configuration is re-evaluated.
      this.selectedOId = this.selectedOId;
    }
  }

  onGridReady(params: GridReadyEvent) {
    this._gridApi = params.api;
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selected = event.api.getSelectedRows()[0] as ExtUserRole | undefined;
    if (!selected) {
      this.selectedOId = '';
    } else {
      this.selectedOId = selected.userRole.userOid;
    }

    this.selectedUserRole = selected?.userRole;
  }

  async onClickDeleteUserRole(userRole: UserRoleOutput) {
    this.activity = true;
    try {
      const variables: DeleteUserRoleMutationArgs = {
        id: userRole.id,
      };

      await firstValueFrom(
        this._apollo.mutate<DeleteUserRoleMutationRoot>({
          mutation: gql`
            mutation DeleteUserRole($id: String!) {
              deleteUserRole(id: $id) {
                id
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      const index = this.extUserRoles.findIndex((x) => x.userRole.id === userRole.id);
      if (index !== -1) {
        this.extUserRoles.splice(index, 1);
      }

      // Force a redraw of the table.
      this.extUserRoles = Array.from(this.extUserRoles);
      this.selectedUserRole = undefined;
    } catch (error) {
      const message = new CatchError(error).message;
      this._toastService.error(message, 'Error');
    } finally {
      this.activity = false;

      // Make sure that the role configuration is re-evaluated.
      this.selectedOId = this.selectedOId;
    }
  }

  addNewConstraint() {
    if (!this.selectedUserConfiguration) {
      return;
    }

    this.selectedUserConfiguration.constraints.push(
      new UserConstraintWrapper(this._http, this._selectionService.selectedTenant?.id ?? 'na')
    );
  }

  private async _loadData() {
    this.loading = true;
    try {
      const result = await firstValueFrom(
        this._apollo.query<UserRolesQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_USER_ROLE}
            query UserRoles {
              userRoles {
                ...FullFragmentUserRole
                role {
                  id
                  name
                }
              }
            }
          `,
          fetchPolicy: 'network-only',
        })
      );

      const extUserRoles: ExtUserRole[] = [];
      for (let x of result.data.userRoles.sortBy((y) => y.userOid)) {
        extUserRoles.push(new ExtUserRole(x, this._userSelectService));
      }
      this.extUserRoles = extUserRoles;

      const variables: TenantQueryArgs = {
        id: this._selectionService.selectedTenant?.id ?? 'na',
      };

      const tenantResult = await firstValueFrom(
        this._apollo.query<TenantQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PROPERTY}
            query Tenant($id: String!) {
              tenant(id: $id) {
                id
                properties {
                  ...FullFragmentProperty
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'cache-first',
        })
      );

      const systemProperties =
        tenantResult.data.tenant.properties
          ?.filter((x) => x.levelId === PropertyLevel.System && x.typeId === PropertyType.Text)
          .sortBy((x) => x.displayName) ?? [];

      const tenantProperties =
        tenantResult.data.tenant.properties
          ?.filter((x) => x.levelId === PropertyLevel.Tenant && x.typeId === PropertyType.Text)
          .sortBy((x) => x.displayName) ?? [];

      const assetTypeProperties =
        tenantResult.data.tenant.properties
          ?.filter((x) => x.levelId === PropertyLevel.Asset && x.typeId === PropertyType.Text)
          .sortBy((x) => x.displayName) ?? [];

      this.properties = [...systemProperties, ...tenantProperties, ...assetTypeProperties];
      console.log(this.properties);
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
    } finally {
      this.loading = false;
    }
  }

  deleteConstraint(constraint: UserConstraintWrapper) {
    if (!constraint.isNew) {
      constraint.delete(); // Mark it as "to be deleted".
      return;
    }

    // It is a new constraint (not saved). We can delete it from the list.
    if (!this.selectedUserConfiguration) {
      return;
    }

    const index = this.selectedUserConfiguration.constraints.findIndex(
      (x) => x.uuid === constraint.uuid
    );

    if (!index || index === -1) {
      return; // Nothing found. Strange.
    }

    this.selectedUserConfiguration.constraints.splice(index, 1);
  }
}
