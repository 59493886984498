import { Component, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-fluid-panel',
  templateUrl: './fluid-panel.component.html',
  styleUrls: ['./fluid-panel.component.scss'],
})
export class FluidPanelComponent {
  @Input() icon = 'settings';
  isContentVisible = false;

  @HostListener('document:keydown.control.shift ', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.toggle();
  }

  open() {
    this.isContentVisible = true;
  }

  close() {
    this.isContentVisible = false;
  }

  toggle() {
    this.isContentVisible = !this.isContentVisible;
  }
}
