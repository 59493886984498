<h2 mat-dialog-title>Add Option Items</h2>

<mat-dialog-content>
    <div class="flex-column form-field-density-1">
        <span class="mt-normal mat-body">
            Please provide a list of names that should be added.
        </span>

        <mat-form-field class="mt-normal">
            <mat-label>New Option Items</mat-label>
            <mat-chip-grid #chipGrid>
                <mat-chip-row *ngFor="let item of items" (removed)="removeItem(item)" [editable]="true"
                    (edited)="editItem(item, $event)">
                    {{item.name}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                <input #myinput placeholder="New item ..." [formControl]="newNameFormControl"
                    [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addItem($event)" [autocomplete]="uuid"
                    [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let optionItem of filteredOptionItems | async">
                        <span [innerHTML]="optionItem.name | highlight: myinput.value"></span>
                        <!-- {{optionItem.name}} -->
                    </mat-option>
                </mat-autocomplete>
            </mat-chip-grid>
        </mat-form-field>

        <div class="error-message">
            {{errorMessage}}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close [disabled]="activity">Cancel</button>
    <button (click)="create()" mat-flat-button color="primary" [disabled]="items.length === 0 || activity">
        <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
        <span> Create </span>
    </button>
</mat-dialog-actions>