<mat-dialog-content>
  <div class="flex-column" *ngIf="data.oUser as oUser">
    <span class="font-weight-500 color-black font-size-20">{{
      oUser.displayName
    }}</span>
    <span class="color-black mb-normal">{{ oUser.jobTitle }}</span>

    <!-- Email -->
    <div *ngIf="oUser.mail" class="info-grid mb-tiny">
      <span class="material-symbols-outlined"> mail </span>
      <a href="mailto:{{ oUser.mail }}" class="a-email"> {{ oUser.mail }}</a>
    </div>

    <!-- Mobile Phone -->
    <div *ngIf="oUser.mobilePhone" class="info-grid mb-tiny">
      <span class="material-symbols-outlined"> call </span>
      <!-- <span>{{ oUser.mobilePhone }}</span> -->
      <a href="tel:{{ oUser.mobilePhone }}" class="a-tel">{{
        oUser.mobilePhone
      }}</a>
    </div>

    <!-- Office Location -->
    <div *ngIf="oUser.officeLocation" class="info-grid mb-tiny">
      <span class="material-symbols-outlined"> location_on </span>
      <span class="font-size-14">{{ oUser.officeLocation }}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" matDialogClose>Ok</button>
</mat-dialog-actions>
