import { EventEmitter, Injectable } from '@angular/core';
import { PlanAssetOutput } from 'projects/shared/src/lib/graphql/output/planAssetOutput';
import { AssetDefectSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/assetDefectSubNotification';
import { AssetMissingSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/assetMissingSubNotification';
import { BookingSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/bookingSubNotification';
import { InventoryAssetsSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/inventoryAssetsSubNotification';
import { InventorySubNotification } from 'projects/shared/src/lib/graphql/subNotifications/inventorySubNotification';
import { PlanSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/planSubNotification';
import { ReturnToCustomerAssetSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerAssetSubNotification';
import { ReturnToCustomerNoteSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerNoteSubNotification';
import { ReturnToCustomerSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerSubNotification';

export enum LocalEventType {
  AssetDefect,
  AssetMissing,
  BookingRealtime,
  BookingPlanned,
  Inventory,
  InventoryAssetsCreated,
  InventoryAssetsDeleted,
  PlanAssetDeleted,
  PlanAssetsAdded,
  PlanDeleted,
  ReturnToCustomer,
  ReturnToCustomerAsset,
  ReturnToCustomerNote,
  Plan,
  // Intention Events
  FilterPlanAssetsInAssetsTable,
}


export type LocalEventData_PlanAssetDeleted = {
  planId: string,
  tenantAssetId: string;
}

export type LocalEventData_PlanAssetsAdded = {
  planId: string,
  planAssets: PlanAssetOutput[];
}

export type LocalEventData_AssetDefect = AssetDefectSubNotification;
export type LocalEventData_AssetMissing = AssetMissingSubNotification;
export type LocalEventData_BookingPlanned = BookingSubNotification;
export type LocalEventData_BookingRealtime = BookingSubNotification;
export type LocalEventData_InventoryAssetsCreated = InventoryAssetsSubNotification;
export type LocalEventData_InventoryAssetsDeleted = InventoryAssetsSubNotification;
export type LocalEventData_Inventory = InventorySubNotification;
export type LocalEventData_ReturnToCustomerNote = ReturnToCustomerNoteSubNotification;
export type LocalEventData_ReturnToCustomer = ReturnToCustomerSubNotification;
export type LocalEventData_ReturnToCustomerAsset = ReturnToCustomerAssetSubNotification;
export type LocalEventData_Plan = PlanSubNotification;

export type LocalEvent = {
  type: LocalEventType;
  data: any;
};

@Injectable({
  providedIn: 'root',
})
export class LocalEventService {
  newEvent = new EventEmitter<LocalEvent>();

  constructor() {}

  emitNewEvent(type: LocalEventType, data: any) {
    this.newEvent.next({
      type,
      data,
    });
  }
}
