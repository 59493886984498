import { Injectable } from '@angular/core';
import { IRowNode } from 'ag-grid-community';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { BaseSelectionService } from 'projects/shared/src/lib/services/base-selection.service';
import { DESKTOP_LOCAL_STORAGE } from '../common/localStorage';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import { ReturnToCustomerOutput } from 'projects/shared/src/lib/graphql/output/returnToCustomerOutput';

export class AssetsView {
  get showPlanBuilder(): boolean {
    if (typeof this.#showPlanBuilder === 'undefined') {
      this.#load();
    }

    return this.#showPlanBuilder ?? false;
  }
  set showPlanBuilder(value) {
    if (this.#showPlanBuilder === value) {
      return;
    }

    this.#showPlanBuilder = value;
    this.#persist();
  }

  get showPlans(): boolean {
    if (typeof this.#showPlans === 'undefined') {
      this.#load();
    }

    return this.#showPlans ?? false;
  }
  set showPlans(value) {
    if (this.#showPlans === value) {
      return;
    }

    this.#showPlans = value;
    this.#persist();
  }

  get showAssets(): boolean {
    if (typeof this.#showAssets === 'undefined') {
      this.#load();
    }
    return this.#showAssets ?? false;
  }
  set showAssets(value) {
    if (this.#showAssets === value) {
      return;
    }
    this.#showAssets = value;
    this.#persist();
  }

  #showPlanBuilder: boolean | undefined;
  #showPlans: boolean | undefined;
  #showAssets: boolean | undefined;

  zenMode(destination: 'plan-builder' | 'plans' | 'assets') {
    switch (destination) {
      case 'plan-builder':
        this.#showPlanBuilder = true;
        this.#showPlans = false;
        this.#showAssets = false;
        this.#persist();
        break;

      case 'plans':
        this.#showPlanBuilder = false;
        this.#showPlans = true;
        this.#showAssets = false;
        this.#persist();
        break;

      case 'assets':
        this.#showPlanBuilder = false;
        this.#showPlans = false;
        this.#showAssets = true;
        this.#persist();
        break;

      default:
        break;
    }
  }

  #persist() {
    const data = {
      showPlanBuilder: this.#showPlanBuilder,
      showPlans: this.#showPlans,
      showAssets: this.#showAssets,
    };

    localStorage.setItem(DESKTOP_LOCAL_STORAGE.ASSET_VIEWS, JSON.stringify(data));
    sessionStorage.setItem(DESKTOP_LOCAL_STORAGE.ASSET_VIEWS, JSON.stringify(data));
  }

  #load() {
    const sessionStorageString = sessionStorage.getItem(DESKTOP_LOCAL_STORAGE.ASSET_VIEWS);
    if (sessionStorageString) {
      // This is due to a reload of the site in a browser window/tab.
      const { showPlanBuilder, showPlans, showAssets } = JSON.parse(sessionStorageString);
      this.#showPlanBuilder = showPlanBuilder;
      this.#showPlans = showPlans;
      this.#showAssets = showAssets;
    } else {
      // First run (no session data available).
      const localStorageString = localStorage.getItem(DESKTOP_LOCAL_STORAGE.ASSET_VIEWS);

      if (localStorageString === null) {
        this.#showPlanBuilder = false;
        this.#showPlans = false;
        this.#showAssets = true;
        return;
      }

      const { showPlanBuilder, showPlans, showAssets } = JSON.parse(localStorageString);
      this.#showPlanBuilder = showPlanBuilder;
      this.#showPlans = showPlans;
      this.#showAssets = showAssets;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class SelectionService extends BaseSelectionService {
  selectedPlan: PlanOutput | undefined;
  selectedAssets: any[] = [];
  selectedNodesInAssetsTable: IRowNode[] = [];
  selectedInventory: InventoryOutput | undefined;
  selectedReturnToCustomer: ReturnToCustomerOutput | undefined;

  assetsView = new AssetsView();
}
