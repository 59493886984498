import { Component } from '@angular/core';

@Component({
  selector: 'lib-expander-header',
  templateUrl: './expander-header.component.html',
  styleUrls: ['./expander-header.component.scss']
})
export class ExpanderHeaderComponent {

}
