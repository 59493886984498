<h2 mat-dialog-title>New Asset Type</h2>

<mat-dialog-content>
    <div class="flex-column">
        <span class="mt-normal mat-body">
            Please provide a name for the new asset type. It will only be visible to Asset Architects.
        </span>

        <mat-form-field class="mt-normal" appearance="fill">
            <mat-label>Name</mat-label>
            <input #assetNameInput matInput [formControl]="name" [autocomplete]="uuid" [matAutocomplete]="auto"
                (keyup.enter)="create()">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let assetName of filteredAssetNames | async">
                    <span [innerHTML]="assetName | highlight: assetNameInput.value"></span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="error-message">
            {{errorMessage}}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close [disabled]="activity">Cancel</button>
    <button (click)="create()" mat-flat-button color="primary" [disabled]="!canApply || activity">
        <span *ngIf="!activity"> Create </span>
        <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
    </button>
</mat-dialog-actions>