<h2 mat-dialog-title>Add Assets</h2>

<mat-dialog-content>
    <div class="add-assets-grid">
        <!-- CONTENT -->
        <div class="content form-field-density-1">
            <mat-form-field>
                <mat-label>Plan</mat-label>
                <input matInput [ngModel]="data.plan.name" [readonly]="true">
            </mat-form-field>

            <div class="grid-2">
                <mat-form-field>
                    <mat-label>Start</mat-label>
                    <input matInput [ngModel]="data.plan.planStart | date:localeService.datePipeString(locale)"
                        [readonly]="true">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>End</mat-label>
                    <input matInput [ngModel]="data.plan.planEnd | date:localeService.datePipeString(locale)"
                        [readonly]="true">
                </mat-form-field>
            </div>

            <ul class="mat-body ul-adapt">
                <mtx-popover #popover="mtxPopover" [yOffset]="8" [position]="['after', 'above']">
                    <ng-template mtxPopoverContent let-plans="plans">
                        <div class="flex-column font-size-14">
                            <span class="mb-tiny">The asset is included in the following plan(s):</span>
                            <div *ngFor="let plan of plans">
                                - {{plan.name}} ({{plan.planStart | date:localeService.datePipeString(locale)}} &minus;
                                {{plan.planEnd | date:localeService.datePipeString(locale)}})
                            </div>
                        </div>
                    </ng-template>
                </mtx-popover>

                <li *ngFor="let x of alreadyIncludedAssets">
                    <div class="flex-row gap-normal">
                        <span>{{getTenantAssetUniqueId(x)}}</span>
                        <span class="details">
                            already included
                        </span>
                    </div>
                </li>

                <li *ngFor="let x of conflictingAssets | keyvalue">
                    <div class="flex-row gap-normal">
                        <span>{{getTenantAssetUniqueId(x.key)}}</span>
                        <span class="details" #popoverTrigger="mtxPopoverTrigger" [mtxPopoverTriggerFor]="popover"
                            [mtxPopoverTriggerData]="{plans: x.value}">
                            conflict(s): {{x.value.length}}
                        </span>
                    </div>
                </li>

                <li *ngFor="let x of okAssets">
                    <span>{{getTenantAssetUniqueId(x)}}</span>
                </li>
            </ul>

            <span class="mat-body mt-normal">
                You can add <b>{{okAssets.length}}</b> of the provided <b>{{data.assets.length}}</b> assets.
            </span>

            <div *ngIf="errorMessage" class="error-message mt-normal">
                {{errorMessage}}
            </div>
        </div>

        <!-- OVERLAY -->
        <div class="overlay" *ngIf="loading">
            <lib-spinner></lib-spinner>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close [disabled]="activity">Cancel</button>
    <button (click)="apply()" mat-flat-button color="primary" [disabled]="!canApply || activity">
        <span *ngIf="!activity"> Add </span>
        <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
    </button>
</mat-dialog-actions>