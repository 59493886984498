import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_PLAN = gql`
  fragment FullFragmentPlan on PlanOutput {
    id
    tenantId
    name
    planStart
    planEnd
    description
    responsibleId
    createdBy
    createdAt
  }
`;
