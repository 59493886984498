import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gat-card-header',
  templateUrl: './gat-card-header.component.html',
  styleUrls: ['./gat-card-header.component.scss']
})
export class GatCardHeaderComponent {
  @Input() headerName: string | undefined;
  
  /** Defaults to 'false'. Set to 'true' if you want to hide the header. */
  @Input() hideHeader: boolean = false;
}
