import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import {
  BookPlanStepActionDialogComponent,
  BookPlanStepActionDialogData,
  BookPlanStepActionDialogResult,
} from 'projects/desktop/src/app/component-dialogs/book-plan-step-action-dialog/book-plan-step-action-dialog.component';
import {
  CreateOrEditPlanStepActionDialogComponent,
  CreateOrEditPlanStepActionDialogData,
} from 'projects/desktop/src/app/component-dialogs/create-or-edit-plan-step-action-dialog/create-or-edit-plan-step-action-dialog.component';
import {
  ShowQrcodeDialogComponent,
  ShowQrcodeDialogData,
} from 'projects/desktop/src/app/component-dialogs/show-qrcode-dialog/show-qrcode-dialog.component';
import { ConfirmService } from 'projects/desktop/src/app/services/confirm.service';
import { NotificationService } from 'projects/desktop/src/app/services/notificationService/notification.service';
import {
  RemoteEventData_BookingPlanned,
  RemoteEventType,
} from 'projects/desktop/src/app/services/remote-event.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { PlanStepActionHelper } from 'projects/shared/src/lib/classes/planStepActionHelper';
import {
  DeletePlanStepActionMutationArgs,
  DeletePlanStepActionMutationRoot,
  PlanStepActionsQueryArgs,
  PlanStepActionsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planStepAction';
import { ActionType } from 'projects/shared/src/lib/graphql/enums/actionType';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { UserSelectService } from 'projects/shared/src/lib/services/user-select.service';
import { ActionAssetsWrapper } from 'projects/shared/src/public-api';
import { Observable, Subscription, firstValueFrom, from, of, map } from 'rxjs';

const PlanStepActionsQuery = gql`
  ${FULL_FRAGMENT_PLAN_STEP_ACTION}
  query PlanStepActions($planId: String!, $planStepId: String!) {
    planStepActions(planId: $planId, planStepId: $planStepId) {
      ...FullFragmentPlanStepAction
    }
  }
`;

@Component({
  selector: 'app-plan-step-actions',
  templateUrl: './plan-step-actions.component.html',
  styleUrls: ['./plan-step-actions.component.scss'],
})
export class PlanStepActionsComponent implements OnInit, OnDestroy {
  @Input() plan!: PlanOutput;
  @Input() planStep!: PlanStepOutput;

  planStepActions: PlanStepActionOutput[] = [];
  selectedPlanStepAction: PlanStepActionOutput | undefined;

  loading = false;
  errorMessage: string | undefined;
  actionType = ActionType;

  planStepActionHelper = PlanStepActionHelper;
  actionAssetsInfo = new Map<string, ActionAssetsWrapper>(); // string = planStepActionId

  private _planStepActionsQuery: QueryRef<PlanStepActionsQueryRoot> | undefined;
  private _planStepActionsSubscription: Subscription | undefined;
  #notificationRemoteSubscription: Subscription | undefined;

  constructor(
    private _apollo: Apollo,
    private _matDialog: MatDialog,
    private _confirmService: ConfirmService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this._loadData();

    this._notificationService.handleRemoteEvents([RemoteEventType.BookingPlanned]);
    this.#notificationRemoteSubscription = this._notificationService.remoteEventHandled.subscribe(
      (value) => {
        if (value[0] === RemoteEventType.BookingPlanned) {
          const eventData = value[1] as RemoteEventData_BookingPlanned;
          const actionIds = eventData.data?.map((x) => x.bookingData.planStepActionId) ?? [];
          const uniqueActionIds = Array.from(new Set(actionIds.filter((x) => !!x) as string[]));
          for (const actionId of uniqueActionIds) {
            const wrapper = this.actionAssetsInfo.get(actionId);
            if (!wrapper) {
              return;
            }
            wrapper.loadingState = 0; // Trigger a reload of the status.
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._planStepActionsSubscription?.unsubscribe();

    this._notificationService.unhandleRemoteEvents([RemoteEventType.BookingPlanned]);
    this.#notificationRemoteSubscription?.unsubscribe();
  }

  bookAction(action: PlanStepActionOutput) {
    const data: BookPlanStepActionDialogData = {
      action,
      plan: this.plan,
    };

    const dialog = this._matDialog.open(BookPlanStepActionDialogComponent, {
      autoFocus: false,
      minWidth: 600,
      maxWidth: 600,
      data,
    });

    dialog.afterClosed().subscribe(() => {
      const wrapper = this.actionAssetsInfo.get(action.id);
      if (!wrapper) {
        return;
      }
      wrapper.loadingState = 0; // Trigger a reload of the status.
    });
  }

  showQRCode(text: string) {
    const data: ShowQrcodeDialogData = {
      items: [text],
    };
    this._matDialog.open(ShowQrcodeDialogComponent, {
      data,
    });
  }

  getActionAssetsInfo(planStepActionId: string): ActionAssetsWrapper | undefined {
    return this.actionAssetsInfo.get(planStepActionId);
  }

  onClickCreateOrEditPlanAction(
    planStep: PlanStepOutput,
    actionType: ActionType | undefined,
    beforeActionIndex: number | undefined,
    afterActionIndex: number | undefined,
    currentActionIndex: number | undefined
  ) {
    let beforeAction: PlanStepActionOutput | undefined;
    if (typeof beforeActionIndex !== 'undefined' && this.planStepActions) {
      beforeAction = this.planStepActions[beforeActionIndex];
    }

    let afterAction: PlanStepActionOutput | undefined;
    if (typeof afterActionIndex !== 'undefined' && this.planStepActions) {
      afterAction = this.planStepActions[afterActionIndex];
    }

    let currentAction: PlanStepActionOutput | undefined;
    if (typeof currentActionIndex !== 'undefined' && this.planStepActions) {
      currentAction = this.planStepActions[currentActionIndex];
    }

    const data: CreateOrEditPlanStepActionDialogData = {
      planStep,
      actionTypeId: actionType,
      beforeAction,
      afterAction,
      currentAction,
    };

    const dialog = this._matDialog.open(CreateOrEditPlanStepActionDialogComponent, {
      autoFocus: false,
      data,
      width: '680px',
    });

    dialog.afterClosed().subscribe((planStepAction: PlanStepActionOutput | undefined) => {
      if (!planStepAction) {
        return;
      }

      if (data.currentAction) {
        // It was an EDIT.
        // Do a refetch just to be sure in case the date was changed.
        this._planStepActionsQuery?.refetch();
        return;
      }

      // It was a CREATE.
      // Update the cache (add the newly created record to the query "planStepActions").
      const variables: PlanStepActionsQueryArgs = {
        planId: this.plan.id,
        planStepId: this.planStep.id,
      };

      const cachedPlanStepActions = this._apollo.client.readQuery<PlanStepActionsQueryRoot>({
        query: PlanStepActionsQuery,
        variables,
      })?.planStepActions;

      if (typeof cachedPlanStepActions === 'undefined') {
        return;
      }

      const newArray = [...cachedPlanStepActions, planStepAction];
      newArray.sort((a, b) => {
        if (new Date(a.date) > new Date(b.date)) {
          return 1;
        }

        if (new Date(a.date) < new Date(b.date)) {
          return -1;
        }
        return 0;
      });

      this._apollo.client.writeQuery<PlanStepActionsQueryRoot>({
        query: PlanStepActionsQuery,
        variables,
        data: {
          planStepActions: newArray,
        },
      });
    });
  }

  async onClickDeletePlanStepAction(
    planStepAction: PlanStepActionOutput,
    planStep: PlanStepOutput
  ) {
    this._confirmService.open(
      'Please confirm',
      `Do you really want to delete the action '<b>${this.planStepActionHelper.getActionName(
        planStepAction,
        'uppercase'
      )}</b>'
      on the step '<b>${planStep.name}</b>'`,
      async () => {
        const variables: DeletePlanStepActionMutationArgs = {
          planStepActionId: planStepAction.id,
          planStepId: planStepAction.planStepId,
        };

        await firstValueFrom(
          this._apollo.mutate<DeletePlanStepActionMutationRoot>({
            mutation: gql`
              mutation DeletePlanStepAction($planStepId: String!, $planStepActionId: String!) {
                deletePlanStepAction(planStepId: $planStepId, planStepActionId: $planStepActionId) {
                  id
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              const variables: PlanStepActionsQueryArgs = {
                planId: this.plan.id,
                planStepId: this.planStep.id,
              };

              const cachedPlanStepActions = cache.readQuery<PlanStepActionsQueryRoot>({
                query: PlanStepActionsQuery,
                variables,
              })?.planStepActions;

              if (typeof cachedPlanStepActions === 'undefined') {
                return;
              }
              const index = cachedPlanStepActions.findIndex((x) => x.id === planStepAction.id);
              if (index === -1) {
                return;
              }

              const clonedArray = Array.from(cachedPlanStepActions);
              clonedArray.splice(index, 1);

              cache.writeQuery<PlanStepActionsQueryRoot>({
                query: PlanStepActionsQuery,
                variables,
                data: {
                  planStepActions: clonedArray,
                },
              });
            },
          })
        );
      },
      undefined,
      (ok) => {
        //
      },
      { maxWidth: '680px' }
    );
  }

  getActionFrom(planStepAction: PlanStepActionOutput): string | undefined | null {
    return (
      planStepAction.fromLocationId ??
      planStepAction.fromUserOid ??
      planStepAction.fromMail ??
      planStepAction.fromOther
    );
  }

  private _loadData() {
    this.loading = true;
    this.errorMessage = undefined;

    const variables: PlanStepActionsQueryArgs = {
      planId: this.plan.id,
      planStepId: this.planStep.id,
    };

    this._planStepActionsQuery = this._apollo.watchQuery<PlanStepActionsQueryRoot>({
      query: PlanStepActionsQuery,
      variables,
      fetchPolicy: 'cache-first',
    });

    this._planStepActionsSubscription = this._planStepActionsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (!loading) {
          this.planStepActions = data.planStepActions;
          this.loading = false;

          // Make sure that we have a handle on the booked actions for every planned action.
          for (let planStepAction of this.planStepActions) {
            if (!this.actionAssetsInfo.has(planStepAction.id)) {
              this.actionAssetsInfo.set(
                planStepAction.id,
                new ActionAssetsWrapper(this._apollo, planStepAction.id)
              );
            }
          }
        }
      },
      error: (error) => {
        this.loading = false;
        const message = new CatchError(error).message;
        this.errorMessage = message;
      },
    });
  }
}
