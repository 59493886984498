import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  CreatePlanMutationArgs,
  CreatePlanMutationRoot,
  PlansQueryArgs,
  PlansQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import { Apollo, gql } from 'apollo-angular';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { firstValueFrom } from 'rxjs';
import { SelectionService } from '../../services/selection.service';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { AppEventService } from '../../services/app-event.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { DesktopToastService } from '../../services/desktop-toast.service';
import {
  LocalEventData_Plan,
  LocalEventService,
  LocalEventType,
} from '../../services/local-event.service';
import { AppModule } from '../../app.module';

export type NewPlanDialogData = {
  assets: any[];
};

export type NewPlanDialogResult = {
  createdPlan: PlanOutput;
};

@Component({
  selector: 'app-new-plan-dialog',
  templateUrl: './new-plan-dialog.component.html',
  styleUrls: ['./new-plan-dialog.component.scss'],
})
export class NewPlanDialogComponent implements OnInit {
  // #region Public Properties

  name = new FormControl<string | null>(null, Validators.required);
  range = new FormGroup({
    start: new FormControl<DateTime | null>(null, Validators.required),
    end: new FormControl<DateTime | null>(null, Validators.required),
  });
  uuid = v4();
  loading = false;
  activity = false;
  errorMessage: string | undefined;
  minStartDate = DateTime.now().toJSDate();
  plans: PlanOutput[] = [];
  responsibleId: string | undefined;

  // If the asset is included in another plan, the value should
  // provide this relevant (conflicting) plan.
  conflictingAssets = new Map<any, PlanOutput[]>();
  okAssets: any[] = [];

  get canApply(): boolean {
    return this.range.valid && this.name.valid && this.okAssets.length > 0;
  }

  // #endregion Public Properties

  // #region Init

  constructor(
    private _dialogRef: MatDialogRef<NewPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewPlanDialogData,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _apollo: Apollo,
    private _selectionService: SelectionService,
    private _toastService: DesktopToastService,
    private _localeService: LocaleService,
    private localEventService: LocalEventService
  ) {
    // Initially, set all assets to be "not in conflict with another plan".
    this.okAssets = data.assets;
  }

  ngOnInit(): void {
    this._loadData();
  }

  // #endregion Init

  // #region Public Methods

  getDateFormatString() {
    switch (this._locale) {
      case 'en-US':
        return 'MM/DD/YYYY';

      case 'de-DE':
      case 'de':
        return 'DD.MM.YYYY';

      default:
        return this._locale;
    }
  }

  getDatePipeString() {
    return this._localeService.datePipeString(this._locale);
  }

  async apply() {
    if (this.range.invalid || this.name.invalid) {
      return;
    }

    try {
      this.activity = true;
      this.errorMessage = undefined;

      const adjustedPlanEndDate = (this.range.controls.end.value as DateTime)
        .endOf('day')
        .toJSDate();

      const variables: CreatePlanMutationArgs = {
        data: {
          tenantId: this._selectionService.selectedTenant?.id ?? 'na',
          name: this.name.value as string,
          planStart: (this.range.controls.start.value as DateTime).toJSDate().toISOString(),
          planEnd: adjustedPlanEndDate.toISOString(),
          assetIds: this.okAssets.map((x) => x.id),
          responsibleId: this.responsibleId,
        },
      };

      const result = await firstValueFrom(
        this._apollo.mutate<CreatePlanMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN}
            mutation CreatePlan($data: PlanInputCreate!) {
              createPlan(data: $data) {
                ...FullFragmentPlan
                planAssets {
                  id
                  tenantAssetId
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.createPlan) {
              return;
            }

            const eventData: LocalEventData_Plan = {
              filterSessionId: AppModule.sessionId,
              data: [
                {
                  action: 'created',
                  plan: data.createPlan,
                  relevantAssetIds: data.createPlan.planAssets?.map((x) => x.tenantAssetId) ?? [],
                },
              ],
            };
            this.localEventService.emitNewEvent(LocalEventType.Plan, eventData);
          },
        })
      );

      this._toastService.info('Plan created successfully.', 'Success');
      const returnValue: NewPlanDialogResult | undefined = result.data
        ? { createdPlan: result.data.createPlan }
        : undefined;
      this._dialogRef.close(returnValue);
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.activity = false;
    }
  }

  // toggleLocale() {
  //   this._locale = 'de';
  //   this._adapter.setLocale(this._locale);
  // }

  onDateChange(event: any) {
    if (this.range.invalid) {
      return;
    }
    this._evaluateConflicts();
  }

  cancel() {
    this._dialogRef.close(undefined);
  }

  // #endregion Public Methods

  private async _loadData() {
    try {
      this.loading = true;
      const variables: PlansQueryArgs = {
        pastIncluded: false,
        currentIncluded: true,
        futureIncluded: true,
      };

      const result = await firstValueFrom(
        this._apollo.query<PlansQueryRoot>({
          query: gql`
            query Plans(
              $pastIncluded: Boolean!
              $currentIncluded: Boolean!
              $futureIncluded: Boolean!
            ) {
              plans(
                pastIncluded: $pastIncluded
                currentIncluded: $currentIncluded
                futureIncluded: $futureIncluded
              ) {
                id
                name
                planStart
                planEnd
                planAssets {
                  id
                  tenantAssetId
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.plans = result.data.plans;
    } catch (error) {
      // TODO
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  private _evaluateConflicts() {
    const intendedStart = this.range.controls.start.value;
    const intendedEnd = this.range.controls.end.value;

    if (!intendedStart || !intendedEnd) {
      return;
    }

    // Step 1: Find the relevant current and future plans.
    // Relevant: The configured range "shomehow"
    const relevantPlans = this.plans.filter((plan) => {
      const planStart = DateTime.fromISO(plan.planStart);
      const planEnd = DateTime.fromISO(plan.planEnd);

      // Relevant situation 1:
      if (intendedStart < planStart && intendedEnd > planStart) {
        return true;
      }

      // Relevant situation 2:
      if (intendedStart > planStart && intendedStart < planEnd) {
        return true;
      }

      return false;
    });

    // Step 2: Re-evaluate the "lists" okAssets and conflictingAssets.
    const okAssets: PlanOutput[] = [];
    const conflictingAssets = new Map<any, PlanOutput[]>();
    for (let tenantAsset of this.data.assets) {
      const conflictingPlans = relevantPlans.filter((x) =>
        x.planAssets?.map((y) => y.tenantAssetId).includes(tenantAsset.id)
      );
      if (conflictingPlans.length > 0) {
        conflictingAssets.set(tenantAsset, conflictingPlans);
      } else {
        okAssets.push(tenantAsset);
      }
    }
    this.okAssets = okAssets;
    this.conflictingAssets = conflictingAssets;
  }
}
