import { Component, EventEmitter, Inject, Input, Output, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ColumnGroup,
  ColumnState,
  DndSourceOnRowDragParams,
  GridApi,
  RowDropZoneParams,
} from 'ag-grid-community';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import {
  CompleteInventoryMutationArgs,
  CompleteInventoryMutationRoot,
  DeleteInventoryMutationArgs,
  DeleteInventoryMutationRoot,
  InventoriesQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/inventory';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { DesktopToastService } from '../../../services/desktop-toast.service';
import { loadInventories } from './inventories';
import {
  InventoryEditDialogComponent,
  InventoryEditDialogData,
  InventoryEditDialogResult,
} from '../../../component-dialogs/inventory-edit-dialog/inventory-edit-dialog.component';
import { AssetsTableComponent } from '../../../components/assets/assets-and-plans/assets-table/assets-table.component';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  CreateInventoryAssetsMutationArgs,
  CreateInventoryAssetsMutationRoot,
  DeleteInventoryAssetsMutationArgs,
  DeleteInventoryAssetsMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/inventoryAsset';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import {
  LocalEventData_Inventory,
  LocalEventData_InventoryAssetsCreated,
  LocalEventData_InventoryAssetsDeleted,
  LocalEventService,
  LocalEventType,
} from '../../../services/local-event.service';
import { SelectionService } from '../../../services/selection.service';
import {
  InventoryStatisticsData,
  InventoryStatisticsDialogComponent,
} from '../../../component-dialogs/inventory-statistics-dialog/inventory-statistics-dialog.component';
import { AppModule } from '../../../app.module';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ConfirmService } from '../../../services/confirm.service';

type Content = 'columns' | 'inventories';

@Component({
  selector: 'app-ag-sidebar',
  templateUrl: './ag-sidebar.component.html',
  styleUrls: ['./ag-sidebar.component.scss'],
})
export class AgSidebarComponent implements OnInit {
  //@Input() columnDefs?: Array<ColDef | ColGroupDef>;

  @Input()
  get gridApi() {
    return this.#gridApi;
  }
  set gridApi(value) {
    this.#gridApi = value;
    if (value) {
      this.onGridApi(value);
    }
  }

  @Output() restoreDefaultColumnOrder = new EventEmitter();
  @Output() filterInventory = new EventEmitter<InventoryOutput | undefined>();

  context: AssetsTableComponent | undefined;

  columnIsMasked = new Map<string, boolean>();
  columnIsHidden = new Map<string, boolean>();

  // string = ColGroupDef.headerName
  sortedColDefsInGroup = new Map<string, ColDef[]>();

  // string = ColGroupDef.headerName
  // Array[0] = intermediate (true if some (unmasked) children are selected)
  // Array[1] = all (true if all (unmasked) children are selected)
  childSelectionInGroup = new Map<string, [boolean, boolean]>();

  showContent: Content | undefined;

  inventoriesLoading = false;
  inventories: InventoryOutput[] = [];
  inventoriesSelected: InventoryOutput | undefined;
  inventoriesQuery: QueryRef<InventoriesQueryRoot> | undefined;
  inventoriesSubscription: Subscription | undefined;
  inventoriesDragSituation = new Map<string, number>();
  inventoriesActivity = new Map<string, boolean>();

  #gridApi: GridApi | undefined;

  constructor(
    public apollo: Apollo,
    public localeService: LocaleService,
    public toastService: DesktopToastService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private matDialog: MatDialog,
    private localEventService: LocalEventService,
    private selectionService: SelectionService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {}

  onGridApi(gridApi: GridApi) {
    this.context = gridApi.getGridOption('context') as AssetsTableComponent;
  }

  rebuildColumnData() {
    if (!this.context || !this.gridApi) {
      return;
    }

    const gridApi = this.gridApi;

    // Mask some columns that the user should never see (but need to be kept internally).
    this.context.hiddenTableColumns.forEach((x) => this.columnIsMasked.set(x.field, true));

    for (const def of this.context.columnDefs ?? []) {
      if (this.isColGroupDef(def)) {
        const colGroupDef = def as ColGroupDef;
        const sortedColDef = this.sortColDefs(colGroupDef.children);
        if (sortedColDef.length === 0) {
          // This will happen "in the beginning" when the function is called
          // before the gridApi has set up their column data.
          // But that is ok, because at that time, the group children are sorted correctly.
          this.sortedColDefsInGroup.set(colGroupDef.headerName ?? 'na', colGroupDef.children);
          colGroupDef.children.forEach((x) =>
            this.columnIsHidden.set((x as ColDef).field ?? 'na', false)
          );
          this.childSelectionInGroup.set(colGroupDef.headerName ?? 'na', [false, true]);
        } else {
          this.sortedColDefsInGroup.set(colGroupDef.headerName ?? 'na', sortedColDef);

          this.evaluateGroupCheckState(colGroupDef.headerName);
        }
      }
    }
  }

  evaluateGroupCheckState(groupHeaderName: string | undefined) {
    if (!groupHeaderName) {
      return;
    }

    const children = this.sortedColDefsInGroup.get(groupHeaderName) ?? [];

    const sortedAndNOTMaskedColDefs = children.filter(
      (x) => !this.context?.hiddenTableColumns.map((y) => y.field).includes(x.field ?? 'na')
    );

    const isIntermediate =
      sortedAndNOTMaskedColDefs
        .map((x) => this.#getSingleColumnState(x.field))
        .map((x) => x?.hide)
        .includes(true) &&
      sortedAndNOTMaskedColDefs
        .map((x) => this.#getSingleColumnState(x.field))
        .map((x) => x?.hide)
        .includes(false);
    const all = sortedAndNOTMaskedColDefs
      .map((x) => this.#getSingleColumnState(x.field))
      .every((x) => x?.hide === false);

    this.childSelectionInGroup.set(groupHeaderName, [isIntermediate, all]);
  }

  #getSingleColumnState(id: string | undefined): ColumnState | undefined {
    if (!this.gridApi || !id) {
      return undefined;
    }

    return this.gridApi.getColumnState().find((x) => x.colId === id);
  }

  onDropColumnHeader(event: CdkDragDrop<any>, colDefs: ColDef[]) {
    if (!this.gridApi) {
      return;
    }

    const currentColumnStates = this.gridApi.getColumnState();
    const workingColumnStateMap = new Map<number, ColumnState>();
    // Build Map for easier swap.
    const toBeMovedColDef = colDefs[event.previousIndex];
    let fromColumnStateIndex: number | undefined;
    for (let i = 0; i < currentColumnStates.length; i++) {
      workingColumnStateMap.set(i, currentColumnStates[i]);
      if (toBeMovedColDef.field === currentColumnStates[i].colId) {
        fromColumnStateIndex = i;
      }
    }

    if (typeof fromColumnStateIndex === 'undefined') {
      return;
    }

    const toColumnStateIndex = fromColumnStateIndex + (event.currentIndex - event.previousIndex);

    const tmpColumnState = workingColumnStateMap.get(toColumnStateIndex) as ColumnState;

    // Swap
    workingColumnStateMap.set(
      toColumnStateIndex,
      workingColumnStateMap.get(fromColumnStateIndex) as ColumnState
    );
    workingColumnStateMap.set(fromColumnStateIndex, tmpColumnState);

    const newColumnStates: ColumnState[] = [];
    for (const i of Array.from(workingColumnStateMap.keys()).sortBy((x) => x, 'asc')) {
      newColumnStates.push(workingColumnStateMap.get(i) as ColumnState);
    }

    this.gridApi.applyColumnState({
      state: newColumnStates,
      applyOrder: true,
    });
  }

  onNavigation(showContent: Content | undefined) {
    if (this.showContent === showContent) {
      // CLOSE
      if (showContent === 'inventories') {
        this.#disableDragAndDrop();
      }

      this.showContent = undefined;
      return;
    }

    this.showContent = showContent;

    switch (showContent) {
      // OPEN
      case 'inventories':
        this.#enableDragAndDrop();
        loadInventories.call(this);
        break;

      default:
        break;
    }
  }

  onInventoryClick(inventory: InventoryOutput) {
    if (this.inventoriesSelected?.id === inventory.id) {
      this.inventoriesSelected = undefined;
      this.filterInventory.emit(undefined);
      return;
    }

    this.inventoriesSelected = inventory;
    this.filterInventory.emit(inventory);
  }

  onInventoryEditClick(event: MouseEvent, inventory: InventoryOutput) {
    event.stopPropagation();

    const data: InventoryEditDialogData = {
      inventoryId: inventory.id,
    };

    const dialog = this.matDialog.open(InventoryEditDialogComponent, {
      autoFocus: false,
      data,
      minWidth: 400,
    });

    dialog.afterClosed().subscribe((result: InventoryEditDialogResult) => {
      if (result === 'deleted') {
        // If the deleted inventory was (is) previously selected,
        // we need to unselect it.
        if (this.inventoriesSelected?.id === inventory.id) {
          this.inventoriesSelected = undefined;
          this.filterInventory.emit(undefined);
        }
      }
    });
  }

  async onInventoryDeleteClick(event: MouseEvent, inventory: InventoryOutput) {
    event.stopPropagation();

    this.confirmService.open(
      'Are you sure?',
      'Do you really want to delete this inventory and all related data?',
      async () => {
        const variables: DeleteInventoryMutationArgs = {
          id: inventory.id,
        };
        await firstValueFrom(
          this.apollo.mutate<DeleteInventoryMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_INVENTORY}
              mutation DeleteInventory($id: String!) {
                deleteInventory(id: $id) {
                  ...FullFragmentInventory
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.deleteInventory) {
                return;
              }

              const query = gql`
                ${FULL_FRAGMENT_INVENTORY}
                query Inventories {
                  inventories {
                    ...FullFragmentInventory
                  }
                }
              `;

              const cachedReadonlyInventories = cache.readQuery<InventoriesQueryRoot>({
                query,
              })?.inventories;

              if (typeof cachedReadonlyInventories !== 'undefined') {
                const cachedInventoriesClone = Array.from(cachedReadonlyInventories);
                const index = cachedReadonlyInventories.findIndex((x) => x.id === variables.id);
                cachedInventoriesClone.splice(index, 1);

                cache.writeQuery<InventoriesQueryRoot>({
                  query,
                  data: {
                    inventories: cachedInventoriesClone,
                  },
                });
              }

              // Notify locally about the removed inventory.

              const eventData: LocalEventData_Inventory = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'deleted',
                    inventory: data.deleteInventory,
                    userOid: this.selectionService.myUser?.oid ?? 'na',
                  },
                ],
              };
              this.localEventService.emitNewEvent(LocalEventType.Inventory, eventData);
            },
          })
        );
      }
    );
  }

  onInventoryCompleteClick(event: MouseEvent, inventory: InventoryOutput) {
    event.stopPropagation();
    this.confirmService.open(
      'Are you sure?',
      `Setting an inventory to 'completed' cannot be revoked.`,
      async () => {
        const variables: CompleteInventoryMutationArgs = {
          id: inventory.id,
        };

        await firstValueFrom(
          this.apollo.mutate<CompleteInventoryMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_INVENTORY}
              mutation CompleteInventory($id: String!) {
                completeInventory(id: $id) {
                  ...FullFragmentInventory
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.completeInventory) {
                return;
              }

              const eventData: LocalEventData_Inventory = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'updated',
                    inventory: data.completeInventory,
                    userOid: this.selectionService.myUser?.oid ?? 'na',
                  },
                ],
              };
              this.localEventService.emitNewEvent(LocalEventType.Inventory, eventData);
            },
          })
        );
      }
    );
  }

  onInventoryStatisticsClick(event: MouseEvent, inventory: InventoryOutput) {
    event.stopPropagation();

    const data: InventoryStatisticsData = {
      inventoryId: inventory.id,
      inventoryName: inventory.name,
    };

    const dialog = this.matDialog.open(InventoryStatisticsDialogComponent, {
      data,
      autoFocus: false,
      minWidth: 800,
    });
  }

  onInventoryItemDragEnter(event: DragEvent, inventoryId: string) {
    event.preventDefault();
    const dragTriggers = this.inventoriesDragSituation.get(inventoryId);
    if (typeof dragTriggers === 'undefined') {
      this.inventoriesDragSituation.set(inventoryId, 1);
    } else {
      this.inventoriesDragSituation.set(inventoryId, dragTriggers + 1);
    }
  }

  onInventoryItemDropZoneDragOver(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer) {
      event.dataTransfer.effectAllowed = 'copy';
    }
  }

  onInventoryItemDragLeave(event: any, inventoryId: string) {
    event.preventDefault();

    const dragTriggers = this.inventoriesDragSituation.get(inventoryId);
    if (typeof dragTriggers === 'undefined') {
      this.inventoriesDragSituation.set(inventoryId, 0);
    } else {
      this.inventoriesDragSituation.set(inventoryId, dragTriggers > 0 ? dragTriggers - 1 : 0);
    }
  }

  async onInventoryDropAddAssets(event: any, inventory: InventoryOutput) {
    event.preventDefault();

    const dragTriggers = this.inventoriesDragSituation.get(inventory.id);
    if (typeof dragTriggers === 'undefined') {
      this.inventoriesDragSituation.set(inventory.id, 0);
    } else {
      this.inventoriesDragSituation.set(inventory.id, dragTriggers > 0 ? dragTriggers - 1 : 0);
    }

    var jsonString = event.dataTransfer.getData('application/json');
    const assetIds = JSON.parse(jsonString) as string[];

    try {
      this.inventoriesActivity.set(inventory.id, true);
      const variables: CreateInventoryAssetsMutationArgs = {
        inventoryId: inventory.id,
        assetIds,
      };

      const result = await firstValueFrom(
        this.apollo.mutate<CreateInventoryAssetsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_INVENTORY}
            mutation CreateInventoryAssets($inventoryId: String!, $assetIds: [String!]!) {
              createInventoryAssets(inventoryId: $inventoryId, assetIds: $assetIds) {
                ...FullFragmentInventory
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.createInventoryAssets) {
              return;
            }

            const eventData: LocalEventData_InventoryAssetsCreated = {
              filterSessionId: AppModule.sessionId,
              data: [
                {
                  action: 'created',
                  assetIds: assetIds,
                  inventories: [data.createInventoryAssets],
                  userOid: this.selectionService.myUser?.oid ?? 'na',
                },
              ],
            };
            this.localEventService.emitNewEvent(LocalEventType.InventoryAssetsCreated, eventData);
          },
        })
      );

      const updatedInventory = result.data?.createInventoryAssets;

      if (updatedInventory && this.inventoriesSelected?.id === inventory.id) {
        this.filterInventory.emit(updatedInventory);
      }
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
    } finally {
      this.inventoriesActivity.set(inventory.id, false);
    }
  }

  async onInventoryDropRemoveAssets(event: any, inventory: InventoryOutput) {
    event.preventDefault();
    event.stopPropagation();

    const dragTriggers = this.inventoriesDragSituation.get(inventory.id);
    if (typeof dragTriggers === 'undefined') {
      this.inventoriesDragSituation.set(inventory.id, 0);
    } else {
      this.inventoriesDragSituation.set(inventory.id, dragTriggers > 0 ? dragTriggers - 1 : 0);
    }

    var jsonString = event.dataTransfer.getData('application/json');
    const assetIds = JSON.parse(jsonString) as string[];

    try {
      this.inventoriesActivity.set(inventory.id, true);
      const variables: DeleteInventoryAssetsMutationArgs = {
        inventoryId: inventory.id,
        assetIds,
      };

      const result = await firstValueFrom(
        this.apollo.mutate<DeleteInventoryAssetsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_INVENTORY}
            mutation DeleteInventoryAssets($inventoryId: String!, $assetIds: [String!]!) {
              deleteInventoryAssets(inventoryId: $inventoryId, assetIds: $assetIds) {
                ...FullFragmentInventory
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.deleteInventoryAssets) {
              return;
            }

            const eventData: LocalEventData_InventoryAssetsDeleted = {
              filterSessionId: 'na',
              data: [
                {
                  action: 'deleted',
                  assetIds: assetIds,
                  inventories: [data.deleteInventoryAssets],
                  userOid: this.selectionService.myUser?.oid ?? 'na',
                },
              ],
            };
            this.localEventService.emitNewEvent(LocalEventType.InventoryAssetsDeleted, eventData);
          },
        })
      );

      const updatedInventory = result.data?.deleteInventoryAssets;

      if (updatedInventory && this.inventoriesSelected?.id === inventory.id) {
        this.filterInventory.emit(updatedInventory);
      }
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
    } finally {
      this.inventoriesActivity.set(inventory.id, false);
    }
  }

  onDropZoneDragEnter(event: DragEvent) {
    const element = event.target as HTMLElement;
    element.classList.add('drop-zone');
  }

  onDropZoneDragLeave(event: DragEvent) {
    const element = event.target as HTMLElement;
    element.classList.remove('drop-zone');
  }

  // #region Handling "Columns"

  switchHideState(colId: string) {
    if (!this.gridApi) {
      return;
    }
    const columnStates = this.gridApi.getColumnState();
    const relevantColumnState = columnStates.find((x) => x.colId === colId);
    if (!relevantColumnState) {
      return;
    }
    relevantColumnState.hide = !relevantColumnState.hide;
    this.gridApi.applyColumnState({
      state: columnStates,
    });
    this.columnIsHidden.set(colId, relevantColumnState.hide);
  }

  switchGroupHideStates(isChecked: boolean, def: ColGroupDef) {
    if (!this.gridApi) {
      return;
    }
    const columnStates = this.gridApi.getColumnState();

    for (let child of def.children) {
      const columnState = columnStates.find((x) => x.colId === (child as ColDef).field);
      if (!columnState) {
        return;
      }
      columnState.hide = !isChecked;
      this.columnIsHidden.set(columnState.colId, columnState.hide);
    }

    this.gridApi.applyColumnState({
      state: columnStates,
    });
  }

  columnGroupIsChecked(def: ColGroupDef): boolean {
    // The group should be checked, if all children are checked (NOT hidden).
    if (!this.gridApi) {
      return false;
    }
    const columnStates = this.gridApi.getColumnState();
    for (let child of def.children) {
      const columnState = columnStates.find((x) => x.colId === (child as ColDef).field);
      if (!columnState) {
        continue;
      }

      const context = this.gridApi.getGridOption('context') as AssetsTableComponent;
      if (context.hiddenTableColumns.map((x) => x.field).includes(columnState.colId)) {
        continue;
      }

      if (columnState.hide === true) {
        return false;
      }
    }
    return true;
  }

  columnGroupIsIndeterminate(def: ColGroupDef): boolean {
    if (!this.gridApi) {
      return false;
    }

    let hiddenNumber = 0;

    const columnStates = this.gridApi.getColumnState();
    for (let child of def.children) {
      const hideState = columnStates.find((x) => x.colId === (child as ColDef).field)?.hide;
      if (hideState === true) {
        hiddenNumber++;
      }
    }

    return hiddenNumber > 0 && hiddenNumber < def.children.length;
  }

  isColGroupDef(def: ColDef | ColGroupDef) {
    return typeof (def as ColGroupDef).children !== 'undefined';
  }

  sortColDefs(colDefs: ColDef[]): ColDef[] {
    if (!this.gridApi) {
      return colDefs;
    }

    // Sort according to current column state (which shows the visual order).
    const currentColumnState = this.gridApi.getColumnState();
    const matchingOrderedColumnStates = currentColumnState.filter((x) =>
      colDefs.map((y) => y.field).includes(x.colId)
    );

    const orderedColDefs: ColDef[] = [];
    for (const orderedColumnState of matchingOrderedColumnStates) {
      const matchingColDef = colDefs.find((x) => x.field === orderedColumnState.colId);
      if (!matchingColDef) {
        continue; // Should not happen.
      }
      orderedColDefs.push(matchingColDef);
    }

    return orderedColDefs;
  }

  // #endregion Handling "Columns"

  #enableDragAndDrop() {
    const context = this.gridApi?.getGridOption('context') as AssetsTableComponent;
    const columnDefs = context.gridApi.getColumnDefs();
    if (typeof columnDefs !== 'undefined') {
      const firstColumnDef = columnDefs[0] as ColDef;
      firstColumnDef.width = 64;
      firstColumnDef.dndSource = true;
      firstColumnDef.dndSourceOnRowDrag = this.#dndSourceOnRowDrag;
      context.gridApi.updateGridOptions({ columnDefs });
    }
  }

  #disableDragAndDrop() {
    const context = this.gridApi?.getGridOption('context') as AssetsTableComponent;
    const columnDefs = context.gridApi.getColumnDefs();
    if (typeof columnDefs !== 'undefined') {
      const firstColumnDef = columnDefs[0] as ColDef;
      firstColumnDef.width = 40;
      firstColumnDef.dndSource = undefined;
      firstColumnDef.dndSourceOnRowDrag = undefined;
      context.gridApi.updateGridOptions({ columnDefs });
    }
  }

  #dndSourceOnRowDrag(params: DndSourceOnRowDragParams) {
    const context = params.context as AssetsTableComponent;
    let jsonString: string | undefined;

    // We have 2 initial drag situations
    // Situation 1: The drag started on an unselected row
    // Situation 2: The drag started on a selected row

    if (!context.selectedNodes.map((x) => x.data.id).includes(params.rowNode.data.id)) {
      // Situation 1
      // => Only consider the SINGLE row where the drag started
      // and ignore any other selected rows that might be available.
      jsonString = JSON.stringify([params.rowNode.data.id]);
    } else {
      // Situation 2
      // => Consider ALL rows that are selected.
      const dataArray: string[] = [];
      for (const selectedNode of context.selectedNodes) {
        dataArray.push(selectedNode.data.id);
      }

      jsonString = JSON.stringify(dataArray);
    }

    // create the data that we want to drag
    var e = params.dragEvent;
    e.dataTransfer!.setData('application/json', jsonString);
    e.dataTransfer!.setData('text/plain', jsonString);
  }
}
