import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  // #region In/Out

  @Input() isTransparent = false;

  // #endregion In/Out

  constructor() { }

  ngOnInit(): void {
  }

}
