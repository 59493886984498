<lib-spinner *ngIf="loading; else notLoadingElement"></lib-spinner>

<ng-template #notLoadingElement>
  <div *ngIf="errorMessage; else noErrorMessageElement">{{ errorMessage }}</div>

  <ng-template #noErrorMessageElement>
    <lib-select-button
      *ngIf="planStepActions.length === 0; else hasPlanStepActions"
      icon="swipe_up"
      text="Add Action: pickup"
      (onClick)="
        onClickCreateOrEditPlanAction(
          planStep,
          actionType.PickUp,
          undefined,
          undefined,
          undefined
        )
      "
    >
      <button
        mat-menu-item
        (click)="
          onClickCreateOrEditPlanAction(
            planStep,
            actionType.PickUp,
            undefined,
            undefined,
            undefined
          )
        "
      >
        <mat-icon>swipe_up</mat-icon>
        <span>Add Action: <b>pickup</b></span>
      </button>
      <button
        mat-menu-item
        (click)="
          onClickCreateOrEditPlanAction(
            planStep,
            actionType.Handover,
            undefined,
            undefined,
            undefined
          )
        "
      >
        <mat-icon>swipe_right</mat-icon>
        <span>Add Action: <b>handover</b></span>
      </button>
      <button
        mat-menu-item
        (click)="
          onClickCreateOrEditPlanAction(
            planStep,
            actionType.Deposit,
            undefined,
            undefined,
            undefined
          )
        "
      >
        <mat-icon>swipe_down</mat-icon>
        <span>Add Action: <b>deposit</b></span>
      </button>
    </lib-select-button>

    <ng-template #hasPlanStepActions>
      <ng-container *ngFor="let action of planStepActions; let i = index">
        <app-space-plus
          (onClick)="
            onClickCreateOrEditPlanAction(
              planStep,
              undefined,
              i - 1,
              i,
              undefined
            )
          "
        >
        </app-space-plus>

        <!-- ########### -->
        <!-- ACTION ITEM -->
        <!-- ########### -->
        <div
          class="action-item"
          [class.selected]="action === selectedPlanStepAction"
        >
          <div class="summary">
            <mat-icon
              style="min-width: fit-content"
              [class.orange]="getActionAssetsInfo(action.id)?.someBooked"
              [class.red]="getActionAssetsInfo(action.id)?.noneBooked"
              [class.green]="getActionAssetsInfo(action.id)?.allBooked"
            >
              radio_button_unchecked
            </mat-icon>

            <div
              style="min-width: 50px"
              [mtxPopoverTriggerFor]="actionAssetsPopover"
              mtxPopoverTriggerOn="hover"
              [mtxPopoverTriggerData]="{
                actionAssetsWrapper: getActionAssetsInfo(action.id)
              }"
            >
              <mat-icon
                *ngIf="
                  getActionAssetsInfo(action.id)?.isLoading;
                  else actionAssetsInfoLoadedElement
                "
                class="spinner"
              >
                donut_large
              </mat-icon>
              <ng-template #actionAssetsInfoLoadedElement>
                {{ getActionAssetsInfo(action.id)?.bookedAssets?.length }} /
                {{ getActionAssetsInfo(action.id)?.relevantAssets?.length }}
              </ng-template>
            </div>
            <mtx-popover
              #actionAssetsPopover="mtxPopover"
              [position]="['above', 'after']"
            >
              <ng-template mtxPopoverContent let-wrapper="actionAssetsWrapper">
                <div class="flex-column mat-body">
                  <div
                    *ngFor="let relevantAsset of wrapper.relevantAssets"
                    class="grid-3"
                  >
                    <span>{{ relevantAsset.assetId }}</span>
                    <div>
                      <mat-icon *ngIf="relevantAsset.assetBooked"
                        >done</mat-icon
                      >
                    </div>
                    <mat-icon
                      matRipple
                      class="qrcode"
                      (click)="showQRCode(relevantAsset.assetId)"
                      >qr_code</mat-icon
                    >
                  </div>
                </div>
              </ng-template>
            </mtx-popover>

            <span class="nowrap">
              {{
                action.date | date : localeService.datetimePipeString(locale)
              }}
            </span>
            <span>
              <b>
                {{ planStepActionHelper.getActionName(action, "uppercase") }}
              </b>
            </span>

            <span *ngIf="action.transitLocationId" class="flex-row">
              (
              <lib-location
                [locationId]="action.transitLocationId"
              ></lib-location>
              )
            </span>

            <span></span>

            <app-action-details
              fromOrTo="from"
              [action]="action"
            ></app-action-details>

            <mat-icon>arrow_right_alt</mat-icon>

            <app-action-details
              fromOrTo="to"
              [action]="action"
            ></app-action-details>

            <span></span>
            <div class="action-description">{{ action.description }}</div>
          </div>
          <div class="icons">
            <button
              mat-icon-button
              [matMenuTriggerFor]="moreMenu"
              (click)="selectedPlanStepAction = action"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #moreMenu="matMenu"
              (closed)="selectedPlanStepAction = undefined"
            >
              <button
                mat-menu-item
                (click)="
                  onClickCreateOrEditPlanAction(
                    planStep,
                    undefined,
                    i - 1,
                    i + 1,
                    i
                  )
                "
              >
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <!-- [disabled]="getActionAssetsInfo(action.id)?.allBooked" -->
              <button appNotReader mat-menu-item (click)="bookAction(action)">
                <mat-icon>task_alt</mat-icon>
                <span>Adjust Bookings</span>
              </button>
              <button
                appNotReader
                mat-menu-item
                (click)="onClickDeletePlanStepAction(action, planStep)"
              >
                <mat-icon
                  fontSet="material-symbols-outlined"
                  class="color-warn-400"
                  >delete</mat-icon
                >
                <span class="color-warn-400">Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </ng-container>

      <app-space-plus
        (onClick)="
          onClickCreateOrEditPlanAction(
            planStep,
            undefined,
            planStepActions ? planStepActions.length - 1 : undefined,
            undefined,
            undefined
          )
        "
      ></app-space-plus>
    </ng-template>
  </ng-template>
</ng-template>
