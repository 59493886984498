<h1 class="flex-row align-items-center">
  <span>Updating Assets</span>
</h1>

<div class="content">
  <div class="flex-column gap-small">
    <div
      *ngFor="let result of editResults"
      class="flex-row gap-normal align-items-center"
    >
      <span class="font-weight-500">{{ result.tenantAssetId }}</span>
      <lib-spinner *ngIf="result.ongoing; else finishedElement"></lib-spinner>
      <ng-template #finishedElement>
        <mat-icon *ngIf="result.finishedOk" fontSet="material-symbols-outlined">
          check
        </mat-icon>

        <mat-icon
          *ngIf="!result.finishedOk"
          fontSet="material-symbols-outlined"
          class="color-warn-400"
          [matTooltip]="result.details ?? ''"
        >
          error
        </mat-icon>
      </ng-template>
    </div>
  </div>

  <div *ngIf="finished" class="flex-column mt-normal mat-body">
    <span>Updating finished.</span>
    <span *ngIf="!finishedOk">
      There were errors. Please try again later or contact support for help.
    </span>
    <button
      mat-stroked-button
      (click)="close()"
      class="mt-small align-self-start"
    >
      OK
    </button>
  </div>
</div>
