import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  PropertyQueryArgs,
  PropertyQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/property';
import { PropertyType } from 'projects/shared/src/lib/graphql/enums/propertyType';
import { FULL_FRAGMENT_PROPERTY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentProperty';
import { PropertyOutput } from 'projects/shared/src/lib/graphql/output/propertyOutput';
import { firstValueFrom } from 'rxjs';
import {
  EditMultipleAssetsDrawerComponent,
  EditMultipleAssetsDrawerData,
  EditMultipleAssetsDrawerResult,
} from '../../component-drawers/edit-multiple-assets-drawer/edit-multiple-assets-drawer.component';
import { v4 } from 'uuid';
import { OptionQueryArgs, OptionQueryRoot } from 'projects/shared/src/lib/graphql/crud/option';
import { FULL_FRAGMENT_OPTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOption';
import { OptionOutput } from 'projects/shared/src/lib/graphql/output/optionOutput';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';
import { OptionItemOutput } from 'projects/shared/src/lib/graphql/output/optionItemOutput';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { DateTime } from 'luxon';
import { DesktopToastService } from '../../services/desktop-toast.service';

export type EditMultipleAssetsDialogData = {
  columnPropertyId: string;
  columnPropertyHeaderName: string;
  assets: any[];
};

type BooleanOption = {
  name: string;
  value: boolean;
};

@Component({
  selector: 'app-edit-multiple-assets-dialog',
  templateUrl: './edit-multiple-assets-dialog.component.html',
  styleUrls: ['./edit-multiple-assets-dialog.component.scss'],
})
export class EditMultipleAssetsDialogComponent implements OnInit {
  loading = false;
  loadingErrorMessage: string | undefined;
  property: PropertyOutput | undefined;
  option: OptionOutput | undefined;
  propertyType = PropertyType;
  readonly uuid1 = v4();
  readonly booleanOptions: BooleanOption[] = [
    {
      name: 'true',
      value: true,
    },
    {
      name: 'false',
      value: false,
    },
  ];

  selectedText: string | undefined; // handles also numbers
  selectedTextOptionItem: OptionItemOutput | undefined;
  selectedBooleanOption: BooleanOption | undefined;
  selectedDateTime: any;
  selectedDate: any;

  get canApply(): boolean {
    if (!this.property) {
      return false;
    }

    const propertyTypeId = this.property.typeId;
    const isNullable = this.property.isNullable;

    if (propertyTypeId === this.propertyType.Text && !this.property.optionId) {
      // Text and NO OPTION
      if (isNullable) {
        return true;
      } else {
        return !!this.selectedText;
      }
    } else if (propertyTypeId === this.propertyType.Text && this.property.optionId) {
      // Text and OPTION
      if (isNullable) {
        return true;
      } else {
        return typeof this.selectedTextOptionItem !== 'undefined';
      }
    } else if (propertyTypeId === this.propertyType.Number) {
      // Number
      if (isNullable) {
        return true;
      } else {
        return !!this.selectedText;
      }
    } else if (propertyTypeId === this.propertyType.Boolean) {
      if (isNullable) {
        return true;
      } else {
        return typeof this.selectedBooleanOption !== 'undefined';
      }
    } else if (propertyTypeId === this.propertyType.Date) {
      if (isNullable) {
        return true;
      } else {
        return typeof this.selectedDate !== 'undefined';
      }
    } else if (propertyTypeId === this.propertyType.DateTime) {
      if (isNullable) {
        return true;
      } else {
        return typeof this.selectedDateTime !== 'undefined';
      }
    }

    return false;
  }

  constructor(
    private _dialogRef: MatDialogRef<EditMultipleAssetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditMultipleAssetsDialogData,
    private _apollo: Apollo,
    private _drawer: MtxDrawer,
    private _toastService: DesktopToastService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  async apply() {
    let transformedValue: any;

    if (this.property?.typeId === this.propertyType.Text && !this.property.optionId) {
      // Text and NO OPTION.
      transformedValue = this.selectedText;
    } else if (this.property?.typeId === this.propertyType.Text && this.property.optionId) {
      // Text and OPTION.
      transformedValue = this.selectedTextOptionItem?.name;
    } else if (this.property?.typeId === this.propertyType.Number) {
      // Number (handle as string and hope that the backend can handle it as well)
      transformedValue = this.selectedText;
    } else if (this.property?.typeId === this.propertyType.Boolean) {
      transformedValue = this.selectedBooleanOption?.value;
    } else if (this.property?.typeId === this.propertyType.DateTime) {
      transformedValue = this.#getJSDate(this.selectedDateTime);
    } else if (this.property?.typeId === this.propertyType.Date) {
      transformedValue = this.#getJSDate(this.selectedDate);
    } else {
      // Should not happen.
      throw new Error('Unknown property type detected. Please contact support.');
    }

    const transformedAssets = [];
    for (const asset of this.data.assets) {
      const transformedAsset: { [index: string]: any } = {};
      transformedAsset['id'] = asset.id;
      transformedAsset[this.data.columnPropertyId] = transformedValue;

      transformedAssets.push(transformedAsset);
    }

    const data: EditMultipleAssetsDrawerData = {
      assets: transformedAssets,
    };

    const drawer = this._drawer.open(EditMultipleAssetsDrawerComponent, {
      position: 'right',
      width: '400px',
      height: '100%',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: false,
      data,
    });

    drawer.afterDismissed().subscribe((result: EditMultipleAssetsDrawerResult) => {
      if (typeof result === 'undefined') {
        return;
      }

      if (result.length === this.data.assets.length) {
        this._toastService.info(
          `Successfully updated ${result.length} ${result.length > 1 ? 'assets' : 'asset'}.`,
          'Success'
        );
        this._dialogRef.close(true);
      }

      // Not all updates were successful.
      // TODO
    });
  }

  async #loadData() {
    try {
      this.loading = true;

      const variables: PropertyQueryArgs = {
        id: this.data.columnPropertyId,
      };

      const result = await firstValueFrom(
        this._apollo.query<PropertyQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PROPERTY}
            query Property($id: String!) {
              property(id: $id) {
                ...FullFragmentProperty
                propertyType {
                  id
                  name
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.property = result.data.property ?? undefined;

      if (!this.property?.optionId) {
        return;
      }

      // We have an option to load.
      const variablesOption: OptionQueryArgs = {
        id: this.property.optionId,
      };

      const optionQueryResult = await firstValueFrom(
        this._apollo.query<OptionQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_OPTION}
            query Option($id: String!) {
              option(id: $id) {
                ...FullFragmentOption
                optionItems {
                  id
                  optionId
                  name
                }
              }
            }
          `,
          variables: variablesOption,
          fetchPolicy: 'network-only',
        })
      );
      this.option = optionQueryResult.data.option;
    } catch (error) {
      this.loadingErrorMessage = new CatchError(error).message;
    } finally {
      this.loading = false;
    }
  }

  #getJSDate(value: any) {
    const valueTest = DateTime.fromJSDate(value);
    const dateTime = valueTest.isValid ? valueTest : (value as DateTime);
    return dateTime.toJSDate();
  }
}
