import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';

export type ConfirmDialogData = {
  title: string;
  text?: string;
  ok: () => Promise<any>;
  notOk?: () => Promise<any>;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  activity = false;
  errorMessage: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    , private _dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  async onOk() {
    try {
      this.activity = true;
      this.errorMessage = undefined;
      await this.data.ok();
      this.activity = false;
      this._dialogRef.close(true);
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
    } finally {
      this.activity = false;
    }
  }

  async onNotOk() {
    if (this.data.notOk) {
      await this.data.notOk();
    }

    this._dialogRef.close(false);
  }
}
