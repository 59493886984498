import { LocalEventData_ReturnToCustomerAsset, LocalEventType } from '../local-event.service';
import { RemoteEventData_ReturnToCustomerAsset, RemoteEventType } from '../remote-event.service';
import { EventSource, NotificationService } from './notification.service';

export const handle_ReturnToCustomerAsset = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_ReturnToCustomerAsset | RemoteEventData_ReturnToCustomerAsset
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // Do the following only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      this.hasSubscribers(EventSource.Local, LocalEventType.ReturnToCustomerAsset)
    ) {
      // We have subscribers. Notify them.
      this.localEventHandled.next([LocalEventType.ReturnToCustomerAsset, eventData]);
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.ReturnToCustomerAsset)
    ) {
      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.ReturnToCustomerAsset, eventData]);
      return;
    }
  }
};
