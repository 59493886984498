import { Injectable } from '@angular/core';
import { Info } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  get weekday1Short(): string {
    return Info.weekdays('short')[0];
  }
  get weekday2Short(): string {
    return Info.weekdays('short')[1];
  }
  get weekday3Short(): string {
    return Info.weekdays('short')[2];
  }
  get weekday4Short(): string {
    return Info.weekdays('short')[3];
  }
  get weekday5Short(): string {
    return Info.weekdays('short')[4];
  }
  get weekday6Short(): string {
    return Info.weekdays('short')[5];
  }
  get weekday7Short(): string {
    return Info.weekdays('short')[6];
  }

  constructor() {}

  datePipeString(locale: string) {
    if (locale.match(/^en[a-zA-z-]*/i)) {
      return 'MM/dd/yyyy';
    }

    return 'yyyy-MM-dd';
  }

  datetimePipeString(locale: string) {
    if (locale.match(/^en[a-zA-z-]*/i)) {
      return 'MM/dd/yyyy HH:mm';
    }

    return 'yyyy-MM-dd HH:mm';
  }
}
