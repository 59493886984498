
export enum ChangeType {
    Keep = 1,
    Add = 2,
    Update = 3,
    Delete = 4,
}

export const changeTypes = new Map<number, string>([
    [ChangeType.Keep, "Keep"],
    [ChangeType.Add, "Add"],
    [ChangeType.Update, "Update"],
    [ChangeType.Delete, "Delete"],
]);
