import { Apollo, gql } from 'apollo-angular';
import {
  InventoryAssetQueryArgs,
  InventoryAssetQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/inventoryAsset';
import { FULL_FRAGMENT_INVENTORY_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventoryAsset';
import { InventoryAssetOutput } from 'projects/shared/src/lib/graphql/output/inventoryAssetOutput';
import { firstValueFrom } from 'rxjs';

export class InventoryAssetLoader {
  get inventoryAsset(): InventoryAssetOutput | undefined | null {
    if (typeof this.#inventoryAsset === 'undefined') {
      this.#loadData();
    }

    return this.#inventoryAsset;
  }

  #inventoryAsset: InventoryAssetOutput | undefined | null;
  #isLoading = false;

  constructor(private inventoryId: string, private assetId: string, private apollo: Apollo) {}

  async #loadData() {
    if (this.#isLoading) {
      return;
    }

    this.#isLoading = true;

    try {
      const variables: InventoryAssetQueryArgs = {
        inventoryId: this.inventoryId,
        assetId: this.assetId,
      };

      const result = await firstValueFrom(
        this.apollo.query<InventoryAssetQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_INVENTORY_ASSET}
            query InventoryAsset($inventoryId: String!, $assetId: String!) {
              inventoryAsset(inventoryId: $inventoryId, assetId: $assetId) {
                ...FullFragmentInventoryAsset
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.#inventoryAsset = result.data.inventoryAsset;
    } catch (error) {
      // Do nothing.
    } finally {
      this.#isLoading = false;
    }
  }
}
