import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  CreateTenantActionsMutationArgs,
  CreateTenantActionsMutationRoot,
  DeleteTenantActionsMutationArgs,
  DeleteTenantActionsMutationRoot,
  TenantActionsQueryArgs,
  TenantActionsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import {
  GetScannedAssetsQueryArgs,
  GetScannedAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAssetPlanning';
import { actionTypes } from 'projects/shared/src/lib/graphql/enums/actionType';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { ScannedAssetOutput } from 'projects/shared/src/lib/graphql/output/scannedAssetOutput';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom } from 'rxjs';
import {
  LocalEventData_BookingPlanned,
  LocalEventService,
  LocalEventType,
} from '../../services/local-event.service';
import { AppModule } from '../../app.module';
import { SelectionService } from '../../services/selection.service';

export type BookPlanStepActionDialogData = {
  action: PlanStepActionOutput;
  plan: PlanOutput;
};

export type BookPlanStepActionDialogResult = boolean;

class AssetState {
  newState: boolean;

  get hasChanged(): boolean {
    return this.newState !== this.currentState.assetBooked;
  }

  get toBeDeleted(): boolean {
    return this.currentState.assetBooked === true && !this.newState;
  }

  get toBeAdded(): boolean {
    return this.currentState.assetBooked === false && this.newState;
  }

  constructor(
    public currentState: ScannedAssetOutput,
    public currentBooking: TenantActionOutput | undefined
  ) {
    this.newState = currentState.assetBooked;
  }
}

@Component({
  selector: 'app-book-plan-step-action-dialog',
  templateUrl: './book-plan-step-action-dialog.component.html',
  styleUrls: ['./book-plan-step-action-dialog.component.scss'],
})
export class BookPlanStepActionDialogComponent implements OnInit {
  loading = false;
  loadingErrorMessage: string | undefined;
  assetStates: AssetState[] = [];
  activity = false;
  actionTypes = actionTypes;

  get canApply(): boolean {
    return this.assetStates.map((x) => x.hasChanged).includes(true);
  }

  constructor(
    private _dialogRef: MatDialogRef<BookPlanStepActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BookPlanStepActionDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private _apollo: Apollo,
    private _localEventService: LocalEventService,
    private _selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  async apply() {
    if (!this.canApply) {
      return;
    }

    this.activity = true;
    try {
      // First perform the deletions (if available).
      const toBeDeleted = this.assetStates.filter((x) => x.toBeDeleted);
      if (toBeDeleted.length > 0) {
        const variables: DeleteTenantActionsMutationArgs = {
          tenantActionIds: toBeDeleted.map((x) => x.currentBooking?.id ?? -1),
        };

        await firstValueFrom(
          this._apollo.mutate<DeleteTenantActionsMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_TENANT_ACTION}
              mutation DeleteTenantActions($tenantActionIds: [Int!]!) {
                deleteTenantActions(tenantActionIds: $tenantActionIds) {
                  ...FullFragmentTenantAction
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.deleteTenantActions) {
                return;
              }

              const eventData: LocalEventData_BookingPlanned = {
                data: [],
                filterSessionId: AppModule.sessionId,
              };

              for (const tenantAction of data.deleteTenantActions) {
                eventData.data?.push({
                  action: 'deleted',
                  bookingType: 'planned',
                  bookingUserOid: this._selectionService.myUser?.oid ?? 'na',
                  bookingData: tenantAction,
                });
              }

              this._localEventService.emitNewEvent(LocalEventType.BookingPlanned, eventData);
            },
          })
        );
      }

      const toBeAdded = this.assetStates.filter((x) => x.toBeAdded);
      if (toBeAdded.length > 0) {
        const variables: CreateTenantActionsMutationArgs = {
          assetIds: toBeAdded.map((x) => x.currentState.assetId),
          data: {
            planId: this.data.plan.id,
            planStepActionId: this.data.action.id,
            actionTypeId: this.data.action.actionTypeId,
            fromUserOid: this.data.action.fromUserOid,
            fromLocationId: this.data.action.fromLocationId,
            fromMail: this.data.action.fromMail,
            fromOther: this.data.action.fromOther,
            toUserOid: this.data.action.toUserOid,
            toLocationId: this.data.action.toLocationId,
            toMail: this.data.action.toMail,
            toOther: this.data.action.toOther,
            description: this.data.action.description,
            transitLocationId: this.data.action.transitLocationId,
          },
        };

        await firstValueFrom(
          this._apollo.mutate<CreateTenantActionsMutationRoot>({
            mutation: gql`
              mutation CreateTenantActions($assetIds: [String!]!, $data: TenantActionInputCreate!) {
                createTenantActions(assetIds: $assetIds, data: $data) {
                  id
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.createTenantActions) {
                return;
              }

              const eventData: LocalEventData_BookingPlanned = {
                filterSessionId: AppModule.sessionId,
                data: [],
              };

              for (const tenantAction of data.createTenantActions) {
                eventData.data?.push({
                  action: 'created',
                  bookingType: 'planned',
                  bookingData: tenantAction,
                  bookingUserOid: this._selectionService.myUser?.oid ?? 'na',
                });
              }

              this._localEventService.emitNewEvent(LocalEventType.BookingPlanned, eventData);
            },
          })
        );
      }

      this._dialogRef.close(true);
    } catch (error) {
    } finally {
      this.activity = false;
    }
  }

  async #loadData() {
    try {
      this.loading = true;
      this.loadingErrorMessage = undefined;

      const variables: GetScannedAssetsQueryArgs = {
        planStepActionId: this.data.action.id,
      };

      const result = await firstValueFrom(
        this._apollo.query<GetScannedAssetsQueryRoot>({
          query: gql`
            query GetScannedAssets($planStepActionId: String!) {
              getScannedAssets(planStepActionId: $planStepActionId) {
                planStepActionId
                assetId
                assetBooked
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      const variablesBookings: TenantActionsQueryArgs = {
        planId: this.data.plan.id,
        planStepId: this.data.action.planStepId,
      };

      const resultBookings = await firstValueFrom(
        this._apollo.query<TenantActionsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            query TenantActions($planId: String!, $planStepId: String) {
              tenantActions(planId: $planId, planStepId: $planStepId) {
                ...FullFragmentTenantAction
              }
            }
          `,
          variables: variablesBookings,
          fetchPolicy: 'network-only',
        })
      );

      for (const scannedAsset of result.data.getScannedAssets.sortBy((x) => x.assetId)) {
        this.assetStates.push(
          new AssetState(
            scannedAsset,
            resultBookings.data.tenantActions.find(
              (x) =>
                x.planStepActionId === this.data.action.id && x.assetId === scannedAsset.assetId
            )
          )
        );
      }
    } catch (error) {
      this.loadingErrorMessage = new CatchError(error).message;
    } finally {
      this.loading = false;
    }
  }
}
