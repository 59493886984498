import { Subject } from 'rxjs';
import { OUser } from '../common/oUser';

export class OUserWrapper {
  oUser: OUser | undefined;
  isLoading = false;
  isError = false;

  constructor() {}
}
