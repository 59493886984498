import { LocalEventData_BookingRealtime, LocalEventType } from '../local-event.service';
import { RemoteEventData_BookingRealtime, RemoteEventType } from '../remote-event.service';
import { EventSource, NotificationService } from './notification.service';

export const handle_BookingRealtime = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_BookingRealtime | RemoteEventData_BookingRealtime
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // Do the following ALWAYS:
    const assetIds = eventData.data?.map((x) => x.bookingData.assetId) ?? [];
    await this.assetService.fetchMultiple(assetIds, 'network-only');

    // Do the next only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      !this.hasSubscribers(EventSource.Local, LocalEventType.BookingRealtime)
    ) {
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      !this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.BookingRealtime)
    ) {
      return;
    }

    // We have subscribers. Notify them.
    this.remoteEventHandled.next([RemoteEventType.BookingRealtime, eventData]);
  }
};
