import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-notifications',
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.scss']
})
export class MyNotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
