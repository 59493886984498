import { Component, OnInit, OnDestroy } from '@angular/core';
import { Loading } from 'projects/shared/src/lib/classes/loading';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { AssetsQueryArgs, AssetsQueryRoot } from 'projects/shared/src/lib/graphql/crud/asset';
import { AssetOutput } from 'projects/shared/src/lib/graphql/output/assetOutput';
import { MatDialog } from '@angular/material/dialog';
import { NewAssetDialogComponent, NewAssetDialogData } from '../../component-dialogs/new-asset-dialog/new-asset-dialog.component';
import { SelectionService } from '../../services/selection.service';
import { Router } from '@angular/router';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { OptionsQueryArgs, OptionsQueryRoot } from 'projects/shared/src/lib/graphql/crud/option';
import { FULL_FRAGMENT_OPTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOption';
import { OptionOutput } from 'projects/shared/src/lib/graphql/output/optionOutput';
import { NewOptionDialogComponent, NewOptionDialogData } from '../../component-dialogs/new-option-dialog/new-option-dialog.component';
import { FULL_FRAGMENT_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentAsset';
import { DesktopToastService } from '../../services/desktop-toast.service';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit, OnDestroy {
  // #region Public Properties

  loading = new Loading(2, true);
  assets: AssetOutput[] = [];
  options: OptionOutput[] = [];

  // #endregion Public Properties


  // #region Private Properties

  private _assetsQuery: QueryRef<AssetsQueryRoot> | undefined;
  private _assetsSubscription: Subscription | undefined;
  private _optionsQuery: QueryRef<OptionsQueryRoot> | undefined;
  private _optionsSubscription: Subscription | undefined;

  // #endregion Private Properties


  // #region Init

  constructor(
    private _apollo: Apollo
    , private _dialog: MatDialog
    , private _selectionService: SelectionService
    , private _router: Router
    , private _toast: DesktopToastService
  ) { }

  ngOnInit(): void {
    this._loadAssets(1);
    this._loadOptions(2);
  }

  ngOnDestroy(): void {
    this._assetsSubscription?.unsubscribe();
    this._optionsSubscription?.unsubscribe();
  }

  // #endregion Init


  // #region Public Methods

  newAsset(event: MouseEvent) {
    event.stopPropagation();

    if (!this._selectionService.selectedTenant) {
      return;
    }

    const data: NewAssetDialogData = {
      assetNames: this.assets.map(x => x.name)
    };

    const dialogRef = this._dialog.open(NewAssetDialogComponent, {
      data,
      maxWidth: '400px'
    });

    dialogRef.afterClosed().subscribe((asset: AssetOutput | undefined) => {
      if (!asset) {
        return;
      }

      this._router.navigateByUrl(`/design/asset/${asset.id}`);
    });
  }

  newOption(event: MouseEvent) {
    event.stopPropagation();

    const data: NewOptionDialogData = {
      optionNames: this.options.map(x => x.name)
    }

    const dialogRef = this._dialog.open(NewOptionDialogComponent, {
      maxWidth: '400px',
      data
    });

    dialogRef.afterClosed().subscribe((option: OptionOutput) => {
      if (!option) {
        return;
      }

      this._router.navigateByUrl(`/design/option/${option.id}`);
    });
  }

  // #endregion Public Methods


  // #region Private Methods

  private _loadAssets(jobNumber: number) {
    this.loading.indicateJobStart(jobNumber);

    const variables: AssetsQueryArgs = {
      tenantId: this._selectionService.selectedTenant?.id ?? 'na'
    };

    this._assetsQuery = this._apollo.watchQuery<AssetsQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_ASSET}
        query Assets($tenantId: String!) {
          assets(tenantId: $tenantId) {
            ...FullFragmentAsset
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network'
    });

    this._assetsSubscription = this._assetsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.assets = data.assets;
        this.loading.indicateJobEnd(jobNumber);
      },
      error: (error) => {
        this.loading.indicateJobEnd(jobNumber)
        const message = new CatchError(error).message;
        this._toast.error(message, 'Error');
      }
    })
  }

  private _loadOptions(jobNumber: number) {
    this.loading.indicateJobStart(jobNumber);

    const variables: OptionsQueryArgs = {
      tenantId: this._selectionService.selectedTenant?.id ?? 'na'
    };

    this._optionsQuery = this._apollo.watchQuery<OptionsQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_OPTION}
        query Options($tenantId: String!) {
          options(tenantId: $tenantId) {
            ...FullFragmentOption
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network'
    });

    this._optionsSubscription = this._optionsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.options = data.options;
        this.loading.indicateJobEnd(jobNumber);
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this._toast.error(message, 'Error');
      }
    })
  }

  // #endregion Private Methods
}
