import { InventoriesQueryRoot } from 'projects/shared/src/lib/graphql/crud/inventory';
import { AgSidebarComponent } from './ag-sidebar.component';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import { gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';

export const loadInventories = async function (this: AgSidebarComponent) {
    if(this.inventoriesQuery) {
        this.inventoriesLoading = true;
        this.inventoriesQuery.refetch();
        return;
    }

    this.inventoriesSubscription?.unsubscribe();

    this.inventoriesLoading = true;

    this.inventoriesQuery = this.apollo.watchQuery<InventoriesQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_INVENTORY}
        query inventories {
          inventories {
            ...FullFragmentInventory
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
    });

    this.inventoriesSubscription = this.inventoriesQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.inventories = data.inventories.sortBy((x) => x.createdAt, 'desc');
        this.inventoriesLoading = false;
      },
      error: (error) => {
        this.toastService.error(new CatchError(error).message);
        this.inventoriesLoading = false;
      },
    });
};
