<mtx-popover #popover="mtxPopover" class="popover-panel">
  <ng-template mtxPopoverContent>
    <div
      *ngIf="errorMessage; else noError"
      class="flex-column color-warn font-size-12"
      style="max-width: 400px"
    >
      <span>Error:</span>
      <span>{{ errorMessage }}</span>
    </div>

    <ng-template #noError>
      <div class="flex-column">
        <div class="action-items">
          <div
            class="action-item"
            [class.inactive]="!hasColumnFilter"
            (click)="onClickClearColumnFilter()"
          >
            <span class="material-symbols-outlined"> filter_alt_off </span>
            <span class="nowrap">Clear Column Filter</span>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div *ngIf="distinctLoading; else notLoadingTemplate">
          <span
            class="material-symbols-outlined spinner"
            style="
              font-size: 18px;
              margin-top: 4px;
              margin-left: 8px;
              margin-bottom: 8px;
            "
          >
            donut_large
          </span>
        </div>

        <ng-template #notLoadingTemplate>
          <ng-container *ngFor="let value of distinctValues">
            <span class="item" (click)="onClickDistinctValue(value[1])">{{
              value[1]
            }}</span>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</mtx-popover>

<div class="ag-cell-label-container" role="presentation">
  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button">
    <span
      class="material-symbols-outlined font-size-18"
      (click)="onClickDistinctMenu()"
      #popoverTrigger="mtxPopoverTrigger"
      [mtxPopoverTriggerFor]="popover"
      mtxPopoverTriggerOn="click"
    >
      menu
    </span>
  </span>

  <div
    ref="eLabel"
    class="ag-header-cell-label"
    role="presentation"
    (click)="onHeaderClicked($event)"
  >
    <!-- IS TENANT PROPERTY -->
    <span
      *ngIf="isTenantProperty"
      class="material-symbols-outlined"
      style="font-size: 18px; font-weight: 400; margin-right: 2px; opacity: 0.3"
    >
      circle
    </span>

    <!-- IS EDITABLE -->
    <span
      *ngIf="isEditable"
      class="material-symbols-outlined"
      style="font-size: 18px; font-weight: 400; margin-right: 2px; opacity: 0.3"
    >
      stylus
    </span>

    <span
      ref="eText"
      class="ag-header-cell-text"
      role="columnheader"
      style="margin-right: 4px"
    >
      {{ params?.displayName }}
    </span>
    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon">
      <span
        *ngIf="sortDirection === 'asc'"
        class="ag-icon ag-icon-set asc"
        unselectable="on"
        role="presentation"
      >
      </span>
    </span>
    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon">
      <span
        *ngIf="sortDirection === 'desc'"
        class="ag-icon ag-icon-set desc"
        unselectable="on"
        role="presentation"
      >
      </span>
    </span>
    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
    <span ref="eFilter" class="ag-header-icon ag-filter-icon">
      <span
        *ngIf="isFilterActive"
        class="ag-icon ag-icon-filter filter"
        unselectable="on"
        role="presentation"
      >
      </span>
    </span>
  </div>
</div>
