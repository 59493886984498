import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../component-dialogs/confirm-dialog/confirm-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(
    private _matDialog: MatDialog
  ) { }

  async open(
    title: string, text: string | undefined,
    ok: () => Promise<any>,
    notOk?: () => Promise<any>,
    afterClosed?: (ok: boolean) => any,
    dialogOptions?: { maxWidth: string | number | undefined }) {
    const data: ConfirmDialogData = {
      title,
      text,
      ok,
      notOk
    }

    const dialog = this._matDialog.open(ConfirmDialogComponent, {
      data,
      autoFocus: false,
      maxWidth: dialogOptions?.maxWidth
    });

    const closedWithTrue: boolean = await firstValueFrom(dialog.afterClosed());
    if (afterClosed) {
      await afterClosed(closedWithTrue);
    }
  }
}
