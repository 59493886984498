import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import {
  CreatePlanStepAssetsMutationArgs,
  CreatePlanStepAssetsMutationRoot,
  DeletePlanStepAssetsMutationArgs,
  DeletePlanStepAssetsMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/planStep';
import {
  PlanStepAssetsQueryArgs,
  PlanStepAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planStepAsset';
import { FULL_FRAGMENT_PLAN_STEP_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAsset';
import { PlanAssetOutput } from 'projects/shared/src/lib/graphql/output/planAssetOutput';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { PlanStepAssetOutput } from 'projects/shared/src/lib/graphql/output/planStepAssetOutput';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { Subscription, firstValueFrom } from 'rxjs';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Clipboard } from '@angular/cdk/clipboard';
import { DesktopToastService } from 'projects/desktop/src/app/services/desktop-toast.service';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import {
  AssetColumnService,
  SPECIAL_NAMED_HEADER_NAME,
} from 'projects/shared/src/lib/services/asset-column.service';
import { SelectionService } from 'projects/desktop/src/app/services/selection.service';

const PlanStepAssetsQuery = gql`
  ${FULL_FRAGMENT_PLAN_STEP_ASSET}
  query PlanStepAssets($planId: String!, $planStepId: String!) {
    planStepAssets(planId: $planId, planStepId: $planStepId) {
      ...FullFragmentPlanStepAsset
    }
  }
`;

@Component({
  selector: 'app-plan-step-assets',
  templateUrl: './plan-step-assets.component.html',
  styleUrls: ['./plan-step-assets.component.scss'],
})
export class PlanStepAssetsComponent implements OnInit, OnDestroy {
  @Input() plan!: PlanOutput;
  @Input() planStep!: PlanStepOutput;
  @Input() planAssets!: PlanAssetOutput[] | undefined;

  planStepAssets: PlanStepAssetOutput[] = [];

  loading = false;
  errorMessage: string | undefined;
  missingColumnField: string | undefined;

  #planStepAssetsQuery: QueryRef<PlanStepAssetsQueryRoot> | undefined;
  #planStepAssetsSubscription: Subscription | undefined;

  constructor(
    private apollo: Apollo,
    private toastService: DesktopToastService,
    public clipboard: Clipboard,
    public assetService: AssetService,
    private assetColumnService: AssetColumnService,
    private selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    const tenantId = this.selectionService.selectedTenant?.id;
    if (!tenantId) {
      return;
    }

    this.assetColumnService.assureColumns(tenantId).then((columns) => {
      const missingColumn = columns?.find((x) => x[1] === SPECIAL_NAMED_HEADER_NAME.Missing);
      this.missingColumnField = missingColumn?.[0];
      this.#loadData();
    });
  }

  ngOnDestroy(): void {
    this.#planStepAssetsSubscription?.unsubscribe();
  }

  async removeAssetFromPlanStep(planStepAsset: PlanStepAssetOutput) {
    try {
      const variables: DeletePlanStepAssetsMutationArgs = {
        planStepId: planStepAsset.planStepId,
        assetIds: [planStepAsset.tenantAssetId],
      };

      await firstValueFrom(
        this.apollo.mutate<DeletePlanStepAssetsMutationRoot>({
          mutation: gql`
            mutation DeletePlanStepAssets($planStepId: String!, $assetIds: [String!]!) {
              deletePlanStepAssets(planStepId: $planStepId, assetIds: $assetIds) {
                id
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            const variables: PlanStepAssetsQueryArgs = {
              planId: this.plan.id,
              planStepId: this.planStep.id,
            };

            const cachedPlanStepAssets = cache.readQuery<PlanStepAssetsQueryRoot>({
              query: PlanStepAssetsQuery,
              variables,
            })?.planStepAssets;

            if (typeof cachedPlanStepAssets === 'undefined') {
              return;
            }

            const index = cachedPlanStepAssets.findIndex((x) => x.id === planStepAsset.id);
            if (index === -1) {
              return;
            }

            const clonedArray = Array.from(cachedPlanStepAssets);
            clonedArray.splice(index, 1);

            cache.writeQuery<PlanStepAssetsQueryRoot>({
              query: PlanStepAssetsQuery,
              variables,
              data: {
                planStepAssets: clonedArray,
              },
            });
          },
        })
      );
    } catch (error) {
      const message = new CatchError(error).message;
      this.toastService.error(message, 'Error');
    }
  }

  async addAssetToPlanStep(value: string) {
    // This method is triggered with a full VALUE of an option from the autocomplete options
    // OR
    // with a possible incomplete VALUE from the input."

    // First get the tenantAssetIds of the plan that are NOT used within this planStep.
    const unusedTenantAssetIds = this.planAssets
      ?.map((x) => x.tenantAssetId)
      .filter((y) => !this.planStepAssets?.map((z) => z.tenantAssetId).includes(y));
    if (typeof unusedTenantAssetIds === 'undefined') {
      return;
    }

    // Now check if the provided "value" has a FULL match.
    let matchTenantAssetId: string | undefined;
    for (let unusedTenantAssetId of unusedTenantAssetIds) {
      if (unusedTenantAssetId.toLowerCase() === value.toLowerCase()) {
        matchTenantAssetId = unusedTenantAssetId;
        break;
      }
    }

    if (!matchTenantAssetId) {
      return;
    }

    // We have a match and found the tenantAssetId that we need to add to the plan step.

    try {
      const variables: CreatePlanStepAssetsMutationArgs = {
        planStepId: this.planStep.id,
        assetIds: [matchTenantAssetId],
      };

      await firstValueFrom(
        this.apollo.mutate<CreatePlanStepAssetsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN_STEP_ASSET}
            mutation CreatePlanStepAssets($planStepId: String!, $assetIds: [String!]!) {
              createPlanStepAssets(planStepId: $planStepId, assetIds: $assetIds) {
                ...FullFragmentPlanStepAsset
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            const updateVariables: PlanStepAssetsQueryArgs = {
              planId: this.plan.id,
              planStepId: this.planStep.id,
            };

            const cachedPlanStepAssets = cache.readQuery<PlanStepAssetsQueryRoot>({
              query: PlanStepAssetsQuery,
              variables: updateVariables,
            })?.planStepAssets;

            if (typeof cachedPlanStepAssets === 'undefined') {
              return;
            }

            cache.writeQuery<PlanStepAssetsQueryRoot>({
              query: PlanStepAssetsQuery,
              variables: updateVariables,
              data: {
                planStepAssets: [...cachedPlanStepAssets, ...(data?.createPlanStepAssets ?? [])],
              },
            });
          },
        })
      );
    } catch (error) {
      const message = new CatchError(error).message;
      this.toastService.error(message, 'Error');
    } finally {
      //this._preventLoadingAllAssets = false;
    }
  }

  #loadData() {
    this.loading = true;

    const variables: PlanStepAssetsQueryArgs = {
      planId: this.plan.id,
      planStepId: this.planStep.id,
    };

    this.#planStepAssetsQuery = this.apollo.watchQuery<PlanStepAssetsQueryRoot>({
      query: PlanStepAssetsQuery,
      variables,
      fetchPolicy: 'cache-first',
    });

    this.#planStepAssetsSubscription = this.#planStepAssetsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (!loading) {
          this.planStepAssets = data.planStepAssets;
          this.loading = false;
        }
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this.errorMessage = message;
        this.loading = false;
      },
    });
  }

  getUnusedTenantAssetsIds(searchString: string): string[] {
    const notUsedPlanAssets = this.planAssets?.filter(
      (x) => !this.planStepAssets?.map((y) => y.tenantAssetId).includes(x.tenantAssetId)
    );

    const returnMap: string[] = [];
    for (let tenantAssetId of notUsedPlanAssets?.map((x) => x.tenantAssetId) ?? []) {
      if (!tenantAssetId.toLowerCase().includes(searchString.toLowerCase())) {
        continue;
      }

      returnMap.push(tenantAssetId);
    }

    return returnMap;
  }
}
