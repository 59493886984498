import {Component, Inject, OnInit} from '@angular/core';
import {MTX_DRAWER_DATA, MtxDrawerRef} from '@ng-matero/extensions/drawer';
import {ActionTypeOutput} from 'projects/shared/src/lib/graphql/output/actionTypeOutput';
import {
  CreateTenantActionsMutationArgs,
  CreateTenantActionsMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import {ActionFrom} from 'projects/shared/src/lib/graphql/enums/actionFrom';
import {ActionTo} from 'projects/shared/src/lib/graphql/enums/actionTo';
import {Apollo, gql} from 'apollo-angular';
import {firstValueFrom} from 'rxjs';
import {
  FULL_FRAGMENT_TENANT_ACTION
} from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import {CatchError} from 'projects/shared/src/lib/classes/catch-error';
import {AssortmentService} from 'projects/shared/src/lib/services/assortment.service';
import {
  LocalEventData_BookingRealtime,
  LocalEventService,
  LocalEventType,
} from '../../services/local-event.service';
import {AppModule} from '../../app.module';
import {SelectionService} from '../../services/selection.service';
import {ActionType} from 'projects/shared/src/lib/graphql/enums/actionType';

export type BookRealtimeActionData = {
  actionTypeId: number;
  transitLocationId?: string;
  fromType: ActionFrom;
  fromDetails: string;
  toType: ActionTo;
  toDetails: string;
  description?: string;
  tenantAssetIds: string[];
};

export type BookRealtimeActionResult = string[];

type BookResult = {
  ongoing: boolean;
  finishedOk: boolean;
  details?: string;
};

@Component({
  selector: 'app-book-realtime-action',
  templateUrl: './book-realtime-action.component.html',
  styleUrls: ['./book-realtime-action.component.scss'],
})
export class BookRealtimeActionComponent implements OnInit {
  actionType: ActionTypeOutput | undefined;
  bookResults = new Map<string, BookResult>();
  bookingFinished = false;

  get bookingFinishedOk(): boolean {
    return (
      this.bookingFinished &&
      Array.from(this.bookResults.values()).every((x) => x.finishedOk === true)
    );
  }

  constructor(
    public drawerRef: MtxDrawerRef<BookRealtimeActionComponent>,
    @Inject(MTX_DRAWER_DATA) public data: BookRealtimeActionData,
    private _assortmentService: AssortmentService,
    private _apollo: Apollo,
    private _localEventService: LocalEventService,
    private _selectionService: SelectionService
  ) {
  }

  ngOnInit(): void {
    this.#loadData().then(() => this.#bookData());
  }

  onClickClose() {
    if (!this.bookingFinished) {
      return;
    }

    this.close();
  }

  close() {
    // Return those tenantAssetIds where the booking was successful.
    const result: BookRealtimeActionResult = [];
    for (const bookResult of this.bookResults) {
      if (bookResult[1].finishedOk) {
        result.push(bookResult[0]);
      }
    }

    this.drawerRef.dismiss(result);
  }

  async #loadData() {
    const actionTypes = await this._assortmentService.getActionTypesAsync();
    this.actionType = actionTypes.find((x) => x.id === this.data.actionTypeId);
  }

  async #bookData() {
    for (const tenantAssetId of this.data.tenantAssetIds.sortBy((x) => x)) {
      const bookResult: BookResult = {
        ongoing: true,
        finishedOk: false,
      };

      this.bookResults.set(tenantAssetId, bookResult);
    }

    try {
      const variables: CreateTenantActionsMutationArgs = {
        assetIds: this.data.tenantAssetIds,
        data: {
          actionTypeId: this.data.actionTypeId,

          transitLocationId:
            this.data.actionTypeId === ActionType.Handover ? this.data.transitLocationId : null,

          fromUserOid: this.data.fromType == ActionFrom.User ? this.data.fromDetails : null,
          fromLocationId:
            this.data.fromType == ActionFrom.Location ? this.data.fromDetails : null,
          fromMail: this.data.fromType == ActionFrom.Mail ? this.data.fromDetails : null,
          fromOther: this.data.fromType == ActionFrom.Other ? this.data.fromDetails : null,

          toUserOid: this.data.toType == ActionTo.User ? this.data.toDetails : null,
          toLocationId: this.data.toType == ActionTo.Location ? this.data.toDetails : null,
          toMail: this.data.toType == ActionTo.Mail ? this.data.toDetails : null,
          toOther: this.data.toType == ActionTo.Other ? this.data.toDetails : null,

          description: this.data.description,
        },
      };

      await firstValueFrom(
        this._apollo.mutate<CreateTenantActionsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            mutation CreateTenantActions($assetIds: [String!]!, $data: TenantActionInputCreate!) {
              createTenantActions(assetIds: $assetIds, data: $data) {
                ...FullFragmentTenantAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, {data}) => {
            if (!data?.createTenantActions) {
              return;
            }

            const eventData: LocalEventData_BookingRealtime = {
              filterSessionId: AppModule.sessionId,
              data: [], // fill below
            };

            for (const tenantAction of data.createTenantActions) {
              eventData.data?.push({
                action: 'created',
                bookingType: 'realtime',
                bookingUserOid: this._selectionService.myUser?.oid ?? 'na',
                bookingData: tenantAction,
              });
            }

            this._localEventService.emitNewEvent(LocalEventType.BookingRealtime, eventData);
          },
        })
      );

      this.markEverythingAsFinished(this.data.tenantAssetIds, true);
    } catch (error) {
      this.markEverythingAsFinished(this.data.tenantAssetIds, false, new CatchError(error).message);
    }

    this.bookingFinished = true;
  }
  // This method is used to mark all the assets as finished.
  // Now we use all or nothing approach, so if one asset fails, all assets are marked as failed.
  // In the future, we might want to change this to mark each asset individually.
  markEverythingAsFinished(assetIds: string[], success: boolean, details?: string) {
    assetIds.forEach((assetId) => {
      const bookResult = this.bookResults.get(assetId);
      if (bookResult) {
        bookResult.finishedOk = success;
        bookResult.details = details;
        bookResult.ongoing = false;
      }
    })
  }
}


