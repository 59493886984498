import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_INVENTORY_ASSET } from './fullFragmentInventoryAsset';

export const FULL_FRAGMENT_INVENTORY_STATISTICS = gql`
  ${FULL_FRAGMENT_INVENTORY_ASSET}
  fragment FullFragmentInventoryStatistics on InventoryStatisticsOutput {
    noOfOverallAssets
    noOfConfirmedAssets
    firstConfirmation {
      ...FullFragmentInventoryAsset
    }
    latestConfirmation {
      ...FullFragmentInventoryAsset
    }
    topConfirmors {
      noOfConfirmations
      userOid
    }
  }
`;
