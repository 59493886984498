import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_USER_CONSTRAINT = gql`
  fragment FullFragmentUserConstraint on UserConstraintOutput {
    id
    tenantId
    userOid
    propertyId
    value
    createdAt
  }
`;
