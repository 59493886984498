import { Injectable, EventEmitter } from '@angular/core';
import {
  LocalEventData_PlanAssetsAdded,
  LocalEventData_PlanAssetDeleted,
  LocalEvent,
  LocalEventService,
  LocalEventType,
  LocalEventData_AssetMissing,
  LocalEventData_AssetDefect,
} from '../local-event.service';
import {
  RemoteEvent,
  RemoteEventData_AssetDefect,
  RemoteEventData_AssetMissing,
  RemoteEventData_PlanAssetsAdded,
  RemoteEventData_PlanAssetsDeleted,
  RemoteEventService,
  RemoteEventType,
} from '../remote-event.service';
import { AppModule } from '../../app.module';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { PlanQueryArgs, PlanQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';
import { FULL_FRAGMENT_PLAN_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanAsset';
import {
  PlanAssetsQueryArgs,
  PlanAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planAsset';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import { handle_BookingRealtime } from './handleBookingRealtime';
import { handle_BookingPlanned } from './handleBookingPlanned';
import {
  handle_InventoryAssetsCreated,
  handle_InventoryAssetsDeleted,
} from './handleInventoryAssets';
import { handle_Inventory } from './handleInventory';
import { handle_ReturnToCustomerNote } from './handleReturnToCustomerNote';
import { handle_ReturnToCustomer } from './handleReturnToCustomer';
import { handle_ReturnToCustomerAsset } from './handleReturnToCustomerAsset';
import { handle_Plan } from './handlePlan';

export enum EventSource {
  Local,
  RemoteMySession,
  RemoteOtherSession,
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly localEventHandled = new EventEmitter<[LocalEventType, any]>();
  readonly remoteEventHandled = new EventEmitter<[RemoteEventType, any]>();

  #handleLocalEvent = new Map<LocalEventType, number>();
  #handleRemoteEvent = new Map<RemoteEventType, number>();

  constructor(
    private _localEventService: LocalEventService,
    private _remoteEventService: RemoteEventService,
    public apollo: Apollo,
    public assetService: AssetService
  ) {}

  initialize() {
    // Subscribe to local events.
    this._localEventService.newEvent.subscribe({
      next: (event: LocalEvent) => {
        const source = EventSource.Local;
        if (event.type === LocalEventType.PlanAssetDeleted) {
          this.#handle_PlanAssetDeleted(source, event.data);
        } else if (event.type === LocalEventType.PlanAssetsAdded) {
          this.#handle_PlanAssetAdded(source, event.data);
        } else if (event.type === LocalEventType.AssetMissing) {
          this.#handle_AssetMissing(source, event.data);
        } else if (event.type === LocalEventType.AssetDefect) {
          this.#handle_AssetDefect(source, event.data);
        } else if (event.type === LocalEventType.BookingRealtime) {
          handle_BookingRealtime.call(this, source, event.data);
        } else if (event.type === LocalEventType.BookingPlanned) {
          handle_BookingPlanned.call(this, source, event.data);
        } else if (event.type === LocalEventType.InventoryAssetsCreated) {
          handle_InventoryAssetsCreated.call(this, source, event.data);
        } else if (event.type === LocalEventType.InventoryAssetsDeleted) {
          handle_InventoryAssetsDeleted.call(this, source, event.data);
        } else if (event.type === LocalEventType.Inventory) {
          handle_Inventory.call(this, source, event.data);
        } else if (event.type === LocalEventType.ReturnToCustomerNote) {
          handle_ReturnToCustomerNote.call(this, source, event.data);
        } else if (event.type === LocalEventType.ReturnToCustomer) {
          handle_ReturnToCustomer.call(this, source, event.data);
        } else if (event.type === LocalEventType.ReturnToCustomerAsset) {
          handle_ReturnToCustomerAsset.call(this, source, event.data);
        } else if (event.type === LocalEventType.Plan) {
          handle_Plan.call(this, source, event.data);
        }
      },
    });

    // Subscribe to remote events.
    this._remoteEventService.newEvent.subscribe({
      next: (event: RemoteEvent) => {
        const source =
          AppModule.sessionId === event.data.filterSessionId
            ? EventSource.RemoteMySession
            : EventSource.RemoteOtherSession;

        if (event.type === RemoteEventType.PlanAssetsDeleted) {
          this.#handle_PlanAssetDeleted(source, event.data);
        } else if (event.type === RemoteEventType.PlanAssetsAdded) {
          this.#handle_PlanAssetAdded(source, event.data);
        } else if (event.type === RemoteEventType.AssetMissing) {
          this.#handle_AssetMissing(source, event.data);
        } else if (event.type === RemoteEventType.AssetDefect) {
          this.#handle_AssetDefect(source, event.data);
        } else if (event.type === RemoteEventType.BookingRealtime) {
          handle_BookingRealtime.call(this, source, event.data);
        } else if (event.type === RemoteEventType.BookingPlanned) {
          handle_BookingPlanned.call(this, source, event.data);
        } else if (event.type === RemoteEventType.InventoryAssetsCreated) {
          handle_InventoryAssetsCreated.call(this, source, event.data);
        } else if (event.type === RemoteEventType.InventoryAssetsDeleted) {
          handle_InventoryAssetsDeleted.call(this, source, event.data);
        } else if (event.type === RemoteEventType.Inventory) {
          handle_Inventory.call(this, source, event.data);
        } else if (event.type === RemoteEventType.ReturnToCustomerNote) {
          handle_ReturnToCustomerNote.call(this, source, event.data);
        } else if (event.type === RemoteEventType.ReturnToCustomer) {
          handle_ReturnToCustomer.call(this, source, event.data);
        } else if (event.type === RemoteEventType.ReturnToCustomerAsset) {
          handle_ReturnToCustomerAsset.call(this, source, event.data);
        } else if (event.type === RemoteEventType.Plan) {
          handle_Plan.call(this, source, event.data);
        }
      },
    });

    this._remoteEventService.initialize();
  }

  handleLocalEvents(types: LocalEventType[]) {
    for (const type of types) {
      const noOfSubscribers = this.#handleLocalEvent.get(type);
      if (typeof noOfSubscribers === 'undefined') {
        this.#handleLocalEvent.set(type, 1);
      } else {
        this.#handleLocalEvent.set(type, noOfSubscribers + 1);
      }
    }
  }

  handleRemoteEvents(types: RemoteEventType[]) {
    for (const type of types) {
      const noOfSubscribers = this.#handleRemoteEvent.get(type);
      if (typeof noOfSubscribers === 'undefined') {
        this.#handleRemoteEvent.set(type, 1);
      } else {
        this.#handleRemoteEvent.set(type, noOfSubscribers + 1);
      }
    }
  }

  unhandleLocalEvents(types: LocalEventType[]) {
    for (const type of types) {
      const noOfSubscribers = this.#handleLocalEvent.get(type);
      if (typeof noOfSubscribers !== 'undefined' && noOfSubscribers > 0) {
        this.#handleLocalEvent.set(type, noOfSubscribers - 1);
      }
    }
  }

  unhandleRemoteEvents(types: RemoteEventType[]) {
    for (const type of types) {
      const noOfSubscribers = this.#handleRemoteEvent.get(type);
      if (typeof noOfSubscribers !== 'undefined' && noOfSubscribers > 0) {
        this.#handleRemoteEvent.set(type, noOfSubscribers - 1);
      }
    }
  }

  //#region HANDLE Events

  async #handle_PlanAssetDeleted(
    eventSource: EventSource,
    eventData: LocalEventData_PlanAssetDeleted | RemoteEventData_PlanAssetsDeleted
  ) {
    // Handle: LOCAL event source.

    if (eventSource === EventSource.RemoteMySession) {
      // DO NOTHING. This client will receive (or has received) a local trigger as well.
      return;
    } else if (eventSource === EventSource.Local) {
      const noOfSubscribers = this.#handleLocalEvent.get(LocalEventType.PlanAssetDeleted) ?? 0;
      if (noOfSubscribers === 0) {
        // Currently there is no local subscriber that wants us to handle the event.
        return;
      }

      // TODO (MAYBE)
      this.localEventHandled.next([LocalEventType.PlanAssetDeleted, eventData]);
    } else if (eventSource === EventSource.RemoteOtherSession) {
      const noOfSubscribers = this.#handleRemoteEvent.get(RemoteEventType.PlanAssetsDeleted) ?? 0;
      if (noOfSubscribers === 0) {
        // Currently there is no local subscriber that wants us to handle the event.
        return;
      }

      // For the PLAN BUILDER:
      // Manipulate the CACHE for the planAssetsQuery
      const castedEventData = eventData as RemoteEventData_PlanAssetsDeleted;
      if (castedEventData.data != null) {
        const relevantPlanIds = Array.from(
          new Set<string>(castedEventData.data.map((x) => x.plan.id))
        );

        for (const planId of relevantPlanIds) {
          const relevantPlanAssets =
            castedEventData.data.find((x) => x.plan.id === planId)?.plan.planAssets ?? [];
          if (relevantPlanAssets.length === 0) {
            continue;
          }

          const variables: PlanAssetsQueryArgs = {
            planId,
          };
          // Directly write the full (notified) array to the cache for the given plan.
          const cachedResult = this.apollo.client.cache.writeQuery<PlanAssetsQueryRoot>({
            query: gql`
              ${FULL_FRAGMENT_PLAN_ASSET}
              query PlanAssets($planId: String!) {
                planAssets(planId: $planId) {
                  ...FullFragmentPlanAsset
                }
              }
            `,
            data: { planAssets: relevantPlanAssets },
            variables,
          });
        }
      }

      this.remoteEventHandled.next([RemoteEventType.PlanAssetsDeleted, eventData]);
    }
  }

  /**
   * The mutation to add one or multiple assets to a plan returns an array: PlanAssetOutput[].
   * There are areas in this client that work with a plan at root and include the
   * relation "planAssets". These areas will NOT be updated automatically with the newly created assets.
   */
  async #handle_PlanAssetAdded(
    eventSource: EventSource,
    eventData: LocalEventData_PlanAssetsAdded | RemoteEventData_PlanAssetsAdded
  ) {
    if (eventSource === EventSource.RemoteMySession) {
      // DO NOTHING. This client will receive (or has received) a local trigger as well.
    } else if (eventSource === EventSource.Local) {
      const noOfSubscribers = this.#handleLocalEvent.get(LocalEventType.PlanAssetsAdded) ?? 0;
      if (noOfSubscribers === 0) {
        // Currently there is no subscriber that wants us to handle the event.
        return;
      }

      const innerEventData = eventData as LocalEventData_PlanAssetsAdded;

      const variables: PlanQueryArgs = {
        id: innerEventData.planId,
      };

      await firstValueFrom(
        this.apollo.query<PlanQueryRoot>({
          query: gql`
            query plan($id: String!) {
              plan(id: $id) {
                id
                planAssets {
                  id
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.localEventHandled.next([LocalEventType.PlanAssetsAdded, eventData]);
    } else if (eventSource === EventSource.RemoteOtherSession) {
      const noOfSubscribers = this.#handleRemoteEvent.get(RemoteEventType.PlanAssetsAdded) ?? 0;
      if (noOfSubscribers === 0) {
        // Currently there is no subscriber that wants us to handle the event.
        return;
      }

      // For the PLAN BUILDER:
      // Manipulate the CACHE for the planAssetsQuery
      const castedEventData = eventData as RemoteEventData_PlanAssetsAdded;
      if (castedEventData.data != null) {
        const relevantPlanIds = Array.from(
          new Set<string>(castedEventData.data.map((x) => x.plan.id))
        );

        for (const planId of relevantPlanIds) {
          const relevantPlanAssets =
            castedEventData.data.find((x) => x.plan.id === planId)?.plan.planAssets ?? [];
          if (relevantPlanAssets.length === 0) {
            continue;
          }

          const variables: PlanAssetsQueryArgs = {
            planId,
          };
          // Directly write the full (notified) array to the cache for the given plan.
          const cachedResult = this.apollo.client.cache.writeQuery<PlanAssetsQueryRoot>({
            query: gql`
              ${FULL_FRAGMENT_PLAN_ASSET}
              query PlanAssets($planId: String!) {
                planAssets(planId: $planId) {
                  ...FullFragmentPlanAsset
                }
              }
            `,
            data: { planAssets: relevantPlanAssets },
            variables,
          });
        }
      }

      this.remoteEventHandled.next([RemoteEventType.PlanAssetsAdded, eventData]);
    }
  }

  async #handle_AssetMissing(
    eventSource: EventSource,
    eventData: LocalEventData_AssetMissing | RemoteEventData_AssetMissing
  ) {
    if (eventSource === EventSource.RemoteMySession) {
      // DO NOTHING. This client will receive (or has received) a local trigger as well.
    } else if (
      eventSource === EventSource.Local ||
      eventSource === EventSource.RemoteOtherSession
    ) {
      // Do the following ALWAYS:
      const assetIds = eventData.data?.map((x) => x.assetId) ?? [];
      await this.assetService.fetchMultiple(assetIds, 'network-only');

      // Do the next things only if some subscribed.

      const noOfSubscribersLocal = this.#getNoOfSubscribers(
        EventSource.Local,
        LocalEventType.AssetMissing
      );
      if (eventSource === EventSource.Local && noOfSubscribersLocal === 0) {
        return;
      }

      const noOfSubscribersRemote = this.#getNoOfSubscribers(
        EventSource.RemoteOtherSession,
        RemoteEventType.AssetMissing
      );
      if (eventSource === EventSource.RemoteOtherSession && noOfSubscribersRemote === 0) {
        return;
      }

      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.AssetMissing, eventData]);
    }
  }

  async #handle_AssetDefect(
    eventSource: EventSource,
    eventData: LocalEventData_AssetDefect | RemoteEventData_AssetDefect
  ) {
    if (eventSource === EventSource.RemoteMySession) {
      // DO NOTHING. This client will receive (or has received) a local trigger as well.
    } else if (
      eventSource === EventSource.Local ||
      eventSource === EventSource.RemoteOtherSession
    ) {
      // Do the following ALWAYS:
      const assetIds = eventData.data?.map((x) => x.assetDefect.assetId) ?? [];
      await this.assetService.fetchMultiple(assetIds, 'network-only');

      // Do the next things only if some subscribed.

      const noOfSubscribersLocal = this.#getNoOfSubscribers(
        EventSource.Local,
        LocalEventType.AssetDefect
      );
      if (eventSource === EventSource.Local && noOfSubscribersLocal === 0) {
        return;
      }

      const noOfSubscribersRemote = this.#getNoOfSubscribers(
        EventSource.RemoteOtherSession,
        RemoteEventType.AssetDefect
      );
      if (eventSource === EventSource.RemoteOtherSession && noOfSubscribersRemote === 0) {
        return;
      }

      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.AssetDefect, eventData]);
    }
  }

  //#endregion HANDLE Events

  hasSubscribers(eventSource: EventSource, eventType: LocalEventType | RemoteEventType): boolean {
    const noOfSubscribers = this.#getNoOfSubscribers(eventSource, eventType);
    return noOfSubscribers > 0;
  }

  #getNoOfSubscribers(
    eventSource: EventSource,
    eventType: LocalEventType | RemoteEventType
  ): number {
    if (eventSource === EventSource.Local) {
      return this.#handleLocalEvent.get(eventType as LocalEventType) ?? 0;
    }

    return this.#handleRemoteEvent.get(eventType as RemoteEventType) ?? 0;
  }
}
