import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { SelectionService } from '../../services/selection.service';
import { TenantOutput } from 'projects/shared/src/lib/graphql/output/tenantOutput';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { UserConfigService } from 'projects/shared/src/lib/services/user-config.service';
import * as Sentry from '@sentry/angular-ivy';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
@Sentry.TraceClassDecorator()
export class HomeComponent implements OnInit {
  account: AccountInfo | undefined;

  constructor(
    private _msalService: MsalService,
    public selectionService: SelectionService,
    private _userConfigService: UserConfigService,
    private _router: Router,
    private _http: HttpClient,
    private _assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.account = this._msalService.instance.getAllAccounts()[0];
  }

  async onClickTenant(tenant: TenantOutput) {
    await this._userConfigService.updateAsync({
      tenantId: tenant.id,
    });
    this.selectionService.selectedTenant = tenant;
    this._assetService.setTenantId(tenant.id);
    this._router.navigateByUrl('/home');
  }

  niceifyName(name: string | undefined) {
    if (typeof name === 'undefined' || !name.includes(',')) {
      return name;
    }

    return name.split(',')[1].trim();
  }

  async test() {
    const url = 'https://graph.microsoft.com/v1.0/users/christian.ellerbrock@accenture.com';

    const result = await firstValueFrom(this._http.get(url));
  }
}
