import { Component, Input } from '@angular/core';
import { LocationOutput } from '../../graphql/output/locationOutput';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { LocationsQueryRoot } from '../../graphql/crud/location';
import { FULL_FRAGMENT_LOCATION } from '../../graphql/fragments/fullFragmentLocation';
import { LocationSelectComponent } from '../location-select/location-select.component';

@Component({
  selector: 'lib-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent {
  @Input()
  get locationId(): string | undefined {
    return this._locationId;
  }
  set locationId(value: string | undefined) {
    this._locationId = value;
    if (value) {
      this._loadLocation(value);
    }
  }

  location: LocationOutput | undefined;
  activity = true;
  getLocationDisplayString = LocationSelectComponent.getLocationDisplayString;

  private _locationId: string | undefined;

  constructor(private _apollo: Apollo) {}

  private async _loadLocation(locationId: string) {
    this.activity = true;
    try {
      const result = await firstValueFrom(
        this._apollo.query<LocationsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_LOCATION}
            query Locations {
              locations {
                ...FullFragmentLocation
              }
            }
          `,
          fetchPolicy: 'cache-first',
        })
      );
      this.location = result.data.locations.find((x) => x.id === locationId);
    } catch (error) {
      console.log(error);
    } finally {
      this.activity = false;
    }
  }
}
