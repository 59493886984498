import { ChangeType } from '../enums/changeType';
import { AssetOutput } from '../output/assetOutput';
import { ChangeAssetOutput } from '../output/changeAssetOutput';
import { ChangeAssetPropertyChangeable } from './changeAssetPropertyChangeable';
import { Changeable } from "./changeable";

export class ChangeAssetChangeable extends Changeable<ChangeAssetOutput> {
    // #region Changeable Properties

    markAsToBeDeleted: boolean = false;
    changeTypeId!: number;

    name!: string;
    isActive!: boolean;
    changeAssetProperties: ChangeAssetPropertyChangeable[] = [];
    hasChangedToDatabaseSource = false;

    // #endregion Changeable Properties


    // #region Immutable Properties

    private _originalName!: string;
    get originalName() {
        return this._originalName;
    }

    private _originalIsActive!: boolean;
    get originalIsActive() {
        return this._originalIsActive;
    }

    // #endregion Immutable Properties


    // #region Computed Properties

    get isLocked(): boolean {
        if (this.databaseSource?.approvedAt) {
            return true;
        }
        return false;
    }

    // #endregion Computed Properties


    // #region Immutables

    get asset(): AssetOutput | undefined {
        return this._asset;
    }
    private _asset: AssetOutput | undefined;

    // #endregion Immutables


    // #region Constructor

    constructor(databaseSource: ChangeAssetOutput | undefined, assetOutput: AssetOutput | undefined = undefined) {
        if (!databaseSource && !assetOutput) {
            throw new Error('Cannot create ChangeAssetChangeable. Please provide an asset.')
        }

        super(databaseSource);

        if (!databaseSource) {
            this._asset = assetOutput;
        }
        this.applyDatabaseSource();
    }

    // #endregion Constructor


    // #region Public Methods

    hasChangedToOriginal(): boolean {
        if (this.name !== this.originalName) {
            return true;
        }
        if (this.isActive !== this.originalIsActive) {
            return true;
        }

        return false;
    }

    evaluateHasChangedToDatabaseSource() {
        let hasChanged = false;

        if (this.databaseSource?.name !== this.name) {
            hasChanged = true;
        } else if (this.databaseSource.isActive !== this.isActive) {
            hasChanged = true;
        }

        // TODO Evaluate changeAssetProperties
        for (let x of this.changeAssetProperties) {
            if (x.hasChangedToDatabaseSource) {
                hasChanged = true;
                break;
            }
        }

        this.hasChangedToDatabaseSource = hasChanged;
    }

    isLiveObject(live: ChangeAssetChangeable | undefined) {
        return this === live;
    }

    applyDatabaseSource(databaseSource: ChangeAssetOutput | undefined = undefined) {
        if (databaseSource) {
            this.setDatabaseSource(databaseSource);
        }

        if (!this.databaseSource) {
            // We create this object "blank". There is no saved record available.
            // Use the asset as source. 
            this.changeTypeId = ChangeType.Keep;
            this.name = this.asset?.name ?? 'Will not be seen';
            this._originalName = this.asset?.name ?? 'Will not be seen';
            this.isActive = this.asset?.isActive ?? false;
            this._originalIsActive = this.asset?.isActive ?? false;

            for (let x of this.asset?.assetProperties ?? []) {
                this.changeAssetProperties.push(new ChangeAssetPropertyChangeable(undefined, x));
            }
        } else {
            // We create this object from an already saved record.
            // Use the saved record as source.
            this.changeTypeId = this.databaseSource.changeTypeId;
            this.name = this.databaseSource.name;
            this._originalName = this.databaseSource.originalName;
            this.isActive = this.databaseSource.isActive
            this._originalIsActive = this.databaseSource.originalIsActive;
            this.changeAssetProperties = [];
            for (let x of this.databaseSource.changeAssetProperties ?? []) {
                this.changeAssetProperties.push(new ChangeAssetPropertyChangeable(x));
            }
        }

        this.evaluateHasChangedToDatabaseSource();
    }

    // #endregion Public Methods
}