import { v4 } from "uuid";

export class Changeable<T> {
    readonly id = v4();


    get databaseSource(): T | undefined {
        return this._databaseSource;
    }
    private _databaseSource: T | undefined;

    constructor(databaseSource: T | undefined) {
        if (databaseSource) {
            this._databaseSource = databaseSource;
        }
    }

    protected setDatabaseSource(databaseSource: T) {
        this._databaseSource = databaseSource;
    }

    isAlreadyPersistedObject() {
        return typeof this.databaseSource !== 'undefined';
    }
}