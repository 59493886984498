
export enum PropertyLevel {
    System = 1,
    Tenant = 2,
    Asset = 3
}

export const propertyLevels = new Map<number, string>([
    [PropertyLevel.System, "System"],
    [PropertyLevel.Tenant, "Tenant"],
    [PropertyLevel.Asset, "Asset"]
]);
