import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hover-icon',
  templateUrl: './hover-icon.component.html',
  styleUrls: ['./hover-icon.component.scss'],
})
export class HoverIconComponent {
  @Input({ required: true }) icon: string = 'help';
  @Input() color: 'primary' | 'accent' | 'warn' | undefined;
  @Input() tooltip: string | undefined;
  @Input() disabled: boolean = false;
}
