import { Component, Input } from '@angular/core';
import { LocationNode } from '../../components/design/locations/locations.component';
import { v4 } from 'uuid';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss'],
})
export class EditLocationComponent {
  @Input()
  get locationNode(): LocationNode | undefined {
    return this._locationNode;
  }
  set locationNode(value) {
    this._locationNode = value;
    this._build(value);
  }

  level1: string | undefined;
  level2: string | undefined;
  level3: string | undefined;
  level4: string | undefined;
  level5: string | undefined;

  level1uuid = v4();
  level2uuid = v4();
  level3uuid = v4();
  level4uuid = v4();
  level5uuid = v4();

  private _locationNode: LocationNode | undefined;

  onKeyupEnter(level: number) {
    console.log(level);
  }

  private _build(locationNode: LocationNode | undefined) {
    this.level1 = locationNode?.locations[0].level1;
    this.level2 = locationNode?.locations[0].level2;
    this.level3 = locationNode?.locations[0].level3;
    this.level4 = locationNode?.locations[0].level4;
    this.level5 = locationNode?.locations[0].level5;
  }
}
