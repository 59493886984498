<form class="box" #fileDrop method="post" action="" enctype="multipart/form-data">
    <div class="box__input">

        <i class="material-icons icon">download</i>


        <input #inputElement (change)="onFileSelected($event)" class="box__file" type="file" name="files[]" id="file"
            accept="{{acceptString}}" />

        <ng-container *ngIf="!file">
            <div class="mt-normal flex-row align-items-center" for="file">
                <div class="choose-a-file" (click)="inputElement.click()">Choose a file</div>
                <span class="box__dragndrop">&nbsp;or drop it here.</span>
            </div>
            <div *ngIf="accepts" class="flex-row align-items-center font-size-12 gap-small pointer-events-none">
                <div>Allowed:</div>
                <div *ngFor="let extension of accepts">{{extension}}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="file">
            <div class="flex-row gap-small align-items-center mt-normal">
                <div class="spointer-events-none"><b> {{file.name}}</b></div>
                <button mat-icon-button (click)="file = undefined">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</form>