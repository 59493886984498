import { gql } from "apollo-angular";

export const FULL_FRAGMENT_CHANGE_ASSET = gql`
    fragment FullFragmentChangeAsset on ChangeAssetOutput {
        id
        tenantId
        assetId
        changeTypeId
        originalName
        originalIsActive
        name
        isActive
        readyForApproval
        createdAt
        createdBy
        updatedAt
        updatedBy
        approvedAt
        approvedBy
    }
`;