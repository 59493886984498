import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { PropertyOutput } from 'projects/shared/src/lib/graphql/output/propertyOutput';
import { startWith, map, Observable } from 'rxjs';
import { v4 } from 'uuid';

@Component({
  selector: 'app-auto-complete-property-input',
  templateUrl: './auto-complete-property-input.component.html',
  styleUrls: ['./auto-complete-property-input.component.scss'],
})
export class AutoCompletePropertyInputComponent implements OnInit {
  @Input({ required: true }) properties!: PropertyOutput[];
  @Input() required = false;
  @Input() label: string | undefined;
  @Input() subscriptSizing: SubscriptSizing = 'dynamic';
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() disabled = false;

  @Input()
  get selected() {
    return this.#selected;
  }
  set selected(value) {
    if (value) {
      this.searchValue.setValue(value.displayName);
    }

    if (value == this.#selected) {
      return;
    }

    this.#selected = value;
  }

  @Output()
  selectedChange = new EventEmitter<PropertyOutput | undefined>();

  @Output()
  selectedChanged = new EventEmitter<PropertyOutput | undefined>();

  searchValue: FormControl<string | null> = new FormControl(null);
  filteredProperties: Observable<PropertyOutput[]> | undefined;
  uuid = v4();

  #selected: PropertyOutput | undefined;

  constructor() {}

  ngOnInit(): void {
    if (this.required) {
      this.searchValue = new FormControl(
        { value: null, disabled: this.disabled },
        Validators.required
      );
    } else {
      this.searchValue = new FormControl({ value: null, disabled: this.disabled });
    }

    this.filteredProperties = this.searchValue.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return !value ? this.properties : this.#filter(value);
      })
    );
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.selected = event.option.value;
    this.selectedChange.emit(this.selected);
    this.selectedChanged.emit(this.selected);
  }

  #filter(value: string): PropertyOutput[] {
    if (typeof value === 'object') {
      return this.properties;
    }
    return this.properties.filter((x) => x.displayName.toLowerCase().includes(value.toLowerCase()));
  }
}
