<div class="assets-plans-root">
  <div class="table-actions">
    <div class="section">
      <div>PLANS</div>
      <span
        *ngIf="subscriptionService.online"
        class="material-symbols-outlined color-green prevent-select"
        matTooltipPosition="above"
        matTooltip="Online Sync ON"
      >
        wifi
      </span>
      <span
        *ngIf="!subscriptionService.online"
        class="material-symbols-outlined color-warn-400 prevent-select"
        matTooltipPosition="above"
        matTooltip="Online Sync OFF"
      >
        wifi_off
      </span>
    </div>

    <button
      mat-icon-button
      (click)="clearAllFilters()"
      [disabled]="!canClearFilter"
      matTooltip="Remove all filters"
      matTooltipPosition="above"
    >
      <mat-icon>filter_alt_off</mat-icon>
    </button>

    <div class="actions-divider"></div>

    <!-- LOADING OPTIONS -->
    <div class="loading-options form-field-density-2">
      <!-- LOADING OPTIONS - DROPDOWN -->
      <div class="flex-row align-items-center">
        <span class="mr-small">Plans:</span>
        <mat-form-field subscriptSizing="dynamic">
          <mat-select
            [(ngModel)]="loadPlanOptions.selectedOptions"
            (ngModelChange)="onSelectedOptionsChange($event)"
            multiple
          >
            <mat-option
              *ngFor="let loadPlanOption of loadPlanOptions.options"
              [value]="loadPlanOption"
              >{{ loadPlanOption }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <button
          mat-icon-button
          style="margin-left: 2px"
          #popoverTrigger="mtxPopoverTrigger"
          [mtxPopoverTriggerFor]="planOptionsPopover"
          mtxPopoverTriggerOn="click"
          [mtxPopoverTriggerData]="{ loadPlanOptions: loadPlanOptions }"
        >
          <mat-icon>tune</mat-icon>
        </button>

        <mtx-popover
          #planOptionsPopover="mtxPopover"
          [closeOnBackdropClick]="true"
          [hasBackdrop]="true"
          [position]="['above', 'after']"
        >
          <ng-template mtxPopoverContent let-loadPlanOptions="loadPlanOptions">
            <div
              class="flex-column form-field-density-1 gap-normal"
              style="max-width: 240px"
            >
              <span class="mat-body">
                Define until when plans should be loaded.
              </span>

              <mat-form-field subscriptSizing="dynamic">
                <mat-label>Past until</mat-label>
                <input
                  matInput
                  [matDatepicker]="datePickerPast"
                  [formControl]="loadPlanOptions.pastUntil"
                  [max]="pastUntilMax"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="datePickerPast"
                ></mat-datepicker-toggle>
                <mat-datepicker #datePickerPast></mat-datepicker>
              </mat-form-field>

              <mat-form-field subscriptSizing="dynamic">
                <mat-label>Future until</mat-label>
                <input
                  matInput
                  [matDatepicker]="datePickerFuture"
                  [(ngModel)]="loadPlanOptions.futureUntil"
                  [min]="futureUntilMin"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="datePickerFuture"
                ></mat-datepicker-toggle>
                <mat-datepicker #datePickerFuture></mat-datepicker>
              </mat-form-field>
            </div>
          </ng-template>
        </mtx-popover>
      </div>
    </div>

    <div class="flex-grow-1"></div>

    <button
      mat-flat-button
      color="warn"
      [class.green]="showLivePanel"
      (click)="showLivePanel = !showLivePanel"
    >
      <mat-icon fontSet="material-symbols-outlined">sensors</mat-icon>
      <span>LIVE</span>
    </button>
  </div>

  <div class="main-area">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="table ag-theme-alpine"
      [gridOptions]="gridOptions"
      [rowClassRules]="rowClassRules"
      [columnDefs]="colDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="plans"
      [rowSelection]="'single'"
      [animateRows]="true"
      (gridReady)="onGridReady($event)"
      [rowHeight]="26"
      (filterChanged)="onFilterChanged($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (contextmenu)="onTableRightClick($event)"
      (cellContextMenu)="onCellContextMenu($event)"
      [enableCellTextSelection]="true"
      [ensureDomOrder]="true"
    >
    </ag-grid-angular>

    <app-assets-plans-live
      class="live"
      *ngIf="showLivePanel"
      [plans]="plans"
    ></app-assets-plans-live>
  </div>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu
  #contextMenu="matMenu"
  (closed)="onContextMenuClosed()"
  class="no-padding"
>
  <ng-template matMenuContent let-plan="plan">
    <button
      appNotReader
      mat-menu-item
      [matMenuTriggerFor]="goTo"
      [matMenuTriggerData]="{ plan: plan }"
    >
      <mat-icon fontSet="material-symbols-outlined">build</mat-icon>
      <span>Go to plan builder ...</span>
    </button>

    <button mat-menu-item (click)="filterPlanAssetsInAssetsTable(plan)">
      <mat-icon fontSet="material-symbols-outlined">filter_list</mat-icon>
      <span>Filter in assets table</span>
    </button>

    <button appNotReader mat-menu-item (click)="onClickDeletePlan(plan)">
      <mat-icon fontSet="material-symbols-outlined" class="warn">
        delete
      </mat-icon>
      <span class="warn">Delete plan</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #goTo>
  <ng-template matMenuContent let-plan="plan">
    <button
      mat-menu-item
      (click)="
        this.selectionService.selectedPlan = plan;
        this.selectionService.assetsView.showPlanBuilder = true
      "
    >
      <mat-icon fontSet="material-symbols-outlined">arrow_upward</mat-icon>
      <span>... and show above</span>
    </button>

    <button
      mat-menu-item
      (click)="
        this.selectionService.selectedPlan = plan;
        this.selectionService.assetsView.zenMode('plan-builder')
      "
    >
      <mat-icon fontSet="material-symbols-outlined">self_improvement</mat-icon>
      <span>... in Zen Mode</span>
    </button>
  </ng-template>
</mat-menu>
