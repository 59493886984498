import { Injectable } from '@angular/core';
import { UserConfigOutput } from '../graphql/output/userConfigOutput';
import { Apollo, gql } from 'apollo-angular';
import { UserOutput } from '../graphql/output/userOutput';
import {
  CreateUserConfigMutationArgs,
  CreateUserConfigMutationRoot,
  UpdateUserConfigMutationArgs,
  UpdateUserConfigMutationRoot,
} from '../graphql/crud/userConfig';
import { UserConfigInputUpdate } from '../graphql/input/update/userConfigInputUpdate';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserConfigService {
  get hasConfig() {
    return typeof this.#userConfig !== 'undefined';
  }
  get userConfig() {
    return this.#userConfig;
  }

  #userConfig: UserConfigOutput | undefined;

  constructor(private _apollo: Apollo) {}

  init(userConfig: UserConfigOutput) {
    this.#userConfig = userConfig;
  }

  async updateAsync(
    input: UserConfigInputUpdate
  ): Promise<UserConfigOutput> {
    const variables: UpdateUserConfigMutationArgs = {
      data: input,
    };

    const result = await firstValueFrom(
      this._apollo.mutate<UpdateUserConfigMutationRoot>({
        mutation: gql`
          mutation UpdateMyUserConfig($data: UserConfigInputUpdate!) {
            updateMyUserConfig(data: $data) {
              id
              userOid
              tenantId
              mTenantId
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    if (!result.data?.updateMyUserConfig) {
      throw new Error('User config could not be created.');
    }

    this.init(result.data.updateMyUserConfig);
    return result.data.updateMyUserConfig;
  }
}
