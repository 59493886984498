import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export enum DesktopToastType {
  Info,
  Warning,
  Error,
}

export interface DesktopToastEvent {
  id: number;
  type: DesktopToastType;
  message: string;
  title?: string;
  staysOpen: boolean;
  createdAt: DateTime;
}

@Injectable({
  providedIn: 'root'
})
export class DesktopToastService {
  // #region Public Properties

  get toastEvents(): DesktopToastEvent[] {
    return this._toastEvents;
  }

  get toastEventHistory(): DesktopToastEvent[] {
    return this._toastEventHistory;
  }

  // #endregion Public Properties

  // #region Private Properties

  private _toastEvents: DesktopToastEvent[] = [];
  private _toastEventHistory: DesktopToastEvent[] = [];

  private _counter = 0;
  private _delayInfo = 4000;
  private _delayWarning = 5000;
  private _delayError = 6000;

  private _maxNoOfHistoryItems = 50;

  // #endregion Private Properties

  constructor() {
    // this.error('The provided code does not match the one we sent to you.', 'Error', true);
    // this.error('This is a test warning', 'Error2', true);
  }

  // #region Public Methods

  info(message: string, title?: string) {
    const event: DesktopToastEvent = {
      id: this._counter++,
      type: DesktopToastType.Info,
      message,
      title,
      staysOpen: false,
      createdAt: DateTime.now()
    };
    this._toastEvents.push(event);
    this._addToHistory(event);

    this._setTimeout(event);
  }

  warning(message: string, title?: string) {
    const event: DesktopToastEvent = {
      id: this._counter++,
      type: DesktopToastType.Warning,
      message,
      title,
      staysOpen: false,
      createdAt: DateTime.now()
    };
    this._toastEvents.push(event);
    this._addToHistory(event);

    this._setTimeout(event);
  }

  error(message: string, title?: string) {
    const event: DesktopToastEvent = {
      id: this._counter++,
      type: DesktopToastType.Error,
      message,
      title,
      staysOpen: false,
      createdAt: DateTime.now()
    };
    this._toastEvents.push(event);
    this._addToHistory(event);

    this._setTimeout(event);
  }

  keepOpen(event: DesktopToastEvent) {
    const index = this._toastEvents.findIndex((x) => x.id === event.id);
    if (index === -1) {
      return;
    }

    this._toastEvents[index].staysOpen = true;
  }

  close(event: DesktopToastEvent) {
    const index = this._toastEvents.findIndex((x) => x.id === event.id);
    if (index === -1) {
      return;
    }

    this._toastEvents.splice(index, 1);
  }

  // #endregion Public Methods

  // #region Private Methods

  private _setTimeout(event: DesktopToastEvent) {
    let delay: number = 4000;

    switch (event.type) {
      case DesktopToastType.Info:
        delay = this._delayInfo;
        break;

      case DesktopToastType.Warning:
        delay = this._delayWarning;
        break;

      case DesktopToastType.Error:
        delay = this._delayError;
        break;

      default:
        break;
    }

    window.setTimeout(() => {
      if (event.staysOpen) {
        return;
      }

      const index = this._toastEvents.findIndex((x) => x.id === event.id);
      if (index === -1) {
        return;
      }

      this._toastEvents.splice(index, 1);
    }, delay);
  }

  private _addToHistory(event: DesktopToastEvent) {
    this._toastEventHistory.unshift(event);

    if (this._toastEventHistory.length <= this._maxNoOfHistoryItems) {
      return; // nothing to do here
    }

    // Too many items in the history list. Remove "some". 
    const tooMany = this._toastEventHistory.length - this._maxNoOfHistoryItems;
    const startIndex = this._toastEventHistory.length - tooMany;
    this._toastEventHistory.splice(startIndex, tooMany);
  }

  // #endregion Private Methods
}
