import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { sleep } from 'projects/shared/src/public-api';

export type ActivityDialogData = {
  title: string;
  text?: string;
  run: () => Promise<void>;
};

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
})
export class ActivityDialogComponent implements OnInit {
  activity = false;
  errorMessage: string | undefined;

  #minDialogOpenTimeInMs = 2000;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActivityDialogData,
    private _dialogRef: MatDialogRef<ActivityDialogComponent>
  ) {}

  ngOnInit(): void {
    this.#run();
  }

  async #run() {
    try {
      const start = DateTime.now();

      this.activity = true;
      this.errorMessage = undefined;

      await this.data.run();

      const end = DateTime.now();

      const diff = end.diff(start, 'milliseconds').toObject().milliseconds ?? 0;
      if (diff < this.#minDialogOpenTimeInMs) {
        // Wait some more time.

        await sleep(this.#minDialogOpenTimeInMs - diff);
      }

      this._dialogRef.close(true);
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.activity = false;
    }
  }
}
