import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Loading } from 'projects/shared/src/lib/classes/loading';
import {
  AssetsQueryArgs,
  AssetsQueryRoot,
  ExportAssetExcelDefaultOrderQueryArgs,
  ExportAssetExcelQueryArgs,
  ExportAssetExcelQueryRoot,
  ImportAssetExcelQueryArgs,
  ImportAssetExcelQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/asset';
import { SelectionService } from '../../services/selection.service';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentAsset';
import { AssetOutput } from 'projects/shared/src/lib/graphql/output/assetOutput';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { FileService } from '../../services/file.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImportAssetOutput } from 'projects/shared/src/lib/graphql/output/importAssetOutput';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { ErrorDialogComponent, ErrorDialogData } from '../error-dialog/error-dialog.component';
import { DESKTOP_LOCAL_STORAGE } from '../../common/localStorage';

@Component({
  selector: 'app-import-assets-dialog',
  templateUrl: './import-assets-dialog.component.html',
  styleUrls: ['./import-assets-dialog.component.scss'],
})
export class ImportAssetsDialogComponent implements OnInit {
  // #region Public Properties

  loading = new Loading(1);
  loadingError = false;
  loadingErrorMessage: string | undefined;
  downloadExcelTemplateActivity = false;
  errorMessage: string | undefined;
  assets: AssetOutput[] = [];
  file: File | undefined;
  activity = false;
  importAssetOutput: ImportAssetOutput | undefined;
  selectedLocationId: string | undefined;

  get canImport(): boolean {
    return typeof this.file !== 'undefined' && !!this.selectedLocationId;
  }

  // #endregion Public Properties

  // #region Init

  constructor(
    private _apollo: Apollo,
    private _selectionService: SelectionService,
    private _fileService: FileService,
    private _toastService: DesktopToastService,
    private _dialogRef: MatDialogRef<ImportAssetsDialogComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._loadData(1);
  }

  // #endregion Init

  // #region Public Methods

  async onClickDownloadExcelTemplate(assets: AssetOutput[]) {
    this.downloadExcelTemplateActivity = true;

    try {

      const variables: ExportAssetExcelDefaultOrderQueryArgs = { assetIds: assets.map((x) => x.id) };
      const result = await firstValueFrom(
        this._apollo.query<ExportAssetExcelQueryRoot>({
          query: gql`
            query ExportAssetExcel($assetIds: [String!]!) {
              exportAssetExcel(assetIds: $assetIds)
            }
          `,
          variables,
          fetchPolicy: 'no-cache',
        })
      );

      await this._fileService.download(
        this._selectionService.selectedTenant?.id,
        result.data.exportAssetExcel
      );
    } catch (error) {
      const message = new CatchError(error).message;
      this._toastService.error(message, 'Error');
    } finally {
      this.downloadExcelTemplateActivity = false;
    }
  }

  async import() {
    if (!this.file || !this.selectedLocationId) {
      return;
    }
    try {
      this.activity = true;
      this.errorMessage = undefined;
      const fileId = await this._fileService.upload(
        this._selectionService.selectedTenant?.id,
        this.file
      );
      if (fileId) {
        const variables: ImportAssetExcelQueryArgs = {
          fileId,
          locationId: this.selectedLocationId,
        };

        const result = await firstValueFrom(
          this._apollo.query<ImportAssetExcelQueryRoot>({
            query: gql`
              query ImportAssetExcel($fileId: String!, $locationId: String!) {
                importAssetExcel(fileId: $fileId, locationId: $locationId) {
                  inserted
                  skipped
                  err
                }
              }
            `,
            variables,
            fetchPolicy: 'no-cache',
          })
        );

        // Analyze response for error message.
        if (
          result.data.importAssetExcel.skipped.length === 0 &&
          !result.data.importAssetExcel.err
        ) {
          this._toastService.info('Assets imported successfully.', 'Success');
          this._dialogRef.close(true);
        } else {
          this.importAssetOutput = result.data.importAssetExcel;
        }
      }
    } catch (error) {
      const data: ErrorDialogData = {
        message: new CatchError(error).message,
        fontSize: '14px',
      };
      this.matDialog.open(ErrorDialogComponent, {
        data,
      });
    } finally {
      this.activity = false;
    }
  }

  // #endregion Public Methods

  // #region Private Methods

  private async _loadData(jobNumber: number) {
    try {
      this.loading.indicateJobStart(jobNumber);
      this.loadingError = false;
      this.loadingErrorMessage = undefined;

      const variables: AssetsQueryArgs = {
        tenantId: this._selectionService.selectedTenant?.id ?? 'na',
      };

      const result = await firstValueFrom(
        this._apollo.query<AssetsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_ASSET}
            query Assets($tenantId: String!) {
              assets(tenantId: $tenantId) {
                ...FullFragmentAsset
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.assets = result.data.assets.filter((x) => x.isActive).sortBy((x) => x.name);
    } catch (error) {
      this.loadingErrorMessage = new CatchError(error).message;
      this.loadingError = true;
    } finally {
      this.loading.indicateJobEnd(jobNumber);
    }
  }

  // #endregion Private Methods
}
