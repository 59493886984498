import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { Loading } from 'projects/shared/src/lib/classes/loading';
import { AssetQueryArgs, AssetQueryRoot } from 'projects/shared/src/lib/graphql/crud/asset';
import { AssetOutput } from 'projects/shared/src/lib/graphql/output/assetOutput';
import { Subscription, firstValueFrom } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { SelectionService } from '../../../services/selection.service';
import { ChangeAssetChangeable } from 'projects/shared/src/lib/graphql/changeables/changeAssetChangeable';
import { ChangeAssetPropertyChangeable } from 'projects/shared/src/lib/graphql/changeables/changeAssetPropertyChangeable';
import {
  ChangeAssetsByAssetIdQueryArgs,
  ChangeAssetsByAssetIdQueryRoot,
  CreateChangeAssetMutationArgs,
  CreateChangeAssetMutationRoot,
  UpdateChangeAssetMutationArgs,
  UpdateChangeAssetMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/change-asset';
import { ChangeAssetOutput } from 'projects/shared/src/lib/graphql/output/changeAssetOutput';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { ChangeType } from 'projects/shared/src/lib/graphql/enums/changeType';
import { ChangeAssetPropertyInputCreate } from 'projects/shared/src/lib/graphql/input/create/changeAssetPropertyInputCreate';
import { FULL_FRAGMENT_CHANGE_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentChangeAsset';
import { FULL_FRAGMENT_CHANGE_ASSET_PROPERTY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentChangeAssetProperty';
import { ChangeAssetPropertyInputUpdate } from 'projects/shared/src/lib/graphql/input/update/changeAssetPropertyInputUpdate';
import { OptionOutput } from 'projects/shared/src/lib/graphql/output/optionOutput';
import { OptionsQueryRoot } from 'projects/shared/src/lib/graphql/crud/option';
import { ApolloCacheService } from '../../../services/apollo-cache.service';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent implements OnInit, OnDestroy {
  // #region Public Properties

  loading = new Loading(1);
  loadingChanges = new Loading(1);
  assetName = new FormControl<string | null>(null, Validators.required);
  assetIsActive = new FormControl<boolean | null>(false);
  options: OptionOutput[] = [];
  uuid1 = uuid();
  uuid2 = uuid();
  uuid3 = uuid();
  uuid4 = uuid();
  liveChangeAssetChangeable: ChangeAssetChangeable | undefined;

  get isLiveLocked(): boolean {
    if (
      typeof this.changeAssetChangeables === 'undefined' ||
      this.changeAssetChangeables.length === 0
    ) {
      return false;
    }

    // We have change entries. Check, if there is an "open" one (not approved).

    if (
      this.changeAssetChangeables
        .filter((x) => x !== this.liveChangeAssetChangeable)
        .find((x) => !x.isLocked)
    ) {
      return true;
    }

    return false;
  }

  get isChangeAssetLocked(): boolean {
    // Check if the user is on the live object and if this object is locked
    if (
      this.selectedChangeAssetChangeable === this.liveChangeAssetChangeable &&
      this.isLiveLocked
    ) {
      return true;
    }

    // Check if the user is on an already approved object.
    if (
      this.selectedChangeAssetChangeable !== this.liveChangeAssetChangeable &&
      this.selectedChangeAssetChangeable?.databaseSource?.approvedAt
    ) {
      return true;
    }

    return false;
  }

  selectedChangeAssetChangeable: ChangeAssetChangeable | undefined;
  selectedChangeAssetPropertyChangeable: ChangeAssetPropertyChangeable | undefined;

  historyIsExpanded = false;
  changeAssetChangeables: ChangeAssetChangeable[] | undefined;

  // #endregion Public Properties

  // #region Private Properties

  private _assetSubscription: Subscription | undefined;
  private _assetQuery: QueryRef<AssetQueryRoot> | undefined;
  private _asset: AssetOutput | undefined;

  private _changeAssetsSubscription: Subscription | undefined;
  private _changeAssetsQuery: QueryRef<ChangeAssetsByAssetIdQueryRoot> | undefined;

  private _activatedRouteSubscription: Subscription | undefined;

  // #endregion Private Properties

  // #region Init

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _apollo: Apollo,
    public assortmentService: AssortmentService,
    private _selectionService: SelectionService,
    private _apolloCache: ApolloCacheService
  ) {}

  ngOnInit(): void {
    const options = this._apolloCache.options(this._selectionService.selectedTenant?.id);
    if (typeof options !== 'undefined') {
      this.options = options;
    }

    this._activatedRouteSubscription = this._activatedRoute.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (!id) {
        return;
      }

      this.loading.indicateJobStart(1);
      this.loadingChanges.indicateJobStart(1);

      // If we already have loaded data only perform a REFETCH
      if (this._asset) {
        const variables: AssetQueryArgs = {
          id,
        };

        this._assetQuery?.refetch(variables);
      } else {
        this._loadAsset(id, 1);
      }

      if (typeof this.changeAssetChangeables !== 'undefined') {
        const variables: ChangeAssetsByAssetIdQueryArgs = {
          assetId: id,
        };

        this._changeAssetsQuery?.refetch(variables);
      } else {
        this._loadChangeAssets(id, 1);
      }
    });
  }

  ngOnDestroy(): void {
    this._assetSubscription?.unsubscribe();
    this._changeAssetsSubscription?.unsubscribe();
    this._activatedRouteSubscription?.unsubscribe();
  }

  // #endregion Init

  // #region Public Methods

  deleteNotPersistedObject() {
    if (!this.selectedChangeAssetPropertyChangeable || !this.selectedChangeAssetChangeable) {
      return;
    }

    const index = this.selectedChangeAssetChangeable.changeAssetProperties.findIndex(
      (x) => x.id === this.selectedChangeAssetPropertyChangeable?.id
    );
    this.selectedChangeAssetChangeable.changeAssetProperties.splice(index, 1);

    // Since we deleted the currently selected item, reselect another one.
    this.selectedChangeAssetPropertyChangeable =
      this.selectedChangeAssetChangeable.changeAssetProperties[0];

    this.selectedChangeAssetChangeable.evaluateHasChangedToDatabaseSource();
  }

  getOptionNameById(id: string | undefined | null): string | undefined {
    if (!id) {
      return undefined;
    }

    return this.options.find((x) => x.id === id)?.name;
  }

  getUuid(): string {
    return uuid();
  }

  /**
   * This methods checks if the user currently has selected the live object.
   * If so, it also checks if a new (open) changeAsset object can be created.
   * Only one open changeAsset is allowed at a time.
   */
  tryCreateOpenChangeAsset() {
    console.log('tryCreateOpenChangeAsset');
    if (!this._asset || !this.selectedChangeAssetChangeable || !this.liveChangeAssetChangeable) {
      return;
    }

    // Are we "working" on the live object?
    if (this.liveChangeAssetChangeable !== this.selectedChangeAssetChangeable) {
      return; // we are not working on live, nothing to do here
    }

    // Working on "live"
    if (this.isLiveLocked) {
      throw new Error('The live object is locked as there is an open change present.');
    }

    // Do the following
    // 1: Attach the live object to the changeAsset list
    // 2: Build new live object
    // 3: Selection still remains

    this.changeAssetChangeables?.unshift(this.liveChangeAssetChangeable);
    // Manipulate the previously live object
    this.liveChangeAssetChangeable.isActive = false;

    this._buildLiveObject(this._asset, false);
  }

  setSelectedChangeAssetPropertyChangeable(
    changeAssetChangeable: ChangeAssetChangeable | undefined
  ) {
    if (!changeAssetChangeable?.changeAssetProperties) {
      // Do nothing.
      return;
    }

    this.selectedChangeAssetPropertyChangeable = changeAssetChangeable.changeAssetProperties[0];
  }

  async saveChangeAssetAsync(changeAssetChangeable: ChangeAssetChangeable | undefined) {
    if (!changeAssetChangeable || !changeAssetChangeable.hasChangedToDatabaseSource) {
      return;
    }

    // Detect if we have to create a new entry or update an existing one.
    if (changeAssetChangeable.isAlreadyPersistedObject()) {
      // => update mutation
      console.log('update mutation');

      const updatedChangeAssetOutput = await this._updateChangeAssetAsync(changeAssetChangeable);
      // The update mutation triggers the internal refresh of the assets subscription
      // The re(selection) is handled there as well as the building of the new (!) changeAssetChangeable

      //const selectedChangeAssetPropertyChangeableDisplayName = this.selectedChangeAssetPropertyChangeable?.displayName;
      //changeAssetChangeable.applyDatabaseSource(updatedChangeAssetOutput); // also applies to nested properties
      //this.selectedChangeAssetPropertyChangeable = changeAssetChangeable.changeAssetProperties.find(x => x.displayName === selectedChangeAssetPropertyChangeableDisplayName);
    } else {
      // => create mutation
      console.log('create mutation');
      const createdChangeAssetOutput = await this._createChangeAssetAsync(changeAssetChangeable);

      // The create mutation DOES NOT trigger an internal refresh of the assets subscription!!!!
      // We have to handle the (re)selection here.
      const selectedChangeAssetPropertyChangeableDisplayName =
        this.selectedChangeAssetPropertyChangeable?.displayName;
      changeAssetChangeable.applyDatabaseSource(createdChangeAssetOutput); // also applies to nested properties
      this.selectedChangeAssetPropertyChangeable = changeAssetChangeable.changeAssetProperties.find(
        (x) => x.displayName === selectedChangeAssetPropertyChangeableDisplayName
      );
    }
  }

  addAssetProperty() {
    this.tryCreateOpenChangeAsset();

    // Is the user still on the live object?
    // If so, do nothing (or maybe throw/show an error message?)
    if (this.selectedChangeAssetChangeable === this.liveChangeAssetChangeable) {
      return;
    }

    // The user is NOT on the live object.
    // Check if the object is locked.
    if (this.selectedChangeAssetChangeable?.isLocked) {
      return;
    }

    const newChangeAssetPropertyChangeable = new ChangeAssetPropertyChangeable(
      undefined,
      undefined
    );
    this.selectedChangeAssetChangeable?.changeAssetProperties.push(
      newChangeAssetPropertyChangeable
    );

    this.selectedChangeAssetPropertyChangeable = newChangeAssetPropertyChangeable;

    // Evaluate Changes
    this.selectedChangeAssetPropertyChangeable.evaluateHasChangedToDatabaseSource();
    this.selectedChangeAssetChangeable?.evaluateHasChangedToDatabaseSource();
  }

  // #endregion Public Methods

  // #region Private Methods

  private _loadAsset(assetId: string, jobNumber: number) {
    const variables: AssetQueryArgs = {
      id: assetId,
    };

    this._assetQuery = this._apollo.watchQuery<AssetQueryRoot>({
      query: gql`
        query Asset($id: String!) {
          asset(id: $id) {
            id
            name
            isActive
            assetProperties {
              id
              assetId
              propertyId
              createdAt
              property {
                id
                typeId
                levelId
                displayName
                displayOrder
                shortInfo
                longInfo
                isNullable
                createdAt
                optionId
                typeId

                propertyType {
                  id
                  name
                }

                option {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this._assetSubscription = this._assetQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this._asset = data.asset;
        this.loading.indicateJobEnd(jobNumber);

        if (!loading) {
          this._buildLiveObject(data.asset, true);
        }
      },
      error: (error) => {
        this.loading.indicateJobEnd(jobNumber);
      },
    });
  }

  private _loadChangeAssets(assetId: string, jobNumber: number) {
    const variables: ChangeAssetsByAssetIdQueryArgs = {
      assetId,
    };

    this._changeAssetsQuery = this._apollo.watchQuery<ChangeAssetsByAssetIdQueryRoot>({
      query: gql`
        query ChangeAssetsByAssetId($assetId: String!) {
          changeAssetsByAssetId(assetId: $assetId) {
            id
            assetId
            changeTypeId
            originalName
            name
            originalIsActive
            isActive
            readyForApproval
            approvedAt
            approvedBy
            createdAt
            createdBy
            updatedAt
            updatedBy

            changeAssetProperties {
              id
              changeTypeId
              originalTypeId
              typeId
              originalDisplayName
              displayName
              originalDisplayOrder
              displayOrder
              originalShortInfo
              shortInfo
              originalLongInfo
              longInfo
              originalIsNullable
              isNullable
              originalOptionId
              optionId
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this._changeAssetsSubscription = this._changeAssetsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (loading) {
          return; // loading has not finished yet
        }

        // This here is NOT ONLY executed once at the beginning, but also
        // after EACH update of an assetChange!
        // We have to handle the (re)selection here so that the user does not
        // notice that completely new objects are created.
        //
        // Handle
        // this.selectedChangeAssetChangeable
        // this.selectedChangeAssetPropertyChangeable

        const selectedChangeAssetChangeableId =
          this.selectedChangeAssetChangeable?.databaseSource?.id;
        const selectedChangeAssetPropertyChangeableDisplayName =
          this.selectedChangeAssetPropertyChangeable?.displayName;

        this.changeAssetChangeables = [];
        for (let x of data.changeAssetsByAssetId) {
          this.changeAssetChangeables.push(new ChangeAssetChangeable(x));
        }

        if (selectedChangeAssetChangeableId) {
          this.selectedChangeAssetChangeable = this.changeAssetChangeables.find(
            (x) => x.databaseSource?.id === selectedChangeAssetChangeableId
          );
        }
        if (selectedChangeAssetPropertyChangeableDisplayName) {
          this.selectedChangeAssetPropertyChangeable =
            this.selectedChangeAssetChangeable?.changeAssetProperties.find(
              (x) => x.displayName === selectedChangeAssetPropertyChangeableDisplayName
            );
        }

        this.loadingChanges.indicateJobEnd(jobNumber);
      },
      error: (error) => {
        this.loadingChanges.indicateJobEnd(jobNumber);
      },
    });
  }

  private _buildLiveObject(asset: AssetOutput, selectIt: boolean) {
    const live = new ChangeAssetChangeable(undefined, asset);
    this.liveChangeAssetChangeable = live;

    if (!selectIt) {
      return;
    }

    this.selectedChangeAssetChangeable = this.liveChangeAssetChangeable;
    if (this.selectedChangeAssetChangeable.changeAssetProperties) {
      this.selectedChangeAssetPropertyChangeable =
        this.selectedChangeAssetChangeable.changeAssetProperties[0];
    }
  }

  private async _createChangeAssetAsync(
    changeAssetChangeable: ChangeAssetChangeable
  ): Promise<ChangeAssetOutput | undefined> {
    try {
      const changeAssetProperties: ChangeAssetPropertyInputCreate[] = [];
      for (let x of changeAssetChangeable.changeAssetProperties) {
        // Determine the change type of the assetChangeProperty
        let changeType: ChangeType;

        // Possible new/changed change types: Keep, Update, Add, Delete
        if (x.markAsToBeDeleted) {
          changeType = ChangeType.Delete;
        } else if (x.changeTypeId == ChangeType.Add) {
          // Keep it that way
          changeType = ChangeType.Add;
        } else {
          // "Old" situation is Keep or Update
          // Evaluate new
          changeType = x.hasChangedToOriginal() ? ChangeType.Update : ChangeType.Keep;
        }

        changeAssetProperties.push({
          changeTypeId: changeType,
          assetPropertyId: x.assetPropertyId ?? undefined,
          originalTypeId: x.originalTypeId,
          originalDisplayName: x.originalDisplayName,
          originalDisplayOrder: x.originalDisplayOrder,
          originalShortInfo: x.originalShortInfo,
          originalLongInfo: x.originalLongInfo,
          originalIsNullable: x.originalIsNullable,
          originalNotNulLDefault: x.originalNotNullDefault,
          originalOptionId: x.originalOptionId,
          typeId: x.typeId,
          displayName: x.displayName,
          displayOrder: x.displayOrder,
          shortInfo: x.shortInfo,
          longInfo: x.longInfo,
          isNullable: x.isNullable,
          notNullDefault: x.notNullDefault,
          optionId: x.optionId,
        });
      }

      // Determine the change type of the assetChange
      let assetChangeType: ChangeType;
      // Possible new/changed change types: Keep, Update, Delete
      if (changeAssetChangeable.markAsToBeDeleted) {
        assetChangeType = ChangeType.Delete;
      } else {
        // "Old" situation is Keep or Update
        // Evaluate new
        assetChangeType = changeAssetChangeable.hasChangedToOriginal()
          ? ChangeType.Update
          : ChangeType.Keep;
      }

      const variables: CreateChangeAssetMutationArgs = {
        data: {
          tenantId: this._selectionService.selectedTenant?.id ?? 'na',
          assetId: this._asset?.id ?? 'na',
          changeTypeId: assetChangeType,
          originalName: changeAssetChangeable.originalName,
          originalIsActive: changeAssetChangeable.originalIsActive,
          name: changeAssetChangeable.name,
          isActive: changeAssetChangeable.isActive,
          changeAssetProperties,
        },
      };

      const result = await firstValueFrom(
        this._apollo.mutate<CreateChangeAssetMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_CHANGE_ASSET}
            ${FULL_FRAGMENT_CHANGE_ASSET_PROPERTY}
            mutation CreateChangeAsset($data: ChangeAssetInputCreate!) {
              createChangeAsset(data: $data) {
                ...FullFragmentChangeAsset
                changeAssetProperties {
                  ...FullFragmentChangeAssetProperty
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      return result.data?.createChangeAsset;
    } catch (error) {
      const message = new CatchError(error).message;
      console.log(error);
      return undefined;
    }
  }

  private async _updateChangeAssetAsync(
    changeAssetChangeable: ChangeAssetChangeable
  ): Promise<ChangeAssetOutput | undefined> {
    try {
      const changeAssetProperties: ChangeAssetPropertyInputUpdate[] = [];
      for (let x of changeAssetChangeable.changeAssetProperties) {
        // Determine the change type of the assetChangeProperty
        let changeType: ChangeType;

        // Possible new/changed change types: Keep, Update, Add, Delete
        if (x.markAsToBeDeleted) {
          changeType = ChangeType.Delete;
        } else if (x.changeTypeId == ChangeType.Add) {
          // Keep it that way
          changeType = ChangeType.Add;
        } else {
          // "Old" situation is Keep or Update
          // Evaluate new
          changeType = x.hasChangedToOriginal() ? ChangeType.Update : ChangeType.Keep;
        }

        changeAssetProperties.push({
          id: x.databaseSource?.id,
          changeTypeId: changeType,
          assetPropertyId: x.assetPropertyId ?? undefined,
          originalTypeId: x.originalTypeId,
          originalDisplayName: x.originalDisplayName,
          originalDisplayOrder: x.originalDisplayOrder,
          originalShortInfo: x.originalShortInfo,
          originalLongInfo: x.originalLongInfo,
          originalIsNullable: x.originalIsNullable,
          originalNotNullDefault: x.originalNotNullDefault,
          originalOptionId: x.originalOptionId,
          typeId: x.typeId,
          displayName: x.displayName,
          displayOrder: x.displayOrder,
          shortInfo: x.shortInfo,
          longInfo: x.longInfo,
          isNullable: x.isNullable,
          notNullDefault: x.notNullDefault,
          optionId: x.optionId,
        });
      }

      // Determine the change type of the assetChange
      let assetChangeType: ChangeType;
      // Possible new/changed change types: Keep, Update, Delete
      if (changeAssetChangeable.markAsToBeDeleted) {
        assetChangeType = ChangeType.Delete;
      } else {
        // "Old" situation is Keep or Update
        // Evaluate new
        assetChangeType = changeAssetChangeable.hasChangedToOriginal()
          ? ChangeType.Update
          : ChangeType.Keep;
      }

      const variables: UpdateChangeAssetMutationArgs = {
        id: changeAssetChangeable.databaseSource?.id ?? 'na',
        data: {
          changeTypeId: assetChangeType,
          name: changeAssetChangeable.name,
          isActive: changeAssetChangeable.isActive,
          changeAssetProperties,
        },
      };

      const result = await firstValueFrom(
        this._apollo.mutate<UpdateChangeAssetMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_CHANGE_ASSET}
            ${FULL_FRAGMENT_CHANGE_ASSET_PROPERTY}
            mutation UpdateChangeAsset($id: String!, $data: ChangeAssetInputUpdate!) {
              updateChangeAsset(id: $id, data: $data) {
                ...FullFragmentChangeAsset
                changeAssetProperties {
                  ...FullFragmentChangeAssetProperty
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      return result.data?.updateChangeAsset;
    } catch (error) {
      const message = new CatchError(error).message;
      return undefined;
    }
  }

  // #endregion Private Methods
}
