import { LocalEventData_InventoryAssetsCreated, LocalEventData_InventoryAssetsDeleted, LocalEventType } from '../local-event.service';
import { RemoteEventData_InventoryAssetsCreated, RemoteEventData_InventoryAssetsDeleted, RemoteEventType } from '../remote-event.service';
import { NotificationService, EventSource } from './notification.service';

export const handle_InventoryAssetsCreated = async function (
  this: NotificationService,
  eventSource: EventSource,
  eventData: LocalEventData_InventoryAssetsCreated | RemoteEventData_InventoryAssetsCreated
) {
  if (eventSource === EventSource.RemoteMySession) {
    // DO NOTHING. This client will receive (or has received) a local trigger as well.
  } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
    // Do the following ALWAYS:
    const assetIds = eventData.data.map(x => x.assetIds).flat();
    await this.assetService.fetchMultiple(assetIds, 'network-only');

    // Do the next only if we have subscribers.
    if (
      eventSource === EventSource.Local &&
      this.hasSubscribers(EventSource.Local, LocalEventType.InventoryAssetsCreated)
    ) {
      // We have subscribers. Notify them.
      this.localEventHandled.next([LocalEventType.InventoryAssetsCreated, eventData]);
      return;
    }

    if (
      eventSource === EventSource.RemoteOtherSession &&
      this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.InventoryAssetsCreated)
    ) {
      // We have subscribers. Notify them.
      this.remoteEventHandled.next([RemoteEventType.InventoryAssetsCreated, eventData]);
      return;
    }
  }
};

export const handle_InventoryAssetsDeleted = async function (
    this: NotificationService,
    eventSource: EventSource,
    eventData: LocalEventData_InventoryAssetsDeleted | RemoteEventData_InventoryAssetsDeleted
  ) {
    if (eventSource === EventSource.RemoteMySession) {
      // DO NOTHING. This client will receive (or has received) a local trigger as well.
    } else if (eventSource === EventSource.Local || eventSource === EventSource.RemoteOtherSession) {
      // Do the following ALWAYS:
      const assetIds = eventData.data.map(x => x.assetIds).flat();
      await this.assetService.fetchMultiple(assetIds, 'network-only');
  
      // Do the next only if we have subscribers.
      if (
        eventSource === EventSource.Local &&
        this.hasSubscribers(EventSource.Local, LocalEventType.InventoryAssetsDeleted)
      ) {
        // We have subscribers. Notify them.
        this.localEventHandled.next([LocalEventType.InventoryAssetsDeleted, eventData]);
        return;
      }
  
      if (
        eventSource === EventSource.RemoteOtherSession &&
        this.hasSubscribers(EventSource.RemoteOtherSession, RemoteEventType.InventoryAssetsDeleted)
      ) {
        // We have subscribers. Notify them.
        this.remoteEventHandled.next([RemoteEventType.InventoryAssetsDeleted, eventData]);
        return;
      }
    }
  };