<span
  class="material-symbols-outlined icon prevent-select"
  (mouseenter)="isContentVisible = true"
  (click)="isContentVisible = !isContentVisible"
  matRipple
>
  {{ icon }}
</span>
<div class="content-wrapper" (mouseleave)="isContentVisible = false">
  <div class="content" *ngIf="isContentVisible">
    <div class="main">
      <ng-content></ng-content>
    </div>
    <span class="info">show / hide: CTRL + SHIFT </span>
  </div>
</div>
