import { gql } from "apollo-angular";

export const FULL_FRAGMENT_PLAN_STEP = gql`
    fragment FullFragmentPlanStep on PlanStepOutput {
        id
        tenantId
        planId
        name
        completed
        description
    }
`