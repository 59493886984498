<div class="gat-card-header-and-actions">
  <!-- HEADER -->
  <div class="gat-card-header">
    <ng-content select="app-gat-card-header"></ng-content>
  </div>

  <!-- ACTIONS -->
  <div class="gat-card-actions">
    <ng-content select="app-gat-card-actions"></ng-content>
  </div>
</div>
<div class="gat-card-content">
  <ng-content select="app-gat-card-content"></ng-content>
</div>
