<!-- <h2 mat-dialog-title>Rename</h2> -->

<mat-dialog-content class="form-field-density-1">
    <mat-form-field class="form-field-adjustment">
        <mat-label>Name</mat-label>
        <input #optionItemInput matInput required [formControl]="name" [matAutocomplete]="auto" [autocomplete]="uuid"
            (keyup.escape)="onKeyupEscape()" (keyup.enter)="apply()">
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let optionItemName of filteredOptionItemNames | async">
                <span [innerHTML]="optionItemName | highlight: optionItemInput.value"></span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div class="error-message">
        {{errorMessage}}
    </div>
</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
    <div class="flex-row width-100 justify-content-space-between">
        <button class="width-100" mat-stroked-button mat-dialog-close [disabled]="activity">Cancel</button>
        <button class="width-100" (click)="apply()" mat-flat-button color="primary" [disabled]="!canApply">
            <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
            <span> Apply </span>
        </button>
    </div>

</mat-dialog-actions> -->