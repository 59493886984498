import { Injectable } from '@angular/core';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppEventService {
  readonly eventNewPlan = new Subject<PlanOutput | undefined>();
  constructor() { }

  announceNewPlan(plan: PlanOutput | undefined) {
    this.eventNewPlan.next(plan);
  }
}
