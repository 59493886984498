import { Component } from '@angular/core';
import { DesktopToastService } from '../../services/desktop-toast.service';

@Component({
  selector: 'app-desktop-toast',
  templateUrl: './desktop-toast.component.html',
  styleUrls: ['./desktop-toast.component.scss'],
})
export class DesktopToastComponent {
  constructor(public toastService: DesktopToastService) {}
}
