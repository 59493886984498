<div class="flex-column align-items-center">
  <span class="mat-body">You are signed out!</span>

  <button
    mat-stroked-button
    color="primary"
    (click)="signIn()"
    class="mt-normal"
  >
    Sign in
  </button>
</div>
