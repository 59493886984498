import {
  LocalEventData_Plan,
  LocalEventType,
} from 'projects/desktop/src/app/services/local-event.service';
import { AssetsPlansComponent } from './assets-plans.component';
import {
  RemoteEventData_Plan,
  RemoteEventType,
} from 'projects/desktop/src/app/services/remote-event.service';

export const localEventHandling = async function (
  this: AssetsPlansComponent,
  value: [LocalEventType, any]
) {
  console.log('value');
  if (value[0] === LocalEventType.Plan) {
    // Trigger a refetch via loadPlans() for DELETE AND CREATE.
    // UPDATEs are automatically handled due to the watchQuery nature.

    const eventData = value[1] as LocalEventData_Plan;
    if (eventData.data?.some((x) => x.action === 'created' || x.action === 'deleted')) {
      this.loadPlans();
    }
  }
};

export const remoteEventHandling = async function (
  this: AssetsPlansComponent,
  value: [RemoteEventType, any]
) {
  if (value[0] === RemoteEventType.Plan) {
    // Trigger a refresh via loadPlans() for DELETE AND CREATE.
    // UPDATEs are handled automatically due to the watchQuery nature.

    const eventData: RemoteEventData_Plan = value[1];
    if (eventData.data?.some((x) => x.action === 'created' || x.action === 'deleted')) {
      this.loadPlans();
    }
  }
};
