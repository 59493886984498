import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE = gql`
  fragment FullFragmentReturnToCustomerNote on ReturnToCustomerNoteOutput {
    id
    tenantId
    returnToCustomerId
    note
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
