
export enum PropertyType {
    Text = 1,
    Number = 2,
    Date = 3,
    DateTime = 4,
    Boolean = 5
}

export const propertyTypes = new Map<number, string>([
    [PropertyType.Text, "Text"],
    [PropertyType.Number, "Number"],
    [PropertyType.Date, "Date"],
    [PropertyType.DateTime, "DateTime"],
    [PropertyType.Boolean, "Boolean"],
]);