import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  InventoryQueryArgs,
  InventoryQueryRoot,
  UpdateInventoryMutationArgs,
  UpdateInventoryMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/inventory';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import { v4 } from 'uuid';
import { ConfirmService } from '../../services/confirm.service';
import {
  LocalEventData_Inventory,
  LocalEventService,
  LocalEventType,
} from '../../services/local-event.service';
import { SelectionService } from '../../services/selection.service';
import { AppModule } from '../../app.module';

export type InventoryEditDialogData = {
  inventoryId: string;
};

export type InventoryEditDialogResult = 'deleted' | 'updated' | 'cancelled';

@Component({
  selector: 'app-edit-inventory-dialog',
  templateUrl: './inventory-edit-dialog.component.html',
  styleUrls: ['./inventory-edit-dialog.component.scss'],
})
export class InventoryEditDialogComponent implements OnInit {
  loading = false;
  activity = false;
  inventory: InventoryOutput | undefined;
  name: string | undefined;
  notes: string | null = null;
  uuid1 = v4();
  uuid2 = v4();

  get canUpdate(): boolean {
    if (!this.inventory || !this.name) {
      return false;
    }

    if (this.loading || this.activity) {
      return false;
    }

    if (this.name === this.inventory.name && this.notes === this.inventory.description) {
      return false;
    }
    return true;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InventoryEditDialogData,
    private apollo: Apollo,
    private toastService: DesktopToastService,
    private dialogRef: MatDialogRef<InventoryEditDialogComponent>,
    private confirmService: ConfirmService,
    private selectionService: SelectionService,
    private localEventService: LocalEventService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  cancel() {
    const result: InventoryEditDialogResult = 'cancelled';
    this.dialogRef.close(result);
  }

  async update() {
    if (!this.name) {
      return;
    }

    this.activity = true;
    try {
      const variables: UpdateInventoryMutationArgs = {
        id: this.data.inventoryId,
        data: {
          name: this.name,
          description: this.notes,
        },
      };

      await firstValueFrom(
        this.apollo.mutate<UpdateInventoryMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_INVENTORY}
            mutation UpdateInventory($id: String!, $data: InventoryInputUpdate!) {
              updateInventory(id: $id, data: $data) {
                ...FullFragmentInventory
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.updateInventory) {
              return;
            }

            const eventData: LocalEventData_Inventory = {
              filterSessionId: AppModule.sessionId,
              data: [
                {
                  action: 'updated',
                  inventory: data.updateInventory,
                  userOid: this.selectionService.myUser?.oid ?? 'na',
                },
              ],
            };
            this.localEventService.emitNewEvent(LocalEventType.Inventory, eventData);
          },
        })
      );
      const result: InventoryEditDialogResult = 'updated';
      this.dialogRef.close(result);
    } catch (error) {
    } finally {
      this.activity = false;
    }
  }

  async #loadData() {
    this.loading = true;
    try {
      const variables: InventoryQueryArgs = {
        inventoryId: this.data.inventoryId,
      };

      const result = await firstValueFrom(
        this.apollo.query<InventoryQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_INVENTORY}
            query Inventory($inventoryId: String!) {
              inventory(inventoryId: $inventoryId) {
                ...FullFragmentInventory
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.inventory = result.data.inventory;
      this.name = this.inventory.name;
      this.notes = this.inventory.description;
    } catch (error) {
      this.toastService.error(new CatchError(error).message);
    } finally {
      this.loading = false;
    }
  }
}
