<!-- ############# -->
<!-- ASSET POPOVER -->
<!-- ############# -->
<mtx-popover
  #assetPopover="mtxPopover"
  [position]="[popoverPositionStart, popoverPositionEnd]"
>
  <ng-template mtxPopoverContent let-context="context">
    <div class="popover-container">
      <!-- Header -->
      <div class="header">
        <span class="font-weight-500 font-size-16">
          {{ context.asset.id }}
        </span>

        <div
          style="margin-top: 4px"
          class="flex-column gap-tiny"
          *ngIf="
            context.asset.actionCurrentlyLocatedAt ||
            context.asset.currentPlanName
          "
        >
          <div
            *ngIf="context.asset.actionCurrentlyLocatedAt"
            class="flex-row align-items-center gap-tiny"
          >
            <span
              class="material-symbols-outlined opacity-40 font-size-18"
              style="margin-left: -2px"
            >
              location_on
            </span>
            <span>{{ context.asset.actionCurrentlyLocatedAt }}</span>
          </div>

          <div
            *ngIf="context.asset.currentPlanName"
            class="flex-row align-items-center gap-tiny"
          >
            <span class="material-symbols-outlined opacity-40 font-size-18">
              calendar_today
            </span>
            <span>{{ context.asset.currentPlanName }}</span>
          </div>
        </div>

        <!-- ACTIONS -->
        <div class="mt-normal flex-row align-items-center gap-small">
          <button
            [disabled]="context.asset.currentPlanName"
            mat-flat-button
            style="background-color: #f0f0f0; font-weight: 400"
            (click)="bookAction()"
          >
            Book Action
          </button>

          <button
            mat-flat-button
            matTooltip="Set asset as {{
              context.asset[specialTableColumn_Missing?.field ?? 'na'] === true
                ? 'AVAILABLE'
                : 'MISSING'
            }}"
            matTooltipPosition="above"
            (click)="setMissingOrAvailable()"
            class="icon-button"
          >
            <div class="flex-row align-items-center opacity-50">
              <mat-icon fontSet="material-symbols-outlined">
                {{
                  context.asset[specialTableColumn_Missing?.field ?? "na"] ===
                  true
                    ? "verified_user"
                    : "shield_question"
                }}
              </mat-icon>
            </div>
          </button>

          <button
            mat-flat-button
            matTooltipPosition="above"
            matTooltip="{{
              context.asset.defectType === 1
                ? 'Verify latest defect report'
                : 'Create defect record'
            }}"
            class="icon-button"
            (click)="reportDefect()"
          >
            <div class="flex-row align-items-center opacity-50">
              <mat-icon fontSet="material-symbols-outlined">
                heart_broken
              </mat-icon>
            </div>
          </button>
        </div>

        <div class="mt-normal mb-normal flex-column gap-small">
          <div
            class="missing"
            *ngIf="
              context.asset[specialTableColumn_Missing?.field ?? 'na'] === true
            "
          >
            MISSING
          </div>

          <div
            class="defect"
            *ngIf="
              context.asset.defectState > 1 || context.asset.defectType === 1
            "
            [class.red]="context.asset.defectState > 1"
          >
            <span class="font-size-14 font-weight-500">
              {{
                context.asset.defectState === 1
                  ? "OK"
                  : context.asset.defectState === 2
                  ? "DEFECT"
                  : "DEFECT 100"
              }}
            </span>

            <span *ngIf="context.asset.defectState === 1"
              >fully functional</span
            >
            <span *ngIf="context.asset.defectState === 2"
              >partly functional</span
            >
            <span *ngIf="context.asset.defectState === 3">nonfunctional</span>

            <span *ngIf="context.asset.defectType === 1" class="color-warn">
              UNVERIFIED
            </span>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div class="content">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <!-- Tab: Defect History -->
          <mat-tab label="Defect History">
            <ng-template matTabContent>
              <div class="mt-small flex-column font-size-12">
                <lib-spinner
                  *ngIf="getAssetDefectsInProgress; else assetDefectsAreLoaded"
                ></lib-spinner>
                <ng-template #assetDefectsAreLoaded>
                  <span *ngIf="assetDefects.length === 0">
                    No defect records available.
                  </span>
                  <div *ngIf="assetDefects.length > 0" class="defect-items">
                    <ng-container *ngFor="let assetDefect of assetDefects">
                      <!-- LEFT -->
                      <div class="flex-column">
                        <span>
                          {{
                            assetDefect.timestamp
                              | date : localeService.datetimePipeString(locale)
                          }}
                        </span>
                        <span
                          [class.color-green]="assetDefect.defectTypeId === 2"
                        >
                          {{
                            assetDefect.defectTypeId === 1
                              ? "reported"
                              : "verified"
                          }}
                        </span>
                        <span
                          *ngIf="assetDefect.defectStateId === 1"
                          class="flex-row gap-tiny"
                          style="white-space: nowrap"
                        >
                          <span class="font-weight-500">OK</span>
                          <span> - fully functional</span>
                        </span>
                        <span
                          *ngIf="assetDefect.defectStateId === 2"
                          class="flex-row gap-tiny"
                          style="white-space: nowrap"
                        >
                          <span class="font-weight-500">DEFECT</span>
                          <span> - partially functional</span>
                        </span>
                        <span
                          *ngIf="assetDefect.defectStateId === 3"
                          class="flex-row gap-tiny"
                          style="white-space: nowrap"
                        >
                          <span class="font-weight-500">DEFECT 100</span>
                          <span> - nonfunctional</span>
                        </span>
                      </div>

                      <!-- RIGHT -->
                      <div class="flex-column">
                        <lib-user [oId]="assetDefect.userOid"></lib-user>
                        <span>{{ assetDefect.comment }}</span>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </mat-tab>

          <!-- Tab: Bookings -->
          <!-- <mat-tab label="Bookings">
            <ng-template>
              todo
            </ng-template>
          </mat-tab> -->
        </mat-tab-group>
      </div>
    </div>
  </ng-template>
</mtx-popover>

<div
  class="asset-info"
  #popoverTrigger="mtxPopoverTrigger"
  [mtxPopoverTriggerFor]="assetPopover"
  [mtxPopoverTriggerData]="{ context: this }"
>
  <span class="prevent-select">
    {{ assetId }}
  </span>
  <div
    *ngIf="showDefectInfo || showMissingInfo"
    class="flex-row align-items-center gap-small"
  >
    <!-- Binding to "asset" fires asset-fetch -->

    <!-- DEFECT RELATED -->
    <div
      class="flex-row align-items-center gap-tiny2"
      *ngIf="asset.defectType === 1 || asset.defectState > 1"
    >
      <!-- VERIFICATION REQUIRED -->
      <span
        *ngIf="asset.defectType === 1"
        class="material-symbols-outlined fill color-primary-400 font-size-16 prevent-select"
        style="margin-bottom: 4px"
      >
        star
      </span>

      <!-- DEFECT -->

      <span
        *ngIf="asset.defectState === 2"
        class="defect-char color-warn prevent-select"
      >
        D
      </span>

      <!-- DEFECT 100 -->
      <div
        *ngIf="asset.defectState === 3"
        class="flex-column align-items-center"
      >
        <span class="defect-100-char color-warn prevent-select"> D </span>
        <span class="defect-100-sub">100</span>
      </div>
    </div>

    <!-- MISSING -->
    <span
      *ngIf="asset[specialTableColumn_Missing?.field ?? 'na'] === true"
      class="missing-char"
      >M</span
    >
  </div>
</div>
