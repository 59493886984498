import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ToolbarToggleService } from '../../../services/toolbar-toggle.service';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar-toggle-item',
  templateUrl: './toolbar-toggle-item.component.html',
  styleUrls: ['./toolbar-toggle-item.component.scss'],
})
export class ToolbarToggleItemComponent implements OnInit, OnDestroy {
  // #region In/Out

  @Input() icon: string | undefined;
  @Input() value!: string;
  @Input() count: number | undefined;

  // #endregion In/Out

  // #region Public Properties

  get finalIcon() {
    return this.icon ?? 'help';
  }

  isSelected: boolean | undefined;

  // #endregion Public Properties

  // #region Private Properties

  private _uuid = uuid();
  private _serviceSubscription: Subscription | undefined;

  // #endregion Private Properties

  // #region Init

  constructor(private _toolbarToggleService: ToolbarToggleService) {}

  ngOnInit(): void {
    this._toolbarToggleService.register(this._uuid, this.value);

    this._serviceSubscription = this._toolbarToggleService.itemClickedEvent.subscribe(
      (itemUuid) => {
        if (itemUuid !== this._uuid) {
          this.isSelected = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._toolbarToggleService.unregister(this._uuid);
    this._serviceSubscription?.unsubscribe();
  }

  // #endregion Init

  // #region Public Methods

  onItemClicked() {
    this.isSelected = !this.isSelected;
    this._toolbarToggleService.clicked(this._uuid, this.isSelected);
  }

  // #endregion Public Methods
}
