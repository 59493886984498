import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_INVENTORY } from '../fragments/fullFragmentInventory';

export const FULL_FRAGMENT_INVENTORY_ASSETS_SUB = gql`
  ${FULL_FRAGMENT_INVENTORY}
  fragment FullFragmentInventoryAssetsSub on InventoryAssetsSubNotification {
    filterSessionId
    data {
      action
      assetIds
      userOid
      inventories {
        ...FullFragmentInventory
      }
    }
  }
`;
