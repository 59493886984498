<h1 mat-dialog-title class="dialog-header">
  Delete Asset
</h1>
<div mat-dialog-content class="dialog-body">
  Are you sure you want to delete <br> <strong>{{ data.id }}</strong>?
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onNoClick()">No</button>
  <button mat-raised-button color="primary" (click)="onYesClick()">Yes</button>
</div>
