import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_TENANT_AUDIT_LOG = gql`
  fragment FullFragmentTenantAuditLog on TenantAuditLogOutput {
    id
    timestamp
    auditLogEventId
    assetId
    userOid
    changeInfo
    oldValue
    newValue
    displayValue
    latestBookingBy_calcStr
    latestBookingLocation_calcStr
    latestBookingNotes_calcStr
  }
`;
