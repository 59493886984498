import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET } from '../fragments/fullFragmentReturnToCustomerAsset';

export const FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET_SUB = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET}
  fragment FullFragmentReturnToCustomerAssetSub on ReturnToCustomerAssetSubNotification {
    filterSessionId
    data {
      action
      returnToCustomerAssets {
        ...FullFragmentReturnToCustomerAsset
      }
    }
  }
`;
