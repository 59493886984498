import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OptionItemOutput } from 'projects/shared/src/lib/graphql/output/optionItemOutput';
import { v4 } from 'uuid';
import { Apollo, gql } from 'apollo-angular';
import { FormControl, Validators } from '@angular/forms';
import { Observable, firstValueFrom, map, startWith } from 'rxjs';
import { UpdateOptionItemMutationArgs, UpdateOptionItemMutationRoot } from 'projects/shared/src/lib/graphql/crud/optionItem';
import { FULL_FRAGMENT_OPTION_ITEM } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOptionItem';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';


export type RenameOptionItemDialogData = {
  optionItem: OptionItemOutput;
  optionItemNames: string[];
}

@Component({
  selector: 'app-rename-option-item-dialog',
  templateUrl: './rename-option-item-dialog.component.html',
  styleUrls: ['./rename-option-item-dialog.component.scss']
})
export class RenameOptionItemDialogComponent implements OnInit {
  // #region Public Methods

  activity = false;
  errorMessage: string | undefined;
  filteredOptionItemNames: Observable<string[]> | undefined;
  name = new FormControl<string | null>(null, Validators.required);
  uuid = v4();


  get canApply(): boolean {
    return this.activity == false
      && this.name.valid
      && !this.data.optionItemNames.map(x => x.toLowerCase()).includes(this.name.value?.toLowerCase() ?? '')
      ;
  }

  // #endregion Public Methods


  // #region Init

  constructor(
    private _dialogRef: MatDialogRef<RenameOptionItemDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: RenameOptionItemDialogData
    , private _apollo: Apollo
  ) {
    this.name.setValue(data.optionItem.name);
  }

  ngOnInit(): void {
    this.filteredOptionItemNames = this.name.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value);
      })
    )
  }

  // #endregion Init


  // #region Public Methods

  onKeyupEscape() {
    this.name.setValue(this.data.optionItem.name);
  }

  async apply() {
    if (!this.canApply || this.name.value === null) {
      return;
    }

    try {
      this.activity = true;
      this.name.disable();

      const variables: UpdateOptionItemMutationArgs = {
        id: this.data.optionItem.id,
        data: {
          name: this.name.value
        }
      };

      await firstValueFrom(this._apollo.mutate<UpdateOptionItemMutationRoot>({
        mutation: gql`
          ${FULL_FRAGMENT_OPTION_ITEM}
          mutation UpdateOptionItem($id: String!, $data: OptionItemInputUpdate!) {
            updateOptionItem(id: $id, data: $data) {
              ...FullFragmentOptionItem
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only'
      }));

      this._dialogRef.close(true);
    } catch (error) {
      this.errorMessage = new CatchError(error).message;
    } finally {
      this.activity = false;
      this.name.enable();
    }
  }

  // #endregion Public Methods 


  // #region Private Methods

  private _filter(value: string | null) {
    console.log(this.data.optionItemNames);
    if (value === null) {
      return this.data.optionItemNames;
    }

    return this.data.optionItemNames.filter(x => x.toLowerCase().includes(value.toLowerCase()));
  }

  // #endregion Private Methods
}
