import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { SelectionService } from '../../../../services/selection.service';
import { Subscription } from 'rxjs';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import {
  DeletePlanMutationArgs,
  DeletePlanMutationRoot,
  PlanQueryArgs,
  PlanQueryRoot,
  PlansQueryArgs,
  PlansQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import {
  CellContextMenuEvent,
  ColDef,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowClassParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { UserService } from '../../../../services/user.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { DatePipe } from '@angular/common';
import { AppEventService } from '../../../../services/app-event.service';
import { PlanInfoRendererComponent } from '../../../../component-helpers/ag/plan-info-renderer/plan-info-renderer.component';
import { DateTime } from 'luxon';
import { FormControl } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ConfirmService } from '../../../../services/confirm.service';
import { firstValueFrom } from 'rxjs';
import {
  LocalEventData_Plan,
  LocalEventService,
  LocalEventType,
} from '../../../../services/local-event.service';
import { NotificationService } from '../../../../services/notificationService/notification.service';
import { RemoteEventType } from '../../../../services/remote-event.service';
import { SubscriptionService } from '../../../../serices/subscription.service';
import { localEventHandling, remoteEventHandling } from './assets-plans.event-handling';
import { AppModule } from 'projects/desktop/src/app/app.module';
import {RoleId} from "../../../../../../../shared/src/lib/graphql/output/roleOutput";

class LoadPlanOptions {
  options = ['past', 'current', 'future'];
  selectedOptions: Array<'past' | 'current' | 'future'> = ['past', 'current', 'future'];

  // The datepicker component works with luxon DateTime. However, the initial
  // value MUST be a string (don't know if that is a bug). The value of the FormControl
  // emits DateTime (after the initial string was changed)) but CANNOT be setup as
  // "new FormControl<DateTime | null>".
  pastUntil = new FormControl<any>(DateTime.now().startOf('day').plus({ days: -30 }).toISODate());

  futureUntil = new FormControl<any>(null);
}

@Component({
  selector: 'app-assets-plans',
  templateUrl: './assets-plans.component.html',
  styleUrls: ['./assets-plans.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class AssetsPlansComponent implements OnInit, OnDestroy {
  // #region Public Properties

  loading = false;
  plans: PlanOutput[] = [];
  canClearFilter = false;
  showLivePanel = false;

  colDefs: ColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      headerName: 'Info',
      resizable: true,
      width: 160,
      maxWidth: 160,
      suppressAutoSize: true,
      cellRenderer: PlanInfoRendererComponent,
      sortable: false,
      filterValueGetter: (params) => {
        const plan = params.data as PlanOutput;
        const now = new Date();

        if (now < new Date(plan.planStart)) {
          return 'future';
        }

        if (now <= new Date(plan.planEnd)) {
          return 'current';
        }

        return 'past';
      },
    },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'planStart',
      headerName: 'Start',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return (
          this.datePipe.transform(
            new Date(params.value),
            this.localeService.datePipeString(this._locale)
          ) ?? 'na'
        );
      },
      filterParams: {
        comparator: (filterDate: Date, cellValue: string) => {
          const cellDate = new Date(cellValue);
          if (cellDate < filterDate) {
            return -1;
          } else if (cellDate > filterDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      field: 'planEnd',
      headerName: 'End',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return (
          this.datePipe.transform(
            new Date(params.value),
            this.localeService.datePipeString(this._locale)
          ) ?? 'na'
        );
      },
      filterParams: {
        comparator: (filterDate: Date, cellValue: string) => {
          const cellDate = new Date(cellValue);
          if (cellDate < filterDate) {
            return -1;
          } else if (cellDate > filterDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      headerName: '# of Assets',
      filter: 'agNumberColumnFilter',
      valueGetter: (row: any) => {
        const plan = row.data as PlanOutput;
        return plan.planAssets?.length ?? 0;
      },
    },
    {
      headerName: 'Responsible',
      valueGetter: (row: any) => {
        const email = this.userService.getUserOutputById(row.data.responsibleId)?.email ?? '-';
        if (email.includes('@')) {
          return email.split('@')[0];
        }

        return email;
      },
    },
    {
      headerName: 'Created By',
      valueGetter: (row: any) => {
        const email = this.userService.getUserOutputById(row.data.createdBy)?.email ?? '-';
        if (email.includes('@')) {
          return email.split('@')[0];
        }

        return email;
      },
    },
    {
      headerName: 'Created At',
      filter: 'agDateColumnFilter',
      sort: 'desc',
      field: 'createdAt',
      valueFormatter: (params: ValueFormatterParams) => {
        return (
          this.datePipe.transform(
            params.value,
            this.localeService.datetimePipeString(this._locale)
          ) ?? ''
        );
      },
    },
  ];

  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: 'agTextColumnFilter',
    resizable: true,
    floatingFilter: true,
    filterParams: {
      buttons: ['clear', 'apply'],
    },
    //filter: 'agSetColumnFilter',
  };

  loadPlanOptions = new LoadPlanOptions();
  pastUntilMax = DateTime.now().startOf('day').plus({ days: -1 }).toJSDate();
  futureUntilMin = DateTime.now().startOf('day').plus({ days: 1 }).toJSDate();
  @ViewChild(MatMenu) contextMenu: MatMenu | undefined;
  @ViewChild(MatMenuTrigger) contextMenuTrigger: MatMenuTrigger | undefined;
  contextMenuPosition = { x: '0px', y: '0px' };
  gridOptions = {
    context: this,
  };
  rowClassRules = {
    'ag-row-selected': function (params: RowClassParams): boolean {
      const context = params.context as AssetsPlansComponent;
      return context.#highlightedRowNodes.has(params.node);
    },
  };
  gridApi: GridApi | undefined;

  // #endregion Public Properties

  // #region Private Properties

  #plansQuery: QueryRef<PlansQueryRoot> | undefined;
  #plansSubscription: Subscription | undefined;
  #newPlanSubscription: Subscription | undefined;
  #pastUntilSubscription: Subscription | undefined;
  #futureUntilSubscription: Subscription | undefined;
  #highlightedRowNodes: Set<IRowNode> = new Set();
  #notificationLocalSubscription: Subscription | undefined;
  #notificationRemoteSubscription: Subscription | undefined;

  // #endregion Private Properties

  // #region Init

  constructor(
    public selectionService: SelectionService,
    private apollo: Apollo,
    private userService: UserService,
    private localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private datePipe: DatePipe,
    private appEventService: AppEventService,
    private confirmService: ConfirmService,
    private localEventService: LocalEventService,
    private notificationService: NotificationService,
    public subscriptionService: SubscriptionService
  ) {
    let roles = this.selectionService.myUser?.userRoles?.map((x) => x.roleId) ?? [];
    let minRole = Math.min(...roles) ?? 9;
    if (minRole === RoleId.ASSET_READER) {
      this.showLivePanel = true;
    }
  }

  ngOnInit(): void {
    // this.#newPlanSubscription = this.appEventService.eventNewPlan.subscribe((newPlan) => {
    //   this.#plansQuery?.refetch();
    // });

    this.#pastUntilSubscription = this.loadPlanOptions.pastUntil.valueChanges.subscribe(() => {
      this.loadPlans();
    });

    this.#futureUntilSubscription = this.loadPlanOptions.futureUntil.valueChanges.subscribe(() => {
      this.loadPlans();
    });

    this.loadPlans();

    // Setup local & remote event handling.

    this.notificationService.handleLocalEvents([
      LocalEventType.PlanAssetsAdded,
      LocalEventType.Plan,
    ]);
    this.notificationService.handleRemoteEvents([
      RemoteEventType.PlanAssetsAdded,
      RemoteEventType.PlanAssetsDeleted,
      RemoteEventType.Plan,
    ]);

    this.#notificationLocalSubscription = this.notificationService.localEventHandled.subscribe(
      (event) => {
        localEventHandling.call(this, event);
      }
    );
    this.#notificationRemoteSubscription = this.notificationService.remoteEventHandled.subscribe(
      (event) => {
        remoteEventHandling.call(this, event);
      }
    );
  }

  ngOnDestroy(): void {
    this.#plansSubscription?.unsubscribe();
    this.#newPlanSubscription?.unsubscribe();

    this.#pastUntilSubscription?.unsubscribe();
    this.#futureUntilSubscription?.unsubscribe();

    // Cleanup local & remote event handling.
    this.notificationService.unhandleLocalEvents([
      LocalEventType.PlanAssetsAdded,
      LocalEventType.Plan,
    ]);
    this.notificationService.unhandleRemoteEvents([
      RemoteEventType.PlanAssetsAdded,
      RemoteEventType.PlanAssetsDeleted,
      RemoteEventType.Plan,
    ]);

    this.#notificationLocalSubscription?.unsubscribe();
    this.#notificationRemoteSubscription?.unsubscribe();
  }

  // #endregion Init

  // #region Public Methods

  filterPlanAssetsInAssetsTable(plan: PlanOutput) {
    const tenantAssetIds = plan.planAssets?.map((x) => x.tenantAssetId) ?? [];
    if (tenantAssetIds.length === 0) {
      return;
    }

    this.localEventService.emitNewEvent(
      LocalEventType.FilterPlanAssetsInAssetsTable,
      tenantAssetIds
    );

    // Make sure that the assets table is visible.
    this.selectionService.assetsView.showAssets = true;
  }

  onClickDeletePlan(plan: PlanOutput) {
    this.confirmService.open(
      'Please confirm',
      `Do you really want to delete the plan '<b>${plan.name}</b>' and all related data?`,
      async () => {
        // We first need to find all tenantAssetIds of the plan.
        // These are required in the local event notification.
        const variablesPlan: PlanQueryArgs = {
          id: plan.id,
        };
        const relevantAssetIds =
          (
            await firstValueFrom(
              this.apollo.query<PlanQueryRoot>({
                query: gql`
                  query Plan($id: String!) {
                    plan(id: $id) {
                      id
                      planAssets {
                        id
                        tenantAssetId
                      }
                    }
                  }
                `,
                variables: variablesPlan,
                fetchPolicy: 'network-only',
              })
            )
          ).data.plan.planAssets?.map((x) => x.tenantAssetId) ?? [];

        const variables: DeletePlanMutationArgs = {
          id: plan.id,
        };
        await firstValueFrom(
          this.apollo.mutate<DeletePlanMutationRoot>({
            mutation: gql`
              mutation DeletePlan($id: String!) {
                deletePlan(id: $id) {
                  id
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.deletePlan) {
                return;
              }

              const eventData: LocalEventData_Plan = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'deleted',
                    plan: data.deletePlan,
                    relevantAssetIds,
                  },
                ],
              };

              this.localEventService.emitNewEvent(LocalEventType.Plan, eventData);
            },
          })
        );
      },
      undefined,
      (ok) => {
        if (ok) {
          this.selectionService.selectedPlan = undefined;
        }
      }
    );
  }

  onTableRightClick(event: MouseEvent) {
    // This is necessary to prevent the browser from opening its context menu.
    // However, allow the browser context menu with the Ctrl key pressed.
    if (event.ctrlKey) {
      return;
    }
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    if (this.contextMenuTrigger?.menuOpen) {
      this.contextMenuTrigger.closeMenu();
    }
  }

  onCellContextMenu(contextEvent: CellContextMenuEvent) {
    if (!this.contextMenuTrigger || (contextEvent.event as MouseEvent).ctrlKey) {
      return;
    }

    // Highlight the cell so that the user has a visual feedback about
    // "where" he performed the right click.
    this.#highlightedRowNodes.add(contextEvent.node);
    contextEvent.api.redrawRows({ rowNodes: [contextEvent.node] });

    this.contextMenuTrigger.menuData = {
      plan: contextEvent.data,
    };
    this.contextMenuTrigger.openMenu();

    // Now that the overlay will be "active", handle a contextmenu (right click) on it.
    const overlayElement = document.body.getElementsByClassName('cdk-overlay-container')[0];
    overlayElement.addEventListener('contextmenu', (event: any) => {
      this.contextMenuTrigger?.closeMenu();
      (event as MouseEvent).preventDefault();
    });
  }

  onContextMenuClosed() {
    // It might be that we will have a contextmenu handler on the overlay. Remove it.
    const overlayElement = document.body.getElementsByClassName('cdk-overlay-container')[0];
    overlayElement.removeAllListeners?.('contextmenu');

    // It might be that we have some highlighted rows. Remove the highlights.
    const rowNodes = Array.from(this.#highlightedRowNodes);
    this.#highlightedRowNodes.clear();
    this.gridApi?.redrawRows({ rowNodes });
  }

  onSelectedOptionsChange(event: Array<'past' | 'current' | 'future'>) {
    this.loadPlans();
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.selectionService.selectedPlan = event.api.getSelectedRows()[0];
  }

  clearAllFilters() {
    this.gridApi?.setFilterModel(null);
  }

  onFilterChanged(event: FilterChangedEvent) {
    const model = event.api.getFilterModel();
    const hasNoFilter = Object.keys(model).length === 0 && model.constructor === Object;
    this.canClearFilter = !hasNoFilter;
  }

  getDatePipeString() {
    return this.localeService.datePipeString(this._locale);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  loadPlans() {
    this.loading = true;

    if (typeof this.#plansQuery !== 'undefined') {
      // Reload plans. Just chage the variables and refetch.
      const variables = this.#buildPlansQueryArgs();
      this.#plansQuery.refetch(variables);
      return;
    }

    this.#plansSubscription?.unsubscribe();

    // Running for the first time. Set up query and subscription.
    const variables = this.#buildPlansQueryArgs();

    this.#plansQuery = this.apollo.watchQuery<PlansQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_PLAN}
        query Plans(
          $pastIncluded: Boolean!
          $currentIncluded: Boolean!
          $futureIncluded: Boolean!
          $pastUntil: DateTime
          $futureUntil: DateTime
        ) {
          plans(
            pastIncluded: $pastIncluded
            currentIncluded: $currentIncluded
            futureIncluded: $futureIncluded
            pastUntil: $pastUntil
            futureUntil: $futureUntil
          ) {
            ...FullFragmentPlan
            planAssets {
              id
              tenantAssetId
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });

    this.#plansSubscription = this.#plansQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.loading = false;
        const createdBys = data.plans.map((x) => x.createdBy);
        const responsibleIds = data.plans
          .filter((x) => x.responsibleId)
          .map((x) => x.responsibleId) as string[];

        const combinedIds = [...createdBys, ...responsibleIds];

        this.userService.queryAndCacheUsersByIds(combinedIds).then(() => {
          this.plans = data.plans;
        });
      },
      error: (error) => {
        this.loading = false;
        console.log(error);

        // The following is necessary. Otherwise the watchQuery "is dead and will
        // not trigger updates".
        this.#plansSubscription?.unsubscribe();
        this.#plansQuery = undefined;
      },
    });
  }

  // #endregion Public Methods

  // #region Private Methods

  #buildPlansQueryArgs(): PlansQueryArgs {
    // Please note, that the initial values for this.loadPlanOptions.pastUntil and
    // this.loadPlanOptions.futureUntil ARE STRINGS. Only AFTER the user has changed
    // them, they are of type "DateTime".

    const variables: PlansQueryArgs = {
      pastIncluded: false,
      currentIncluded: false,
      futureIncluded: false,
      pastUntil: undefined,
      futureUntil: undefined,
    };

    if (this.loadPlanOptions.selectedOptions.includes('current')) {
      // Load current plans.
      variables.currentIncluded = true;
    }

    if (this.loadPlanOptions.selectedOptions.includes('past')) {
      // Load past plans.
      // => Check "until" date (could be of type "string" or "DateTime").
      variables.pastIncluded = true;

      let pastUntil: Date | undefined;
      if (this.loadPlanOptions.pastUntil.value) {
        pastUntil = this.#toJSDate(this.loadPlanOptions.pastUntil.value);
      } else {
        pastUntil = DateTime.now().startOf('day').plus({ days: -30 }).toJSDate();
      }
      variables.pastUntil = pastUntil;
    }

    if (this.loadPlanOptions.selectedOptions.includes('future')) {
      // Load future plans.
      // => Check "until" date.
      variables.futureIncluded = true;
      if (this.loadPlanOptions.futureUntil.value) {
        variables.futureUntil = this.#toJSDate(this.loadPlanOptions.futureUntil.value);
      }
    }

    return variables;
  }

  #toJSDate(value: string | DateTime): Date {
    try {
      return (value as DateTime).toJSDate();
    } catch (error) {
      // value was NOT a DateTime but a string.
      return DateTime.fromISO(value as string).toJSDate();
    }
  }

  // #endregion Private Methods
}
