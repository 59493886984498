import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_INVENTORY = gql`
  fragment FullFragmentInventory on InventoryOutput {
    id
    tenantId
    name
    description
    createdAt
    createdBy
    completed
    noOfOverallAssets
    noOfConfirmedAssets
  }
`;
