import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { UsersQueryArgs, UsersQueryRoot } from 'projects/shared/src/lib/graphql/crud/user';
import { UserOutput } from 'projects/shared/src/lib/graphql/output/userOutput';
import { firstValueFrom } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly users = new Map<string, UserOutput>();

  constructor(
    private _apollo: Apollo
  ) { }

  getUserOutputById(oid: string): UserOutput | undefined {
    return this.users.get(oid);
  }

  async queryAndCacheUsersByIds(oids: string[]): Promise<void> {
    const uniqueOids = Array.from(new Set(oids));
    const uniqueNewOids = uniqueOids.filter(x => !this.users.has(x));

    const variables: UsersQueryArgs = {
      ids: uniqueNewOids
    };

    const result = await firstValueFrom(
      this._apollo.query<UsersQueryRoot>({
        query: gql`
          query Users($ids: [String!]!) {
            users(ids: $ids) {
              oid
              email
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only'
      }));

    for (let userOutput of result.data.users) {
      this.users.set(userOutput.oid, userOutput);
    }
  }


}
