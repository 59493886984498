import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Apollo, gql } from 'apollo-angular';
import { v4 } from 'uuid';
import {
  CreateInventoryMutationArgs,
  CreateInventoryMutationRoot,
  InventoriesQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/inventory';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_INVENTORY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentInventory';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import {
  LocalEventData_Inventory,
  LocalEventService,
  LocalEventType,
} from '../../services/local-event.service';
import { SelectionService } from '../../services/selection.service';
import { AppModule } from '../../app.module';

export type InventoryCreateDialogData = {
  mode: 'all' | 'selected' | 'filtered';
  assetIds: string[];
};

@Component({
  selector: 'app-inventory-create-dialog',
  templateUrl: './inventory-create-dialog.component.html',
  styleUrls: ['./inventory-create-dialog.component.scss'],
})
export class InventoryCreateDialogComponent implements OnInit {
  name: string | undefined;
  notes: string | undefined;
  assetIds: string[] = [];
  activity: boolean = false;
  uuid1 = v4();
  uuid2 = v4();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InventoryCreateDialogData,
    private dialogRef: MatDialogRef<InventoryCreateDialogComponent>,
    private toastService: DesktopToastService,
    private apollo: Apollo,
    private selectionService: SelectionService,
    private localEventService: LocalEventService
  ) {}

  ngOnInit(): void {
    this.assetIds = this.data.assetIds.sortBy((x) => x);
  }

  async apply() {
    if (!this.name || this.activity) {
      return;
    }

    this.activity = true;
    try {
      const variables: CreateInventoryMutationArgs = {
        assetIds: this.assetIds,
        data: {
          name: this.name,
          description: this.notes,
        },
      };

      const result = await firstValueFrom(
        this.apollo.mutate<CreateInventoryMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_INVENTORY}
            mutation CreateInventory($assetIds: [String!]!, $data: InventoryInputCreate!) {
              createInventory(assetIds: $assetIds, data: $data) {
                ...FullFragmentInventory
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.createInventory) {
              return;
            }

            const query = gql`
              ${FULL_FRAGMENT_INVENTORY}
              query Inventories {
                inventories {
                  ...FullFragmentInventory
                }
              }
            `;

            const cachedReadonlyInventories = cache.readQuery<InventoriesQueryRoot>({
              query,
            })?.inventories;

            let newArray: InventoryOutput[] = [];

            if (typeof cachedReadonlyInventories !== 'undefined') {
              newArray = [data.createInventory, ...cachedReadonlyInventories];
            } else {
              // Don't know if that can happen.
              newArray.push(data.createInventory);
            }

            cache.writeQuery<InventoriesQueryRoot>({
              query,
              data: {
                inventories: newArray,
              },
            });

            // Maybe TODO:
            const eventData: LocalEventData_Inventory = {
              filterSessionId: AppModule.sessionId,
              data: [
                {
                  action: 'created',
                  inventory: data.createInventory,
                  userOid: this.selectionService.myUser?.oid ?? 'na',
                },
              ],
            };
            this.localEventService.emitNewEvent(LocalEventType.Inventory, eventData);
          },
        })
      );

      if (!result.data) {
        return;
      }

      this.dialogRef.close(undefined);
    } catch (error) {
      this.toastService.error(new CatchError(error).message);
    } finally {
      this.activity = false;
    }
  }
}
