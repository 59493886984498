import { gql } from "apollo-angular";

export const FULL_FRAGMENT_TABLE_COLUMN = gql`
    fragment FullFragmentTableColumn on TableColumnOutput {
        field
        headerName
        sortOrder
        propertyLevelId
        propertyTypeId
        propertyOptionId
        groupAssetId
    }
`;