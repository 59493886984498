<mat-form-field
  [appearance]="appearance"
  [subscriptSizing]="subscriptSizing"
  class="width-100"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-icon *ngIf="!activity && !hideSearchIcon" matPrefix>search</mat-icon>
  <mat-icon *ngIf="activity" matPrefix class="spinner"> donut_large </mat-icon>
  <input
    #input
    matInput
    [formControl]="searchText"
    [autocomplete]="uuid"
    [matAutocomplete]="auto"
    [readonly]="readonly"
    [required]="required"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

  <button
    matSuffix
    mat-icon-button
    [class.hide]="!oUser || activity || readonly || disabled"
    (click)="onClickClear()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-autocomplete
    #auto="matAutocomplete"
    panelWidth="auto"
    (optionSelected)="onOptionSelected($event)"
    [displayWith]="displayFn"
  >
    <mat-option *ngFor="let oUser of oUsers" [value]="oUser">
      <div class="flex-column">
        <span class="display-name">{{ oUser.displayName }}</span>
        <div
          class="flex-row align-items-center gap-small mat-body"
          style="overflow-x: hidden"
        >
          <span *ngIf="oUser.mail" class="email white-space-nowrap">
            ({{ extractEID(oUser.mail) }})
          </span>
          <span class="white-space-nowrap ellipsis">{{ oUser.jobTitle }}</span>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
