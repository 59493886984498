<h2 mat-dialog-title>
  Start Inventory:
  <span *ngIf="data.mode === 'all'" class="color-primary-400">ALL ASSETS</span>
  <span
    *ngIf="data.mode === 'selected' || data.mode === 'filtered'"
    class="color-primary-400"
  >
    {{ data.assetIds.length }}
    {{ data.assetIds.length === 1 ? "ASSET" : "ASSETS" }}
  </span>
</h2>

<mat-dialog-content>
  <div style="height: 4px">
    <mat-progress-bar *ngIf="activity" mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="flex-column form-field-density-1">
    <mat-form-field
      appearance="fill"
      subscriptSizing="dynamic"
      class="mb-normal"
    >
      <mat-label>Name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        [autocomplete]="uuid1"
        required
      />
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic" class="mb-normal">
      <mat-label>Notes</mat-label>
      <textarea
        type="text"
        matInput
        [autocomplete]="uuid2"
        [(ngModel)]="notes"
        rows="2"
        [cdkTextareaAutosize]="true"
      ></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close [disabled]="activity">
    CANCEL
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="activity || !name"
    (click)="apply()"
  >
    START
  </button>
</mat-dialog-actions>
