import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { AppModule } from 'projects/desktop/src/app/app.module';
import { ConfirmService } from 'projects/desktop/src/app/services/confirm.service';
import { DesktopToastService } from 'projects/desktop/src/app/services/desktop-toast.service';
import {
  LocalEventData_ReturnToCustomerAsset,
  LocalEventData_ReturnToCustomerNote,
  LocalEventService,
  LocalEventType,
} from 'projects/desktop/src/app/services/local-event.service';
import { NotificationService } from 'projects/desktop/src/app/services/notificationService/notification.service';
import {
  RemoteEventData_ReturnToCustomerAsset,
  RemoteEventData_ReturnToCustomerNote,
  RemoteEventType,
} from 'projects/desktop/src/app/services/remote-event.service';
import { SelectionService } from 'projects/desktop/src/app/services/selection.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  ReturnToCustomerQueryArgs,
  ReturnToCustomerQueryRoot,
  UpdateReturnToCustomerMutationArgs,
  UpdateReturnToCustomerMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/returnToCustomer';
import {
  DeleteReturnToCustomerAssetsMutationArgs,
  DeleteReturnToCustomerAssetsMutationRoot,
  ReturnToCustomerAssetsQueryArgs,
  ReturnToCustomerAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/returnToCustomerAsset';
import {
  CreateReturnToCustomerNoteMutationArgs,
  CreateReturnToCustomerNoteMutationRoot,
  DeleteReturnToCustomerNoteMutationArgs,
  DeleteReturnToCustomerNoteMutationRoot,
  ReturnToCustomerNotesQueryArgs,
  ReturnToCustomerNotesQueryRoot,
  UpdateReturnToCustomerNoteMutationArgs,
  UpdateReturnToCustomerNoteMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/returnToCustomerNote';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER } from 'projects/shared/src/lib/graphql/fragments/fullFragmentReturnToCustomer';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentReturnToCustomerAsset';
import { FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE } from 'projects/shared/src/lib/graphql/fragments/fullFragmentReturnToCustomerNote';
import { ReturnToCustomerAssetOutput } from 'projects/shared/src/lib/graphql/output/returnToCustomerAssetOutput';
import { ReturnToCustomerNoteOutput } from 'projects/shared/src/lib/graphql/output/returnToCustomerNoteOutput';
import { ReturnToCustomerOutput } from 'projects/shared/src/lib/graphql/output/returnToCustomerOutput';
import { ReturnToCustomerAssetSubNotification } from 'projects/shared/src/lib/graphql/subNotifications/returnToCustomerAssetSubNotification';
import {
  AssetColumn,
  AssetColumnService,
} from 'projects/shared/src/lib/services/asset-column.service';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';

const returnToCustomerNotesQuery = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE}
  query ReturnToCustomerNotes($returnToCustomerId: String!) {
    returnToCustomerNotes(returnToCustomerId: $returnToCustomerId) {
      ...FullFragmentReturnToCustomerNote
    }
  }
`;

const returnToCustomerAssetsQuery = gql`
  ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET}
  query ReturnToCustomerAssets($returnToCustomerId: String!) {
    returnToCustomerAssets(returnToCustomerId: $returnToCustomerId) {
      ...FullFragmentReturnToCustomerAsset
    }
  }
`;

@Component({
  selector: 'app-return-to-customer-details',
  templateUrl: './return-to-customer-details.component.html',
  styleUrls: ['./return-to-customer-details.component.scss'],
})
export class ReturnToCustomerDetailsComponent implements OnInit, OnDestroy {
  activity = false;
  rtc: ReturnToCustomerOutput | undefined;
  rtcNotes: ReturnToCustomerNoteOutput[] = [];
  rtcAssets: ReturnToCustomerAssetOutput[] = [];
  assets: any[] = [];
  id: string | undefined;
  uuid1 = v4();
  uuid2 = v4();
  uuid3 = v4();
  uuid4 = v4();
  newNote: string | undefined;
  readonly notesEditMode = new Map<string, boolean>();
  assetColumns: AssetColumn[] | undefined;
  tenantId = 'na';
  column_ModifiedAt: AssetColumn | undefined;
  column_ModifiedBy: AssetColumn | undefined;
  isSelectAll = false;
  isSelectedAllIntermediate = false;
  isSelected = new Map<string, boolean>();
  selectedAssets: any[] = [];

  #activatedRouteSubscription: Subscription | undefined;
  #rtcQuery: QueryRef<ReturnToCustomerQueryRoot> | undefined;
  #rtcSubscription: Subscription | undefined;
  #rtcNotesQuery: QueryRef<ReturnToCustomerNotesQueryRoot> | undefined;
  #rtcNotesSubscription: Subscription | undefined;
  #rtcAssetsQuery: QueryRef<ReturnToCustomerAssetsQueryRoot> | undefined;
  #rtcAssetsSubscription: Subscription | undefined;
  #notificationLocalSubscription: Subscription | undefined;
  #notificationRemoteSubscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo,
    private toastService: DesktopToastService,
    private selectionService: SelectionService,
    private confirmService: ConfirmService,
    private notificationService: NotificationService,
    private localEventService: LocalEventService,
    private assetService: AssetService,
    public assetColumnService: AssetColumnService
  ) {}

  ngOnInit(): void {
    this.#activatedRouteSubscription = this.activatedRoute.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (!id) {
        return;
      }
      this.id = id;

      if (this.rtc) {
        // We already have loaded some data before. Just do a refetch with
        // updated variables.
        const variables1: ReturnToCustomerQueryArgs = {
          id,
        };
        this.#rtcQuery?.refetch(variables1);

        const variables2: ReturnToCustomerNotesQueryArgs | ReturnToCustomerAssetsQueryArgs = {
          returnToCustomerId: id,
        };
        this.#rtcNotesQuery?.refetch(variables2);
        this.#rtcAssetsQuery?.refetch(variables2);
      } else {
        this.#loadReturnToCustomer(id);
        this.#loadReturnToCustomerAssets(id);
        this.#loadReturnToCustomerNotes(id);
      }
    });

    this.notificationService.handleLocalEvents([
      LocalEventType.ReturnToCustomerNote,
      LocalEventType.ReturnToCustomerAsset,
    ]);
    this.notificationService.handleRemoteEvents([
      RemoteEventType.ReturnToCustomerNote,
      RemoteEventType.ReturnToCustomerAsset,
    ]);

    this.#notificationLocalSubscription = this.notificationService.localEventHandled.subscribe(
      this.#localEventHandling.bind(this)
    );
    this.#notificationRemoteSubscription = this.notificationService.remoteEventHandled.subscribe(
      this.#remoteEventHandling.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.#activatedRouteSubscription?.unsubscribe();
    this.#rtcSubscription?.unsubscribe();
    this.#rtcNotesSubscription?.unsubscribe();
    this.selectionService.selectedReturnToCustomer = undefined;

    this.notificationService.unhandleLocalEvents([
      LocalEventType.ReturnToCustomerNote,
      LocalEventType.ReturnToCustomerAsset,
    ]);
    this.notificationService.unhandleRemoteEvents([
      RemoteEventType.ReturnToCustomerNote,
      RemoteEventType.ReturnToCustomerAsset,
    ]);

    this.#notificationLocalSubscription?.unsubscribe();
    this.#notificationRemoteSubscription?.unsubscribe();
  }

  removeSelectedAssets() {
    if (this.selectedAssets.length === 0) {
      return;
    }

    const relevantReturnToCustomerAssets =
      this.rtcAssets?.filter((x) =>
        this.selectedAssets.map((x) => x.id).includes(x.tenantAssetId)
      ) ?? [];

    if (relevantReturnToCustomerAssets.length === 0) {
      return;
    }

    this.confirmService.open(
      'Are you sure?',
      `Do you really want to remove the selected ${this.selectedAssets.length} ${
        this.selectedAssets.length === 1 ? 'asset' : 'assets'
      }` +
        ` from this 'Return to Customer'?<br>The assets will immediately be made available for planning and booking.`,
      async () => {
        const variables: DeleteReturnToCustomerAssetsMutationArgs = {
          ids: relevantReturnToCustomerAssets.map((x) => x.id),
        };

        await firstValueFrom(
          this.apollo.mutate<DeleteReturnToCustomerAssetsMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_ASSET}
              mutation DeleteReturnToCustomerAssets($ids: [String!]!) {
                deleteReturnToCustomerAssets(ids: $ids) {
                  ...FullFragmentReturnToCustomerAsset
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.deleteReturnToCustomerAssets) {
                return;
              }

              const eventData: ReturnToCustomerAssetSubNotification = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'deleted',
                    returnToCustomerAssets: data.deleteReturnToCustomerAssets,
                  },
                ],
              };
              this.localEventService.emitNewEvent(LocalEventType.ReturnToCustomerAsset, eventData);
            },
          })
        );
      },
      undefined,
      () => {
        (document.activeElement as HTMLElement)?.blur();
      }
    );
  }

  isSelectedAllChanged(value: boolean) {
    this.assets.forEach((x) => {
      this.isSelected.set(x.id, value);
    });

    this.#evaluateSelectionState();
  }

  isSelectedChanged(assetId: string, value: boolean) {
    // Make sure that every asset has a record in isSelected
    this.assets.forEach((x) => {
      if (!this.isSelected.has(x.id)) {
        this.isSelected.set(x.id, false);
      }
    });

    this.isSelected.set(assetId, value);
    this.#evaluateSelectionState();
  }

  async update(
    name: string | undefined,
    description: string | undefined,
    input: HTMLInputElement | HTMLTextAreaElement
  ) {
    if (!this.rtc) {
      return;
    }

    if (typeof name !== 'undefined' && this.rtc.name === name) {
      return;
    }

    if (typeof description !== 'undefined' && this.rtc.description === description) {
      return;
    }

    try {
      this.activity = true;

      const variables: UpdateReturnToCustomerMutationArgs = {
        id: this.rtc.id,
        data: {
          name,
          description,
        },
      };

      await firstValueFrom(
        this.apollo.mutate<UpdateReturnToCustomerMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
            mutation UpdateReturnToCustomer($id: String!, $data: ReturnToCustomerInputUpdate!) {
              updateReturnToCustomer(id: $id, data: $data) {
                ...FullFragmentReturnToCustomer
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
      if (typeof name !== 'undefined') {
        input.value = this.rtc.name;
      }
      if (typeof description !== 'undefined') {
        input.value = this.rtc.description ?? '';
      }
    } finally {
      this.activity = false;
    }
  }

  editNote(note: ReturnToCustomerNoteOutput) {
    Array.from(this.notesEditMode.keys()).forEach((x) => this.notesEditMode.set(x, false));
    this.notesEditMode.set(note.id, true);
  }

  async applyNoteText(note: ReturnToCustomerNoteOutput, text: string) {
    if (note.note === text) {
      this.notesEditMode.set(note.id, false);
      return;
    }

    try {
      const variables: UpdateReturnToCustomerNoteMutationArgs = {
        id: note.id,
        data: {
          note: text,
        },
      };

      await firstValueFrom(
        this.apollo.mutate<UpdateReturnToCustomerNoteMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE}
            mutation UpdateReturnToCustomerNote(
              $id: String!
              $data: ReturnToCustomerNoteInputUpdate!
            ) {
              updateReturnToCustomerNote(id: $id, data: $data) {
                ...FullFragmentReturnToCustomerNote
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.updateReturnToCustomerNote) {
              return;
            }

            const eventData: LocalEventData_ReturnToCustomerNote = {
              filterSessionId: AppModule.sessionId,
              data: [
                {
                  action: 'updated',
                  returnToCustomerNote: data.updateReturnToCustomerNote,
                },
              ],
            };

            this.localEventService.emitNewEvent(LocalEventType.ReturnToCustomerNote, eventData);
          },
        })
      );

      this.notesEditMode.set(note.id, false);
    } catch (error) {
      this.toastService.error(new CatchError(error).message, 'Error');
    }
  }

  async deleteNote(note: ReturnToCustomerNoteOutput) {
    this.confirmService.open(
      'Are you sure?',
      `Do you really want to delete this message?`,
      async () => {
        const variables: DeleteReturnToCustomerNoteMutationArgs = {
          id: note.id,
        };

        await firstValueFrom(
          this.apollo.mutate<DeleteReturnToCustomerNoteMutationRoot>({
            mutation: gql`
              ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE}
              mutation DeleteReturnToCustomerNote($id: String!) {
                deleteReturnToCustomerNote(id: $id) {
                  ...FullFragmentReturnToCustomerNote
                }
              }
            `,
            variables,
            fetchPolicy: 'network-only',
            update: (cache, { data }) => {
              if (!data?.deleteReturnToCustomerNote) {
                return;
              }

              const eventData: LocalEventData_ReturnToCustomerNote = {
                filterSessionId: AppModule.sessionId,
                data: [
                  {
                    action: 'deleted',
                    returnToCustomerNote: data.deleteReturnToCustomerNote,
                  },
                ],
              };

              this.localEventService.emitNewEvent(LocalEventType.ReturnToCustomerNote, eventData);
            },
          })
        );
      },
      undefined,
      () => {
        (document.activeElement as HTMLElement)?.blur();
      }
    );
  }

  async createNote() {
    if (!this.newNote || !this.id) {
      return;
    }

    try {
      const variables: CreateReturnToCustomerNoteMutationArgs = {
        data: {
          note: this.newNote,
          returnToCustomerId: this.id,
        },
      };

      await firstValueFrom(
        this.apollo.mutate<CreateReturnToCustomerNoteMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_RETURN_TO_CUSTOMER_NOTE}
            mutation CreateReturnToCustomerNote($data: ReturnToCustomerNoteInputCreate!) {
              createReturnToCustomerNote(data: $data) {
                ...FullFragmentReturnToCustomerNote
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            if (!data?.createReturnToCustomerNote) {
              return;
            }

            const eventData: LocalEventData_ReturnToCustomerNote = {
              filterSessionId: AppModule.sessionId,
              data: [
                {
                  action: 'created',
                  returnToCustomerNote: data.createReturnToCustomerNote,
                },
              ],
            };

            this.localEventService.emitNewEvent(LocalEventType.ReturnToCustomerNote, eventData);
          },
        })
      );
      this.newNote = undefined;
    } catch (error) {
      console.log(error);
      this.toastService.error(new CatchError(error).message, 'Error');
    }
  }

  async #loadReturnToCustomer(id: string) {
    const variables: ReturnToCustomerQueryArgs = {
      id,
    };

    this.#rtcQuery = this.apollo.watchQuery<ReturnToCustomerQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_RETURN_TO_CUSTOMER}
        query ReturnToCustomer($id: String!) {
          returnToCustomer(id: $id) {
            ...FullFragmentReturnToCustomer
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this.#rtcSubscription = this.#rtcSubscription = this.#rtcQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        console.log('NEXT');
        this.rtc = data.returnToCustomer ?? undefined;
        this.selectionService.selectedReturnToCustomer = data.returnToCustomer ?? undefined;
      },
      error: (error) => {
        this.toastService.error(new CatchError(error).message, 'Error');
      },
    });
  }

  async #loadReturnToCustomerAssets(id: string) {
    const variables: ReturnToCustomerAssetsQueryArgs = {
      returnToCustomerId: id,
    };

    this.#rtcAssetsQuery = this.apollo.watchQuery<ReturnToCustomerAssetsQueryRoot>({
      query: returnToCustomerAssetsQuery,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this.#rtcAssetsSubscription = this.#rtcAssetsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.rtcAssets = data.returnToCustomerAssets;
        if (!loading) {
          this.#loadAssets();
        }
      },
      error: (error) => {
        this.toastService.error(new CatchError(error).message, 'Error');
      },
    });
  }

  async #loadReturnToCustomerNotes(id: string) {
    const variables: ReturnToCustomerNotesQueryArgs = {
      returnToCustomerId: id,
    };

    this.#rtcNotesQuery = this.apollo.watchQuery<ReturnToCustomerNotesQueryRoot>({
      query: returnToCustomerNotesQuery,
      variables,
      fetchPolicy: 'cache-and-network',
    });

    this.#rtcNotesSubscription = this.#rtcNotesQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.rtcNotes = data.returnToCustomerNotes.sortBy((x) => x.createdAt);
      },
      error: (error) => {
        this.toastService.error(new CatchError(error).message, 'Error');
      },
    });
  }

  async #loadAssets() {
    const tenantId = this.selectionService.selectedTenant?.id;
    if (!tenantId) {
      return;
    }

    if (typeof this.assetColumns === 'undefined') {
      // Make sure that the asset column info is loaded/available.
      this.assetColumns = await this.assetColumnService.assureColumns(
        this.selectionService.selectedTenant?.id ?? 'na'
      );
      this.column_ModifiedAt = this.assetColumnService.column_ModifiedAt.get(tenantId);
      this.column_ModifiedBy = this.assetColumnService.column_ModifiedBy.get(tenantId);
    }

    const relevantAssetIds = this.rtcAssets?.map((x) => x.tenantAssetId) ?? [];
    if (relevantAssetIds.empty()) {
      this.assets = [];
      this.isSelected.clear();
      this.selectedAssets = [];
      this.isSelectAll = false;
      this.isSelectedAllIntermediate = false;
      return;
    }

    this.assets = (
      await this.assetService.fetchMultiple(relevantAssetIds, 'cache-and-network', 'only')
    ).sortBy((x) => x.id);

    this.isSelected.clear();
    this.selectedAssets = [];
    this.isSelectAll = false;
    this.isSelectedAllIntermediate = false;
  }

  #localEventHandling(value: [LocalEventType, any]) {
    if (value[0] === LocalEventType.ReturnToCustomerNote) {
      const eventData: LocalEventData_ReturnToCustomerNote = value[1];
      this.#handleReturnToCustomerNoteChanges(eventData);
    } else if (value[0] === LocalEventType.ReturnToCustomerAsset) {
      this.#handleReturnToCustomerAssetChanges(value[1]);
    }
  }

  #remoteEventHandling(value: [RemoteEventType, any]) {
    if (value[0] === RemoteEventType.ReturnToCustomerNote) {
      this.#handleReturnToCustomerNoteChanges(value[1]);
    } else if (value[0] === RemoteEventType.ReturnToCustomerAsset) {
      this.#handleReturnToCustomerAssetChanges(value[1]);
    }
  }

  #handleReturnToCustomerNoteChanges(
    eventData: LocalEventData_ReturnToCustomerNote | RemoteEventData_ReturnToCustomerNote
  ) {
    if (!this.rtc) {
      return;
    }

    const relevant =
      eventData.data?.filter((x) => x.returnToCustomerNote.returnToCustomerId === this.rtc?.id) ??
      [];

    const created = relevant.filter((x) => x.action === 'created');
    const deleted = relevant.filter((x) => x.action === 'deleted');

    const variables: ReturnToCustomerNotesQueryArgs = {
      returnToCustomerId: this.rtc.id,
    };

    if (deleted.length > 0) {
      const cachedNotes =
        AppModule.graphqlCache.readQuery<ReturnToCustomerNotesQueryRoot>({
          query: returnToCustomerNotesQuery,
          variables,
        })?.returnToCustomerNotes ?? [];

      const notesAfterDelete: ReturnToCustomerNoteOutput[] = [];
      for (const cachedNote of cachedNotes) {
        if (deleted.map((x) => x.returnToCustomerNote.id).includes(cachedNote.id)) {
          continue;
        }
        notesAfterDelete.push(cachedNote);
      }
      AppModule.graphqlCache.writeQuery<ReturnToCustomerNotesQueryRoot>({
        query: returnToCustomerNotesQuery,
        variables,
        data: {
          returnToCustomerNotes: notesAfterDelete.sortBy((x) => x.createdAt),
        },
      });
    }

    if (created.length > 0) {
      const cachedNotes =
        AppModule.graphqlCache.readQuery<ReturnToCustomerNotesQueryRoot>({
          query: returnToCustomerNotesQuery,
          variables,
        })?.returnToCustomerNotes ?? [];

      AppModule.graphqlCache.writeQuery<ReturnToCustomerNotesQueryRoot>({
        query: returnToCustomerNotesQuery,
        variables,
        data: {
          returnToCustomerNotes: [
            ...created.map((x) => x.returnToCustomerNote),
            ...cachedNotes,
          ].sortBy((x) => x.createdAt),
        },
      });
    }
  }

  #handleReturnToCustomerAssetChanges(
    eventData: LocalEventData_ReturnToCustomerAsset | RemoteEventData_ReturnToCustomerAsset
  ) {
    if (!this.rtc) {
      return;
    }

    const deletedReturnToCustomerAssets =
      eventData.data
        ?.filter((x) => x.action === 'deleted')
        .map((x) => x.returnToCustomerAssets)
        .flat()
        .filter((x) => x.returnToCustomerId === this.rtc?.id) ?? [];
    console.log(deletedReturnToCustomerAssets);

    const createdReturnToCustomerAssets =
      eventData.data
        ?.filter((x) => x.action === 'created')
        .map((x) => x.returnToCustomerAssets)
        .flat()
        .filter((x) => x.returnToCustomerId === this.rtc?.id) ?? [];

    const variables: ReturnToCustomerAssetsQueryArgs = {
      returnToCustomerId: this.rtc.id,
    };

    if (!deletedReturnToCustomerAssets.empty()) {
      const cachedReturnToCustomerAssets =
        AppModule.graphqlCache.readQuery<ReturnToCustomerAssetsQueryRoot>({
          query: returnToCustomerAssetsQuery,
          variables,
        })?.returnToCustomerAssets ?? [];

      const returnToCustomerAssetsAfterDelete: ReturnToCustomerAssetOutput[] = [];
      for (const cachedAsset of cachedReturnToCustomerAssets) {
        if (deletedReturnToCustomerAssets.map((x) => x.id).includes(cachedAsset.id)) {
          continue;
        }

        returnToCustomerAssetsAfterDelete.push(cachedAsset);
      }
      AppModule.graphqlCache.writeQuery<ReturnToCustomerAssetsQueryRoot>({
        query: returnToCustomerAssetsQuery,
        variables,
        data: {
          returnToCustomerAssets: returnToCustomerAssetsAfterDelete,
        },
      });
    }

    if (!createdReturnToCustomerAssets.empty()) {
      const cachedReturnToCustomerAssets =
        AppModule.graphqlCache.readQuery<ReturnToCustomerAssetsQueryRoot>({
          query: returnToCustomerAssetsQuery,
          variables,
        })?.returnToCustomerAssets ?? [];

      AppModule.graphqlCache.writeQuery<ReturnToCustomerAssetsQueryRoot>({
        query: returnToCustomerAssetsQuery,
        variables,
        data: {
          returnToCustomerAssets: [
            ...createdReturnToCustomerAssets,
            ...cachedReturnToCustomerAssets,
          ],
        },
      });
    }
  }

  #evaluateSelectionState() {
    this.isSelectAll = Array.from(this.isSelected.values()).every((x) => x === true);
    this.isSelectedAllIntermediate =
      Array.from(this.isSelected.values()).some((x) => x === true) &&
      Array.from(this.isSelected.values()).some((x) => x === false);

    const relevantAssetIds = Array.from(this.isSelected.entries())
      .filter((x) => x[1] === true)
      .map((x) => x[0]);
    this.selectedAssets = this.assets.filter((x) => relevantAssetIds.includes(x.id));
  }
}
