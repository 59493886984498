<mat-form-field [subscriptSizing]="subscriptSizing" [appearance]="appearance">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    #searchInput
    type="text"
    matInput
    [formControl]="searchValue"
    [autocomplete]="uuid"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option
      *ngFor="let filteredProperty of filteredProperties | async"
      [value]="filteredProperty"
    >
      <span
        [innerHTML]="
          filteredProperty.displayName | highlight : searchInput.value : true
        "
      ></span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
