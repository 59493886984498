import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { AssetMissingSubNotificationData } from 'projects/shared/src/lib/graphql/subNotifications/assetMissingSubNotification';

export enum SubscriptionEventType {
  PlannedActionBookingAdded = 'planned action booking added',
  PlannedActionBookingRemoved = 'planned action booking removed',
  AssetMissingSet = 'asset set to missing',
  AssetMissingReset = 'asset set to available',
}

export type PlannedActionBookingData = {
  tenantAssetId: string;
  bookingUserOid: string;
  planStepAction: PlanStepActionOutput;
};

export type AssetMissing = {
  notification: AssetMissingSubNotificationData;
};

export class SubscriptionEvent<T> {
  readonly date: string;

  constructor(public type: SubscriptionEventType, public data: T) {
    this.date = new Date().toISOString();
  }
}
