import { Injectable } from '@angular/core';
import { UserOutput } from '../graphql/output/userOutput';
import { RoleId } from '../graphql/output/roleOutput';
import { TenantOutput } from '../graphql/output/tenantOutput';

@Injectable({
  providedIn: 'root',
})
export class BaseSelectionService {
  // #region Tenant Related

  selectedTenant: TenantOutput | undefined;

  get hasAnyUserRoles(): boolean {
    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    return true;
  }

  get hasMultiTenantAccess(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const distinctTenantIds = [
      ...new Set(userRoles.filter((x) => typeof x.tenantId !== 'undefined').map((x) => x.tenantId)),
    ];
    return distinctTenantIds.length > 1;
  }

  get otherAvailableTenants(): TenantOutput[] {
    if (!this.selectedTenant) {
      return [];
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return [];
    }

    const otherTenantsMap = new Map<string, TenantOutput>();

    for (const role of userRoles) {
      if (!role.tenantId || !role.tenant) {
        continue;
      }

      if (role.tenantId === this.selectedTenant.id) {
        continue;
      }

      if (!otherTenantsMap.has(role.tenantId)) {
        otherTenantsMap.set(role.tenantId, role.tenant);
      }
    }

    return Array.from(otherTenantsMap.values()).sortBy((x) => x.name);
  }

  get isTenantOwner(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const tenantOwner = userRoles
      .filter((x) => x.roleId === RoleId.TENANT_OWNER)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof tenantOwner === 'undefined' ? false : true;
  }

  get isTenantAdmin(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const tenantOwner = userRoles
      .filter((x) => x.roleId === RoleId.TENANT_ADMIN)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof tenantOwner === 'undefined' ? false : true;
  }

  get isAssetArchitect(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const assetArchitect = userRoles
      .filter((x) => x.roleId === RoleId.ASSET_ARCHITECT)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof assetArchitect === 'undefined' ? false : true;
  }

  get isAssetManager(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const assetManager = userRoles
      .filter((x) => x.roleId === RoleId.ASSET_MANAGER)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof assetManager === 'undefined' ? false : true;
  }

  get isAssetManagerOrReader(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const assetManager = userRoles
      .filter((x) => x.roleId === RoleId.ASSET_MANAGER || x.roleId === RoleId.ASSET_READER)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof assetManager === 'undefined' ? false : true;
  }

  get isAssetReader(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const assetManager = userRoles
      .filter((x) => x.roleId === RoleId.ASSET_READER)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof assetManager === 'undefined' ? false : true;
  }

  get isAssetUser(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const userRoles = this._myUser?.userRoles;
    if (
      typeof userRoles === 'undefined' ||
      userRoles == null ||
      !Array.isArray(userRoles) ||
      userRoles.length === 0
    ) {
      return false;
    }

    const assetManager = userRoles
      .filter((x) => x.roleId === RoleId.ASSET_USER)
      .find((x) => x.tenantId === this.selectedTenant?.id);
    return typeof assetManager === 'undefined' ? false : true;
  }

  get myUser(): UserOutput | undefined {
    return this._myUser;
  }

  // #endregion Tenant Related
  constructor() {}

  // #region Private Properties

  private _myUser: UserOutput | undefined;

  // #endregion Private Properties

  // #region Public methods

  updateMyUser(myUser: UserOutput | undefined) {
    this._myUser = myUser;
  }

  // #endregion Public methods
}
