import { UserConstraintOutput } from 'projects/shared/src/lib/graphql/output/userConstraintOutput';
import { PropertyOutput } from 'projects/shared/src/lib/graphql/output/propertyOutput';
import { v4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/desktop/src/environments/environment';
import { firstValueFrom } from 'rxjs';

export class UserConstraintWrapper {
  static propertyValues = new Map<string, string[]>();

  readonly uuid = v4(); // relevant for new (not saved) items
  value: string | undefined;
  isDeleted = false;
  isNew = false;

  get needsSaving(): boolean {
    return this.isNew || this.isDeleted;
  }

  get property() {
    return this.#property;
  }
  set property(value) {
    this.#property = value;
    this.propertyValues = [];
    if (value) {
      this.fetchPropertyValues(value.id, this.tenantId).then((x) => (this.propertyValues = x));
    }
  }

  propertyValues: string[] = [];

  #property: PropertyOutput | undefined;

  constructor(
    private http: HttpClient,
    private tenantId: string,
    public original?: UserConstraintOutput
  ) {
    if (original) {
      this.#property = original.property;
      this.value = original.value;
    } else {
      this.isNew = true;
    }
  }

  delete() {
    if (!this.original) {
      return;
    }

    this.isDeleted = true;
  }

  reset() {
    if (this.isNew) {
      return; // Cannot reset anything
    }

    if (this.isDeleted) {
      this.isDeleted = false;
    }
  }

  async fetchPropertyValues(propertyId: string, tenantId: string): Promise<string[]> {
    const propertyValues = UserConstraintWrapper.propertyValues.get(propertyId);
    if (typeof propertyValues !== 'undefined') {
      return propertyValues;
    }

    // The values are not cached. Fetch them from the backend.
    const url = `${environment.apiBaseUrl}/api/assets/distinctValues/${tenantId}/${propertyId}`;
    const data = await firstValueFrom(this.http.post<(string | null)[]>(url, null));
    const realData = data.filter(x => x != null && x !== '') as string[];
    UserConstraintWrapper.propertyValues.set(propertyId, realData);
    return realData;
  }
}
