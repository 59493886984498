<app-fluid-panel icon="switch_access">
  <div class="flex-row align-items-center gap-normal">
    <mat-checkbox
      appNotReader
      class="mat-body-2"
      color="primary"
      [(ngModel)]="selectionService.assetsView.showPlanBuilder"
    >
      Plan Builder
    </mat-checkbox>
    <mat-checkbox
      class="mat-body-2"
      color="primary"
      [(ngModel)]="selectionService.assetsView.showPlans"
      >Plans</mat-checkbox
    >
    <mat-checkbox
      class="mat-body-2"
      color="primary"
      [(ngModel)]="selectionService.assetsView.showAssets"
      >Assets</mat-checkbox
    >
    <div class="actions-divider"></div>
    <button appNotReader mat-flat-button color="primary" (click)="import()">Import</button>
  </div>
</app-fluid-panel>

<mtx-split style="height: 100%" direction="vertical" [gutterSize]="8">
  <mtx-split-pane
    [order]="1"
    [visible]="selectionService.assetsView.showPlanBuilder"
  >
    <!-- AREA PLAN DESIGNER -->
    <div class="pane-container">
      <app-assets-plan
        [planId]="selectionService.selectedPlan?.id"
      ></app-assets-plan>
    </div>
  </mtx-split-pane>
  <mtx-split-pane [order]="2" [visible]="selectionService.assetsView.showPlans">
    <!-- AREA: PLANS -->
    <div class="pane-container">
      <app-assets-plans></app-assets-plans>
    </div>
  </mtx-split-pane>

  <mtx-split-pane
    [order]="3"
    [visible]="selectionService.assetsView.showAssets"
  >
    <!-- AREA: ASSETS -->
    <div class="pane-container">
      <app-assets-table></app-assets-table>
    </div>
  </mtx-split-pane>
</mtx-split>
