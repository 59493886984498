
export enum ActionTo {
    Location = 1,
    User = 2,
    Mail = 3,
    Other = 4
}

export const actionTos = new Map<number, string>([
    [ActionTo.Location, "location"],
    [ActionTo.User, "user"],
    [ActionTo.Mail, "mail"],
    [ActionTo.Other, "other"],
]);
