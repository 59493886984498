<mat-form-field
  [appearance]="appearance"
  [subscriptSizing]="subscriptSizing"
  class="width-100"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-icon *ngIf="!activity && !hideSearchIcon" matPrefix>search</mat-icon>
  <mat-icon *ngIf="activity" matPrefix class="spinner"> donut_large </mat-icon>
  <input
    #input
    matInput
    [formControl]="searchText"
    [autocomplete]="uuid"
    [matAutocomplete]="auto"
    [readonly]="readonly"
    [required]="required"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

  <button
    matSuffix
    mat-icon-button
    [class.hide]="!location || activity || readonly || disabled"
    (click)="onClickClear()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-autocomplete
    #auto="matAutocomplete"
    panelWidth="auto"
    (optionSelected)="onOptionSelected($event)"
    [displayWith]="displayFn"
  >
    <mat-option
      *ngFor="let filteredLocation of filteredLocations"
      [value]="filteredLocation"
    >
      <span
        [innerHTML]="
          getLocationDisplay(filteredLocation) | highlight : input.value : true
        "
      ></span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
