import { Component, Input } from '@angular/core';
import { UserSelectService } from '../../services/user-select.service';
import { OUser } from '../../common/oUser';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import {
  UserInfoDialogComponent,
  UserInfoDialogData,
} from '../user-info-dialog/user-info-dialog.component';

@Component({
  selector: 'lib-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  @Input()
  get oId(): string | undefined {
    return this.#oId;
  }
  set oId(value: string | undefined) {
    this.#oId = value;
    if (value) {
      this.#loadOUser(value);
    }
  }

  @Input() variant: 'phone' | 'desktop' = 'desktop';
  @Input() inheritAppearance: boolean = false;

  oUser: OUser | undefined;
  activity = true;

  #oId: string | undefined;

  constructor(
    private userSelectService: UserSelectService,
    private clipboard: Clipboard,
    private matDialog: MatDialog
  ) {}

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
  }

  onClickNameVariantPhone(oUser: OUser | undefined) {
    if (!oUser) {
      return;
    }

    const data: UserInfoDialogData = {
      oUser,
    };

    const dialog = this.matDialog.open(UserInfoDialogComponent, {
      data,
      maxWidth: 704,
    });

    dialog.afterClosed().subscribe(() => {
      (document.activeElement as HTMLElement)?.blur();
    });
  }

  async #loadOUser(oid: string) {
    if(oid === 'na') {
      return;
    }
    
    this.activity = true;
    try {
      const oUser = await this.userSelectService.getOUserByOId(oid);
      this.oUser = oUser;
    } catch (error) {
      console.log(error);
    } finally {
      this.activity = false;
    }
  }
}
