<h2 mat-dialog-title>Create New Plan</h2>

<mat-dialog-content>
  <div class="new-plan-dialog-grid">
    <div class="content form-field-density-1">
      <span class="mat-body">
        Please provide a plan name and the intended start and end date. The
        system will check each asset if it can be used or if it's blocked due to
        usage in other plans.
      </span>

      <mat-form-field class="mt-normal" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="name" [autocomplete]="uuid" />
      </mat-form-field>

      <mat-form-field class="mt-normal" subscriptSizing="dynamic">
        <mat-label>Intended Plan Duration</mat-label>
        <mat-date-range-input
          [formGroup]="range"
          [rangePicker]="picker"
          [min]="minStartDate"
        >
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
            (dateChange)="onDateChange($event)"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="End date"
            (dateChange)="onDateChange($event)"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <lib-user-select
        class="mt-normal"
        label="Plan Responsible"
        appearance="fill"
        [(oId)]="responsibleId"
      ></lib-user-select>

      <lib-expander class="assets-expander">
        <lib-expander-header>
          <div class="flex-row align-items-center gap-normal">
            <span> Assets ({{ data.assets.length }}) </span>
            <ng-container *ngIf="conflictingAssets.size > 0">
              <div class="mat-body">
                ok:
                <span
                  ><b>{{ okAssets.length }}</b></span
                >
              </div>
              <div class="mat-body">
                not-ok:
                <span
                  ><b>{{ conflictingAssets.size }}</b></span
                >
              </div>
            </ng-container>
          </div>
        </lib-expander-header>
        <lib-expander-content>
          <div class="flex-column">
            <ul class="mat-body ul-adapt">
              <mtx-popover
                #popover="mtxPopover"
                [yOffset]="8"
                [position]="['after', 'above']"
              >
                <ng-template mtxPopoverContent let-plans="plans">
                  <div class="flex-column font-size-14">
                    <span class="mb-tiny">
                      The asset is included in the following plan(s):
                    </span>
                    <div *ngFor="let plan of plans">
                      - {{ plan.name }} ({{
                        plan.planStart | date : getDatePipeString()
                      }}
                      &minus; {{ plan.planEnd | date : getDatePipeString() }})
                    </div>
                  </div>
                </ng-template>
              </mtx-popover>

              <li
                *ngFor="let x of conflictingAssets | keyvalue"
                style="width: fit-content"
              >
                <div class="flex-row gap-normal">
                  <span>{{ x.key.id }}</span>
                  <app-asset-info
                    [assetId]="x.key.id"
                    [showDefectInfo]="true"
                    [showMissingInfo]="true"
                    popoverPositionStart="after"
                    popoverPositionEnd="center"
                  ></app-asset-info>

                  <span
                    class="details"
                    #popoverTrigger="mtxPopoverTrigger"
                    [mtxPopoverTriggerFor]="popover"
                    [mtxPopoverTriggerData]="{ plans: x.value }"
                  >
                    conflict(s): {{ x.value.length }}
                  </span>
                </div>
              </li>

              <li *ngFor="let x of okAssets" style="width: fit-content">
                <app-asset-info
                  [assetId]="x.id"
                  [showDefectInfo]="true"
                  [showMissingInfo]="true"
                  popoverPositionStart="after"
                  popoverPositionEnd="center"
                ></app-asset-info>
              </li>
            </ul>
          </div>
        </lib-expander-content>
      </lib-expander>

      <span *ngIf="conflictingAssets.size > 0" class="mt-normal mat-body"
        >Some assets are already included in other plans. If you create the
        plan, these assets will <b>NOT</b> be added to the new plan.</span
      >

      <div *ngIf="errorMessage" class="error-message mt-normal">
        {{ errorMessage }}
      </div>
    </div>
    <div class="overlay" *ngIf="loading">
      <lib-spinner></lib-spinner>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button [disabled]="activity" (click)="cancel()">
    Cancel
  </button>
  <button
    (click)="apply()"
    mat-flat-button
    color="primary"
    [disabled]="!canApply || activity"
  >
    <span *ngIf="!activity"> Create </span>
    <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
  </button>
</mat-dialog-actions>
