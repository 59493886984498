import { gql } from "apollo-angular";

export const FULL_FRAGMENT_ASSET = gql`
    fragment FullFragmentAsset on AssetOutput {
        id
        tenantId
        name
        createdAt
        isActive
    }
`;