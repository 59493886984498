<mat-toolbar style="height: 64px; min-height: 64px">
    <button mat-icon-button (click)="back()">
        <mat-icon>navigate_before</mat-icon>
    </button>
    <span class="ml-normal">GAT A-B-C</span>
</mat-toolbar>

<div class="abc-content">
    <div class="menu">
        <markdown src="https://gatassets.blob.core.windows.net/$web/ABC_CONTENTS.md"></markdown>
    </div>

    <div style="overflow-y: auto">
        <markdown src="https://gatassets.blob.core.windows.net/$web/ABC.md"></markdown>
    </div>
</div>