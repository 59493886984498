import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolbarToggleService {
  // #region Public Properties

  selectedValue: string | undefined;
  selectedValueChanged = new EventEmitter<string | undefined>();
  itemClickedEvent = new EventEmitter<string>();

  // #endregion Public Properties


  // #region Private Properties

  private _children = new Map<string, string>();

  // #endregion Private Properties


  // #region Public Methods

  register(uuid: string, value: string) {
    if (this._children.has(uuid)) {
      throw new Error(`The component '${uuid}' with value '${value}' is already registered.`);
    }

    this._children.set(uuid, value);
  }

  unregister(uuid: string): boolean {
    return this._children.delete(uuid);
  }

  clicked(uuid: string, isActive: boolean) {
    if (!isActive) {
      this.selectedValue = undefined;
    } else {
      this.selectedValue = this._children.get(uuid);
    }

    this.selectedValueChanged.emit(this.selectedValue);
    this.itemClickedEvent.emit(uuid);
  }

  unselectAll() {

  }

  // #endregion Public Methods
}
