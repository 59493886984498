import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type ShowQrcodeDialogData = {
  items: string[];
};

@Component({
  selector: 'app-show-qrcode-dialog',
  templateUrl: './show-qrcode-dialog.component.html',
  styleUrls: ['./show-qrcode-dialog.component.scss'],
})
export class ShowQrcodeDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ShowQrcodeDialogData) {}
}
