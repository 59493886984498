<div class="lib-card" [ngClass]="isTransparent ? '' : 'light-bg'">
    <div class="lib-card-header">
        <div><ng-content select="lib-card-title"></ng-content></div>
        <div>
            <ng-content select="lib-card-actions"></ng-content>
        </div>
    </div>
    <div class="lib-card-content">
        <ng-content select="lib-card-content"></ng-content>
    </div>
</div>