<app-gat-card class="rtc">
  <app-gat-card-header headerName="RETURN TO CUSTOMER" [hideHeader]="false">
    <span
      class="material-symbols-outlined prevent-select"
      [class.color-green]="subscriptionService.online"
      [class.color-warn-400]="!subscriptionService.online"
      matTooltipPosition="above"
      matTooltip="Live Sync"
    >
      {{ subscriptionService.online ? "wifi" : "wifi_off" }}
    </span>
  </app-gat-card-header>

  <!-- ACTIONS -->
  <!--  TODO: style this properly-->
  <app-gat-card-actions align="end" style="position: relative">

    <div style="position:absolute; left: 10px">
      <span>Search</span>
      <input type="text"
             class="general-search-input"
             placeholder="Search"
             (keyup.enter)="onGeneralSearchChange($event)"
             [(ngModel)]="generalSearchValue"
      >
    </div>

    <button
      mat-flat-button
      color="accent"
      style="min-width: unset; padding: 0 8px"
      [matMenuTriggerFor]="moreActionsMenu"
      class="ml-normal"
    >
      <div class="flex-row align-items-center">
        <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon>
      </div>
    </button>
    <mat-menu #moreActionsMenu="matMenu">
      <button
        mat-menu-item
        (click)="deleteReturnToCustomer()"
        [disabled]="
          !selectionService.selectedReturnToCustomer ||
          selectionService.selectedReturnToCustomer.completedAt
        "
      >
        <mat-icon fontSet="material-symbols-outlined" class="color-warn">
          delete
        </mat-icon>
        <span class="color-warn">Delete</span>
      </button>
    </mat-menu>

    <button
      color="accent"
      mat-flat-button
      (click)="completeReturnToCustomer()"
      [disabled]="
        !selectionService.selectedReturnToCustomer ||
        selectionService.selectedReturnToCustomer.completedAt
      "
    >
      <mat-icon fontSet="material-symbols-outlined">task_alt</mat-icon>
      <span>Completed</span>
    </button>
  </app-gat-card-actions>

  <!-- CONTENT -->
  <app-gat-card-content>
    <mtx-split style="height: 100%" direction="horizontal" unit="pixel">
      <mtx-split-pane [size]="300">
        <div class="flex-column">
          <ng-container *ngFor="let rtc of rtcs">
            <a
              class="rtc-list-item"
              [routerLink]="rtc.id"
              routerLinkActive="active"
              (click)="navigateTo(rtc.id)"
            >
              <div class="flex-row align-items-center gap-normal">
                <span>{{ rtc.name }}</span>

                <span
                  *ngIf="(rtc.returnToCustomerAssets?.length ?? 0) > 0"
                  class="font-size-12"
                >
                  ({{ rtc.returnToCustomerAssets?.length }})
                </span>

                <span
                  *ngIf="rtc.completedBy"
                  class="material-symbols-outlined color-green"
                >
                  task_alt
                </span>
              </div>
              <span
                *ngIf="rtc.description"
                class="font-size-12 mt-tiny mb-tiny"
                style="opacity: 0.87; overflow-x: hidden"
              >{{ rtc.description }}</span
              >
              <div
                class="flex-row align-items-center gap-normal font-size-12 nowrap"
              >
                <span>{{
                    rtc.createdAt
                      | date : localeService.datetimePipeString(locale)
                  }}</span>
                <lib-user
                  [oId]="rtc.createdBy"
                  variant="phone"
                  [inheritAppearance]="true"
                ></lib-user>
              </div>
              <div
                *ngIf="rtc.completedAt"
                class="flex-row align-items-center gap-normal font-size-12 nowrap"
              >
                <span>{{
                    rtc.completedAt
                      | date : localeService.datetimePipeString(locale)
                  }}</span>
                <lib-user
                  [oId]="rtc.completedBy ?? 'na'"
                  variant="phone"
                  [inheritAppearance]="true"
                ></lib-user>
              </div>
            </a>
          </ng-container>
        </div>
      </mtx-split-pane>
      <mtx-split-pane>
        <div class="rtc-details-area">
          <!-- OVERLAY -->
          <div
            *ngIf="!selectionService.selectedReturnToCustomer"
            class="overlay"
          >
            <span class="font-weight-500">
              Please select a record on the left.
            </span>
          </div>

          <!-- ROUTER OUTLET -->
          <div class="router-outlet">
            <router-outlet></router-outlet>
          </div>
        </div>
      </mtx-split-pane>
    </mtx-split>
  </app-gat-card-content>
</app-gat-card>
