<!-- ############################### -->
<!-- ### POPOVER: Planned Action ### -->
<!-- ############################### -->
<mtx-popover
  #actionPopover="mtxPopover"
  [position]="['below', 'center']"
  [closeOnBackdropClick]="true"
  [hasBackdrop]="true"
>
  <ng-template
    mtxPopoverContent
    let-plannedAction="plannedAction"
    let-lookupId="lookupId"
  >
    <div class="flex-column gap-small font-size-14" style="width: 380px">
      <div class="flex-row align-items-center gap-normal">
        <span class="uppercase font-weight-500 font-size-16">
          {{ plannedAction.name }}
        </span>
        <span class="flex-grow-1"></span>
        <div class="flex-column gap-tiny">
          <span class="font-weight-500">
            {{
              plannedAction.data.planStepAction.date
                | date : localeService.datetimePipeString(locale)
            }}
          </span>
          <span class="opacity-50">
            {{
              plannedAction.gracePeriodDate
                | date : localeService.datetimePipeString(locale)
            }}
          </span>
        </div>
      </div>

      <div class="h-grid-2-auto-auto gap-small">
        <span class="material-symbols-outlined icon"> location_on </span>
        <app-action-details
          fromOrTo="from"
          [action]="plannedAction.data.planStepAction"
        ></app-action-details>
        <span class="material-symbols-outlined icon"> location_searching </span>
        <app-action-details
          fromOrTo="to"
          [action]="plannedAction.data.planStepAction"
        ></app-action-details>
        <ng-container
          *ngIf="plannedAction.data.planStepAction.description as notes"
        >
          <span class="material-symbols-outlined icon"> sticky_note_2 </span>
          <span>{{ notes }}</span>
        </ng-container>
      </div>

      <div class="flex-row align-items-center gap-tiny">
        <button
          appNotReader
          mat-flat-button
          class="width-100a"
          style="background-color: #f0f0f0; font-weight: 400"
          (click)="
            adjustBookings(
              lookupId,
              plannedAction.data.plan,
              plannedAction.data.planStepAction
            )
          "
        >
          <span>Adjust Bookings</span>
        </button>

        <button appNotReader mat-flat-button (click)="editAction(lookupId, plannedAction)">
          <div class="flex-row align-items-center opacity-50">
            <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
          </div>
        </button>
      </div>

      <div class="action-popover-content">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <!-- TAB: GENERAL -->
          <mat-tab label="Overview">
            <div class="mt-small flex-column gap-small font-size-12">
              <span class="font-weight-500 font-size-14">Plan</span>
              <div class="h-grid-2-auto-auto gap-small">
                <span class="material-symbols-outlined icon"> label </span>
                <span>{{ plannedAction.data.plan.name }}</span>
                <span class="material-symbols-outlined icon"> alarm_on </span>
                <span>
                  {{
                    plannedAction.data.plan.planStart
                      | date : localeService.datetimePipeString(locale)
                  }}
                  -
                  {{
                    plannedAction.data.plan.planEnd
                      | date : localeService.datetimePipeString(locale)
                  }}
                </span>
                <ng-container *ngIf="plannedAction.data.plan.responsibleId">
                  <span class="material-symbols-outlined icon">
                    support_agent
                  </span>
                  <lib-user
                    [oId]="plannedAction.data.plan.responsibleId"
                  ></lib-user>
                </ng-container>

                <ng-container *ngIf="plannedAction.data.plan.description">
                  <span class="material-symbols-outlined icon">
                    sticky_note_2
                  </span>
                  <span>{{ plannedAction.data.plan.description }}</span>
                </ng-container>
              </div>

              <span class="font-weight-500 mt-normal font-size-14">
                Plan Step
              </span>
              <div class="h-grid-2-auto-auto gap-small">
                <span class="material-symbols-outlined icon"> label </span>
                <span>{{ plannedAction.data.planStep.name }}</span>
                <ng-container *ngIf="plannedAction.data.planStep.description">
                  <span class="material-symbols-outlined icon">
                    sticky_note_2
                  </span>
                  <span>{{ plannedAction.data.planStep.description }}</span>
                </ng-container>
              </div>

              <span class="font-weight-500 mt-normal font-size-14">Assets</span>
              <div class="h-grid-2-auto-auto gap-small">
                <ng-container
                  *ngFor="
                    let planStepAsset of plannedAction.data.planStepAssets
                  "
                >
                  <span class="material-symbols-outlined icon">
                    {{
                      plannedAction.getBooking(planStepAsset.tenantAssetId)
                        ? "check_box"
                        : "check_box_outline_blank"
                    }}
                  </span>
                  <div class="flex-row align-items-center gap-small">
                    <span>{{ planStepAsset.tenantAssetId }}</span>
                    <span
                      *ngIf="planStepAsset.assetMissing?.missing"
                      class="color-warn-400"
                      >(currently missing)</span
                    >
                  </div>
                </ng-container>
              </div>
              <div style="height: 1px"></div>
            </div>
          </mat-tab>

          <!-- TAB: Bookings -->
          <mat-tab
            [label]="
              'Bookings (' +
              plannedAction.bookings.length +
              '/' +
              plannedAction.data.planStepAssets.length +
              ')'
            "
          >
            <div class="mt-small flex-column gap-normal font-size-12">
              <div
                *ngFor="let planStepAsset of plannedAction.data.planStepAssets"
                class="h-grid-2-auto-auto gap-normal"
              >
                <span
                  class="material-symbols-outlined icon"
                  style="justify-self: flex-start"
                >
                  {{
                    plannedAction.getBooking(planStepAsset.tenantAssetId)
                      ? "check_box"
                      : "check_box_outline_blank"
                  }}
                </span>
                <div class="flex-column gap-tiny">
                  <span class="font-weight-500">{{
                    planStepAsset.tenantAssetId
                  }}</span>
                  <ng-container
                    *ngIf="
                      plannedAction.getBooking(
                        planStepAsset.tenantAssetId
                      ) as booking
                    "
                  >
                    <div class="flex-row align-items-center gap-small">
                      <span>{{
                        booking.timestamp
                          | date : localeService.datetimePipeString(locale)
                      }}</span>
                      <span>/</span>
                      <lib-user [oId]="booking.userOid"></lib-user>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- TAB: Updates -->
          <mat-tab
            *ngIf="plannedAction.subscriptionEvents.length > 0"
            [label]="
              'Updates (' + plannedAction.subscriptionEvents.length + ')'
            "
          >
            <div class="mt-small flex-column gap-normal font-size-12">
              <div
                class="flex-row gap-normal"
                *ngFor="let event of plannedAction.subscriptionEvents"
              >
                <span>
                  {{
                    event.date | date : localeService.datetimePipeString(locale)
                  }}
                </span>
                <div class="flex-column gap-tiny">
                  <div class="flex-row align-items-center gap-small">
                    <span
                      class="material-symbols-outlined opacity-50"
                      style="font-size: 18px"
                    >
                      mail
                    </span>
                    <span>{{ event.type }}</span>
                  </div>

                  <!-- Event type PlannedActionBookingAdded OR PlannedActionBookingRemoved -->
                  <ng-container
                    *ngIf="
                      event.type ===
                        subscriptionEventType.PlannedActionBookingAdded ||
                      event.type ===
                        subscriptionEventType.PlannedActionBookingRemoved
                    "
                  >
                    <div class="flex-row align-items-center gap-small">
                      <span
                        class="material-symbols-outlined opacity-50"
                        style="font-size: 18px"
                      >
                        data_object
                      </span>
                      <span>{{ event.data.tenantAssetId }}</span>
                    </div>

                    <div class="flex-row align-items-center gap-small">
                      <span
                        class="material-symbols-outlined opacity-50"
                        style="font-size: 18px"
                      >
                        person
                      </span>
                      <lib-user [oId]="event.data.bookingUserOid"></lib-user>
                    </div>
                  </ng-container>

                  <!-- Event type AssetMissingSet OR AssetMissingReset -->
                  <ng-container
                    *ngIf="
                      event.type === subscriptionEventType.AssetMissingSet ||
                      event.type === subscriptionEventType.AssetMissingReset
                    "
                  >
                    <div class="flex-row align-items-center gap-small">
                      <span
                        class="material-symbols-outlined opacity-50"
                        style="font-size: 18px"
                      >
                        data_object
                      </span>
                      <span>{{ event.data.assetId }}</span>
                    </div>

                    <div class="flex-row align-items-center gap-small">
                      <span
                        class="material-symbols-outlined opacity-50"
                        style="font-size: 18px"
                      >
                        person
                      </span>
                      <lib-user
                        *ngIf="event.data.userOid"
                        [oId]="event.data.userOid"
                      ></lib-user>
                      <span *ngIf="!event.data.userOid"> set by system </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div style="height: 1px"></div>
      </div>
    </div>
  </ng-template>
</mtx-popover>

<!-- ################################ -->
<!-- ### TEMPLATE: Planned Action ###-->
<!-- ################################ -->
<ng-template
  #plannedActionTemplate
  let-plannedAction="plannedAction"
  let-lookupId="lookupId"
>
  <div class="flex-row align-items-center gap-tiny">
    <span
      *ngIf="plannedAction.hasMissingAssets"
      class="color-warn-400 material-symbols-outlined prevent-select"
      style="font-size: 18px"
      matTooltip="Some assets are missing"
      matTooltipPosition="above"
    >
      warning
    </span>

    <div
      class="flex-grow-1 planned-action prevent-select"
      [class.green]="
        plannedAction.bookings.length ===
        plannedAction.data.planStepAssets.length
      "
      [class.red]="
        isPlannedActionConsideredRed.get(plannedAction.data.planStepAction.id)
      "
      #popoverTrigger="mtxPopoverTrigger"
      [mtxPopoverTriggerFor]="actionPopover"
      mtxPopoverTriggerOn="hover"
      [mtxPopoverTriggerData]="{ plannedAction: plannedAction, lookupId }"
      [matBadge]="plannedAction.subscriptionEvents.length"
      matBadgeColor="warn"
      [matBadgeHidden]="plannedAction.subscriptionEvents.length === 0"
    >
      <span class="font-size-12">
        {{
          plannedAction.data.planStepAction.date
            | date : localeService.datetimePipeString(locale)
            | slice : 11 : 16
        }}
      </span>

      <div class="flex-grow-1 flex-row justify-content-center">
        <span class="font-weight-500">
          {{ plannedAction.bookings.length }} /
          {{ plannedAction.data.planStepAssets.length }}
        </span>
      </div>
      <span class="uppercase font-weight-500">{{ plannedAction.name }}</span>
    </div>
  </div>
</ng-template>

<!-- ############## -->
<!-- ### HEADER ### -->
<!-- ############## -->
<div class="header">
  <button mat-stroked-button (click)="today()">Today</button>

  <button mat-icon-button (click)="minusOneWeek()">
    <mat-icon fontSet="material-symbols-outlined">navigate_before</mat-icon>
  </button>

  <button mat-icon-button (click)="plusOneWeek()">
    <mat-icon fontSet="material-symbols-outlined">navigate_next</mat-icon>
  </button>

  <div>CW{{ weekNumber }}, {{ weekYear }}</div>

  <div class="form-field-density-2 ml-normal">
    <mat-form-field
      subscriptSizing="dynamic"
      appearance="outline"
      style="min-width: 280px"
    >
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="Start date"
          [(ngModel)]="cwStartDate"
          readonly
        />
        <input
          matEndDate
          placeholder="End date"
          [(ngModel)]="cwEndDate"
          readonly
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker
        #picker
        (closed)="onDatePickerClosed($event)"
      ></mat-date-range-picker>
    </mat-form-field>
  </div>

  <div class="flex-grow-1"></div>

  <span
    *ngIf="subscriptionService.online"
    class="material-symbols-outlined color-green prevent-select"
    matTooltipPosition="above"
    matTooltip="Live events can be received"
  >
    wifi
  </span>
  <span
    *ngIf="!subscriptionService.online"
    class="material-symbols-outlined color-warn-400 prevent-select"
    matTooltipPosition="above"
    matTooltip="Disconnected. Live events can NOT be received."
  >
    wifi_off
  </span>

  <ng-container *ngIf="subscriptionEvents.length > 0">
    <div class="flex-column font-size-12">
      <span class="align-self-end">
        Updates:
        <span class="font-weight-500 green">
          {{ subscriptionEvents.length }}
        </span>
      </span>
      <span>
        Last Activity:
        <span class="font-weight-500 green">
          {{ subscriptionEvents[0].date | date : "HH:mm" }}
        </span>
      </span>
    </div>
    <button mat-stroked-button (click)="clearSubscriptionsEvents()">
      Clear
    </button>
  </ng-container>
</div>

<!-- ###################### -->
<!-- ### CONTENT (Grid) ### -->
<!-- ###################### -->
<div class="content">
  <div class="cw-grid">
    <!-- HEADER -->
    <div class="header-cell white">
      <!-- <input type="text" class="filter-input" placeholder="Filter plans" /> -->
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday1Short }}</span>

      <div class="day" [class.today]="isToday(1)">
        <span>
          {{ dayOfMonth(1) }}
        </span>
      </div>
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday2Short }}</span>
      <div class="day" [class.today]="isToday(2)">
        <span>{{ dayOfMonth(2) }}</span>
      </div>
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday3Short }}</span>
      <div class="day" [class.today]="isToday(3)">
        <span>{{ dayOfMonth(3) }}</span>
      </div>
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday4Short }}</span>
      <div class="day" [class.today]="isToday(4)">
        <span>{{ dayOfMonth(4) }}</span>
      </div>
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday5Short }}</span>
      <div class="day" [class.today]="isToday(5)">
        <span>{{ dayOfMonth(5) }}</span>
      </div>
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday6Short }}</span>
      <div class="day" [class.today]="isToday(6)">
        <span>{{ dayOfMonth(6) }}</span>
      </div>
    </div>
    <div class="header-cell filled">
      <span class="mat-body">{{ localeService.weekday7Short }}</span>
      <div class="day" [class.today]="isToday(7)">
        <span>{{ dayOfMonth(7) }}</span>
      </div>
    </div>

    <!-- ROWS -->
    <ng-container *ngFor="let plan of plans">
      <!-- <div class="row"></div>  -->
      <div class="cell mat-body">
        <div class="flex-row align-items-center gap-small">
          <span>{{ plan.name }}</span>
          <div class="flex-grow-1"></div>
          <span
            class="material-symbols-outlined"
            [class.hide]="!planActivity.get(plan.id)"
            style="font-size: 14px"
          >
            cloud_download
          </span>
        </div>
      </div>

      <!-- DAY-1 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_1'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_1'
            }"
          ></ng-container>
        </div>
      </div>

      <!-- DAY-2 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_2'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_2'
            }"
          ></ng-container>
        </div>
      </div>

      <!-- DAY-3 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_3'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_3'
            }"
          ></ng-container>
        </div>
      </div>

      <!-- DAY-4 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_4'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_4'
            }"
          ></ng-container>
        </div>
      </div>

      <!-- DAY-5 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_5'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_5'
            }"
          ></ng-container>
        </div>
      </div>

      <!-- DAY-6 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_6'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_6'
            }"
          ></ng-container>
        </div>
      </div>

      <!-- DAY-7 -->
      <div class="cell filled">
        <div
          *ngFor="
            let plannedAction of plannedActions.get(
              plan.id + '_' + weekYear + '_' + weekNumber + '_7'
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="plannedActionTemplate"
            [ngTemplateOutletContext]="{
              plannedAction: plannedAction,
              lookupId: plan.id + '_' + weekYear + '_' + weekNumber + '_7'
            }"
          ></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
