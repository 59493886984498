import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ToolbarToggleService } from '../../services/toolbar-toggle.service';
import { Subscription } from 'rxjs'


@Component({
  selector: 'app-toolbar-toggle',
  templateUrl: './toolbar-toggle.component.html',
  styleUrls: ['./toolbar-toggle.component.scss'],
  providers: [ToolbarToggleService]
})
export class ToolbarToggleComponent implements OnInit, OnDestroy {
  // #region Public Properties

  selectedValue: string | undefined;

  // #endregion Public Properties


  // #region Private Properties

  private _serviceSubscription: Subscription | undefined;

  // #endregion Private Properties


  // #region Init

  constructor(private _toolbarToggleService: ToolbarToggleService) { }

  ngOnInit(): void {
    this._serviceSubscription = this._toolbarToggleService.selectedValueChanged.subscribe(
      selectedValue => {
        this.selectedValue = selectedValue;
      }
    )
  }

  ngOnDestroy(): void {
    this._serviceSubscription?.unsubscribe();
  }

  // #endregion Init


  // #region Public Methods

  unselectAll() {
    this._toolbarToggleService.clicked('na', false);
  }

  // #endregion Public Methods
}
