<h2 mat-dialog-title>{{ isCreate ? "Create" : "Edit" }} Action</h2>

<mat-dialog-content>
  <div class="flex-column form-field-density-1 gap-normal">
    <!-- ########### -->
    <!-- BEFORE AREA -->
    <!-- ########### -->
    <div class="before-area mb-normal" *ngIf="data.beforeAction">
      <div class="align-self-center flex-row gap-normal">
        <span class="type">
          {{ actionTypes.get(data.beforeAction.actionTypeId) }}
        </span>
        <span>
          {{
            data.beforeAction.date
              | date : localeService.datetimePipeString(locale)
          }}
        </span>
      </div>

      <div class="h-grid-1-auto-1 align-items-center">
        <app-action-details
          fromOrTo="from"
          [action]="data.beforeAction"
        ></app-action-details>
        <mat-icon class="font-weight-600">arrow_right_alt</mat-icon>
        <app-action-details
          fromOrTo="to"
          [action]="data.beforeAction"
        ></app-action-details>
      </div>
    </div>

    <!-- ######################### -->
    <!-- ### NEW / UPDATE AREA ### -->
    <!-- ######################### -->

    <!-- ROW 1-->
    <div class="h-grid-1-2">
      <mat-form-field subscriptSizing="dynamic" required>
        <mat-label>Action</mat-label>
        <mat-select
          [(ngModel)]="selectedActionTypeOutput"
          required
          (ngModelChange)="onActionTypeChange()"
        >
          <mat-select-trigger>
            <span class="uppercase">{{ selectedActionTypeOutput?.name }}</span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let actionTypeOutput of assortmentService.actionTypes"
            [value]="actionTypeOutput"
          >
            <span class="uppercase">{{ actionTypeOutput.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="flex-row gap-small">
        <mat-form-field subscriptSizing="dynamic" class="flex-grow-1">
          <mat-label>Date + Time</mat-label>
          <mtx-datetimepicker
            #datetimePicker
            type="datetime"
            mode="portrait"
            [multiYearSelector]="false"
            startView="month"
            [twelvehour]="false"
            [timeInterval]="1"
            [touchUi]="false"
            [timeInput]="true"
          ></mtx-datetimepicker>
          <input
            [mtxDatetimepicker]="datetimePicker"
            [formControl]="datetime"
            matInput
            [min]="minDate"
            [max]="maxDate"
            required
            (dateChange)="evaluateCanApply()"
          />
          <mtx-datetimepicker-toggle
            [for]="datetimePicker"
            matSuffix
          ></mtx-datetimepicker-toggle>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" style="width: 128px">
          <mat-label>Grace Period [h]</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [autocomplete]="uuid3"
            required
            [(ngModel)]="missingGracePeriod"
            (ngModelChange)="evaluateCanApply()"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- ROW 1.1 (ONLY FOR HANDOVER) -->
    <lib-location-select
      *ngIf="selectedActionTypeOutput?.id === 2"
      #handoverLocationSelect
      label="Handover Location"
      [required]="false"
      [(locationId)]="transitLocationId"
      (locationIdChange)="evaluateCanApply()"
    ></lib-location-select>

    <!-- ROW 2 -->
    <div class="h-grid-1-2">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>From</mat-label>
        <mat-select
          [(ngModel)]="selectedFromType"
          required
          (ngModelChange)="evaluateCanApply()"
        >
          <mat-option
            *ngFor="let from of fromTypes | keyvalue"
            [value]="from.key"
          >
            {{ from.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="(selectedFromType ?? 0) > 2">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label class="capitalize">
            {{ fromTypes.get(selectedFromType ?? 0) }} Details
          </mat-label>
          <input
            matInput
            [(ngModel)]="fromDetails"
            (ngModelChange)="evaluateCanApply()"
            [autocomplete]="uuid1"
            required
            [disabled]="!selectedFromType"
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="selectedFromType === 1">
        <lib-location-select
          #fromLocationSelect
          label="Location"
          [required]="true"
          [(locationId)]="fromDetails"
          (locationIdChange)="evaluateCanApply()"
        >
        </lib-location-select>
      </ng-container>

      <ng-container *ngIf="selectedFromType === 2">
        <lib-user-select
          #fromUserSelect
          appearance="fill"
          label="User"
          [required]="true"
          [(oId)]="fromDetails"
          (oIdChange)="evaluateCanApply()"
          [tenantUsersOnly]="true"
        ></lib-user-select>
      </ng-container>
    </div>

    <!-- ROW 3 -->
    <div class="h-grid-1-2">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>To</mat-label>
        <mat-select
          [(ngModel)]="selectedToType"
          required
          (ngModelChange)="evaluateCanApply()"
        >
          <mat-option *ngFor="let to of toTypes | keyvalue" [value]="to.key">
            {{ to.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="(selectedToType ?? 0) > 2">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label class="capitalize">
            {{ toTypes.get(selectedToType ?? 0) }} Details
          </mat-label>
          <input
            matInput
            [(ngModel)]="toDetails"
            (ngModelChange)="evaluateCanApply()"
            [autocomplete]="uuid2"
            required
            [disabled]="!selectedToType"
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="selectedToType === 1">
        <lib-location-select
          #toLocationSelect
          label="Location"
          [required]="true"
          [(locationId)]="toDetails"
          (locationIdChange)="evaluateCanApply()"
        >
        </lib-location-select>
      </ng-container>

      <ng-container *ngIf="selectedToType === 2">
        <lib-user-select
          #toUserSelect
          appearance="fill"
          label="User"
          [required]="true"
          [(oId)]="toDetails"
          (oIdChange)="evaluateCanApply()"
          [tenantUsersOnly]="true"
        ></lib-user-select>
      </ng-container>
    </div>

    <!-- NOTES -->
    <mat-form-field class="mt-tiny">
      <mat-label>Notes</mat-label>
      <textarea
        matInput
        [(ngModel)]="description"
        (blur)="evaluateCanApply()"
      ></textarea>
    </mat-form-field>

    <!-- AFTER AREA -->
    <div class="after-area" *ngIf="data.afterAction">
      <div class="align-self-center flex-row gap-normal">
        <span class="type">{{
          actionTypes.get(data.afterAction.actionTypeId)
        }}</span>
        <span>{{
          data.afterAction.date
            | date : localeService.datetimePipeString(locale)
        }}</span>
      </div>

      <div class="h-grid-1-auto-1 align-items-center">
        <app-action-details
          fromOrTo="from"
          [action]="data.afterAction"
        ></app-action-details>
        <mat-icon class="font-weight-600">arrow_right_alt</mat-icon>
        <app-action-details
          fromOrTo="to"
          [action]="data.afterAction"
        ></app-action-details>
      </div>
    </div>
  </div>

  <div *ngIf="errorMessage" class="error-message mt-normal">
    {{ errorMessage }}
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()" [disabled]="activity">
    Cancel
  </button>
  <button
    (click)="apply()"
    mat-flat-button
    color="primary"
    [disabled]="!canApply || activity"
  >
    <div class="flex-row">
      <mat-icon
        *ngIf="activity"
        fontSet="material-symbols-outlined"
        class="spinner"
      >
        donut_large
      </mat-icon>
      <span *ngIf="!activity"> {{ isCreate ? "Create" : "Apply" }} </span>
    </div>
  </button>
</mat-dialog-actions>
