import { Component } from '@angular/core';

@Component({
  selector: 'app-gat-card-content',
  templateUrl: './gat-card-content.component.html',
  styleUrls: ['./gat-card-content.component.scss']
})
export class GatCardContentComponent {

}
