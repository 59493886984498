<h2 mat-dialog-title>
  Start <span class="color-primary-400">Return to Customer</span> with
  {{ data.assetIds.length }}
  {{ data.assetIds.length === 1 ? "asset" : "assets" }}
</h2>

<mat-dialog-content>
  <div class="flex-column form-field-density-1">
    <div style="height: 4px" class="mb-tiny">
      <mat-progress-bar
        *ngIf="activity"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>

    <mat-form-field
      appearance="fill"
      subscriptSizing="dynamic"
      class="mb-normal"
    >
      <mat-label>Name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="name"
        [autocomplete]="uuid1"
        required
      />
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic" class="mb-normal">
        <mat-label>Description</mat-label>
        <textarea
          type="text"
          matInput
          [autocomplete]="uuid2"
          [(ngModel)]="description"
          rows="2"
          [cdkTextareaAutosize]="true"
        ></textarea>
      </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button [disabled]="activity" (click)="close()">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="activity || !name"
      (click)="apply()"
    >
      Start
    </button>
  </mat-dialog-actions>
