<div class="design-left">
  <div style="padding: 24px 24px 24px 24px">
    <mat-expansion-panel hideToggle="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="action-title">
          <span>Options</span>
          <button mat-button color="primary" (click)="newOption($event)">
            <span>new</span>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex-column">
        <lib-spinner
          *ngIf="loading.isJobXOngoing[2]; else optionsHaveLoaded"
        ></lib-spinner>
        <ng-template #optionsHaveLoaded>
          <span *ngIf="options.length === 0" class="mat-body"
            >No options defined yet.</span
          >
          <mat-nav-list *ngIf="options.length > 0">
            <a
              *ngFor="let option of options"
              mat-list-item
              routerLink="option/{{ option.id }}"
              routerLinkActive="active-select-bg"
            >
              <span>{{ option.name }}</span>
            </a>
          </mat-nav-list>
        </ng-template>
      </div>
    </mat-expansion-panel>

    <div class="mb-normal"></div>

    <mat-expansion-panel [expanded]="false" hideToggle="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Locations & Global Properties </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list class="mat-nav-list-adjustment">
        <a
          mat-list-item
          routerLink="locations"
          routerLinkActive="active-select-bg"
        >
          <span>Locations</span>
        </a>
        <a
          mat-list-item
          routerLink="global-system"
          routerLinkActive="active-select-bg"
        >
          <span>Global System Properties</span>
        </a>
        <a
          mat-list-item
          routerLink="global-custom"
          routerLinkActive="active-select-bg"
        >
          <span>Global Custom Properties</span>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>

    <div class="mb-normal"></div>

    <!-- <mat-accordion [multi]="true"> -->
    <mat-expansion-panel [expanded]="false" hideToggle="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="action-title">
          <span>Assets Types ({{ assets.length }})</span>
          <button mat-button color="primary" (click)="newAsset($event)">
            <span>new</span>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-column">
        <lib-spinner *ngIf="loading.isJobXOngoing[1]"></lib-spinner>
        <mat-nav-list *ngIf="!loading.isJobXOngoing[1]">
          <a
            *ngFor="let asset of assets"
            mat-list-item
            routerLink="asset/{{ asset.id }}"
            routerLinkActive="active-select-bg"
          >
            <span [ngClass]="!asset.isActive ? 'inactive' : ''">{{
              asset.name
            }}</span>
          </a>
        </mat-nav-list>
      </div>
    </mat-expansion-panel>

    <!-- </mat-accordion> -->
  </div>
</div>

<div class="design-right">
  <mat-spinner *ngIf="loading.isOngoing"></mat-spinner>
  <ng-container *ngIf="!loading.isOngoing">
    <router-outlet></router-outlet>
  </ng-container>
</div>
