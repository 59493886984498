<h2 mat-dialog-title>{{ data.title }}</h2>

<ng-container>
  <mat-dialog-content>
    <div class="flex-column">
      <div *ngIf="data.text" [innerHTML]="data.text"></div>

      <div *ngIf="errorMessage" class="error-message mt-normal">
        {{ errorMessage }}
      </div>
    </div>
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    color="warn"
    (click)="onNotOk()"
    [disabled]="activity"
  >
    NO
  </button>
  <button (click)="onOk()" mat-flat-button color="warn" [disabled]="activity">
    <div class="flex-row align-items-center">
      <span *ngIf="!activity"> YES </span>
      <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
    </div>
  </button>
</mat-dialog-actions>
