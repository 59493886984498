import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_ASSET_MISSING = gql`
  fragment FullFragmentAssetMissing on AssetMissingOutput {
    id
    name
    missing
    missingComment
  }
`;
