import { NgModule } from '@angular/core';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MtxPopoverModule } from '@ng-matero/extensions/popover';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CardComponent } from './components/card/card.component';
import { CardTitleComponent } from './components/card/card-title/card-title.component';
import { CardContentComponent } from './components/card/card-content/card-content.component';
import { CardActionsComponent } from './components/card/card-actions/card-actions.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ExpanderComponent } from './components/expander/expander.component';
import { ExpanderHeaderComponent } from './components/expander/expander-header/expander-header.component';
import { ExpanderContentComponent } from './components/expander/expander-content/expander-content.component';
import { SelectButtonComponent } from './components/select-button/select-button.component';
import { SelectButtonItemsComponent } from './components/select-button/select-button-items/select-button-items.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { UserComponent } from './components/user/user.component';
import { LocationSelectComponent } from './components/location-select/location-select.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { LocationComponent } from './components/location/location.component';
import { UserInfoDialogComponent } from './components/user-info-dialog/user-info-dialog.component';

@NgModule({
  declarations: [
    CardActionsComponent,
    CardContentComponent,
    CardComponent,
    CardTitleComponent,
    SpinnerComponent,
    ConfirmDialogComponent,
    ExpanderComponent,
    ExpanderHeaderComponent,
    ExpanderContentComponent,
    SelectButtonComponent,
    SelectButtonItemsComponent,
    UserSelectComponent,
    UserComponent,
    LocationSelectComponent,
    HighlightPipe,
    LocationComponent,
    UserInfoDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    MtxPopoverModule,

    MatAutocompleteModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  exports: [
    CardActionsComponent,
    CardContentComponent,
    CardComponent,
    CardTitleComponent,
    ConfirmDialogComponent,
    ExpanderComponent,
    ExpanderContentComponent,
    ExpanderHeaderComponent,
    SpinnerComponent,
    SelectButtonComponent,
    SelectButtonItemsComponent,
    UserSelectComponent,
    UserComponent,
    LocationSelectComponent,
    HighlightPipe,
    LocationComponent
  ],
})
export class SharedModule {}
