import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MsalGuard } from '@azure/msal-angular';
import { TokenComponent } from './components/token/token.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { DesignComponent } from './components/design/design.component';
import { AssetComponent } from './components/design/asset/asset.component';
import { GlobalSystemComponent } from './components/design/global-system/global-system.component';
import { GlobalCustomComponent } from './components/design/global-custom/global-custom.component';
import { OptionComponent } from './components/design/option/option.component';
import { AssetsComponent } from './components/assets/assets.component';
import { SecurityComponent } from './components/security/security.component';
import { LocationsComponent } from './components/design/locations/locations.component';
import { AbcComponent } from './components/abc/abc.component';
import { AssetsAndPlansComponent } from './components/assets/assets-and-plans/assets-and-plans.component';
import { ReturnToCustomerComponent } from './components/assets/return-to-customer/return-to-customer.component';
import { ReturnToCustomerDetailsComponent } from './components/assets/return-to-customer/return-to-customer-details/return-to-customer-details.component';
import {RestoreComponent} from "./components/assets/restore/restore.component";

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'tenant',
        component: TenantComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'design',
        component: DesignComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: 'locations',
            component: LocationsComponent,
          },
          {
            path: 'global-system',
            component: GlobalSystemComponent,
          },
          {
            path: 'global-custom',
            component: GlobalCustomComponent,
          },
          {
            path: 'asset/:id',
            component: AssetComponent,
          },
          {
            path: 'option/:id',
            component: OptionComponent,
          },
        ],
      },
      {
        path: 'security',
        component: SecurityComponent,
      },
      {
        path: 'assets',
        component: AssetsComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'assets-and-plans',
          },
          {
            path: 'assets-and-plans',
            component: AssetsAndPlansComponent,
          },
          {
            path: 'restore',
            component: RestoreComponent,
          },
          {
            path: 'return-to-customer',
            component: ReturnToCustomerComponent,
            children: [
              {
                path: ':id',
                component: ReturnToCustomerDetailsComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'privacy-statement',
    component: PrivacyStatementComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'token',
    component: TokenComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'abc',
    component: AbcComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
