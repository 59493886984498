import { Component, ViewChild, OnInit, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit, AfterViewInit {
  // #region In/out

  @Input() accepts: string[] | undefined;

  @Input() file: File | undefined;
  @Output() fileChange = new EventEmitter<File | undefined>();

  // #endregion In/out


  // #region Public Properties

  @ViewChild('fileDrop') fileDrop: ElementRef<HTMLFormElement> | undefined;

  get acceptString(): string {
    if (typeof this.accepts === 'undefined') {
      return '';
    }

    let acceptStrings: string[] = [];

    this.accepts.forEach(x => {
      if (x[0] === '.') {
        acceptStrings.push(x);
      } else {
        acceptStrings.push(`.${x}`);
      }
    });

    return acceptStrings.join(',');
  }
  // #endregion Public Properties


  // #region Private Properties

  // https://diviengine.com/how-to-fix-sorry-this-file-type-is-not-permitted-for-security-reasons/
  private _type2Extension = new Map<string, string>([
    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xlsx'],
    ['application/pdf', '.pdf']
  ])

  // #endregion Private Properties


  // #region Init

  ngOnInit(): void {
    if (typeof this.accepts === 'undefined') {
      return; // nothing to do here
    }

    const cleanedAccepts = this.accepts.map(x => {
      return x.trim()[0] === '.'
        ? x.trim().toLowerCase()
        : '.' + x.trim().toLowerCase();
    });

    for (let x of cleanedAccepts) {
      if (!Array.from(this._type2Extension.values()).includes(x)) {
        throw new Error(`Component(app-file-drop): The accepts attribute contains a value not configured in the component: ${x}. Please add it to the component.`);
      }
    }

  }

  ngAfterViewInit(): void {
    if (typeof this.fileDrop === 'undefined') {
      return;
    }

    if (!this._isAdvancedUpload()) {
      return;
    }

    const form = this.fileDrop.nativeElement;

    this.fileDrop.nativeElement.classList.add('has-advanced-upload');

    const prevent = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    }

    form.addEventListener('drag', prevent);
    form.addEventListener('dragstart', prevent);
    form.addEventListener('dragend', prevent);
    form.addEventListener('dragover', prevent);
    form.addEventListener('dragenter', prevent);
    form.addEventListener('dragleave', prevent);
    form.addEventListener('drop', prevent);

    const addClass = (event: DragEvent) => {
      if (typeof event.dataTransfer?.items === 'undefined') {
        return;
      }

      const items = Array.from(event.dataTransfer.items);
      if (items.length !== 1) {
        form.classList.add('is-dragover-error')
        return;
      }

      const itemType = items[0].type;
      if (typeof this.accepts === 'undefined' || this.accepts.length === 0) {
        form.classList.add('is-dragover');
      } else {
        // We have a list of accepted extensions
        // Check if the current type is "among them".

        const extension = this._type2Extension.get(itemType);
        const cleanedAccepts = this.accepts.map(x => {
          return x.trim()[0] === '.'
            ? x.trim().toLowerCase()
            : '.' + x.trim().toLowerCase()
        });
        if (!extension || !cleanedAccepts.includes(extension)) {
          form.classList.add('is-dragover-error')
        } else {
          form.classList.add('is-dragover');
        }
      }
    }
    form.addEventListener('dragover', addClass);
    form.addEventListener('dragenter', addClass);

    const removeClass = () => {
      form.classList.remove('is-dragover');
      form.classList.remove('is-dragover-error');
    }
    form.addEventListener('dragleave', removeClass);
    form.addEventListener('dragend', removeClass);
    form.addEventListener('drop', removeClass);

    form.addEventListener('drop', (event: DragEvent) => {
      const files = event.dataTransfer?.files as ArrayLike<File>
      this._handleFiles(files);
    });
  }

  // #endregion Init


  // #region Public Methods

  onFileSelected(event: Event) {
    const files = (event.target as HTMLInputElement)?.files as ArrayLike<File>;
    this._handleFiles(files);
  }

  // #endregion Public Methods


  // #region Private Methods

  private _isAdvancedUpload() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  };

  private _handleFiles(files: ArrayLike<File>) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (typeof this.accepts === 'undefined') {
      // All file types are allowed.
      this.fileChange.emit(file);
      return;
    }

    const fileNameParts = file.name.split('.');
    const fileExtension = '.' + fileNameParts[fileNameParts.length - 1];

    const cleanedAccepts = this.accepts.map(x => {
      return x.trim()[0] === '.'
        ? x.trim().toLowerCase()
        : '.' + x.trim().toLowerCase()
    })
    if (cleanedAccepts.includes(fileExtension.toLowerCase())) {
      this.fileChange.emit(file);
    }
  }

  // #endregion Private Methods
}
