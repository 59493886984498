<mat-form-field subscriptSizing="dynamic">
  <mat-label>Level 1</mat-label>
  <input
    matInput
    [(ngModel)]="level1"
    [readonly]="(locationNode?.level ?? 0) > 1"
    [disabled]="!level1"
    (keyup.enter)="onKeyupEnter(1)"
    [autocomplete]="level1uuid"
  />
</mat-form-field>

<mat-form-field subscriptSizing="dynamic">
  <mat-label>Level 2</mat-label>
  <input
    matInput
    [(ngModel)]="level2"
    [readonly]="(locationNode?.level ?? 0) > 2"
    [disabled]="!level2"
    (keyup.enter)="onKeyupEnter(2)"
    [autocomplete]="level2uuid"
  />
</mat-form-field>

<mat-form-field subscriptSizing="dynamic">
  <mat-label>Level 3</mat-label>
  <input
    matInput
    [(ngModel)]="level3"
    [readonly]="(locationNode?.level ?? 0) > 3"
    [disabled]="!level3"
    (keyup.enter)="onKeyupEnter(3)"
    [autocomplete]="level3uuid"
  />
</mat-form-field>

<mat-form-field subscriptSizing="dynamic">
  <mat-label>Level 4</mat-label>
  <input
    matInput
    [(ngModel)]="level4"
    [readonly]="(locationNode?.level ?? 0) > 4"
    [disabled]="!level4"
    (keyup.enter)="onKeyupEnter(4)"
    [autocomplete]="level4uuid"
  />
</mat-form-field>

<mat-form-field subscriptSizing="dynamic">
  <mat-label>Level 5</mat-label>
  <input
    matInput
    [(ngModel)]="level5"
    [readonly]="(locationNode?.level ?? 0) > 5"
    [disabled]="!level5"
    (keyup.enter)="onKeyupEnter(5)"
    [autocomplete]="level5uuid"
  />
</mat-form-field>
