import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_TENANT_ACTION = gql`
  fragment FullFragmentTenantAction on TenantActionOutput {
    id
    actionTypeId
    assetId
    userOid
    planId
    planStepActionId
    timestamp
    fromLocationId
    fromUserOid
    fromMail
    fromOther
    toLocationId
    toUserOid
    toMail
    toOther
    description
    transitLocationId
  }
`;
