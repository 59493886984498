import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  UserRolesByUserQueryArgs,
  UserRolesByUserQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/userRole';
import { Role } from 'projects/shared/src/lib/graphql/enums/role';
import { FULL_FRAGMENT_USER_ROLE } from 'projects/shared/src/lib/graphql/fragments/fullFragmentUserRole';
import { RoleId } from 'projects/shared/src/lib/graphql/output/roleOutput';
import { firstValueFrom } from 'rxjs';
import { DesktopToastService } from '../../services/desktop-toast.service';
import {
  UserConstraintsByUserQueryArgs,
  UserConstraintsByUserQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/userConstraint';
import { FULL_FRAGMENT_USER_CONSTRAINT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentUserConstraint';
import { UserConstraintOutput } from 'projects/shared/src/lib/graphql/output/userConstraintOutput';
import { UserConstraintWrapper } from './userConstraintWrapper';
import { FULL_FRAGMENT_PROPERTY } from 'projects/shared/src/lib/graphql/fragments/fullFragmentProperty';
import { SelectionService } from '../../services/selection.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserConfigurationFactory {
  constructor(
    public apollo: Apollo,
    public toastService: DesktopToastService,
    public selectionService: SelectionService,
    public http: HttpClient
  ) {}

  create(oId: string): UserConfiguration {
    return new UserConfiguration(this, oId);
  }
}

export class UserConfiguration {
  readonly oId;
  loadingRoles = false;
  loadingConstraints = false;

  isTenantOwner = false;
  isTenantAdmin = false;
  isAssetArchitect = false;
  isAssetManager = false;
  isAssetUser = false;
  isAssetReader = false;

  constraints: UserConstraintWrapper[] = [];

  get isChanged(): boolean {
    if (
      this.isTenantOwner !== this.#isTenantOwner_ORIGINAL ||
      this.isTenantAdmin !== this.#isTenantAdmin_ORIGINAL ||
      this.isAssetArchitect !== this.#isAssetArchitect_ORIGINAL ||
      this.isAssetManager !== this.#isAssetManager_ORIGINAL ||
      this.isAssetUser !== this.#isAssetUser_ORIGINAL ||
      this.isAssetReader !== this.#isAssetReader_ORIGINAL
    ) {
      return true;
    }

    if (this.constraints.some((x) => x.isNew && x.property && x.value)) {
      return true;
    }

    if (this.constraints.some((x) => x.isDeleted)) {
      return true;
    }

    return false;
  }

  get deleteRoleIds(): number[] {
    const deleteRoleIds: number[] = [];

    if (!this.isChanged) {
      return deleteRoleIds;
    }

    if (this.#isTenantOwner_ORIGINAL && !this.isTenantOwner) {
      deleteRoleIds.push(RoleId.TENANT_OWNER);
    }

    if (this.#isTenantAdmin_ORIGINAL && !this.isTenantAdmin) {
      deleteRoleIds.push(RoleId.TENANT_ADMIN);
    }

    if (this.#isAssetArchitect_ORIGINAL && !this.isAssetArchitect) {
      deleteRoleIds.push(RoleId.ASSET_ARCHITECT);
    }

    if (this.#isAssetManager_ORIGINAL && !this.isAssetManager) {
      deleteRoleIds.push(RoleId.ASSET_MANAGER);
    }

    if(this.#isAssetReader_ORIGINAL && !this.isAssetReader) {
      deleteRoleIds.push(RoleId.ASSET_READER);
    }

    if (this.#isAssetUser_ORIGINAL && !this.isAssetUser) {
      deleteRoleIds.push(RoleId.ASSET_USER);
    }

    return deleteRoleIds;
  }

  get createRoleIds(): number[] {
    const createRoleIds: number[] = [];

    if (!this.isChanged) {
      return createRoleIds;
    }

    if (!this.#isTenantOwner_ORIGINAL && this.isTenantOwner) {
      createRoleIds.push(RoleId.TENANT_OWNER);
    }

    if (!this.#isTenantAdmin_ORIGINAL && this.isTenantAdmin) {
      createRoleIds.push(RoleId.TENANT_ADMIN);
    }

    if (!this.#isAssetArchitect_ORIGINAL && this.isAssetArchitect) {
      createRoleIds.push(RoleId.ASSET_ARCHITECT);
    }

    if (!this.#isAssetManager_ORIGINAL && this.isAssetManager) {
      createRoleIds.push(RoleId.ASSET_MANAGER);
    }

    if (!this.#isAssetUser_ORIGINAL && this.isAssetUser) {
      createRoleIds.push(RoleId.ASSET_USER);
    }

    if (!this.#isAssetReader_ORIGINAL && this.isAssetReader) {
      createRoleIds.push(RoleId.ASSET_READER);
    }

    return createRoleIds;
  }

  #isTenantOwner_ORIGINAL = false;
  #isTenantAdmin_ORIGINAL = false;
  #isAssetArchitect_ORIGINAL = false;
  #isAssetManager_ORIGINAL = false;
  #isAssetUser_ORIGINAL = false;
  #isAssetReader_ORIGINAL = false;

  constructor(private factory: UserConfigurationFactory, oId: string) {
    this.oId = oId;
    this.#loadRoles();
    this.#loadConstraints();
  }

  reset() {
    this.isTenantOwner = this.#isTenantOwner_ORIGINAL;
    this.isTenantAdmin = this.#isTenantAdmin_ORIGINAL;
    this.isAssetArchitect = this.#isAssetArchitect_ORIGINAL;
    this.isAssetManager = this.#isAssetManager_ORIGINAL;
    this.isAssetUser = this.#isAssetUser_ORIGINAL;
    this.isAssetReader = this.#isAssetReader_ORIGINAL;

    this.constraints.forEach((x) => x.reset());

    // Now, we have to remove the "new" entries in the list.
    this.constraints = this.constraints.filter((x) => !x.isNew);
  }

  async #loadRoles() {
    this.loadingRoles = true;
    try {
      const variables: UserRolesByUserQueryArgs = {
        userOid: this.oId,
      };

      const result = await firstValueFrom(
        this.factory.apollo.query<UserRolesByUserQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_USER_ROLE}
            query UserRolesByUser($userOid: String!) {
              userRolesByUser(userOid: $userOid) {
                ...FullFragmentUserRole
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      const userRoleIds = result.data.userRolesByUser.map((x) => x.roleId);
      this.#isTenantOwner_ORIGINAL = userRoleIds.includes(RoleId.TENANT_OWNER);
      this.#isTenantAdmin_ORIGINAL = userRoleIds.includes(RoleId.TENANT_ADMIN);
      this.#isAssetArchitect_ORIGINAL = userRoleIds.includes(RoleId.ASSET_ARCHITECT);
      this.#isAssetManager_ORIGINAL = userRoleIds.includes(RoleId.ASSET_MANAGER);
      this.#isAssetUser_ORIGINAL = userRoleIds.includes(RoleId.ASSET_USER);
      this.#isAssetReader_ORIGINAL = userRoleIds.includes(RoleId.ASSET_READER);
      this.reset();
    } catch (error) {
      this.factory.toastService.error('Could not load user roles.', 'ERROR');
    } finally {
      this.loadingRoles = false;
    }
  }

  async #loadConstraints() {
    this.loadingConstraints = true;
    try {
      const variables: UserConstraintsByUserQueryArgs = {
        userOid: this.oId,
      };

      const result = await firstValueFrom(
        this.factory.apollo.query<UserConstraintsByUserQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_USER_CONSTRAINT}
            ${FULL_FRAGMENT_PROPERTY}
            query UserConstraintsByUser($userOid: String!) {
              userConstraintsByUser(userOid: $userOid) {
                ...FullFragmentUserConstraint
                property {
                  ...FullFragmentProperty
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      for (const userConstraint of result.data.userConstraintsByUser.sortBy(
        (x) => x.createdAt,
        'asc'
      )) {
        this.constraints.push(
          new UserConstraintWrapper(
            this.factory.http,
            this.factory.selectionService.selectedTenant?.id ?? 'na',
            userConstraint
          )
        );
      }
    } catch (error) {
      this.factory.toastService.error('Could not load user asset constraints.', 'ERROR');
    } finally {
      this.loadingConstraints = false;
    }
  }
}
