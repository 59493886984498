import { gql } from 'apollo-angular';
import { FULL_FRAGMENT_TENANT_DEFECT } from '../fragments/fullFragmentTenantDefect';

export const FULL_FRAGMENT_ASSET_DEFECT_SUB = gql`
  ${FULL_FRAGMENT_TENANT_DEFECT}
  fragment FullFragmentAssetDefectSub on AssetDefectSubNotification {
    filterSessionId
    data {
      action
      assetDefect {
        ...FullFragmentTenantDefect
      }
    }
  }
`;
