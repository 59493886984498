import { Component } from '@angular/core';
import { SelectionService } from '../../../services/selection.service';
import { MatDialog } from '@angular/material/dialog';
import { ImportAssetsDialogComponent } from '../../../component-dialogs/import-assets-dialog/import-assets-dialog.component';

@Component({
  selector: 'app-assets-and-plans',
  templateUrl: './assets-and-plans.component.html',
  styleUrls: ['./assets-and-plans.component.scss']
})
export class AssetsAndPlansComponent {
  constructor(public selectionService: SelectionService, private matDialog: MatDialog) {}

  import() {
    this.matDialog.open(ImportAssetsDialogComponent, {
      autoFocus: false,
      maxWidth: '600px',
    });
  }
}
