<div class="mt-large">
  <div class="mat-h1">Hi {{ niceifyName(account?.name) }}!</div>
</div>

<div *ngIf="!selectionService.hasAnyUserRoles; else userWithAccessElement">
  You don't have access. Please contact someone :-)
</div>

<ng-template #userWithAccessElement>
  <div class="mb-large current-tenant-grid">
    <div class="flex-column justify-content-center align-items-center">
      <span class="mb-tiny">You are logged into the <b>tenant</b>:</span>
      <span class="tenant">{{ selectionService.selectedTenant?.name }}</span>
    </div>
    <div class="flex-column justify-content-center">
      <div class="flex-column gap-tiny">
        <div *ngIf="selectionService.isTenantOwner" class="mat-body role">
          Tenant Owner
        </div>
        <div *ngIf="selectionService.isTenantAdmin" class="mat-body role">
          Tenant Admin
        </div>
        <div *ngIf="selectionService.isAssetArchitect" class="mat-body role">
          Asset Architect
        </div>
        <div *ngIf="selectionService.isAssetManager" class="mat-body role">
          Asset Manager
        </div>
        <div *ngIf="selectionService.isAssetReader" class="mat-body role">
          Asset Reader
        </div>
        <div *ngIf="selectionService.isAssetUser" class="mat-body role">
          Asset User
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="selectionService.hasMultiTenantAccess">
    <div class="mt-huge mat-body">You have access to these other tenants:</div>
    <div *ngFor="let tenant of selectionService.otherAvailableTenants">
      <button
        mat-stroked-button
        color="primary"
        class="switch-tenant-button"
        (click)="onClickTenant(tenant)"
      >
        {{ tenant.name }}
      </button>
    </div>
  </ng-container>
</ng-template>
