import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_TENANT_DEFECT = gql`
  fragment FullFragmentTenantDefect on TenantDefectOutput {
    id
    timestamp
    assetId
    defectTypeId
    defectStateId
    userOid
    comment
  }
`;
