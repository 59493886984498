import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ExtUserRole } from '../../../components/security/security.component';

@Component({
  selector: 'app-user-name-renderer',
  templateUrl: './user-role-renderer.component.html',
  styleUrls: ['./user-role-renderer.component.scss'],
})
export class UserRoleRendererComponent implements ICellRendererAngularComp {
  extUserRole: ExtUserRole | undefined;

  get text(): string | undefined {
    if (!this.extUserRole?.oUserWrapper.oUser) {
      return undefined;
    }

    return (this.extUserRole.oUserWrapper.oUser as any)[this._propertyName];
  }

  private _propertyName = 'id';

  agInit(params: ICellRendererParams<any, any, any>): void {
    if (typeof (params as any).propertyName !== 'undefined') {
      this._propertyName = (params as any).propertyName;
    }

    if (typeof params.data === 'undefined') {
      return;
    }

    this._evaluateData(params);
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    if (typeof params.data === 'undefined') {
      return true;
    }

    this._evaluateData(params);
    return true;
  }

  private _evaluateData(params: ICellRendererParams | undefined) {
    if (!params) {
      return;
    }

    this.extUserRole = params.data as ExtUserRole;
  }
}
