import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private _http: HttpClient) {}

  async fetchAsset(tenantId: string | undefined, tenantAssetId: string): Promise<any> {
    if (!tenantId) {
      return undefined;
    }

    const url = environment.apiBaseUrl + '/api/assets/byId/' + tenantId;
    const body = {
      assetIds: [tenantAssetId],
    };

    const assets = await firstValueFrom(this._http.post<any[]>(url, body));
    return assets[0];
  }

  async fetchAssets(tenantId: string | undefined, tenantAssetIds: string[]): Promise<any[] | undefined> {
    if (!tenantId) {
      return undefined;
    }

    const url = environment.apiBaseUrl + '/api/assets/byId/' + tenantId;
    const body = {
      assetIds: tenantAssetIds,
    };

    const assets = await firstValueFrom(this._http.post<any[]>(url, body));
    return assets;
  }
}
