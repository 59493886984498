import { UserRoleOutput } from './userRoleOutput';

/**
 * RoleId mapping from the database
 * id	name
 * 1	Tenant Owner
 * 2	Tenant Admin
 * 3	Asset Architect
 * 4	Asset Manager
 * 5  Asset Reader
 * 6	Asset User
 * 7	SYS Admin
 */
export const RoleId = {
  TENANT_OWNER: 1,
  TENANT_ADMIN: 2,
  ASSET_ARCHITECT: 3,
  ASSET_MANAGER: 4,
  ASSET_READER: 5,
  ASSET_USER: 6,
  SYS_ADMIN: 7,
  USER: 8,
};

export type RoleOutput = {
  id: number;
  name?: string;
  createdAt?: Date;

  // Relations
  userRoles?: UserRoleOutput[] | null;
};
