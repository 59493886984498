import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss']
})
export class TextButtonComponent {
  @Input() color = '';

  get isPrimary() {
    return this.color.toLowerCase() === "primary";
  }

  get isAccent() {
    return this.color.toLowerCase() === "accent";
  }
}
