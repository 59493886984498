import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  CreatePlanAssetsMutationArgs,
  CreatePlanAssetsMutationRoot,
  PlansQueryArgs,
  PlansQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import {
  PlanAssetsQueryArgs,
  PlanAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/planAsset';
import { FULL_FRAGMENT_PLAN_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanAsset';
import { PlanAssetOutput } from 'projects/shared/src/lib/graphql/output/planAssetOutput';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom } from 'rxjs';
import {
  LocalEventData_PlanAssetsAdded,
  LocalEventService,
  LocalEventType,
} from '../../services/local-event.service';

export type AddAssetsToPlanDialogData = {
  plan: PlanOutput;
  planAssets: PlanAssetOutput[];
  assets: any[];
  uniqueIdPropName: string;
};

const PlanAssetsQuery = gql`
  ${FULL_FRAGMENT_PLAN_ASSET}
  query PlanAssets($planId: String!) {
    planAssets(planId: $planId) {
      ...FullFragmentPlanAsset
    }
  }
`;

@Component({
  selector: 'app-add-assets-to-plan-dialog',
  templateUrl: './add-assets-to-plan-dialog.component.html',
  styleUrls: ['./add-assets-to-plan-dialog.component.scss'],
})
export class AddAssetsToPlanDialogComponent implements OnInit {
  loading = false;
  errorMessage: string | undefined;
  activity = false;
  get canApply(): boolean {
    return this.okAssets.length > 0 && !this.activity;
  }

  alreadyIncludedAssets: any[] = [];
  conflictingAssets = new Map<any, PlanOutput[]>();
  okAssets: any[] = [];

  planHasAlreadyEnded = false;
  plans: PlanOutput[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddAssetsToPlanDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private _dialogRef: MatDialogRef<AddAssetsToPlanDialogComponent>,
    private _apollo: Apollo,
    public localeService: LocaleService,
    private _localEventService: LocalEventService
  ) {
    console.log(data);
  }

  ngOnInit(): void {
    this._loadData();
  }

  getTenantAssetUniqueId(tenantAsset: any) {
    return tenantAsset[this.data.uniqueIdPropName];
  }

  async apply() {
    if (!this.canApply) {
      return;
    }

    try {
      this.activity = true;
      this.errorMessage = undefined;
      const variables: CreatePlanAssetsMutationArgs = {
        planId: this.data.plan.id,
        assetIds: this.okAssets.map((x) => x.id),
      };

      const result = await firstValueFrom(
        this._apollo.mutate<CreatePlanAssetsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_PLAN_ASSET}
            mutation CreatePlanAssets($planId: String!, $assetIds: [String!]!) {
              createPlanAssets(planId: $planId, assetIds: $assetIds) {
                ...FullFragmentPlanAsset
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
          update: (cache, { data }) => {
            const updateVariables: PlanAssetsQueryArgs = {
              planId: this.data.plan.id,
            };

            const cachedPlanAssets = cache.readQuery<PlanAssetsQueryRoot>({
              query: PlanAssetsQuery,
              variables: updateVariables,
            })?.planAssets;

            if (typeof cachedPlanAssets !== 'undefined') {
              cache.writeQuery<PlanAssetsQueryRoot>({
                query: PlanAssetsQuery,
                variables: updateVariables,
                data: {
                  planAssets: [...cachedPlanAssets, ...(data?.createPlanAssets ?? [])],
                },
              });
            }

            const eventData: LocalEventData_PlanAssetsAdded = {
              planId: variables.planId,
              planAssets: data?.createPlanAssets ?? [],
            };
            this._localEventService.emitNewEvent(LocalEventType.PlanAssetsAdded, eventData);
          },
        })
      );
      this._dialogRef.close(true);
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
    } finally {
      this.activity = false;
    }
  }

  private async _loadData() {
    try {
      this.loading = true;

      // There are 3 situations:
      // Situation 1: The plan has not YET started
      // Situation 2: The plan is currently ongoing
      // Situation 3: The plan has already ended

      const now = new Date();

      if (now > new Date(this.data.plan.planEnd)) {
        // Situation 3
        this.planHasAlreadyEnded = true;
      }

      const variables: PlansQueryArgs = {
        pastIncluded: true,
        pastUntil: new Date(this.data.plan.planStart),
        currentIncluded: true,
        futureIncluded: true,
        futureUntil: new Date(this.data.plan.planEnd),
      };

      const result = await firstValueFrom(
        this._apollo.query<PlansQueryRoot>({
          query: gql`
            query Plans(
              $pastIncluded: Boolean!
              $currentIncluded: Boolean!
              $futureIncluded: Boolean!
            ) {
              plans(
                pastIncluded: $pastIncluded
                currentIncluded: $currentIncluded
                futureIncluded: $futureIncluded
              ) {
                id
                name
                planStart
                planEnd
                planAssets {
                  id
                  tenantAssetId
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.plans = result.data.plans;
      this._evaluateConflicts();
    } catch (error) {
      const message = new CatchError(error).message;
      this.errorMessage = message;
    } finally {
      this.loading = false;
    }
  }

  private _evaluateConflicts() {
    const sortedAssets = this.data.assets.sort((a, b) => {
      return (a[this.data.uniqueIdPropName] as string).localeCompare(b[this.data.uniqueIdPropName]);
    });

    for (let asset of sortedAssets) {
      // Step 0: Check if the asset is already included in the plan.
      if (this.data.planAssets.map((x) => x.tenantAssetId).includes(asset.id)) {
        this.alreadyIncludedAssets.push(asset);
        continue;
      }

      // Step 1: Find the RELEVANT plans to check FOR THIS ASSET.
      const plansWithThisAsset = this.plans
        .filter((x) => x.id !== this.data.plan.id)
        .filter((x) => x.planAssets?.map((y) => y.tenantAssetId).includes(asset.id));
      if (plansWithThisAsset.length === 0) {
        // No relevant plans found. This asset is "ok" to be added.
        this.okAssets.push(asset);
        continue;
      }

      // Step 2: Check if the found plans with that asset "collide" with the plan.
      const conflictingPlans: PlanOutput[] = [];
      for (let plan of plansWithThisAsset) {
        const planEnd = new Date(plan.planEnd);
        const planStart = new Date(plan.planStart);
        if (
          planEnd > new Date(this.data.plan.planStart) &&
          planEnd < new Date(this.data.plan.planEnd)
        ) {
          conflictingPlans.push(plan);
          continue;
        }

        if (
          planEnd > new Date(this.data.plan.planEnd) &&
          planStart <= new Date(this.data.plan.planEnd)
        ) {
          conflictingPlans.push(plan);
        }
      }

      if (conflictingPlans.length === 0) {
        this.okAssets.push(asset);
      } else {
        this.conflictingAssets.set(asset, conflictingPlans);
      }
    }
  }
}
