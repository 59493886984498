import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { Loading } from 'projects/shared/src/lib/classes/loading';
import { LocationsQueryRoot } from 'projects/shared/src/lib/graphql/crud/location';
import { FULL_FRAGMENT_LOCATION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentLocation';
import { LocationOutput } from 'projects/shared/src/lib/graphql/output/locationOutput';
import { Subscription } from 'rxjs';

export interface LocationNode {
  name: string;
  level: number;
  locations: LocationOutput[];
  children?: LocationNode[];
}

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit, OnDestroy {
  loading = new Loading(1);
  locations: LocationOutput[] = [];
  treeDataSource = new MatTreeNestedDataSource<LocationNode>();
  treeControl = new NestedTreeControl<LocationNode>((node) => node.children);
  showEditLocation = false;
  editLocationNode: LocationNode | undefined;

  private _locationsQuery: QueryRef<LocationsQueryRoot> | undefined;
  private _locationsSubscription: Subscription | undefined;

  constructor(private _apollo: Apollo, private _router: Router) {}

  ngOnInit(): void {
    this._loadData(1);
  }

  ngOnDestroy(): void {
    this._locationsSubscription?.unsubscribe();
  }

  hasChild = (_: number, node: LocationNode) => !!node.children && node.children.length > 0;

  onClickLeaf(node: LocationNode) {
    this.editLocationNode = node;
    this.showEditLocation = true;
    //this._router.navigateByUrl('edit');
  }

  private _loadData(jobNumber: number) {
    this.loading.indicateJobStart(jobNumber);

    this._locationsQuery = this._apollo.watchQuery<LocationsQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_LOCATION}
        query Locations {
          locations {
            ...FullFragmentLocation
          }
        }
      `,
      fetchPolicy: 'network-only',
    });

    this._locationsSubscription = this._locationsQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        if (!loading) {
          this.locations = data.locations;
          this._buildTree();
          this.loading.indicateJobEnd(jobNumber);
        }
      },
      error: (error) => {
        // TODO
        this.loading.indicateJobEnd(jobNumber);
      },
    });
  }

  private _buildTree() {
    const rootNodes: LocationNode[] = [];

    // Handle level 1.
    const level1s = this._getDistinctLevelNames(1);
    for (const level1 of level1s) {
      const level1Locations = this.locations.filter((x) => x.level1 === level1);
      const level1SelectableLocation = this._selectableLocation(1, level1);
      if (level1SelectableLocation) {
        rootNodes.push({
          name: level1,
          level: 1,
          locations: [level1SelectableLocation],
          children: [],
        });
      }
      const level2s = this._getDistinctLevelNames(2, level1);
      if (level2s.length === 0) {
        continue;
      }

      const level1Node: LocationNode = {
        name: level1,
        level: 1,
        locations: level1Locations.filter(
          (x) =>
            typeof level1SelectableLocation === 'undefined' || x.id !== level1SelectableLocation.id
        ),
        children: [],
      };
      rootNodes.push(level1Node);

      // Handle level 2.
      for (const level2 of level2s) {
        const level2Locations = this.locations.filter(
          (x) => x.level1 === level1 && x.level2 === level2
        );
        const level2SelectableLocation = this._selectableLocation(2, level1, level2);
        if (level2SelectableLocation) {
          level1Node.children?.push({
            name: level2,
            level: 2,
            locations: [level2SelectableLocation],
            children: [],
          });
        }
        const level3s = this._getDistinctLevelNames(3, level1, level2);
        if (level3s.length === 0) {
          continue;
        }

        const level2Node: LocationNode = {
          name: level2,
          level: 2,
          locations: level2Locations.filter(
            (x) =>
              typeof level2SelectableLocation === 'undefined' ||
              x.id !== level2SelectableLocation.id
          ),
          children: [],
        };
        level1Node.children?.push(level2Node);

        // Handle level 3.
        for (const level3 of level3s) {
          const level3Locations = this.locations.filter(
            (x) => x.level1 === level1 && x.level2 === level2 && x.level3 === level3
          );
          const level3SelectableLocation = this._selectableLocation(3, level1, level2, level3);
          if (level3SelectableLocation) {
            level2Node.children?.push({
              name: level3,
              level: 3,
              locations: [level3SelectableLocation],
              children: [],
            });
          }
          const level4s = this._getDistinctLevelNames(4, level1, level2, level3);
          if (level4s.length === 0) {
            continue;
          }

          const level3Node: LocationNode = {
            name: level3,
            level: 3,
            locations: level3Locations.filter(
              (x) =>
                typeof level3SelectableLocation === 'undefined' ||
                x.id !== level3SelectableLocation.id
            ),
            children: [],
          };
          level2Node.children?.push(level3Node);

          // Handle level 4.
          for (const level4 of level4s) {
            const level4Locations = this.locations.filter(
              (x) =>
                x.level1 === level1 &&
                x.level2 === level2 &&
                x.level3 === level3 &&
                x.level4 === level4
            );
            const level4SelectableLocation = this._selectableLocation(
              4,
              level1,
              level2,
              level3,
              level4
            );
            if (level4SelectableLocation) {
              level3Node.children?.push({
                name: level4,
                level: 4,
                locations: [level4SelectableLocation],
                children: [],
              });
            }
            const level5s = this._getDistinctLevelNames(5, level1, level2, level3, level4);
            if (level5s.length === 0) {
              continue;
            }

            const level4Node: LocationNode = {
              name: level4,
              level: 4,
              locations: level4Locations.filter(
                (x) =>
                  typeof level4SelectableLocation === 'undefined' ||
                  x.id !== level4SelectableLocation.id
              ),
              children: [],
            };
            level3Node.children?.push(level4Node);

            // Handle level 5.
            for (const level5 of level5s) {
              const level5Locations = this.locations.filter(
                (x) =>
                  x.level1 === level1 &&
                  x.level2 === level2 &&
                  x.level3 === level3 &&
                  x.level4 === level4 &&
                  x.level5 === level5
              );
              const level5Node: LocationNode = {
                name: level5,
                level: 5,
                locations: level5Locations,
                children: [],
              };
              level4Node.children?.push(level5Node);
            }
          }
        }
      }
    }

    this.treeDataSource.data = rootNodes;
  }

  private _getDistinctLevelNames(
    level: 1 | 2 | 3 | 4 | 5,
    level1?: string,
    level2?: string,
    level3?: string,
    level4?: string
  ): string[] {
    if (level === 1) {
      return Array.from(new Set(this.locations.map((x) => x.level1))).sortBy((x) => x);
    }

    if (level === 2 && level1) {
      const matchingLocations = this.locations.filter(
        (x) => x.level1.toLowerCase() === level1.toLowerCase()
      );

      const level2s = matchingLocations.filter((x) => x.level2).map((y) => y.level2) as string[];

      // Return distinct values.
      return Array.from(new Set(level2s)).sortBy((x) => x);
    }

    if (level === 3 && level1 && level2) {
      const matchingLocations = this.locations.filter(
        (x) =>
          x.level1.toLowerCase() === level1.toLowerCase() &&
          x.level2 &&
          x.level2.toLowerCase() === level2.toLowerCase()
      );
      const level3s = matchingLocations.filter((x) => x.level3).map((y) => y.level3) as string[];

      return Array.from(new Set(level3s)).sortBy((x) => x);
    }

    if (level === 4 && level1 && level2 && level3) {
      const matchingLocations = this.locations.filter(
        (x) =>
          x.level1.toLowerCase() === level1.toLowerCase() &&
          x.level2 &&
          x.level2.toLowerCase() === level2.toLowerCase() &&
          x.level3 &&
          x.level3.toLowerCase() === level3.toLowerCase()
      );
      const level4s = matchingLocations.filter((x) => x.level4).map((y) => y.level4) as string[];

      return Array.from(new Set(level4s)).sortBy((x) => x);
    }

    if (level === 5 && level1 && level2 && level3 && level4) {
      const matchingLocations = this.locations.filter(
        (x) =>
          x.level1.toLowerCase() === level1.toLowerCase() &&
          x.level2 &&
          x.level2.toLowerCase() === level2.toLowerCase() &&
          x.level3 &&
          x.level3.toLowerCase() === level3.toLowerCase() &&
          x.level4 &&
          x.level4.toLowerCase() === level4.toLowerCase()
      );
      const level5s = matchingLocations.filter((x) => x.level5).map((y) => y.level5) as string[];

      return Array.from(new Set(level5s)).sortBy((x) => x);
    }

    return [];
  }

  private _selectableLocation(
    level: 1 | 2 | 3 | 4 | 5,
    level1?: string,
    level2?: string,
    level3?: string,
    level4?: string
  ): LocationOutput | undefined {
    if (level === 1 && level1) {
      return this.locations.find((x) => x.level1 === level1 && !x.level2);
    }

    if (level === 2 && level1 && level2) {
      return this.locations.find((x) => x.level1 === level1 && x.level2 === level2 && !x.level3);
    }

    if (level === 3 && level1 && level2 && level3) {
      return this.locations.find(
        (x) => x.level1 === level1 && x.level2 === level2 && x.level3 === level3 && !x.level4
      );
    }
    if (level === 4 && level1 && level2 && level3 && level4) {
      return this.locations.find(
        (x) =>
          x.level1 === level1 &&
          x.level2 === level2 &&
          x.level3 === level3 &&
          x.level4 === level4 &&
          !x.level5
      );
    }

    return undefined;
  }
}
