import {Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GridReadyEvent, ICellRendererParams, SelectionChangedEvent} from "ag-grid-community";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {Apollo, gql} from "apollo-angular";
import {
  InactiveAssetOutput,
  InactiveAssetsQueryRoot
} from "../../../../../../shared/src/lib/graphql/crud/asset";
import {
  ButtonRendererComponent
} from "../../../component-helpers/ag/button-renderer/button-renderer.component";
import {UserSelectService} from "../../../../../../shared/src/lib/services/user-select.service";
import {MatDialog} from "@angular/material/dialog";
import {RestoreDialogComponent} from "./restore-dialog/restore-dialog.component";
import {DesktopToastService} from "../../../services/desktop-toast.service";

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent {
  public colDefs = [
    {headerName: 'ID', field: 'id', sortable: true, filter: true, cellStyle: {textAlign: 'left'}},
    // {
    //   headerName: 'Tenant ID',
    //   field: 'tenantId',
    //   sortable: true,
    //   filter: true,
    //   cellStyle: {textAlign: 'left'}
    // },
    // {
    //   headerName: 'Tenant Asset ID',
    //   field: 'tenantAssetId',
    //   sortable: true,
    //   filter: true,
    //   cellStyle: {textAlign: 'left'}
    // },
    {
      headerName: 'Comment',
      field: 'comment',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: 'left'}
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: 'left'}
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: 'left'}
    },
    // {
    //   headerName: 'Type Name',
    //   field: '__typename',
    //   sortable: true,
    //   filter: true,
    //   cellStyle: {textAlign: 'left'}
    // },
    {
      headerName: 'Restore',
      field: 'restore',
      suppressAutoSize: false,
      suppressMovable: true,
      sortable: false,
      filter: false,
      cellRenderer: ButtonRendererComponent,
      cellRendererParams: {
        onClick: this.onRestoreButtonClick.bind(this),
        label: 'Restore'
      },
      cellStyle: {textAlign: 'center'},
      center: true
    }
  ];
  public defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true
  };
  public rowData: any[] = [];

  private gridApi: any;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private userSelectService: UserSelectService,
    private matDialog: MatDialog,
    private _toastService: DesktopToastService,
  ) {

  }

  async fetchInactiveAssets() {
    const data = await firstValueFrom(
      this.apollo.query<InactiveAssetsQueryRoot>({
        query: gql`
            query InactiveAssets {
              inactiveAssets {
                id
                tenantId
                tenantAssetId
                comment
                createdAt
                createdBy
              }
            }
          `,
        fetchPolicy: 'network-only',
      })
    );
    return data.data.inactiveAssets.map(async (asset: InactiveAssetOutput) => {
      return {
        ...asset,
        createdBy: (await this.userSelectService.getOUserByOId(asset.createdBy))?.displayName || asset.createdBy
      }
    });
  }


  async onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit();
    const data = await this.fetchInactiveAssets();
    Promise.all(data).then((data) => {
      this.rowData = data
      this.gridApi.updateGridOptions({rowData: this.rowData})
    });

  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selectedRows = event.api.getSelectedRows();
    console.log('Selected rows:', selectedRows);
  }

  onRestoreButtonClick(params: ICellRendererParams) {
    console.log('Restore button clicked',);
    //@ts-ignore
    let id = params.rowData.id;
    this.matDialog.open(RestoreDialogComponent, {
      data: {

        id: id
      },
      maxWidth: 400,
    }).afterClosed().subscribe(async (resp: {restoreAsset: boolean}) => {
      if (resp.restoreAsset) {
        let res = await lastValueFrom(this.apollo.mutate({
          mutation: gql`
            mutation RestoreInactiveAsset($id: String!) {
              restoreInactiveAsset(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: id
          }
        }));
        if (res.data) {
          this.rowData = this.rowData.filter((asset) => asset.id !== id);
          this.gridApi.updateGridOptions({rowData: this.rowData})
          this._toastService.info('Asset restored successfully')
        }
      }
    })
  }

}
