import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_USER_ROLE = gql`
  fragment FullFragmentUserRole on UserRoleOutput {
    id
    roleId
    tenantId
    userOid
    createdAt
  }
`;
