import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
