import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Loading } from 'projects/shared/src/lib/classes/loading';
import { OptionQueryArgs, OptionQueryRoot, OptionsQueryRoot, UpdateOptionMutationArgs, UpdateOptionMutationRoot } from 'projects/shared/src/lib/graphql/crud/option';
import { FULL_FRAGMENT_OPTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOption';
import { FULL_FRAGMENT_OPTION_ITEM } from 'projects/shared/src/lib/graphql/fragments/fullFragmentOptionItem';
import { OptionOutput } from 'projects/shared/src/lib/graphql/output/optionOutput';
import { Subscription, firstValueFrom } from 'rxjs';
import { NewOptionItemsDialogComponent, NewOptionItemsDialogData } from '../../../component-dialogs/new-option-items-dialog/new-option-items-dialog.component';
import { OptionItemOutput } from 'projects/shared/src/lib/graphql/output/optionItemOutput';
import { SelectionService } from '../../../services/selection.service';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { ConfirmDialogData } from 'projects/shared/src/public-api';
import { ConfirmDialogComponent } from 'projects/shared/src/lib/components/confirm-dialog/confirm-dialog.component';
import { DeleteOptionItemsMutationArgs, DeleteOptionsItemsMutationRoot } from 'projects/shared/src/lib/graphql/crud/optionItem';
import { RenameOptionItemDialogComponent, RenameOptionItemDialogData } from '../../../component-dialogs/rename-option-item-dialog/rename-option-item-dialog.component';
import { DesktopToastService } from '../../../services/desktop-toast.service';


@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit, OnDestroy {
  // #region Public Properties

  loading = new Loading(1);
  option: OptionOutput | undefined;
  optionNames: string[] = [];
  filteredOptionNames: string[] = [];
  activity = false;

  showDeleteOptionItemsButton = false;
  @ViewChild('optionItemsSelectionList') optionItemsSelectionList: MatSelectionList | undefined;

  // #endregion Public Properties


  // #region Private Properties

  private _optionQuery: QueryRef<OptionQueryRoot> | undefined;
  private _optionSubscription: Subscription | undefined;
  private _activatedRouteSubscription: Subscription | undefined;

  // #endregion Private Properties


  // #region Init

  constructor(
    private _apollo: Apollo
    , private _activatedRoute: ActivatedRoute
    , private _toast: DesktopToastService
    , private _matDialog: MatDialog
    , private _selectionService: SelectionService
  ) { }

  ngOnInit(): void {
    this._activatedRouteSubscription = this._activatedRoute.paramMap.subscribe(params => {
      // Set the optionNames array. Read data from cache.
      this._setOptionNames();

      // (Re)set showDeleteOptionItemsButton
      this.optionItemsSelectionList?.deselectAll();
      this.showDeleteOptionItemsButton = false;

      const id = params.get('id');
      if (!id) {
        return;
      }

      this.loading.indicateJobStart(1);

      // If we already have loaded data only perform a REFETCH
      if (this.option) {
        const variables: OptionQueryArgs = {
          id
        };

        this._optionQuery?.refetch(variables)
      } else {
        this._loadData(id, 1);
      }
    })
  }

  ngOnDestroy(): void {
    this._optionSubscription?.unsubscribe();
    this._activatedRouteSubscription?.unsubscribe();
  }

  // #endregion Init


  // #region Public Methods

  onClickDeleteItems() {
    const noOfSelections = this.optionItemsSelectionList?.selectedOptions.selected.length ?? 0;
    if (noOfSelections === 0) {
      return;
    }

    const varString = noOfSelections === 1
      ? 'item'
      : `${noOfSelections} items`;


    const data: ConfirmDialogData = {
      title: 'Are you sure?',
      text: `Do you really want to delete the selected ${varString}?`,
      onConfirm: async () => {
        await this._deleteOptionItems();
      }
    }

    this._matDialog.open(ConfirmDialogComponent, {
      data
    });
  }

  onClickOptionItem(event: MouseEvent, optionItem: OptionItemOutput) {
    event.stopPropagation();

    const data: RenameOptionItemDialogData = {
      optionItem,
      optionItemNames: this.option?.optionItems?.map(x => x.name) ?? []
    };

    this._matDialog.open(RenameOptionItemDialogComponent, {
      data
    });
  }

  onChangeOptionName(name: string) {
    this.filteredOptionNames = this._filterOptionNames(name);
    console.log(this.filteredOptionNames);
  }

  onBlurOptionName(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._tryUpdateOptionName(inputElement.value, inputElement);
  }

  onKeyupEnterOptionName(event: any) {
    const inputElement = event.target as HTMLInputElement;
    this._tryUpdateOptionName(inputElement.value, inputElement);
  }

  addOptionItems() {
    if (!this.option) {
      return;
    }

    const data: NewOptionItemsDialogData = {
      option: this.option
    };

    this._matDialog.open(NewOptionItemsDialogComponent, {
      maxWidth: '600px',
      width: '600px',
      data,
      autoFocus: false
    });
  }

  onClickSelectAllOptionItems() {
    if (typeof this.optionItemsSelectionList === 'undefined') {
      return;
    }

    if (this.optionItemsSelectionList.selectedOptions.selected.length === this.option?.optionItems?.length) {
      this.optionItemsSelectionList.deselectAll();
    } else {
      this.optionItemsSelectionList.selectAll();
    }

    this.showDeleteOptionItemsButton = this.optionItemsSelectionList.selectedOptions.selected.length > 0
      ? true
      : false;
  }

  onSelectionChangeOptionItemsList(event: MatSelectionListChange) {
    this.showDeleteOptionItemsButton = event.source.selectedOptions.selected.length > 0
      ? true
      : false;
  }


  // #endregion Public Methods


  // #region Private Methods

  private async _loadData(id: string, jobNumber: number) {
    this.loading.indicateJobStart(jobNumber);

    const variables: OptionQueryArgs = {
      id
    };

    this._optionQuery = this._apollo.watchQuery<OptionQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_OPTION}
        ${FULL_FRAGMENT_OPTION_ITEM}
        query option($id: String!) {
          option(id: $id) {
            ...FullFragmentOption
            
            optionItems {
              ...FullFragmentOptionItem
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'cache-and-network'
    });

    this._optionSubscription = this._optionQuery.valueChanges.subscribe({
      next: ({ data, loading }) => {
        this.option = data.option;
        this.loading.indicateJobEnd(jobNumber);
      },
      error: (error) => {
        const message = new CatchError(error).message;
        this._toast.error(message, 'Error');
        this.loading.indicateJobEnd(jobNumber);
      }
    });
  }

  private async _tryUpdateOptionName(value: string, inputElement: HTMLInputElement) {
    if (!this.option) {
      return;
    }

    const trimmedValue = value.trim();

    if (this.optionNames.map(x => x.toLowerCase()).includes(trimmedValue.toLowerCase())) {
      return; // nothing to do here
    }
    // if (this.option.name === trimmedValue) {
    //   return; // nothing to do
    // }

    this.activity = true;
    try {
      const variables: UpdateOptionMutationArgs = {
        id: this.option?.id ?? 'na',
        data: {
          name: value
        }
      };

      await firstValueFrom(this._apollo.mutate<UpdateOptionMutationRoot>({
        mutation: gql`
          ${FULL_FRAGMENT_OPTION}
          mutation updateOption($id: String!, $data: OptionInputUpdate!) {
            updateOption(id: $id, data: $data) {
              ...FullFragmentOption
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only'
      }));

      this._setOptionNames();
      inputElement.focus();
    } catch (error) {
      const message = new CatchError(error).message;
      this._toast.error(message, 'Error');

      // Reset value of the input field
      inputElement.value = this.option.name ?? 'na';
    } finally {
      this.activity = false;
    }
  }

  private _filterOptionNames(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.optionNames.filter(x => x.toLowerCase().includes(filterValue));
  }

  private _setOptionNames() {
    const result = this._apollo.client.readQuery<OptionsQueryRoot>({
      query: gql`
        ${FULL_FRAGMENT_OPTION}
        query Options($tenantId: String!) {
          options(tenantId: $tenantId) {
            ...FullFragmentOption
          }
        }
      `,
      variables: { tenantId: this._selectionService.selectedTenant?.id ?? 'na' }
    });
    if (result) {
      this.optionNames = result.options.filter(x => typeof x.name !== 'undefined').map(x => x.name) as string[];
    }
  }

  private async _deleteOptionItems() {
    const selection = this.optionItemsSelectionList?.selectedOptions.selected.map(x => x.value) as OptionItemOutput[] | undefined;
    if (typeof selection === 'undefined') {
      return;
    }

    const variables: DeleteOptionItemsMutationArgs = {
      optionId: this.option?.id ?? 'na',
      ids: selection.map(x => x.id)
    };

    await firstValueFrom(this._apollo.mutate<DeleteOptionsItemsMutationRoot>({
      mutation: gql`
        ${FULL_FRAGMENT_OPTION_ITEM}
        mutation DeleteOptionsItems($optionId: String!, $ids: [String!]!) {
          deleteOptionItems(optionId: $optionId, ids: $ids) {
            id
            optionItems {
              ...FullFragmentOptionItem
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only'
    }));
  }

  // #endregion Private Methods
}
