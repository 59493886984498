import * as uuid from 'uuid';

interface IIndex {
    [key: number]: boolean;
}

export class Loading {
    get isOngoing() {
        return this._runningJobs.length > 0;
    }

    isJobXOngoing: IIndex = {};


    private _jobs: string[] = [];
    private _runningJobs: string[] = [];
    private _debug: boolean = false;

    constructor(numberOfJobs: number, debugToConsole: boolean | undefined = undefined) {
        if (typeof debugToConsole !== 'undefined') {
            this._debug = debugToConsole;
        }

        for (let i = 0; i < numberOfJobs; i++) {
            this._jobs.push(uuid.v4());
            this.isJobXOngoing[i + 1] = false;
        }
    }

    indicateJobStart(jobNumber: number) {
        this._log(`Indicate START for job: ${jobNumber}`);

        const job = this._jobs[jobNumber];
        if (this._runningJobs.includes(job)) {
            return;
        }

        this._runningJobs.push(job);
        this.isJobXOngoing[jobNumber] = true;
    }

    indicateJobEnd(jobNumber: number) {
        this._log(`Indicate END for job: ${jobNumber}`);
        const job = this._jobs[jobNumber];

        const index = this._runningJobs.indexOf(job);
        if (index === -1) {
            return;
        }

        this._runningJobs.splice(index, 1);
        this.isJobXOngoing[jobNumber] = false;
    }

    private _log(text: string) {
        if (!this._debug) {
            return;
        }

        const now = new Date();
        console.log(`[HelperLoading - ${now.toISOString()}] : ${text}`);
    }
}