import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  AssetDefectsQueryArgs,
  AssetDefectsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_TENANT_DEFECT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantDefect';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { TenantDefectOutput } from 'projects/shared/src/lib/graphql/output/tenantDefectOutput';

export type ViewDefectsDialogData = {
  assetId: string;
};

class SimpleItem {
  constructor(public id: number, public name: string, public subname?: string) {}
}

@Component({
  selector: 'app-view-defects-dialog',
  templateUrl: './view-defects-dialog.component.html',
  styleUrls: ['./view-defects-dialog.component.scss'],
})
export class ViewDefectsDialogComponent implements OnInit {
  loading = false;
  assetDefects: TenantDefectOutput[] = [];
  readonly kinds: Map<number, SimpleItem> = new Map<number, SimpleItem>([
    [2, new SimpleItem(2, 'verified')],
    [1, new SimpleItem(1, 'reported')],
  ]);
  readonly defectOptions = new Map<number, SimpleItem>([
    [3, new SimpleItem(3, 'DEFECT 100', 'nonfunctional')],
    [2, new SimpleItem(2, 'DEFECT', 'partially functional')],
    [1, new SimpleItem(1, 'OK', 'fully functional')],
  ]);

  constructor(
    private _dialogRef: MatDialogRef<ViewDefectsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewDefectsDialogData,
    private _apollo: Apollo,
    private _toastService: DesktopToastService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.#loadData();
  }

  closeDialog() {
    this._dialogRef.close(undefined);
  }

  async #loadData() {
    this.loading = true;
    try {
      const variables: AssetDefectsQueryArgs = {
        assetId: this.data.assetId,
      };

      const result = await firstValueFrom(
        this._apollo.query<AssetDefectsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_DEFECT}
            query AssetDefects($assetId: String!) {
              assetDefects(assetId: $assetId) {
                ...FullFragmentTenantDefect
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.assetDefects = result.data.assetDefects.sortBy((x) => x.timestamp, 'desc');
    } catch (error) {
      this._toastService.error(new CatchError(error).message, 'ERROR');
      this._dialogRef.close(undefined);
    } finally {
      this.loading = false;
    }
  }
}
